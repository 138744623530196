import { CredentialsModel, RefreshTokenModel, UserModel } from 'models';

import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { TokenType } from 'store/auth';
import { wait } from 'utils/wait';
import { Role } from 'config/roles';
import { Env } from 'config/env';
import store from 'store';

class AuthService extends EntityService {
  constructor() {
    super('auth');
  }

  async login(credentials: CredentialsModel) {
    if (Env.REACT_APP_USE_API) {
      return request<TokenType>({
        method: Methods.POST,
        resource: `v1/${this.resource}/login/email`,
        data: credentials,
      });
    }

    return wait({ data: { accessToken: 'test-token', refreshToken: 'test-refresh-token' } });
  }

  async getProfile() {
    if (Env.REACT_APP_USE_API) {
      return request<UserModel>({
        method: Methods.GET,
        resource: `v1/${this.resource}/me`,
      });
    }

    return wait({
      data: {
        id: 0,
        name: 'test user',
        deletedAt: null,
        email: 'test@test.test',
        roles: [{ id: 1, code: 'ADMIN', name: Role.ADMIN }],
      },
    });
  }

  async socialLogin(provider: string, accessToken: string, firebase = false) {
    if (Env.REACT_APP_USE_API) {
      return request<TokenType>({
        method: Methods.POST,
        resource: `${this.resource}/login/${firebase ? 'firebase' : 'social'}`,
        data: {
          provider,
          ...(firebase ? { idToken: accessToken } : { accessToken }),
        },
      });
    }

    return wait({ data: { accessToken: 'test-token', refreshToken: 'test-refresh-token' } });
  }

  async logout() {
    if (Env.REACT_APP_USE_API) {
      const { refreshToken } = store.getState().auth;
      return request({
        method: Methods.POST,
        resource: `v1/${this.resource}/logout`,
        data: { refreshToken },
      });
    }

    return wait({
      data: {
        success: 'ok',
      },
    });
  }

  async refreshToken() {
    if (Env.REACT_APP_USE_API) {
      const { refreshToken } = store.getState().auth;
      return request<RefreshTokenModel>({
        method: Methods.POST,
        resource: `v1/${this.resource}/login/refresh`,
        data: { refreshToken },
      });
    }

    return wait({ data: { accessToken: 'test-token', refreshToken: 'test-token' } });
  }

  async getConsentUrls() {
    return wait({
      data: {
        privacyPolicy:
          'https://www.gstatic.com/policies/privacy/pdf/20200828/k0fdi77r/google_privacy_policy_hu_eu.pdf',
        termsAndConditions:
          'https://www.gstatic.com/policies/terms/pdf/20200331/ba461e2f/google_terms_of_service_hu_eu.pdf',
      },
    });
  }

  async checkConsent() {
    return wait({ data: { hasNewConsent: false } });
  }
}

export const authService = new AuthService();

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, PhotoFailureModel } from 'models';
import { RouteNames } from 'config/routeNames';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import { useExportPhotoFailureList, usePhotoFailureList } from 'features/photoFailureReasons';
import { formatDate } from 'utils/dates';
import { formatAddress, getPageName } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { readingDateValidationSchema } from 'pages/Leo/Logs/NotifyLogs/NotifyLogsPage';

const PhotoFailureReasonsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: photoFailures,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = usePhotoFailureList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportPhotoFailureList(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOKOZPONT', 'FOT'],
  });
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });

  const { data: leoKozpontData, accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: fotData, accessor: fotAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'FOT'),
    'key',
    'value',
    true
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const { activePanel, closePanel, setPanel } = usePanelState();
  const doubleFilterPanel = true;

  const filters: FilterOptionModel[] = [
    {
      name: 'plannedReadingDate',
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
    },
    {
      name: 'readingResultDate',
      abbrName: t('FILTERABBR.ACTUAL_READING_DATE'),
      headerTitle: 'LEO_FOTO_MEGHIUSULASOK_OKA.ACTUAL_READING_DATE',
      component: <LeoDateInterval name="readingResultDate" />,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
    },
    {
      name: 'worker',
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.WORKER_ID',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="LEO_LEOLVASO_STATISZTIKA.WORKER_ID"
          readOnly
        />
      ),
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'NOTIFY_LOGS.BINDING_ID',
      component: (
        <LeoTextField name="bindingId" label={<Trans i18nKey="NOTIFY_LOGS.BINDING_ID" />} />
      ),
      name: 'bindingId',
      panelFieldWidth: 12,
      divider: !doubleFilterPanel,
      abbrName: t('FILTERABBR.WIRING'),
    },
    {
      headerTitle: 'LEO_FOTO_MEGHIUSULASOK_OKA.SERIAL_NUMBER',
      component: (
        <LeoTextField
          name="serialNumber"
          label={<Trans i18nKey="LEO_FOTO_MEGHIUSULASOK_OKA.SERIAL_NUMBER" />}
        />
      ),
      name: 'serialNumber',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.SERIAL_NUMBER'),
    },
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenter"
          data={leoKozpontData}
          label={'LEO_LEOLVASO_STATISZTIKA.READING_CENTER'}
        />
      ),
      name: 'readingCenter',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
    {
      headerTitle: 'LEO_FOTO_MEGHIUSULASOK_OKA.PHOTO_FAILURE_REASON',
      component: (
        <LeoSelectField
          name="photoNotTakenReason"
          data={fotData}
          accessor={fotAccessor}
          label={'LEO_FOTO_MEGHIUSULASOK_OKA.PHOTO_FAILURE_REASON'}
        />
      ),
      name: 'photoNotTakenReason',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.PHOTO_FAILURE_REASON'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_FOTO_MEGHIUSULASOK_OKA),
    validationSchema: readingDateValidationSchema,
    doubleFilterPanel: true,
    isFetching,
  });

  const columns = [
    {
      key: 'readingCenter',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.READING_CENTER" />,
      accessor: 'readingCenter',
      tooltip: (item: PhotoFailureModel) =>
        item.readingCenter && leoKozpontAccessor[item.readingCenter],
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.WORKER" />,
      accessor: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'readingUnitCode',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.READING_UNIT" />,
      accessor: 'readingUnitCode',
      sortable: true,
    },
    {
      key: 'bindingId',
      header: <Trans i18nKey="NOTIFY_LOGS.BINDING_ID" />,
      accessor: 'bindingId',
      sortable: true,
    },
    {
      key: 'serialNumber',
      header: <Trans i18nKey="LEO_FOTO_MEGHIUSULASOK_OKA.SERIAL_NUMBER" />,
      accessor: 'serialNumber',
      sortable: true,
    },
    {
      key: 'plannedReadingDate',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.PLANNED_READING_DATE" />,
      accessor: (item: PhotoFailureModel) => formatDate(item.plannedReadingDate),
      field: 'plannedReadingDate',
      sortable: true,
    },
    {
      key: 'readingResultDate',
      header: <Trans i18nKey="LEO_FOTO_MEGHIUSULASOK_OKA.ACTUAL_READING_DATE" />,
      accessor: (item: PhotoFailureModel) => formatDate(item.readingResultDate),
      field: 'readingResultDate',
      sortable: true,
    },
    {
      key: 'readingResult',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.READING_RESULT" />,
      accessor: 'readingResult',
      sortable: true,
    },
    {
      key: 'photoNotTakenReason',
      header: <Trans i18nKey="LEO_FOTO_MEGHIUSULASOK_OKA.PHOTO_FAILURE_REASON" />,
      accessor: (item: PhotoFailureModel) =>
        item.photoNotTakenReason && getValueSetValues(valueSet, 'FOT', item.photoNotTakenReason),
      field: 'photoNotTakenReason',
      sortable: true,
    },
    {
      key: 'consumptionPlaceName',
      header: <Trans i18nKey="LEO_FOTO_MEGHIUSULASOK_OKA.CP_NAME" />,
      accessor: 'consumptionPlaceName',
      sortable: true,
    },
    {
      key: 'address.city',
      header: <Trans i18nKey="LEO_FOTO_MEGHIUSULASOK_OKA.ADDRESS" />,
      accessor: (item: PhotoFailureModel) => formatAddress(item.address),
      field: 'address.city',
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_FOTO_MEGHIUSULASOK_OKA.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ leoKozpontAccessor, fotAccessor }}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          list={photoFailures?.data || []}
          total={photoFailures?.meta.total}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          columns={columns}
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default PhotoFailureReasonsPage;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { PerformanceMetricModel } from 'models/performanceMetric.model';
import { performanceMetricService } from 'services/performanceMetric.service';

type PerformanceMetricQueryContexts = QueryContextFromKeys<typeof performanceMetricKeys>;

const performanceMetricKeys = {
  all: [{ scope: 'performance-metric' }] as const,
  lists: () => [{ ...performanceMetricKeys.all[0], entity: 'list' }] as const,
  performanceMetricList: (params?: QueryParamModel | null) =>
    [{ ...performanceMetricKeys.lists()[0], params }] as const,
};

const fetchPerformanceMetricList = async ({
  queryKey: [{ params }],
}: PerformanceMetricQueryContexts['performanceMetricList']) =>
  performanceMetricService.getPerformanceMetricList(params);

export const usePerformanceMetricList = (params: QueryParamModel | null = null) =>
  useQuery(performanceMetricKeys.performanceMetricList(params), fetchPerformanceMetricList);

export const useEditPerformanceMetric = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: { data: Partial<PerformanceMetricModel>[] }) =>
      performanceMetricService.editPerformanceMetric(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceMetricKeys.lists() });
      },
    }
  );
};

const exportPerformanceMetricList = async ({
  queryKey: [{ params }],
}: PerformanceMetricQueryContexts['performanceMetricList']) =>
  performanceMetricService.exportPerformanceMetric(params);

export const useExportPerformanceMetricList = (params?: QueryParamModel | null) => {
  return useQuery(
    performanceMetricKeys.performanceMetricList(params),
    exportPerformanceMetricList,
    {
      enabled: false,
    }
  );
};

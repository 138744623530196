import React from 'react';
import { Trans } from 'react-i18next';

import Link from '@mui/material/Link';

import { Dialog, Button } from 'components';
import { authService } from 'services';
import { useQuery } from 'utils/useQuery';
import { usePrevious } from 'utils/usePrevious';

interface ConsentDialogProps {
  onCancel: () => void;
  onClose: () => void;
  isOpen?: boolean;
}

export const ConsentDialog: React.FC<ConsentDialogProps> = ({ onCancel, onClose, isOpen }) => {
  const prevOpen = usePrevious(isOpen);

  const { data, isLoading } = useQuery(async () => {
    if (!prevOpen && isOpen) {
      return authService.getConsentUrls();
    }

    return null;
  }, [prevOpen, isOpen]);

  return (
    <Dialog
      title={<Trans i18nKey="CONSENT.NEW" />}
      open={Boolean(isOpen)}
      onClose={onCancel}
      loading={isLoading}
      actions={
        <>
          <Button variant="outlined" onClick={onCancel}>
            <Trans i18nKey="COMMON.NO" />
          </Button>
          <Button onClick={onClose}>
            <Trans i18nKey="COMMON.YES" />
          </Button>
        </>
      }
    >
      <Trans
        i18nKey="CONSENT.ACCEPT"
        components={[
          <Link
            target="_blank"
            key={data?.privacyPolicy}
            color="initial"
            href={data?.privacyPolicy}
            underline="hover"
          />,
          <Link
            target="_blank"
            key={data?.termsAndConditions}
            color="initial"
            href={data?.termsAndConditions}
            underline="hover"
          />,
        ]}
      />
    </Dialog>
  );
};

import React, { FC, Ref } from 'react';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MuiSnackbarContent, { SnackbarContentProps as MuiSnackbarContentProps } from '@mui/material/SnackbarContent';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import { NotificationType } from 'models';
import { mergeSx } from 'utils/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = {
  success: {
    backgroundColor: 'success.main',
  },
  error: {
    backgroundColor: 'error.dark',
  },
  info: {
    backgroundColor: 'primary.main',
  },
  warning: {
    backgroundColor: 'warning.main',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 1,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
};

interface SnackbarContentProps extends Omit<MuiSnackbarContentProps, 'variant'> {
  message: React.ReactNode;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: (event: React.SyntheticEvent<any>) => void;
  /**
   * The type of the snackbar. This defines the color and icon used.
   */
  variant: NotificationType;
  /**
   * Hides the close button in the top right corner of the snackbar.
   */
  hideCloseButton?: boolean;
}

export const SnackbarContent: FC<SnackbarContentProps> = React.forwardRef(
  ({ className, message, onClose, variant, action, hideCloseButton, sx, ...props }, ref: Ref<any>) => {
    const Icon = variantIcon[variant];

    return (
      <MuiSnackbarContent
        ref={ref}
        className={className}
        sx={mergeSx(styles[variant], sx)}
        message={
          <Box component="span" id="client-snackbar" sx={styles.message}>
            <Icon sx={mergeSx(styles.iconVariant)} />
            {message}
          </Box>
        }
        action={
          <>
            {!hideCloseButton ? (
              <IconButton color="inherit" onClick={onClose} size="large">
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null}
            {action}
          </>
        }
        {...props}
      />
    );
  }
);

SnackbarContent.defaultProps = {
  variant: NotificationType.INFO,
};

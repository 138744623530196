import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from 'components/button/Button';
import { Trans } from 'react-i18next';

export interface DialogFooterButtonsProps {
  cancelText?: string;
  submitText?: string;
  cancelClick: () => any;
  submitClick?: () => void;
  isLoading?: boolean;
  submitDisabled?: boolean;
  buttonsPositionRight?: boolean;
  disableDialogButtons?: boolean;
}

const DialogFooterButtons: FC<DialogFooterButtonsProps> = ({
  cancelText,
  cancelClick,
  submitClick,
  submitText,
  isLoading,
  submitDisabled,
  buttonsPositionRight = false,
  disableDialogButtons = false,
}) => {
  const getButtonWidth = (buttonRight: boolean, dialogButton: boolean) => {
    if (buttonRight) return 3;
    if (dialogButton) return 12;

    return 6;
  };
  return (
    <Grid container>
      {buttonsPositionRight && <Grid item xs={6} paddingRight={1.5}></Grid>}
      <Grid
        item
        xs={getButtonWidth(buttonsPositionRight, disableDialogButtons)}
        paddingRight={disableDialogButtons ? 0 : 1.5}
      >
        <Button key="cancel" variant="outlined" onClick={cancelClick} fullWidth>
          <Trans i18nKey={cancelText || 'COMMON.CANCEL'} />
        </Button>
      </Grid>
      {!disableDialogButtons && (
        <Grid item xs={buttonsPositionRight ? 3 : 6} paddingLeft={1.5}>
          <Button
            onClick={submitClick}
            key="save"
            variant="contained"
            type="submit"
            disabled={submitDisabled}
            loading={isLoading}
            fullWidth
          >
            <Trans i18nKey={submitText || 'COMMON.OK'} />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default DialogFooterButtons;

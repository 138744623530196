import { MutationCache, QueryClientConfig } from '@tanstack/react-query';
import { showNotification } from 'components';
import { NotificationType } from 'models';
import i18n from 'i18n';

export enum REACT_QUERY_OPTIONS {
  SKIP_DEFAULT_SUCCESS_CALLBACK = 'skipDefaultOnSuccess',
  CUSTOM_SUCCESS_MESSAGE = 'customSuccessMessage',
}

// Here you can edit the react-query global defaults
// These can be overriden at hook or call level
export const config: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },

  mutationCache: new MutationCache({
    onSuccess: (data, variables, context, mutation) => {
      const { meta } = mutation.options;
      if (meta?.[REACT_QUERY_OPTIONS.SKIP_DEFAULT_SUCCESS_CALLBACK]) return;
      const successMessage =
        (meta?.[REACT_QUERY_OPTIONS.CUSTOM_SUCCESS_MESSAGE] as string) ??
        'COMMON.OPERATION_SUCCESSFUL';
      showNotification({ content: i18n.t(successMessage), type: NotificationType.SUCCESS });
    },
  }),
};

import React from 'react';
import i18n from 'i18n';

import QuestionMark from '@mui/icons-material/QuestionMark';

import { RouteConfigurationModel } from 'models';
import { NoAccess, NotFound } from 'components';
import { RouteNames } from 'config/routeNames';
import { Role } from 'config/roles';
import MenuCardPage from 'components/menu-card/MenuCardPage/MenuCardPage';
import ComponentsPage from 'pages/Components/ComponentsPage';
import ReadingUnitsPage from 'pages/Leo/ReadingUnits/ReadingUnitsPage';
import ReadingCenterPage from 'pages/Leo/ReadingCenter/ReadingCenterPage';
import ReadingUnitDetailsPage from 'pages/Leo/ReadingUnitDetails/ReadingUnitDetailsPage';
import WorkersPage from 'pages/Kozos/Workers/WorkersPage';
import HealthAndSafetyPage from 'pages/Kozos/Statistics/HealthAndSafety/HealthAndSafetyPage';
import ReadingStatisticsPage from 'pages/Leo/Statistics/ReadingStatistics/ReadingStatisticsPage';
import NotifyLogsPage from 'pages/Leo/Logs/NotifyLogs/NotifyLogsPage';
import EstimationExclusionsPage from 'pages/Leo/Statistics/EstimationExclusions/EstimationExclusionsPage';
import EventLogPage from 'pages/Kozos/Statistics/EventLog/EventLogPage';
import EmergencyCodeStatisticsPage from 'pages/Leo/Statistics/EmergencyCodeStatistics/EmergencyCodeStatisticsPage';
import TimeLogPage from 'pages/Kozos/Statistics/TimeLog/TimeLogPage';
import AreaExclusionsPage from 'pages/Ucse/AreaExclusions/AreaExclusionsPage';
import OpemUsers from 'pages/Kozos/OpemUsers/OpemUsersPage';
import UcseAvailabilityPage from 'pages/Ucse/UcseAvailability/UcseAvailabilityPage';
import ReportCodesByWorkerPage from 'pages/Leo/Monitoring/ReportCodesByWorker/ReportCodesByWorkerPage';
import ReportCodes from 'pages/Leo/Monitoring/ReportCodes/ReportCodesPage';
import ReadingFailureReasonsPage from 'pages/Leo/Monitoring/ReadingFailureReasons/ReadingFailureReasonsPage';
import OrderWithReports from 'pages/Leo/Statistics/OrderWithReports/OrderWithReports';
import UnreadableOrdersPage from 'pages/Leo/Statistics/UnreadableOrders/UnreadableOrdersPage';
import CommunicationLogPage from 'pages/Leo/Logs/CommunicationLog/CommunicationLogPage';
import PhotoFailureReasonsPage from 'pages/Leo/Statistics/PhotoFailureReasons/PhotoFailureReasonsPage';
import ReadingResultStatisticsPage from 'pages/Leo/Monitoring/ReadingResults/ReadingResultStatisticsPage';
import UcseFreeCapacityPage from 'pages/Kozos/Statistics/FreeCapacity/UcseFreeCapacity';
import NotCompletedAfterDeadlinePage from 'pages/Ucse/NotCompletedAfterDeadline/NotCompletedAfterDeadlinePage';
import AreaWorkerPage from 'pages/Ucse/AreaWorker/AreaWorkerPage';
import UcseOrdersPage from 'pages/Ucse/UcseOrders/UcseOrdersPage';
import CompletedAfterDeadlinePage from 'pages/Ucse/CompletedAfterDeadline/CompletedAfterDeadlinePage';
import ImportTaskPage from 'pages/Kozos/Statistics/ImportTask/ImportTaskPage';
import DataModificationsPage from 'pages/Leo/Monitoring/DataModifications/DataModificationsPage';
import WageItemsPage from 'pages/Kozos/PerformanceBasedPay/WageItems/WageItemsPage';
import PerformanceBasedPayPage from 'pages/Kozos/PerformanceBasedPay/PerformanceBasedPay/PerformanceBasedPayPage';
import PerformanceTariffsPage from 'pages/Kozos/PerformanceBasedPay/Tariffs/PerformanceTariffs/PerformanceTariffsPage';
import PerformanceTariffsNotReadablePage from 'pages/Kozos/PerformanceBasedPay/Tariffs/PerformanceTariffsNotReadable/PerformanceTariffsNotReadablePage';
import PerformanceTariffsReportsPage from 'pages/Kozos/PerformanceBasedPay/Tariffs/PerformanceTariffsReports/PerformanceTariffsReportsPage';
import SapUploadManualPage from 'pages/Kozos/SapUploadManual/SapUploadManualPage';
import ValueSetUpdatePage from 'pages/Kozos/ValueSetUpdate/ValueSetUpdatePage';
import SubmittedSapOrdersPage from 'pages/Kozos/SubmittedSapOrders/SubmittedSapOrdersPage';
import AppointmentPage from 'pages/Ucse/Appointment/AppointmentPage';
import ReadingOrdersPage from 'pages/Leo/ReadingOrders/ReadingOrdersPage';
import UploadHolidaysPage from 'pages/Kozos/UploadHolidays/UploadHolidaysPage';
import UploadDocumentsPage from 'pages/Kozos/UploadDocuments/UploadDocumentsPage';
import LoadTasksFromExcelPage from 'pages/Kozos/LoadTasks/LoadTasksFromExcelPage';
import SwapOrderIdsPage from 'pages/Kozos/LoadTasks/SwapOrderIdsPage';
import ReadingTimePage from 'pages/Kozos/PerformanceBasedPay/ReadingTime/ReadingTimePage';

export const routes: RouteConfigurationModel[] = [
  {
    icon: QuestionMark,
    allowedFor: [],
    path: 'no-access',
    link: '/no-access',
    component: <NoAccess />,
    title: () => i18n.t('HOME.PAGE_TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [],
    path: 'not-found',
    link: '/not-found',
    component: <NotFound />,
    title: () => i18n.t('HOME.PAGE_TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
    path: 'components',
    link: '/components',
    component: <ComponentsPage />,
    title: () => i18n.t('COMPONENTS.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY, Role.CUSTOMER_SERVICE],
    path: RouteNames.UCSE,
    link: `/${RouteNames.UCSE}`,
    component: <MenuCardPage parentRoute={RouteNames.UCSE} />,
    title: () => i18n.t('UCSE.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.UCSE_STATISZTIKAK,
    link: `/${RouteNames.UCSE_STATISZTIKAK}`,
    component: <MenuCardPage parentRoute={RouteNames.UCSE_STATISZTIKAK} />,
    title: () => i18n.t('UCSE_STATISZTIKAK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TELJESITMENYBER,
    link: `/${RouteNames.KOZOS_TELJESITMENYBER}`,
    component: <MenuCardPage parentRoute={RouteNames.KOZOS_TELJESITMENYBER} />,
    title: () => i18n.t('UCSE_STATISZTIKAK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_FUNKCIOK,
    link: `/${RouteNames.KOZOS_FUNKCIOK}`,
    component: <MenuCardPage parentRoute={RouteNames.KOZOS_FUNKCIOK} />,
    title: () => i18n.t('KOZOS_FUNKCIOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO,
    link: `/${RouteNames.LEO}`,
    component: <MenuCardPage parentRoute={RouteNames.LEO} />,
    title: () => i18n.t('LEO.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_STATISZTIKAK,
    link: `/${RouteNames.LEO_STATISZTIKAK}`,
    component: <MenuCardPage parentRoute={RouteNames.LEO_STATISZTIKAK} />,
    title: () => i18n.t('LEO_STATISZTIKAK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_MONITORING,
    link: `/${RouteNames.LEO_MONITORING}`,
    component: <MenuCardPage parentRoute={RouteNames.LEO_MONITORING} />,
    title: () => i18n.t('LEO_MONITORING.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_LOGOK,
    link: `/${RouteNames.LEO_LOGOK}`,
    component: <MenuCardPage parentRoute={RouteNames.LEO_LOGOK} />,
    title: () => i18n.t('LEO_LOGOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_LEO_EGYSEGEK,
    link: `/${RouteNames.LEO_LEO_EGYSEGEK}`,
    component: <ReadingUnitsPage />,
    title: () => i18n.t('LEO_LEO_EGYSEGEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_LEO_RENDELESEK,
    link: `/${RouteNames.LEO_LEO_RENDELESEK}`,
    component: <ReadingOrdersPage />,
    title: () => i18n.t('LEO_LEO_RENDELESEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_LEO_EGYSEG_RESZLETEK,
    link: `/${RouteNames.LEO_LEO_EGYSEG_RESZLETEK}`,
    component: <ReadingUnitDetailsPage />,
    title: () => i18n.t('LEO_LEO_EGYSEG_RESZLETEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
    path: RouteNames.LEO_LEO_KOZPONT_ALAPERTEKEK,
    link: `/${RouteNames.LEO_LEO_KOZPONT_ALAPERTEKEK}`,
    component: <ReadingCenterPage />,
    title: () => i18n.t('LEO_LEO_KOZPONT_ALAPERTEKEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_MUNKAVEGZOK,
    link: `/${RouteNames.KOZOS_MUNKAVEGZOK}`,
    component: <WorkersPage />,
    title: () => i18n.t('MUNKAVEGZOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
    path: RouteNames.KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK,
    link: `/${RouteNames.KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK}`,
    component: <SubmittedSapOrdersPage />,
    title: () => i18n.t('KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_OPEM_FELHASZNALOK,
    link: `/${RouteNames.KOZOS_OPEM_FELHASZNALOK}`,
    component: <OpemUsers />,
    title: () => i18n.t('KOZOS_OPEM_FELHASZNALOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TIME_LOG,
    link: `/${RouteNames.KOZOS_TIME_LOG}`,
    component: <TimeLogPage />,
    title: () => i18n.t('KOZOS_MUNKAVEGZOK_NAPLOJA.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.UCSE_RENDELESEK,
    link: `/${RouteNames.UCSE_RENDELESEK}`,
    component: <UcseOrdersPage />,
    title: () => i18n.t('UCSE_RENDELESEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
    path: RouteNames.UCSE_KORZETKIZARASOK,
    link: `/${RouteNames.UCSE_KORZETKIZARASOK}`,
    component: <AreaExclusionsPage />,
    title: () => i18n.t('UCSE_KORZETKIZARASOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.UCSE_TELEPULES_LISTA_KAPACITAS,
    link: `/${RouteNames.UCSE_TELEPULES_LISTA_KAPACITAS}`,
    component: <UcseFreeCapacityPage />,
    title: () => i18n.t('UCSE_TELEPULES_LISTA_KAPACITAS.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_H_S_JELZESEK,
    link: `/${RouteNames.KOZOS_H_S_JELZESEK}`,
    component: <HealthAndSafetyPage />,
    title: () => i18n.t('KOZOS_H_S_JELZESEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_LEOLVASO_STATISZTIKA,
    link: `/${RouteNames.LEO_LEOLVASO_STATISZTIKA}`,
    component: <ReadingStatisticsPage />,
    title: () => i18n.t('LEO_LEOLVASO_STATISZTIKA.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_KOMMUNIKACIOS_LOGOK,
    link: `/${RouteNames.LEO_KOMMUNIKACIOS_LOGOK}`,
    component: <CommunicationLogPage />,
    title: () => i18n.t('LEO_KOMMUNIKACIOS_LOGOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_NEM_LEOLVASHATOSAGI_OKOK,
    link: `/${RouteNames.LEO_NEM_LEOLVASHATOSAGI_OKOK}`,
    component: <ReadingFailureReasonsPage />,
    title: () => i18n.t('LEO_NEM_LEOLVASHATOSAGI_OKOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_ERTESITO_JELZESEK,
    link: `/${RouteNames.LEO_ERTESITO_JELZESEK}`,
    component: <NotifyLogsPage />,
    title: () => i18n.t('NOTIFY_LOGS.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_JELENTESEKET_TARTALMAZO_RENDELESEK,
    link: `/${RouteNames.LEO_JELENTESEKET_TARTALMAZO_RENDELESEK}`,
    component: <OrderWithReports />,
    title: () => i18n.t('LEO_TOBBSZOROS_JELENTES.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_BECSLESBOL_KIZARASOK,
    link: `/${RouteNames.LEO_BECSLESBOL_KIZARASOK}`,
    component: <EstimationExclusionsPage />,
    title: () => i18n.t('LEO_BECSLESBOL_KIZARASOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
    path: RouteNames.KOZOS_MUVELETI_NAPLO,
    link: `/${RouteNames.KOZOS_MUVELETI_NAPLO}`,
    component: <EventLogPage />,
    title: () => i18n.t('KOZOS_MUVELETI_NAPLO.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_MUNKAVEDELMI_JELZESEK,
    link: `/${RouteNames.LEO_MUNKAVEDELMI_JELZESEK}`,
    component: <EmergencyCodeStatisticsPage />,
    title: () => i18n.t('LEO_MUNKAVEDELMI_JELZESEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
    path: RouteNames.UCSE_RENDELKEZESRE_ALLAS,
    link: `/${RouteNames.UCSE_RENDELKEZESRE_ALLAS}`,
    component: <UcseAvailabilityPage />,
    title: () => i18n.t('UCSE_RENDELKEZESRE_ALLAS.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE,
    link: `/${RouteNames.UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE}`,
    component: <AreaWorkerPage />,
    title: () => i18n.t('UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_JELENTES_KODOK_LEOLVASONKENT,
    link: `/${RouteNames.LEO_JELENTES_KODOK_LEOLVASONKENT}`,
    component: <ReportCodesByWorkerPage />,
    title: () => i18n.t('LEO_JELENTES_KODOK_LEOLVASONKENT.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_NEM_LEOLVASHATO_RENDELESEK,
    link: `/${RouteNames.LEO_NEM_LEOLVASHATO_RENDELESEK}`,
    component: <UnreadableOrdersPage />,
    title: () => i18n.t('LEO_NEM_LEOLVASHATO_RENDELESEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_FOTO_MEGHIUSULASOK_OKA,
    link: `/${RouteNames.LEO_FOTO_MEGHIUSULASOK_OKA}`,
    component: <PhotoFailureReasonsPage />,
    title: () => i18n.t('LEO_FOTO_MEGHIUSULASOK_OKA.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_JELENTES_KODOK,
    link: `/${RouteNames.LEO_JELENTES_KODOK}`,
    component: <ReportCodes />,
    title: () => i18n.t('LEO_JELENTES_KODOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_LEOLVASOTTSAG,
    link: `/${RouteNames.LEO_LEOLVASOTTSAG}`,
    component: <ReadingResultStatisticsPage />,
    title: () => i18n.t('LEO_LEOLVASOTTSAG.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK,
    link: `/${RouteNames.UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK}`,
    component: <NotCompletedAfterDeadlinePage />,
    title: () => i18n.t('UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK,
    link: `/${RouteNames.UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK}`,
    component: <CompletedAfterDeadlinePage />,
    title: () => i18n.t('UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_FELADAT_IMPORT,
    link: `/${RouteNames.KOZOS_FELADAT_IMPORT}`,
    component: <ImportTaskPage />,
    title: () => i18n.t('KOZOS_FELADAT_IMPORT.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.LEO_ADATPONTOSITASOK,
    link: `/${RouteNames.LEO_ADATPONTOSITASOK}`,
    component: <DataModificationsPage />,
    title: () => i18n.t('LEO_ADATPONTOSITASOK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TARIFAK,
    link: `/${RouteNames.KOZOS_TARIFAK}`,
    component: <MenuCardPage parentRoute={RouteNames.KOZOS_TARIFAK} />,
    title: () => i18n.t('KOZOS_TARIFAK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TARIFAK_BERELEMEK,
    link: `/${RouteNames.KOZOS_TARIFAK_BERELEMEK}`,
    component: <PerformanceTariffsPage />,
    title: () => i18n.t('KOZOS_TARIFAK_BERELEMEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK,
    link: `/${RouteNames.KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK}`,
    component: <PerformanceTariffsNotReadablePage />,
    title: () => i18n.t('KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TARIFAK_JELENTESEK,
    link: `/${RouteNames.KOZOS_TARIFAK_JELENTESEK}`,
    component: <PerformanceTariffsReportsPage />,
    title: () => i18n.t('KOZOS_TARIFAK_JELENTESEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_BERELEMEK,
    link: `/${RouteNames.KOZOS_BERELEMEK}`,
    component: <WageItemsPage />,
    title: () => i18n.t('KOZOS_BERELEMEK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TELJESITMENYBER_SULYSZAMOK,
    link: `/${RouteNames.KOZOS_TELJESITMENYBER_SULYSZAMOK}`,
    component: <ReadingTimePage />,
    title: () => i18n.t('READINGTIME.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.COORDINATOR, Role.PERFORMANCE_PAY],
    path: RouteNames.KOZOS_TELJESITMENYBEREK,
    link: `/${RouteNames.KOZOS_TELJESITMENYBEREK}`,
    component: <PerformanceBasedPayPage />,
    title: () => i18n.t('KOZOS_TELJESITMENYBEREK.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_SAP_FELTOLTES_MANUALIS,
    link: `/${RouteNames.KOZOS_SAP_FELTOLTES_MANUALIS}`,
    component: <SapUploadManualPage />,
    title: () => i18n.t('KOZOS_SAP_FELTOLTES_MANUALIS.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_ERTEKKESZLET,
    link: `/${RouteNames.KOZOS_ERTEKKESZLET}`,
    component: <ValueSetUpdatePage />,
    title: () => i18n.t('KOZOS_ERTEKKESZLET.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN, Role.CUSTOMER_SERVICE, Role.COORDINATOR],
    path: RouteNames.UCSE_IDOPONTFOGLALAS,
    link: `/${RouteNames.UCSE_IDOPONTFOGLALAS}`,
    component: <AppointmentPage />,
    title: () => i18n.t('UCSE_IDOPONTFOGLALAS.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE,
    link: `/${RouteNames.KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE}`,
    component: <UploadHolidaysPage />,
    title: () => i18n.t('KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_DOKUMENTUM_FELTOLTES,
    link: `/${RouteNames.KOZOS_DOKUMENTUM_FELTOLTES}`,
    component: <UploadDocumentsPage />,
    title: () => i18n.t('KOZOS_DOKUMENTUM_FELTOLTES.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_FELADAT_BETOLTES,
    link: `/${RouteNames.KOZOS_FELADAT_BETOLTES}`,
    component: <MenuCardPage parentRoute={RouteNames.KOZOS_FELADAT_BETOLTES} />,
    title: () => i18n.t('KOZOS_FELADAT_BETOLTES.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_FELADAT_BETOLTES_EXCELBOL,
    link: `/${RouteNames.KOZOS_FELADAT_BETOLTES_EXCELBOL}`,
    component: <LoadTasksFromExcelPage />,
    title: () => i18n.t('KOZOS_FELADAT_BETOLTES_EXCELBOL.TITLE'),
  },
  {
    icon: QuestionMark,
    allowedFor: [Role.ADMIN],
    path: RouteNames.KOZOS_RENDELES_AZONOSITOK_CSEREJE,
    link: `/${RouteNames.KOZOS_RENDELES_AZONOSITOK_CSEREJE}`,
    component: <SwapOrderIdsPage />,
    title: () => i18n.t('KOZOS_RENDELES_AZONOSITOK_CSEREJE.TITLE'),
  },
];

import React, { FC, useContext } from 'react';

import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';

import { LowerCaseSortDirection, SortDirection } from 'utils/arrays';

import { ReactComponent as Sort } from 'assets/icons/Titasz/Sort.svg';
import { ReactComponent as SortAsc } from 'assets/icons/Titasz/SortAsc.svg';
import { ReactComponent as SortDesc } from 'assets/icons/Titasz/SortDesc.svg';

import { TableCell, TableCellProps } from './TableCell';
import { TableContext } from './TableRoot';

interface TableHeaderCellProps extends TableCellProps {
  field?: string | null;
  hideSortIcon?: boolean;
}

const styles = {
  active: {
    '&.Mui-active': {
      color: 'action.active',
    },
  },
  sort: {
    minWidth: 14,
    maxWidth: 14,
  },
};

export const TableHeaderCell: FC<TableHeaderCellProps> = ({
  field,
  children,
  noWrap,
  hideSortIcon = false,
  ...props
}) => {
  const { onSort, orderBy, order } = useContext(TableContext);

  const active = orderBy === field;

  const getOrder = () => {
    if (!active) {
      return SortDirection.ASC;
    }

    if (order === SortDirection.ASC) {
      return SortDirection.DESC;
    }

    return SortDirection.ASC;
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (onSort) {
      const nextOrder = getOrder();

      onSort(event, property, nextOrder);
    }
  };

  const direction = active ? order || undefined : SortDirection.ASC;

  const getUppercaseDirection = (sortDirection?: SortDirection) => {
    if (sortDirection) {
      return sortDirection !== 'ASC' ? LowerCaseSortDirection.DESC : LowerCaseSortDirection.ASC;
    }
    return undefined;
  };

  const getSortIcon = () => {
    if (active) {
      if (order === SortDirection.DESC) {
        return <SortDesc style={styles.sort} />;
      }
      return <SortAsc style={styles.sort} />;
    }
    return <Sort style={styles.sort} />;
  };

  return (
    <TableCell
      sortDirection={order && orderBy === field ? getUppercaseDirection(order) : false}
      noWrap={noWrap !== false}
      {...props}
    >
      {field ? (
        <TableSortLabel
          active={active}
          sx={styles.active}
          hideSortIcon={hideSortIcon}
          direction={getUppercaseDirection(direction)}
          onClick={createSortHandler(field)}
          IconComponent={getSortIcon}
        >
          <Typography variant="cellHeaderSmall">{children}</Typography>
        </TableSortLabel>
      ) : (
        <Typography variant="cellHeaderSmall">{children}</Typography>
      )}
    </TableCell>
  );
};

export default TableHeaderCell;

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { swapOrderIdsService } from 'services/swapOrderIds.service';
import { parse } from 'utils/base64';
import { emptyResponse } from 'features/helpers';
import { REACT_QUERY_OPTIONS } from 'config/reactQueryConfig';

type LoadTasksContext = QueryContextFromKeys<typeof swapOrderIdsKeys>;

export const swapOrderIdsKeys = {
  all: [{ scope: 'swapOrderIds' }] as const,
  lists: () => [{ ...swapOrderIdsKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...swapOrderIdsKeys.lists()[0], params }] as const,
  download: (id: string) => [{ ...swapOrderIdsKeys.all[0], entity: 'download', id }] as const,
  active: () => [{ ...swapOrderIdsKeys.all[0], entity: 'active' }] as const,
  status: (id: string) => [{ ...swapOrderIdsKeys.all[0], entity: 'status', id }] as const,
};

const fetchSwapOrderIdsList = async ({ queryKey: [{ params }] }: LoadTasksContext['list']) => {
  const { status: requiredParam } = parse(params?.filter);
  return requiredParam ? swapOrderIdsService.getTasks(params) : emptyResponse;
};

export const useSwapOrderIdsList = (params?: QueryParamModel | null) =>
  useQuery(swapOrderIdsKeys.list(params), fetchSwapOrderIdsList, {
    enabled: !!params?.filter,
  });

export const useSwapOrderIdsUpload = () => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => swapOrderIdsService.upload(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: swapOrderIdsKeys.lists() });
    },
  });
};

export const useRetrySwapOrderIdUpload = () => {
  const queryClient = useQueryClient();

  return useMutation((jobId: string) => swapOrderIdsService.retryUpload(jobId), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: swapOrderIdsKeys.lists() });
    },
  });
};

export const useDownloadSwapOrderIdResult = () => {
  return useMutation((id: string) => swapOrderIdsService.downloadResult(id), {
    meta: {
      [REACT_QUERY_OPTIONS.SKIP_DEFAULT_SUCCESS_CALLBACK]: true,
    },
  });
};

export const useSwapOrderIdActiveTask = () =>
  useQuery(swapOrderIdsKeys.active(), async () => swapOrderIdsService.getActiveTasks());

const JOB_STATUS_REFETCH_INTERVAL = 3000;

export const useJobStatus = (id: string) => {
  return useQuery(swapOrderIdsKeys.status(id), async () => swapOrderIdsService.getJobStatus(id), {
    enabled: !!id,
    refetchInterval: (data) => (data?.status !== 'completed' ? JOB_STATUS_REFETCH_INTERVAL : false),
  });
};

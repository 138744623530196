import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

import { mergeSx } from 'utils/styles';
import useHideBreadCrumbToolbar from 'utils/useHideBreadCrumbToolbar';

interface PageContentProps {
  className?: string;
  inScroll?: boolean;
  isPanelOpen?: boolean;
  hasPanels?: boolean;
  children?: ReactNode;
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    paddingTop: 8,
    flex: 1,
  },
  hideBreadCrumb: {
    pt: 0,
  },
  contentInScroll: {
    overflowY: 'auto',
    marginLeft: -1,
    marginRight: -1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  contentTransition: (theme: Theme) => ({
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  contentShift: (theme: Theme) => ({
    marginLeft: {
      mobileBreakpoint: `${theme.mixins.panel.width}px`,
    },
  }),
};

export function PageContent({ inScroll, hasPanels, isPanelOpen, children }: PageContentProps) {
  const hideBreadCrumbs = useHideBreadCrumbToolbar();

  return (
    <Box
      sx={mergeSx(
        styles.root,
        styles.content,
        inScroll ? styles.contentInScroll : null,
        hideBreadCrumbs ? styles.hideBreadCrumb : null
        // hasPanels ? styles.contentTransition : null,
        // isPanelOpen && hasPanels ? styles.contentShift : null
      )}
    >
      {children}
    </Box>
  );
}

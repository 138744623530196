import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { cloneDeep, differenceWith, get, isEqual, pickBy, set, uniqWith } from 'lodash';
import { t } from 'i18next';

import SaveIcon from '@mui/icons-material/Save';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  ColumnProps,
  ConfirmDialog,
  LeoSelectField,
  Page,
  PageHeaderAction,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel } from 'models';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { useWorkerListSearch } from 'features/workers';
import { useAreaList } from 'features/area';
import { useValueSetList } from 'features/valueSets';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { TransformedAreaExclusionsData, EntriesModel } from 'models/areaWorkerAvailability.model';
import {
  useAreaWorkerAvailabilityList,
  useEditAreaWorkerAvailability,
  useExportAreaWorkerAvailabilityList,
} from 'features/areaWorkerAvailability';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { selectUserRoles } from 'store/profile';

const days = [
  { id: 1, name: 'TABLE.MONDAY' },
  { id: 2, name: 'TABLE.TUESDAY' },
  { id: 3, name: 'TABLE.WEDNESDAY' },
  { id: 4, name: 'TABLE.THURSDAY' },
  { id: 5, name: 'TABLE.FRIDAY' },
  { id: 6, name: 'TABLE.SATURDAY' },
  { id: 7, name: 'TABLE.SUNDAY' },
];

const slots = [
  { id: 1, name: 'TABLE.MORNING' },
  { id: 2, name: 'TABLE.AFTERNOON' },
  { id: 3, name: 'TABLE.EVENING' },
];

const weekType = [
  { id: 1, name: 'TABLE.EVEN_WEEK' },
  { id: 2, name: 'TABLE.ODD_WEEK' },
];

enum Abbreviations {
  WEEK = 'w',
  TIMESLOT = 't',
  ODD = 'o',
  EVEN = 'e',
}

type indexType = {
  [key: string]: number | boolean | string;
};

const AreaExclusionsPage: FC = () => {
  const [areaListState, setAreaListState] = useState<TransformedAreaExclusionsData[]>([]);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);

  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({});

  const { mutateAsync: editAreaWorkerAvailabilityList } = useEditAreaWorkerAvailability();

  const {
    data: areaWorkerAvailabilityData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useAreaWorkerAvailabilityList(apiParams);
  const { isInitialLoading, isRefetching, refetch } =
    useExportAreaWorkerAvailabilityList(exportParams);

  const { activePanel, closePanel, setPanel } = usePanelState();
  const { data: valueSet } = useValueSetList({ code: ['LEOREGIO'] });
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: areaData } = useAreaList();
  const { data: workerHookData, accessor: workerHookAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const { data: areaHookData, accessor: areaHookAccessor } = useFieldDataAndAccessor(
    areaData,
    'id',
    'area'
  );

  const { data: mbaHookData } = useFieldDataAndAccessor(areaData, 'mba');
  const { data: leoRegioData, accessor: leoRegioAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOREGIO')
  );

  const roles = useSelector(selectUserRoles);

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'UCSE_KORZETKIZARASOK.WORKER_ID',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerHookData}
          accessor={workerHookAccessor}
          label={<Trans i18nKey="UCSE_KORZETKIZARASOK.WORKER_ID" />}
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'UCSE_KORZETKIZARASOK.AREA',
      component: (
        <LeoSelectFieldMulti
          name="area"
          data={areaHookData}
          accessor={areaHookAccessor}
          label={<Trans i18nKey="UCSE_KORZETKIZARASOK.AREA" />}
        />
      ),
      name: 'area',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'UCSE_KORZETKIZARASOK.MBA',
      component: (
        <LeoSelectFieldMulti
          name="mba"
          data={mbaHookData}
          label={<Trans i18nKey="UCSE_KORZETKIZARASOK.MBA" />}
        />
      ),
      name: 'mba',
      divider: true,
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.MBA'),
    },
    {
      headerTitle: 'UCSE_KORZETKIZARASOK.REGION',
      component: (
        <LeoSelectField
          name="region"
          data={leoRegioData}
          accessor={leoRegioAccessor}
          label={'UCSE_KORZETKIZARASOK.REGION'}
        />
      ),
      name: 'region',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.REGION'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.UCSE_KORZETKIZARASOK),
    isFetching,
  });

  const panels = [filterConfig];

  const checkboxClickHandler = <T extends Record<string, any>>(
    listState: T[] | undefined,
    item: T,
    column: ColumnProps<T>
  ) => {
    return (state: T[]) => {
      const modifiedRow = listState?.find(
        (x: T) => x.id === item.id && x.workerId === item.workerId
      );

      const newRow = cloneDeep(modifiedRow);
      const newItem = !get(modifiedRow, column.accessor as string);
      set(newRow as T, column.accessor as string, newItem);

      const filteredRows = state.filter((stateItem: T) => {
        return (
          stateItem.id !== modifiedRow?.id ||
          (stateItem.id === modifiedRow?.id && stateItem.workerId !== modifiedRow?.workerId)
        );
      });

      return [...filteredRows, newRow];
    };
  };

  const checkboxFieldHandler = <T extends Record<string, any>>(
    listState: TransformedAreaExclusionsData[],
    item: T,
    column: ColumnProps<T>
  ) =>
    get(
      listState?.find((x) => {
        return x.id === item.id && x.workerId === item.workerId;
      }),
      column.accessor as string
    )
      ? 1
      : 0;

  const getCheckboxKeySchema = (day: number, slot: number) =>
    `${Abbreviations.WEEK}${day}${Abbreviations.TIMESLOT}${slot}`;

  const checkboxColumns: ColumnProps<TransformedAreaExclusionsData>[] = days.flatMap((day) =>
    slots.flatMap((slot) =>
      weekType.flatMap((item) => {
        const transformedEntry = `${getCheckboxKeySchema(day.id, slot.id)}${
          item.id % 2 ? Abbreviations.EVEN : Abbreviations.ODD
        }`;
        return {
          key: transformedEntry,
          accessor: transformedEntry,
          customCheckboxCell: true,
          noWrap: false,
          checkboxClickHandler,
          checkboxFieldHandler,
        };
      })
    )
  );

  const headerColumns: ColumnProps<TransformedAreaExclusionsData>[] = [
    {
      key: 'area.area',
      header: <Trans i18nKey="UCSE_KORZETKIZARASOK.AREA" />,
      accessor: (item) => item.area,
      field: 'area.area',
      sortable: true,
      noWrap: true,
    },
    {
      key: 'area.mba',
      header: <Trans i18nKey="UCSE_KORZETKIZARASOK.MBA" />,
      accessor: (item) => item.mba,
      field: 'area.mba',
      sortable: true,
      noWrap: true,
    },
    {
      key: 'area.region',
      header: <Trans i18nKey="UCSE_KORZETKIZARASOK.REGION" />,
      accessor: (item) => item.region && getValueSetValues(valueSet, 'LEOREGIO', item.region),
      field: 'area.region',
      sortable: true,
      noWrap: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="UCSE_KORZETKIZARASOK.WORKER" />,
      accessor: (item) => item.sapId,
      field: 'worker.sapId',
      sortable: true,
      noWrap: true,
    },
    ...checkboxColumns,
  ];

  const transformData = useMemo(() => {
    const result =
      areaWorkerAvailabilityData?.data.map((item) => {
        const baseData = {
          areaWorkerId: item.id,
          id: item.area.id,
          area: item.area.area,
          mba: item.area.mba,
          region: item.area.region,
          sapId: item.worker.sapId,
          workerId: item.worker.id,
        };

        const decodeEntries = () =>
          item.entries.forEach((entry) => {
            const cellGroup = `${getCheckboxKeySchema(entry.weekday, +entry.timeslot)}`;

            (baseData as indexType)[`${cellGroup}${Abbreviations.ODD}`] = !!entry.odd;
            (baseData as indexType)[`${cellGroup}${Abbreviations.EVEN}`] = !!entry.even;
          });

        decodeEntries();

        return baseData;
      }) ?? [];

    return result as TransformedAreaExclusionsData[];
  }, [areaWorkerAvailabilityData]);

  const difference = differenceWith(areaListState, transformData, isEqual);

  const sendAreaExclusionsList = useCallback(() => {
    const initialState = transformData;
    const encodeEntries = (
      item: TransformedAreaExclusionsData,
      entries: indexType[]
    ): EntriesModel[] => {
      return Object.keys(entries).map((entry) => {
        const weekIndex = entry.indexOf(Abbreviations.WEEK);
        const timeslotIndex = entry.indexOf(Abbreviations.TIMESLOT);
        const slotGroup = entry.substring(0, timeslotIndex + 2);

        return {
          weekday: +entry.substring(weekIndex + 1, timeslotIndex),
          timeslot: entry.substring(timeslotIndex + 1, timeslotIndex + 2),
          odd: get(item, `${slotGroup}${Abbreviations.ODD}`),
          even: get(item, `${slotGroup}${Abbreviations.EVEN}`),
        };
      });
    };

    const data = difference.map((item) => {
      const originalItem = initialState.find(
        (initialItem) => initialItem.id === item.id && initialItem.workerId === item.workerId
      );
      const changedKey = pickBy(
        item,
        (v, k) => !isEqual((originalItem as { [key: string]: any })[k], v)
      );

      return {
        areaWorkerId: item.areaWorkerId,
        entries: uniqWith(encodeEntries(item, changedKey as indexType[]), isEqual),
      };
    });

    if (data.length) {
      editAreaWorkerAvailabilityList(data);
    }
  }, [editAreaWorkerAvailabilityList, transformData, difference]);

  const headerActions = useMemo<PageHeaderAction[]>(() => {
    const onSaveClickHandler = () => {
      setSaveDialog(true);
    };

    const onCancelClickHandler = () => {
      setConfirmDialog(true);
    };

    const disableStatus = () => {
      return !isTouched || !difference.length;
    };

    return [
      {
        name: 'save',
        label: 'COMMON.SAVE',
        icon: <SaveIcon fill="currentColor" />,
        onClick: onSaveClickHandler,
        isBulkAction: disableStatus(),
      },
      {
        name: 'cancel',
        label: 'COMMON.DISCARD_CHANGES',
        icon: <HighlightOffIcon fill="currentColor" />,
        onClick: onCancelClickHandler,
        isBulkAction: disableStatus(),
        visible: getFunctionRole(FunctionRoleName.AREA_EXCLUSIONS_PAGE_ACTIONS, roles),
      },
      {
        name: 'export',
        label: 'COMMON.EXPORT',
        icon: <Export fill="currentColor" />,
        onClick: () => refetch(),
        isLoading: isInitialLoading || isRefetching,
        disabledIf: !exportParams?.filter,
        visible: getFunctionRole(FunctionRoleName.AREA_EXCLUSIONS_PAGE_ACTIONS, roles),
      },
    ];
  }, [isTouched, isInitialLoading, isRefetching, refetch, exportParams?.filter, roles, difference]);

  useEffect(() => {
    setAreaListState(transformData);
    setIsTouched(false);
  }, [transformData]);

  const onSaveDialogHandler = () => {
    sendAreaExclusionsList();
    setIsTouched(false);
  };

  const onCancelDialogHandler = () => {
    setAreaListState(transformData);
    setIsTouched(false);
  };

  return (
    <Page
      title={<Trans i18nKey="UCSE_KORZETKIZARASOK.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
      panels={panels}
    >
      <Table
        filterDetailsData={{ leoRegioAccessor, areaData }}
        listState={areaListState}
        setListState={setAreaListState}
        checkBoxTable={true}
        checkBoxHeaders={days}
        checkBoxSubHeaders={slots}
        enableCheckbox={false}
        panels={panels}
        setPanel={setPanel}
        activePanel={activePanel}
        timestamp={dataUpdatedAt}
        list={transformData}
        total={areaWorkerAvailabilityData?.meta.total}
        loading={isLoading}
        params={params}
        onSort={onSort}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        pageHeaderActions={headerActions}
        columns={headerColumns}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        abbreviations={Abbreviations}
        setIsTouched={setIsTouched}
        onLoadQuery={onLoadQuery}
        checkboxKey="areaWorkerId"
      />
      <ConfirmDialog
        confirmTitle={<Trans i18nKey="COMMON.OK" />}
        onSubmit={onCancelDialogHandler}
        onClose={() => setConfirmDialog(false)}
        open={confirmDialog}
      >
        <Trans i18nKey="COMMON.EDIT_QUESTION" />
      </ConfirmDialog>
      <ConfirmDialog
        confirmTitle={<Trans i18nKey="COMMON.OK" />}
        cancelTitle={<Trans i18nKey="COMMON.CANCEL" />}
        onSubmit={onSaveDialogHandler}
        onClose={() => setSaveDialog(false)}
        open={saveDialog}
      >
        <Trans i18nKey="COMMON.SAVE_QUESTION" />
      </ConfirmDialog>
    </Page>
  );
};

export default AreaExclusionsPage;

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type UnreadableOrderQueryContexts = QueryContextFromKeys<typeof unreadableOrderKeys>;

export const unreadableOrderKeys = {
  all: [{ scope: 'unreadable-order' }] as const,
  lists: () => [{ ...unreadableOrderKeys.all[0], entity: 'list' }] as const,
  unreadableOrderList: (params?: QueryParamModel | null) =>
    [{ ...unreadableOrderKeys.lists()[0], params }] as const,
};

const fetchUnreadableOrderList = async ({
  queryKey: [{ params }],
}: UnreadableOrderQueryContexts['unreadableOrderList']) =>
  statisticsService.getUnreadableOrderList(params);

const exportUnreadableOrderList = async ({
  queryKey: [{ params }],
}: UnreadableOrderQueryContexts['unreadableOrderList']) =>
  statisticsService.exportUnreadableOrderList(params);

export const useUnreadableOrderList = (params?: QueryParamModel | null) => {
  return useQuery(unreadableOrderKeys.unreadableOrderList(params), fetchUnreadableOrderList, {
    enabled: !!params?.filter,
  });
};

export const useExportUnreadableOrders = (params?: QueryParamModel | null) => {
  return useQuery(unreadableOrderKeys.unreadableOrderList(params), exportUnreadableOrderList, {
    enabled: false,
  });
};

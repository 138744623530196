import { AxiosResponseHeaders } from 'axios';
import get from 'lodash/get';

export const exportFile = async (data?: {
  response: BlobPart;
  responseHeaders: AxiosResponseHeaders;
}) => {
  if (data) {
    const blob = new Blob([data.response]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileNameHeaderString = get(data.responseHeaders, 'content-disposition');
    const filename = fileNameHeaderString.slice(
      fileNameHeaderString.indexOf('"') + 1,
      fileNameHeaderString.lastIndexOf('"')
    );
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }
};

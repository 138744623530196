import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button } from 'components';

const Buttons: FC = () => {
  return (
    <Box>
      <Typography variant="h6">Button</Typography>
      <div>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="contained">Contained</Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="contained" color="success">
            Success
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="contained" color="warning">
            Warning
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="contained" color="error">
            Error
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button loading>Loading Contained</Button>
        </Box>
        <Box mb={2} display="inline-block">
          <Button disabled>Disabled Contained</Button>
        </Box>
      </div>
      <div>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="outlined">Outlined</Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="outlined" color="secondary">
            Secondary
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="outlined" color="success">
            Success
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="outlined" color="warning">
            Warning
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="outlined" color="error">
            Error
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="outlined" loading>
            Loading Outlined
          </Button>
        </Box>
        <Box mb={2} display="inline-block">
          <Button disabled variant="outlined">
            Disabled Outlined
          </Button>
        </Box>
      </div>
      <div>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="text">Text</Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="text" color="secondary">
            Secondary
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="text" color="success">
            Success
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="text" color="warning">
            Warning
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="text" color="error">
            Error
          </Button>
        </Box>
        <Box mr={2} mb={2} display="inline-block">
          <Button variant="text" loading>
            Loading Text
          </Button>
        </Box>
        <Box mb={2} display="inline-block">
          <Button disabled variant="text">
            Disabled Text
          </Button>
        </Box>
      </div>
      <Box mb={2}>
        <Button fullWidth>Full width button</Button>
      </Box>

      <Box mb={2}>
        <Typography variant="h6">IconButton</Typography>
        <IconButton size="large">
          <DeleteIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Buttons;

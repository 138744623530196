import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { Dialog, DialogProps } from 'components';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';

export enum TransferErrorType {
  ESTIMATION_DATE_PASSED,
  REVOKED_BY_SAP,
}

export interface TransferErrorDialogProps extends DialogProps {
  type?: TransferErrorType;
}

const messageMap = {
  [TransferErrorType.ESTIMATION_DATE_PASSED]:
    'LEO_LEO_EGYSEGEK.ESTIMATION_DATE_PASSED_TRANSFER_ERROR',
  [TransferErrorType.REVOKED_BY_SAP]: 'LEO_LEO_EGYSEGEK.REVOKED_BY_SAP_TRANSFER_ERROR',
};

const TransferErrorDialog: FC<TransferErrorDialogProps> = ({
  open,
  onClose,
  type = TransferErrorType.ESTIMATION_DATE_PASSED,
  ...props
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      title={<Trans i18nKey={'LEO_LEO_EGYSEGEK.TRANSFER_TASK_MODAL_TITLE'} />}
      open={open}
      onClose={handleClose}
      actions={
        <DialogFooterButtons
          disableDialogButtons={true}
          cancelText="Rendben"
          cancelClick={handleClose}
        />
      }
      {...props}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="body2">
            <Trans i18nKey={messageMap[type]} />
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TransferErrorDialog;

import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import omit from 'lodash/omit';

import { PerformanceMetricUnitPriceModel } from 'models';
import { BaseEditorDialogProps, EditorDialog, LeoDatePicker } from 'components';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import {
  useEditPerformanceMetricUnitPrice,
  usePutPerformanceMetricUnitPrice,
} from 'features/performanceMetricUnitPrice';
import { replaceDecimal } from 'utils/format';

export interface WorkerEditorDialogProps
  extends BaseEditorDialogProps<PerformanceMetricUnitPriceModel> {
  wageItemsData: string[];
  wageItemsAccessor: Record<string | number, string>;
}

const TariffsEditorDialog: FC<WorkerEditorDialogProps> = ({
  wageItemsData,
  wageItemsAccessor,
  selected,
  ...props
}) => {
  const { t } = useTranslation();
  const isEdit = Boolean(selected && selected.id);
  const { mutateAsync: createTariffItem } = useEditPerformanceMetricUnitPrice();
  const { mutateAsync: editTariffItem } = usePutPerformanceMetricUnitPrice();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const onSubmit = async (values: Partial<PerformanceMetricUnitPriceModel>) => {
    if (values?.id) {
      await editTariffItem({
        id: values.id,
        data: omit(
          {
            ...values,
            unitPriceForPower: values.unitPriceForPower && replaceDecimal(values.unitPriceForPower),
            unitPriceForGas: values.unitPriceForGas && replaceDecimal(values.unitPriceForGas),
          },
          'performanceMetric'
        ),
      });
    } else if (values) {
      await createTariffItem({
        data: {
          ...values,
          unitPriceForPower: values.unitPriceForPower && replaceDecimal(values.unitPriceForPower),
          unitPriceForGas: values.unitPriceForGas && replaceDecimal(values.unitPriceForGas),
        },
      });
    }
  };

  const initVal = isEdit
    ? selected
    : {
        performanceMetricId: undefined,
        validFrom: undefined,
        unitPriceForPower: '0',
        unitPriceForGas: '0',
      };

  const dialogTitle = isEdit
    ? `${t('KOZOS_TARIFAK_BERELEMEK.EDIT')} - ${selected?.value}`
    : t('KOZOS_TARIFAK_BERELEMEK.NEW');

  return (
    <EditorDialog
      initialValues={{ ...initVal }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        performanceMetricId: Yup.string().nullable().required(),
        validFrom: Yup.date().nullable().required(),
        unitPriceForPower: Yup.number()
          .transform((_, value) => +replaceDecimal(value))
          .required()
          .min(0)
          .max(99999),
        unitPriceForGas: Yup.number()
          .transform((_, value) => +replaceDecimal(value))
          .required()
          .min(0)
          .max(99999),
      })}
      title={dialogTitle}
      {...props}
    >
      {!isEdit && (
        <LeoSelectField
          name="performanceMetricId"
          data={wageItemsData}
          accessor={wageItemsAccessor}
          label="KOZOS_TARIFAK_BERELEMEK.VALUE"
        />
      )}
      <LeoDatePicker
        name="validFrom"
        label={<Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.VALID_FROM" />}
        isYearAndMonthOnly
      />
      <LeoTextField
        name="unitPriceForPower"
        label={<Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.UNIT_PRICE_FOR_POWER" />}
      />
      <LeoTextField
        name="unitPriceForGas"
        label={<Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.UNIT_PRICE_FOR_GAS" />}
      />
    </EditorDialog>
  );
};

export default TariffsEditorDialog;

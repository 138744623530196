import { EntityService } from 'utils/entityService';
import {
  ActiveJobModel,
  JobModel,
  ListResponseModel,
  QueryParamModel,
  UploadedJobModel,
} from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { exportFile } from 'utils/exportFile';

class LoadTaskFromExcelService extends EntityService {
  constructor() {
    super('admin/order-uploader');
  }

  async getTasks(params?: QueryParamModel | null) {
    return request<ListResponseModel<JobModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/jobs`,
      params,
    });
  }

  async upload(data: any) {
    const formData = new FormData();
    formData.append('file', data as File | string);

    return request<UploadedJobModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  }

  async retryUpload(id: string) {
    return request<JobModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}/jobs/${id}/retry`,
    });
  }

  async getJobStatus(id: string) {
    return request<Partial<JobModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/jobs/${id}`,
    });
  }

  async deleteTask(id: string) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/jobs/${id}`,
    });
  }

  async downloadResult(id: string) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}/results`,
    });
    exportFile(response);
    return response;
  }

  async getActiveTasks() {
    return request<ActiveJobModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/active`,
    });
  }
}

export const loadTaskFromExcelService = new LoadTaskFromExcelService();

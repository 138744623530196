import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18n from 'i18n';

import { readingUnitService } from 'services';
import { NotificationType, QueryContextFromKeys, QueryParamModel, ReadingUnitModel } from 'models';
import { ExcludeFromEstimationFormProps } from 'pages/Leo/ReadingUnits/ExcludeFromEstimationDialog';
import { TransferTaskFormProps } from 'pages/Leo/ReadingUnits/TransferTaskDialog';
import { isAxiosError } from 'utils/errors';
import { Status } from 'utils/request';
import { showNotification } from 'components';

type ReadingUnitQueryContexts = QueryContextFromKeys<typeof readingUnitsKeys>;

enum Errors {
  ASSIGN = 'THERE_IS_NO_WORKER_ASSIGNED_TO_THE_READING_UNIT',
}

export const readingUnitsKeys = {
  all: [{ scope: 'readingUnits' }] as const,
  lists: () => [{ ...readingUnitsKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...readingUnitsKeys.lists()[0], params }] as const,
  orderList: (id: number, params?: QueryParamModel | null) =>
    [{ ...readingUnitsKeys.lists()[0], id, params }] as const,
  order: (id: ID) => [{ ...readingUnitsKeys.lists()[0], id }] as const,
  readingFailureReasons: (ids: ID[]) => [{ ...readingUnitsKeys.lists()[0], ids }] as const,
  orderDetails: (id: string, orderId?: string) =>
    [{ ...readingUnitsKeys.lists()[0], id, orderId }] as const,
  exportList: (params?: QueryParamModel | null) =>
    [{ ...readingUnitsKeys.lists()[0], params }] as const,
  exportDetails: (id: number, params?: QueryParamModel | null) =>
    [{ ...readingUnitsKeys.lists()[0], id, params }] as const,
  orderDelete: (id: string[]) => [{ ...readingUnitsKeys.lists()[0], id }] as const,
};

const fetchReadingUnitList = async ({ queryKey: [{ params }] }: ReadingUnitQueryContexts['list']) =>
  readingUnitService.getReadingUnitList(params);

const fetchReadingUnit = async ({ queryKey: [{ id }] }: ReadingUnitQueryContexts['order']) =>
  readingUnitService.getReadingUnit(id);

const fetchReadingFailureReasons = async ({
  queryKey: [{ ids }],
}: ReadingUnitQueryContexts['readingFailureReasons']) =>
  readingUnitService.getReadingUnitReadingFailureReasons(ids);

const fetchReadingUnitOrderDetails = async ({
  queryKey: [{ id, orderId }],
}: ReadingUnitQueryContexts['orderDetails']) =>
  readingUnitService.getReadingUnitOrderDetails(id, orderId);

const exportReadingUnits = async ({
  queryKey: [{ params }],
}: ReadingUnitQueryContexts['exportList']) => readingUnitService.exportReadingUnits(params);

const fetchReadingUnitOrderList = async ({
  queryKey: [{ id, params }],
}: ReadingUnitQueryContexts['orderList']) => readingUnitService.getReadingUnitOrderList(id, params);

const exportReadingUnitOrders = async ({
  queryKey: [{ id, params }],
}: ReadingUnitQueryContexts['exportDetails']) =>
  readingUnitService.exportReadingUnitOrders(id, params);

export const useSaveTransferTask = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ selected, data }: { selected: number[]; data: TransferTaskFormProps }) =>
      readingUnitService.saveTransferTask(selected, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingUnitsKeys.lists() });
      },
    }
  );
};

export const useSaveTransferConsumptionPlace = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      selected,
      data,
      readingUnitId,
    }: {
      selected: number[];
      readingUnitId: string;
      data: TransferTaskFormProps;
    }) => readingUnitService.saveTransferConsumptionPlace(selected, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingUnitsKeys.lists() });
      },
    }
  );
};

export const useSaveExcludeFromEstimation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ selected, data }: { selected: number[]; data: ExcludeFromEstimationFormProps }) =>
      readingUnitService.excludeFromEstimation(selected, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingUnitsKeys.lists() });
      },
    }
  );
};

export const useEditReadingUnit = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<ReadingUnitModel> }) =>
      readingUnitService.editReadingUnit(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingUnitsKeys.lists() });
      },
      onError: (e) => {
        if (
          isAxiosError(e) &&
          e.response?.status === 400 &&
          e.response.data.message === Errors.ASSIGN
        ) {
          showNotification({
            content: i18n.t(`ERRORS.${Errors.ASSIGN}`),
            type: NotificationType.ERROR,
          });
        }
      },
    }
  );
};

export const useReadingUnitReadingFailureReasons = (ids: ID[]) => {
  return useQuery(readingUnitsKeys.readingFailureReasons(ids), fetchReadingFailureReasons, {
    enabled: !!ids.length,
  });
};

export const useDeleteReadingUnitOrder = (closeDialog: () => void) => {
  const queryClient = useQueryClient();

  return useMutation((id: string[]) => readingUnitService.deleteReadingUnit(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: readingUnitsKeys.lists() });
    },
    onError: (e) => {
      if (isAxiosError(e) && e.response?.status === Status.FORBIDDEN) {
        showNotification({
          content: i18n.t(`ERRORS.NOT_RIGHT`),
          type: NotificationType.ERROR,
        });
      }
      closeDialog();
    },
  });
};

export const useReadingUnitList = (params?: QueryParamModel | null) => {
  return useQuery(readingUnitsKeys.list(params), fetchReadingUnitList, {
    enabled: !!params?.filter,
  });
};

export const useReadingUnit = (id: ID) => {
  return useQuery(readingUnitsKeys.order(id), fetchReadingUnit);
};

export const useReadingUnitWorkers = (id: number, enabled: boolean) => {
  return useQuery({
    queryKey: ['reading-unit-workers', id],
    queryFn: () => readingUnitService.getReadingUnitWorkers(id),
    enabled,
    cacheTime: 300000,
    staleTime: 300000,
  });
};

export const useExportReadingUnits = (params?: QueryParamModel | null) => {
  return useQuery(readingUnitsKeys.exportList(params), exportReadingUnits, { enabled: false });
};

export const useReadingUnitOrderList = (id: number, params?: QueryParamModel | null) => {
  return useQuery(readingUnitsKeys.orderList(id, params), fetchReadingUnitOrderList);
};

export const useExportReadingUnitOrders = (id: number, params?: QueryParamModel | null) => {
  return useQuery(readingUnitsKeys.exportDetails(id, params), exportReadingUnitOrders, {
    enabled: false,
  });
};

export const useReadingUnitOrderDetails = (id: string, orderId?: string) => {
  return useQuery(readingUnitsKeys.orderDetails(id, orderId), fetchReadingUnitOrderDetails);
};

export const useReadingUnitCacheMetadata = () => {
  return useQuery({
    queryKey: ['reading-unit-cache-metadata'],
    queryFn: () => readingUnitService.getCacheMetadata(),
    refetchInterval: () => 10_000,
  });
};

import React, { FC, ReactNode } from 'react';
import { FieldProps } from 'formik';

import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { ConditionalWrapper, ErrorMessage, useTestId } from 'components';
import { inErrorState } from 'utils/form';
import { Env } from 'config/env';
import { mergeSx } from 'utils/styles';
import { neutralVariant } from 'theme/colors';

const styles = {
  fullWidth: {
    width: '100%',
  },
  dayPart: {
    display: 'flex',
    alignItems: 'center',
  },
  dayPartWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleText: {
    '&>div:first-of-type': { marginTop: 0, marginBottom: 0 },
    paddingTop: 2.2,
    width: '64px',
    paddingRight: 2,
  },
  pieceContainer: {
    userSelect: 'none',
    whiteSpace: 'nowrap',
    borderRadius: '4px 0 0 4px',
    alignItems: 'center',
    display: 'flex',
    height: '48px',
    borderLeft: `1px solid ${neutralVariant[200]}`,
    borderTop: `1px solid ${neutralVariant[200]}`,
    borderBottom: `1px solid ${neutralVariant[200]}`,
    marginTop: 2.2,
    backgroundColor: `${neutralVariant[100]}`,
    paddingLeft: 1,
    paddingRight: 3.7,
  },
  pieceText: {
    backgroundColor: `${neutralVariant[200]}`,
  },
  dayPartFieldSet: {
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      paddingX: '12px',
    },
  },
};

export interface StyleProp {
  [key: string]: string | number;
}

interface TextFieldProps extends FieldProps {
  /**
   * If set to ```true```, an error message is going to be visible under the field, in case of an error.
   */
  showError?: boolean;
  /**
   * It prevents the user from changing the value of the field (not from interacting with the field).
   */
  readOnly?: boolean;
  /**
   * For testing purposes, you can set a testid for the text field.
   */
  customTestId?: string;
  /**
   * The label content.
   */
  label?: ReactNode;
  /**
   * The variant to use.
   */
  variant?: MuiTextFieldProps['variant'];
  /**
   * The daypart to use.
   */
  dayPart?: boolean;
  /**
   * Extra input props.
   */
  extraInputProps?: StyleProp;
  /**
   * 'true' if call in select field.
   */
  selectFieldHelper?: boolean;
}

export const TextField: FC<TextFieldProps & MuiTextFieldProps> = ({
  field,
  form,
  value,
  variant,
  showError,
  readOnly,
  margin,
  autoComplete,
  type,
  dayPart = false,
  customTestId,
  selectFieldHelper = false,
  extraInputProps = {},
  ...props
}) => {
  const { testIdObject } = useTestId({
    name: field?.name,
    testId: customTestId,
    componentName: 'TextField',
  });

  const getWrapperChildren = (children: ReactNode) => <Box sx={styles.titleText}>{children}</Box>;

  const getErrorMessageBox = (children: ReactNode) => (
    <Box sx={styles.dayPartWrapper}>
      <Box sx={{ display: 'flex' }}>{children}</Box>
      <ErrorMessage variant={variant} show={showError} form={form} field={field} sx={{ pt: 0.2 }} />
    </Box>
  );

  return (
    <Box sx={mergeSx(props.fullWidth ? styles.fullWidth : null, dayPart ? styles.dayPart : null)}>
      <ConditionalWrapper condition={dayPart} wrapper={(children) => getErrorMessageBox(children)}>
        {dayPart && (
          <Typography sx={styles.pieceContainer} variant="body3">
            <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.JOB_QUANTITY" />
          </Typography>
        )}
        <ConditionalWrapper
          condition={dayPart}
          wrapper={(children) => getWrapperChildren(children)}
        >
          <MuiTextField
            {...field}
            {...props}
            type={autoComplete === 'off' ? 'search' : type}
            margin={margin}
            sx={mergeSx(dayPart ? styles.dayPartFieldSet : null)}
            value={field?.value || value || ''}
            variant={variant}
            error={inErrorState(form, field, selectFieldHelper)}
            autoComplete={autoComplete}
            InputProps={{
              ...(props.InputProps || {}),
              readOnly: (props.InputProps || {}).readOnly || readOnly || false,
              ...testIdObject,
            }}
            inputProps={{ ...props.inputProps, ...extraInputProps }}
            InputLabelProps={{ ...(props.InputLabelProps || {}) }}
          />
          {!dayPart && (
            <ErrorMessage
              variant={variant}
              show={showError}
              form={form}
              field={field}
              selectFieldHelper={selectFieldHelper}
            />
          )}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </Box>
  );
};

TextField.defaultProps = {
  showError: true,
  fullWidth: true,
  variant: Env.DEFAULT_INPUT_VARIANT,
  margin: Env.DEFAULT_INPUT_MARGIN,
};

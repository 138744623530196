import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import * as Yup from 'yup';
import { Field } from 'formik';
import { Box, Typography } from '@mui/material';

import { BaseEditorDialogProps, EditorDialog, TextField } from 'components';
import { SmOrderAvailabilityPatchModel, SmOrderAvailabilityModel } from 'models';
import { usePatchUcseAvailabilty, usePostUcseAvailabilty } from 'features/ucseAvailability';
import { omitBy, toNumber } from 'lodash';

export interface UcseAvailabilityEditDialogProps
  extends BaseEditorDialogProps<SmOrderAvailabilityModel> {}

const UcseAvailabilityEditDialog: FC<UcseAvailabilityEditDialogProps> = ({
  selected,
  ...props
}) => {
  const { mutateAsync: editSmOrderAvailability } = usePatchUcseAvailabilty();
  const { mutateAsync: createSmOrderAvailability } = usePostUcseAvailabilty();

  const initialValues = {
    comment: selected?.capacity?.comment || '',
    capacity: selected?.capacity?.capacity === 0 ? '0' : selected?.capacity?.capacity,
  };

  const smOrderAvailabilityEditSchema = Yup.object().shape({
    comment: Yup.string().min(3).max(60).nullable(),
    capacity: Yup.number().min(0).max(255).nullable(),
  });

  const onSubmit = async (values?: SmOrderAvailabilityPatchModel) => {
    if (selected?.capacity) {
      await editSmOrderAvailability({
        id: selected?.capacity?.id,
        data: { ...values, capacity: toNumber(values?.capacity) },
      });
    } else {
      await createSmOrderAvailability({
        data: omitBy(
          {
            date: selected?.date,
            workerId: selected?.workerId,
            timeslot: selected?.timeslot,
            comment: values?.comment,
            capacity: values?.capacity === undefined ? 0 : toNumber(values?.capacity),
          },
          (v) => v === ''
        ),
      });
    }
  };

  return (
    <EditorDialog
      submitDisabled
      submitText="COMMON.SUBMIT"
      buttonsPositionRight
      initialValues={{ ...initialValues }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={smOrderAvailabilityEditSchema}
      title={
        <Typography variant="h6" component="span">
          <Trans i18nKey="COMMON.EDIT" />
        </Typography>
      }
      {...props}
    >
      <Box sx={{ mb: 2 }} />
      <Field
        component={TextField}
        name="comment"
        label={<Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.COMMENT" />}
      />
      <Field
        component={TextField}
        name="capacity"
        label={<Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.CAPACITY" />}
      />
    </EditorDialog>
  );
};

export default UcseAvailabilityEditDialog;

import React from 'react';
import { Trans } from 'react-i18next';

import { TextFieldProps } from '@mui/material';

import { Field } from 'formik';
import { IInputComponentParams } from 'components/input-fields/helpers/interfaces';
import LeoAutoComplete from './LeoAutoComplete';

export const LeoSelectField = ({
  name,
  data,
  accessor,
  label,
  autoFill,
  updateField,
  clearOnEscape,
  disableClearable,
}: IInputComponentParams & TextFieldProps) => {
  return (
    <Field
      type="text"
      name={name}
      component={LeoAutoComplete}
      label={<Trans i18nKey={label as string} />}
      options={data}
      accessor={accessor}
      autoFill={autoFill}
      updateField={updateField}
      clearOnEscape={clearOnEscape}
      disableClearable={disableClearable}
    />
  );
};

export default LeoSelectField;

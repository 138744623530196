import React, { FC, useRef } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import * as Yup from 'yup';
import { Formik, Field, Form, FormikValues, FormikProps } from 'formik';
import { Box, Divider } from '@mui/material';

import {
  BaseDialogProps,
  Dialog,
  FileUpload,
  LeoDateInterval,
  relativeDateTestFunction,
} from 'components';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';
import { useImportTimeLog } from 'features/timeLog';

const TimeLogExportDialog: FC<BaseDialogProps> = ({ open, onClose, ...props }) => {
  const formikRef = useRef<FormikProps<any> | null>(null);
  const { mutateAsync: importTimeLog, isLoading } = useImportTimeLog();

  const onCloseDialog = () => {
    formikRef.current?.resetForm();
    onClose();
  };

  const onDialogSubmit = async (e: FormikValues) => {
    await importTimeLog({ file: e?.file?.[0], date: e?.date });

    formikRef.current?.resetForm();
    onClose();
  };

  const timeLogValidationSchema = Yup.object().shape({
    date: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .required()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
    file: Yup.mixed().required(),
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ date: { from: undefined, to: undefined }, file: null }}
      onSubmit={onDialogSubmit}
      enableReinitialize
      validationSchema={timeLogValidationSchema}
    >
      {({ resetForm }) => (
        <Dialog
          component={Form}
          maxWidth="sm"
          title={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.EXPORT_ATTENDANCE" />}
          open={open}
          onClose={onCloseDialog}
          actions={
            <DialogFooterButtons
              cancelClick={onCloseDialog}
              submitText="KOZOS_MUNKAVEGZOK_NAPLOJA.DIALOG_SUBMIT"
              isLoading={isLoading}
            />
          }
          {...props}
        >
          <>
            <Divider sx={{ mb: 2 }} />
            <Box mb={2} display="flex">
              <LeoDateInterval
                name="date"
                label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.DATE_INTERVAL" />}
              />
            </Box>
            <Box mb={2} width={550}>
              <Field
                component={FileUpload}
                label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.FILE_LABEL" />}
                multiple={false}
                onDropRejected={resetForm}
                name="file"
                accept={{
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                    '.xls',
                    '.xlsx',
                    '.xlsm',
                  ],
                }}
              />
            </Box>
          </>
        </Dialog>
      )}
    </Formik>
  );
};

export default TimeLogExportDialog;

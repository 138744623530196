import React, { ChangeEventHandler, FC, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { SearchConditions, ClearFieldAdornment } from 'components';
import { Env } from 'config/env';

const styles = {
  searchBar: {
    background: 'background.paper',
    maxWidth: 600,
  },
};

export interface SearchBarProps {
  name: string;
  /**
   * Function that is triggered by the onChange event.
   */
  onSearch: (searchConditions: SearchConditions) => void;
  /**
   * The initial value of the search field.
   */
  initialValue?: string | null;
  /**
   * Delay for the debounce is ms.
   */
  delay?: number;
}

export const SearchBar: FC<
  SearchBarProps & Omit<TextFieldProps, 'value' | 'onChange' | 'InputProps'>
> = ({
  delay = Env.SEARCH_DELAY,
  initialValue,
  name,
  onSearch,
  variant = Env.DEFAULT_INPUT_VARIANT,
  ...props
}) => {
  const [value, setValue] = useState<string>(initialValue || '');

  const handleSearch = useCallback(
    (newValue: string) => {
      onSearch({ name, value: newValue });
    },
    [onSearch, name]
  );

  // Because the parameter of useCallback is the debounce function and the linter can't resolve it's dependencies
  // eslint-disable-next-line
  const onSearchBarSearch = useCallback(
    debounce((newValue: string) => handleSearch(newValue), delay),
    [handleSearch, delay]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue(e.target.value);
      onSearchBarSearch(e.target.value);
    },
    [setValue, onSearchBarSearch]
  );

  const onClear = useCallback(() => {
    setValue('');
    onSearchBarSearch('');
  }, [setValue, onSearchBarSearch]);

  const onIconClick = useCallback(() => {
    if (value) {
      onClear();
    }
  }, [value, onClear]);

  return (
    <TextField
      sx={styles.searchBar}
      onChange={handleChange}
      value={value}
      variant={variant}
      fullWidth
      InputProps={{
        endAdornment: <ClearFieldAdornment onClick={onIconClick} value={value} showSearchIcon />,
      }}
      {...props}
    />
  );
};

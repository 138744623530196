import { AddressModel } from './address.model';
import { WorkerModel } from './worker.model';
import { PartnerModel } from './partner.model';

export interface SmOrderModel {
  id: number;
  orderId: string;
  downloadabilityDate: string;
  region: string;
  appointmentDate: string;
  appointmentTime: string;
  status: string;
  taskStatus: string;
  isUnauthorizedConsumption: boolean;
  city: string;
  workerId: string;
  worker?: WorkerModel;
  addressId: number;
  address?: AddressModel;
  partnerId: number;
  partner?: PartnerModel;
  consumptionPlaceId?: number;
}

export enum OrderStatus {
  ELLENORIZENDO = '1',
  NEM_ELLENORIZHETO = '2',
  ELLENORZOTT = '3',
  MEGKEZDETT = '4',
  IRATFOTORA_VAR = '5',
}

import { EntityService } from 'utils/entityService';
import { ListResponseModel, QueryParamModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { exportFile } from 'utils/exportFile';
import { AreaWorkerModel, AreaWorkerPostModel } from 'models/areaWorker.model';

class AreaWorkerService extends EntityService {
  constructor() {
    super('area-worker');
  }

  getAreaWorker = async (params?: QueryParamModel | null) => {
    return request<ListResponseModel<AreaWorkerModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  };

  private exportAreaWorkerList = async (params?: QueryParamModel | null) => {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });
    await exportFile(response);
    return response;
  };

  exportArea = (params?: QueryParamModel | null) => this.exportAreaWorkerList(params);

  async editAreaWorker(id: ID, data: AreaWorkerPostModel) {
    return request<AreaWorkerModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/${id}`,
      data,
    });
  }

  async createAreaWorker(data: AreaWorkerPostModel) {
    return request<AreaWorkerModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  async deleteAreaWorker(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/${id}`,
    });
  }
}

export const areaWorkerService = new AreaWorkerService();

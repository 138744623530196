import React, { useCallback, useEffect, useState } from 'react';

import { arrayify } from './arrays';
import { useDisposed } from './useDisposed';

export type UseQueryResult<Data> = {
  data: Data | null;
  setData: React.Dispatch<React.SetStateAction<Data | null>>;
  isLoading: boolean;
  reload: () => Promise<Data | null>;
};

export function useQuery<Data>(
  fetcher?: (() => Promise<Data | null>) | null,
  dependencies?: any[]
): UseQueryResult<Data> {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<Data | null>(null);
  const disposed = useDisposed();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const result = fetcher ? await fetcher() : null;

      if (!disposed()) {
        setData(result);
      }

      return result;
    } finally {
      if (!disposed()) {
        setLoading(false);
      }
    }
  }, [disposed, setLoading, fetcher]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    // We only want to reload data if dependencies is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...arrayify(dependencies)]);

  return { data, setData, isLoading, reload: fetchData };
}

import { MenuConfigModel, RouteConfigurationModel } from 'models';
import { primary } from 'theme/colors';
import { ReactComponent as CollaborateMenuCard } from 'assets/icons/Titasz/CollaborateMenuCard.svg';
import { ReactComponent as LicenseMaintenance } from 'assets/icons/Titasz/LicenseMaintenance.svg';
import { ReactComponent as LicenseThirdParty } from 'assets/icons/Titasz/LicenseThirdParty.svg';
import { ReactComponent as CloudMonitoring } from 'assets/icons/Titasz/CloudMonitoring.svg';
import { ReactComponent as UserProfileAlt } from 'assets/icons/Titasz/UserProfileAlt.svg';
import { ReactComponent as DocumentImport } from 'assets/icons/Titasz/DocumentImport.svg';
import { ReactComponent as EventSchedule } from 'assets/icons/Titasz/EventSchedule.svg';
import { ReactComponent as AlarmSubtract } from 'assets/icons/Titasz/AlarmSubtract.svg';
import { ReactComponent as UCSEMenuCard } from 'assets/icons/Titasz/ÜCSEMenuCard.svg';
import { ReactComponent as WarningOther } from 'assets/icons/Titasz/WarningOther.svg';
import { ReactComponent as OrderDetails } from 'assets/icons/Titasz/OrderDetails.svg';
import { ReactComponent as ViewMenuCard } from 'assets/icons/Titasz/ViewMenuCard.svg';
import { ReactComponent as UserFollow } from 'assets/icons/Titasz/UserFollow.svg';
import { ReactComponent as ChartLine } from 'assets/icons/Titasz/ChartLine.svg';
import { ReactComponent as CameraOff } from 'assets/icons/Titasz/CameraOff.svg';
import { ReactComponent as Analytics } from 'assets/icons/Titasz/Analytics.svg';
import { ReactComponent as DataCheck } from 'assets/icons/Titasz/DataCheck.svg';
import { ReactComponent as PiggyBank } from 'assets/icons/Titasz/PiggyBank.svg';
import { ReactComponent as Exchange } from 'assets/icons/Titasz/Exchange.svg';
import { ReactComponent as Activity } from 'assets/icons/Titasz/Activity.svg';
import { ReactComponent as ViewOff } from 'assets/icons/Titasz/ViewOff.svg';
import { ReactComponent as Warning } from 'assets/icons/Titasz/Warning.svg';
import { ReactComponent as License } from 'assets/icons/Titasz/License.svg';
import { ReactComponent as Tariff } from 'assets/icons/Titasz/Tariff.svg';
import { ReactComponent as Wallet } from 'assets/icons/Titasz/Wallet.svg';
import { ReactComponent as Money } from 'assets/icons/Titasz/Money2.svg';
import { ReactComponent as Touch } from 'assets/icons/Titasz/Touch.svg';
import { ReactComponent as Chart } from 'assets/icons/Titasz/Chart.svg';
import { ReactComponent as Error } from 'assets/icons/Titasz/Error.svg';
import { ReactComponent as Data1 } from 'assets/icons/Titasz/Data1.svg';
import { ReactComponent as UCSE } from 'assets/icons/Titasz/ÜCSE.svg';
import { ReactComponent as List } from 'assets/icons/Titasz/List.svg';
import { ReactComponent as Book } from 'assets/icons/Titasz/Book.svg';
import { ReactComponent as Data } from 'assets/icons/Titasz/Data.svg';
import { ReactComponent as Team } from 'assets/icons/Titasz/Team.svg';
import { ReactComponent as Time } from 'assets/icons/Titasz/Time.svg';
import { ReactComponent as View } from 'assets/icons/Titasz/View.svg';

import { MenuDisplayNames } from './menuDisplayNames';
import { RouteNames } from './routeNames';
import { CardLayout } from './cardLayout';
import { routes } from './routes';

export const menuConfigRaw: MenuConfigModel[] = [
  {
    displayName: MenuDisplayNames.HOME,
    route: RouteNames.HOME,
    cardLayout: CardLayout.STANDARD,
    children: [
      {
        displayName: MenuDisplayNames.KOZOS_FUNKCIOK,
        route: RouteNames.KOZOS_FUNKCIOK,
        cardLayout: CardLayout.STANDARD,
        icon: CollaborateMenuCard,
        iconColor: { backgroundColor: 'primary.main' },
        children: [
          {
            icon: Data1,
            displayName: MenuDisplayNames.LEO_ADATPONTOSITASOK,
            route: RouteNames.LEO_ADATPONTOSITASOK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Team,
            displayName: MenuDisplayNames.KOZOS_MUNKAVEGZOK,
            route: RouteNames.KOZOS_MUNKAVEGZOK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Team,
            displayName: MenuDisplayNames.KOZOS_OPEM_FELHASZNALOK,
            route: RouteNames.KOZOS_OPEM_FELHASZNALOK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: LicenseMaintenance,
            displayName: MenuDisplayNames.KOZOS_MUVELETI_NAPLO,
            route: RouteNames.KOZOS_MUVELETI_NAPLO,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Time,
            displayName: MenuDisplayNames.KOZOS_TIME_LOG,
            route: RouteNames.KOZOS_TIME_LOG,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Warning,
            displayName: MenuDisplayNames.KOZOS_H_S_JELZESEK,
            route: RouteNames.KOZOS_H_S_JELZESEK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Touch,
            displayName: MenuDisplayNames.KOZOS_SAP_FELTOLTES_MANUALIS,
            route: RouteNames.KOZOS_SAP_FELTOLTES_MANUALIS,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: DocumentImport,
            displayName: MenuDisplayNames.KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE,
            route: RouteNames.KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: DocumentImport,
            displayName: MenuDisplayNames.KOZOS_DOKUMENTUM_FELTOLTES,
            route: RouteNames.KOZOS_DOKUMENTUM_FELTOLTES,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: DataCheck,
            displayName: MenuDisplayNames.KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK,
            route: RouteNames.KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Exchange,
            displayName: MenuDisplayNames.KOZOS_ERTEKKESZLET,
            route: RouteNames.KOZOS_ERTEKKESZLET,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Wallet,
            displayName: MenuDisplayNames.KOZOS_TELJESITMENYBER,
            route: RouteNames.KOZOS_TELJESITMENYBER,
            cardLayout: CardLayout.COMPACT,
            children: [
              {
                icon: PiggyBank,
                displayName: MenuDisplayNames.KOZOS_TELJESITMENYBEREK,
                route: RouteNames.KOZOS_TELJESITMENYBEREK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: Money,
                displayName: MenuDisplayNames.KOZOS_BERELEMEK,
                route: RouteNames.KOZOS_BERELEMEK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: Data1,
                displayName: MenuDisplayNames.SULYSZAMOK,
                route: RouteNames.KOZOS_TELJESITMENYBER_SULYSZAMOK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: Tariff,
                displayName: MenuDisplayNames.KOZOS_TARIFAK,
                route: RouteNames.KOZOS_TARIFAK,
                cardLayout: CardLayout.COMPACT,
                children: [
                  {
                    icon: Tariff,
                    displayName: MenuDisplayNames.KOZOS_TARIFAK_BERELEMEK,
                    route: RouteNames.KOZOS_TARIFAK_BERELEMEK,
                    cardLayout: CardLayout.COMPACT,
                  },
                  {
                    icon: Tariff,
                    displayName: MenuDisplayNames.KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK,
                    route: RouteNames.KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK,
                    cardLayout: CardLayout.COMPACT,
                  },
                  {
                    icon: Tariff,
                    displayName: MenuDisplayNames.KOZOS_TARIFAK_JELENTESEK,
                    route: RouteNames.KOZOS_TARIFAK_JELENTESEK,
                    cardLayout: CardLayout.COMPACT,
                  },
                ],
              },
            ],
          },
          {
            icon: DocumentImport,
            displayName: MenuDisplayNames.KOZOS_FELADAT_BETOLTES,
            route: RouteNames.KOZOS_FELADAT_BETOLTES,
            cardLayout: CardLayout.COMPACT,
            children: [
              {
                icon: DocumentImport,
                displayName: MenuDisplayNames.KOZOS_FELADAT_IMPORT,
                route: RouteNames.KOZOS_FELADAT_IMPORT,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: DocumentImport,
                displayName: MenuDisplayNames.KOZOS_FELADAT_BETOLTES_EXCELBOL,
                route: RouteNames.KOZOS_FELADAT_BETOLTES_EXCELBOL,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: DocumentImport,
                displayName: MenuDisplayNames.KOZOS_RENDELES_AZONOSITOK_CSEREJE,
                route: RouteNames.KOZOS_RENDELES_AZONOSITOK_CSEREJE,
                cardLayout: CardLayout.COMPACT,
              },
            ],
          },
        ],
      },
      {
        displayName: MenuDisplayNames.LEO,
        route: RouteNames.LEO,
        cardLayout: CardLayout.STANDARD,
        icon: ViewMenuCard,
        iconColor: { backgroundColor: 'secondary.main' },
        children: [
          {
            icon: List,
            displayName: MenuDisplayNames.LEO_LEO_EGYSEGEK,
            route: RouteNames.LEO_LEO_EGYSEGEK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Book,
            displayName: MenuDisplayNames.LEO_LEO_RENDELESEK,
            route: RouteNames.LEO_LEO_RENDELESEK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Analytics,
            displayName: MenuDisplayNames.LEO_LEO_KOZPONT_ALAPERTEKEK,
            route: RouteNames.LEO_LEO_KOZPONT_ALAPERTEKEK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Chart,
            displayName: MenuDisplayNames.LEO_STATISZTIKAK,
            route: RouteNames.LEO_STATISZTIKAK,
            cardLayout: CardLayout.COMPACT,
            children: [
              {
                icon: View,
                displayName: MenuDisplayNames.LEO_LEOLVASO_STATISZTIKA,
                route: RouteNames.LEO_LEOLVASO_STATISZTIKA,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: ViewOff,
                displayName: MenuDisplayNames.LEO_NEM_LEOLVASHATO_RENDELESEK,
                route: RouteNames.LEO_NEM_LEOLVASHATO_RENDELESEK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: OrderDetails,
                displayName: MenuDisplayNames.LEO_JELENTESEKET_TARTALMAZO_RENDELESEK,
                route: RouteNames.LEO_JELENTESEKET_TARTALMAZO_RENDELESEK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: Error,
                displayName: MenuDisplayNames.LEO_BECSLESBOL_KIZARASOK,
                route: RouteNames.LEO_BECSLESBOL_KIZARASOK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: WarningOther,
                displayName: MenuDisplayNames.LEO_MUNKAVEDELMI_JELZESEK,
                route: RouteNames.LEO_MUNKAVEDELMI_JELZESEK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: CameraOff,
                displayName: MenuDisplayNames.LEO_FOTO_MEGHIUSULASOK_OKA,
                route: RouteNames.LEO_FOTO_MEGHIUSULASOK_OKA,
                cardLayout: CardLayout.COMPACT,
              },
            ],
          },
          {
            icon: CloudMonitoring,
            displayName: MenuDisplayNames.LEO_MONITORING,
            route: RouteNames.LEO_MONITORING,
            cardLayout: CardLayout.COMPACT,
            children: [
              {
                icon: Activity,
                displayName: MenuDisplayNames.LEO_JELENTES_KODOK,
                route: RouteNames.LEO_JELENTES_KODOK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: ChartLine,
                displayName: MenuDisplayNames.LEO_JELENTES_KODOK_LEOLVASONKENT,
                route: RouteNames.LEO_JELENTES_KODOK_LEOLVASONKENT,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: ViewOff,
                displayName: MenuDisplayNames.LEO_NEM_LEOLVASHATOSAGI_OKOK,
                route: RouteNames.LEO_NEM_LEOLVASHATOSAGI_OKOK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: View,
                displayName: MenuDisplayNames.LEO_LEOLVASOTTSAG,
                route: RouteNames.LEO_LEOLVASOTTSAG,
                cardLayout: CardLayout.COMPACT,
              },
            ],
          },
          {
            icon: Data,
            displayName: MenuDisplayNames.LEO_LOGOK,
            route: RouteNames.LEO_LOGOK,
            cardLayout: CardLayout.COMPACT,
            children: [
              {
                icon: LicenseThirdParty,
                displayName: MenuDisplayNames.LEO_KOMMUNIKACIOS_LOGOK,
                route: RouteNames.LEO_KOMMUNIKACIOS_LOGOK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: License,
                displayName: MenuDisplayNames.LEO_ERTESITO_JELZESEK,
                route: RouteNames.LEO_ERTESITO_JELZESEK,
                cardLayout: CardLayout.COMPACT,
              },
            ],
          },
        ],
      },
      {
        displayName: MenuDisplayNames.UCSE,
        route: RouteNames.UCSE,
        cardLayout: CardLayout.STANDARD,
        icon: UCSEMenuCard,
        iconColor: { backgroundColor: primary[200] },
        children: [
          {
            icon: UCSE,
            displayName: MenuDisplayNames.UCSE_RENDELESEK,
            route: RouteNames.UCSE_RENDELESEK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: UserFollow,
            displayName: MenuDisplayNames.UCSE_RENDELKEZESRE_ALLAS,
            route: RouteNames.UCSE_RENDELKEZESRE_ALLAS,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: UserProfileAlt,
            displayName: MenuDisplayNames.UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE,
            route: RouteNames.UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Error,
            displayName: MenuDisplayNames.UCSE_KORZETKIZARASOK,
            route: RouteNames.UCSE_KORZETKIZARASOK,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: EventSchedule,
            displayName: MenuDisplayNames.UCSE_IDOPONTFOGLALAS,
            route: RouteNames.UCSE_IDOPONTFOGLALAS,
            cardLayout: CardLayout.COMPACT,
          },
          {
            icon: Chart,
            displayName: MenuDisplayNames.UCSE_STATISZTIKAK,
            route: RouteNames.UCSE_STATISZTIKAK,
            cardLayout: CardLayout.COMPACT,
            children: [
              {
                icon: List,
                displayName: MenuDisplayNames.UCSE_TELEPULES_LISTA_KAPACITAS,
                route: RouteNames.UCSE_TELEPULES_LISTA_KAPACITAS,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: EventSchedule,
                displayName: MenuDisplayNames.UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK,
                route: RouteNames.UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK,
                cardLayout: CardLayout.COMPACT,
              },
              {
                icon: AlarmSubtract,
                displayName: MenuDisplayNames.UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK,
                route: RouteNames.UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK,
                cardLayout: CardLayout.COMPACT,
              },
            ],
          },
        ],
      },
    ],
  },
];

const applyObjKeyRecursively = (
  configSlice: MenuConfigModel[] | undefined,
  routeParam: RouteConfigurationModel[],
  keyName: string,
  keyRule: any
): MenuConfigModel[] | undefined =>
  Array.isArray(configSlice)
    ? configSlice.map(({ children, ...rest }) => ({
        ...rest,
        children: applyObjKeyRecursively(children, routeParam, keyName, keyRule),
        [keyName]: keyRule(routeParam, rest),
      }))
    : configSlice;

const linkingRule = (routeParams: RouteConfigurationModel[], item: MenuConfigModel) =>
  routeParams.filter((routeParam) => routeParam.path === item.route)[0]?.allowedFor;

export const menuConfig: MenuConfigModel[] =
  applyObjKeyRecursively(menuConfigRaw, routes, 'allowedFor', linkingRule) ?? menuConfigRaw;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, ReadingStatisticsModel } from 'models';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import { RouteNames } from 'config/routeNames';
import { getPageName } from 'utils/format';
import { formatDate } from 'utils/dates';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import { useExportReadingStatistics, useReadingStatisticsList } from 'features/readingStatistics';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { plannedReadingDateValidationSchema } from 'pages/Leo/Monitoring/ReadingResults/ReadingResultStatisticsPage';

const ReadingStatisticsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: readingStatisticsData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReadingStatisticsList(apiParams);

  const { isInitialLoading, isRefetching, refetch } = useExportReadingStatistics(exportParams);
  const { data: valueSet } = useValueSetList({ code: ['LEOKOZPONT'] });
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });

  const { data: leoKozpontData, accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      name: 'plannedReadingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
    },
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenter"
          data={leoKozpontData}
          label="LEO_LEOLVASO_STATISZTIKA.READING_CENTER"
        />
      ),
      name: 'readingCenter',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.WORKER_ID',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="LEO_LEOLVASO_STATISZTIKA.WORKER_ID"
          readOnly
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.BUNDLE',
      component: (
        <LeoTextField
          name="bundle.from"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.FROM" />}
          maxLength={10}
        />
      ),
      name: 'bundle.from',
      panelFieldWidth: 6,
    },
    {
      component: (
        <LeoTextField
          name="bundle.to"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.TO" />}
          maxLength={10}
        />
      ),
      name: 'bundle.to',
      panelFieldWidth: 6,
      divider: true,
    },
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.READING_UNIT',
      component: (
        <LeoTextField
          name="readingUnitCode"
          label={<Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.READING_UNIT" />}
        />
      ),
      name: 'readingUnitCode',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.READING_UNIT'),
    },
  ];

  const readingStatisticsFilterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_LEOLVASO_STATISZTIKA),
    validationSchema: plannedReadingDateValidationSchema,
    isFetching,
  });

  const readingStatisticsColumns = [
    {
      key: 'plannedReadingDate',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.PLANNED_READING_DATE" />,
      accessor: (worker: ReadingStatisticsModel) => formatDate(worker.plannedReadingDate),
      field: 'plannedReadingDate',
      sortable: true,
    },
    {
      key: 'bundle',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.BUNDLE" />,
      accessor: 'bundle',
      sortable: true,
    },
    {
      key: 'readingUnitCode',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.READING_UNIT" />,
      accessor: 'readingUnitCode',
      sortable: true,
    },
    {
      key: 'sapId',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.WORKER_SAP_ID" />,
      accessor: 'sapId',
      sortable: true,
    },
    {
      key: 'consumptionPlaceCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.CONSUMPTION_PLACE_COUNT" />,
      accessor: 'consumptionPlaceCount',
      sortable: true,
    },
    {
      key: 'bindingCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.BINDING_COUNT" />,
      accessor: 'bindingCount',
      sortable: true,
    },
    {
      key: 'deviceCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.DEVICE_COUNT" />,
      accessor: 'deviceCount',
      sortable: true,
    },
    {
      key: 'meterCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.METER_COUNT" />,
      accessor: 'meterCount',
      sortable: true,
    },
    {
      key: 'readDeviceCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.READ_DEVICE_COUNT" />,
      accessor: 'readDeviceCount',
      sortable: true,
    },
    {
      key: 'readMeterCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.READ_METER_COUNT" />,
      accessor: 'readMeterCount',
      sortable: true,
    },
    {
      key: 'unreadableConsumptionPlaceCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.UNREADABLE_CP_COUNT" />,
      accessor: 'unreadableConsumptionPlaceCount',
      sortable: true,
    },
    {
      key: 'unreadableDeviceCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.UNREADABLE_DEVICE_COUNT" />,
      accessor: 'unreadableDeviceCount',
      sortable: true,
    },
    {
      key: 'unreadableMeterCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.UNREADABLE_METER_COUNT" />,
      accessor: 'unreadableMeterCount',
      sortable: true,
    },
    {
      key: 'unhandledConsumptionPlaceCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.UNTREATED_CP_COUNT" />,
      accessor: 'unhandledConsumptionPlaceCount',
      sortable: true,
    },
    {
      key: 'unhandledDeviceCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.UNTREATED_DEVICE_COUNT" />,
      accessor: 'unhandledDeviceCount',
      sortable: true,
    },
    {
      key: 'unhandledMeterCount',
      header: <Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.UNTREATED_METER_COUNT" />,
      accessor: 'unhandledMeterCount',
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const panels = [readingStatisticsFilterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_LEOLVASO_STATISZTIKA.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Table
        filterDetailsData={{ leoKozpontAccessor }}
        panels={panels}
        activePanel={activePanel}
        setPanel={setPanel}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        list={readingStatisticsData?.data || []}
        total={readingStatisticsData?.meta.total}
        timestamp={dataUpdatedAt}
        enableCheckbox={false}
        enableTableView={true}
        loading={isLoading}
        params={params}
        onSort={onSort}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        columns={readingStatisticsColumns}
        pageHeaderActions={headerActions}
        onLoadQuery={onLoadQuery}
      />
    </Page>
  );
};

export default ReadingStatisticsPage;

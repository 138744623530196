import { QueryContextFromKeys, QueryParamModel } from 'models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadTaskFromExcelService } from 'services/loadTaskFromExcel.service';
import { parse } from 'utils/base64';
import { emptyResponse } from 'features/helpers';
import { REACT_QUERY_OPTIONS } from 'config/reactQueryConfig';

type LoadTasksContext = QueryContextFromKeys<typeof loadTaskKeys>;

export const loadTaskKeys = {
  all: [{ scope: 'loadTasksFromExcel' }] as const,
  lists: () => [{ ...loadTaskKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...loadTaskKeys.lists()[0], params }] as const,
  download: (id: string) => [{ ...loadTaskKeys.all[0], entity: 'download', id }] as const,
  active: () => [{ ...loadTaskKeys.all[0], entity: 'active' }] as const,
  status: (id: string) => [{ ...loadTaskKeys.all[0], entity: 'status', id }] as const,
};

const fetchLoadTaskList = async ({ queryKey: [{ params }] }: LoadTasksContext['list']) => {
  const { status: requiredParam } = parse(params?.filter);
  return requiredParam ? loadTaskFromExcelService.getTasks(params) : emptyResponse;
};

export const useLoadTaskList = (params?: QueryParamModel | null) =>
  useQuery(loadTaskKeys.list(params), fetchLoadTaskList, {
    enabled: !!params?.filter,
  });

export const useLoadTask = () => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => loadTaskFromExcelService.upload(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: loadTaskKeys.lists() });
    },
  });
};

export const useRetryLoadTask = () => {
  const queryClient = useQueryClient();

  return useMutation((jobId: string) => loadTaskFromExcelService.retryUpload(jobId), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: loadTaskKeys.lists() });
    },
  });
};

export const useDeleteTask = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => loadTaskFromExcelService.deleteTask(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: loadTaskKeys.lists() });
    },
  });
};

export const useDownloadResult = () => {
  return useMutation((id: string) => loadTaskFromExcelService.downloadResult(id), {
    meta: {
      [REACT_QUERY_OPTIONS.SKIP_DEFAULT_SUCCESS_CALLBACK]: true,
    },
  });
};

export const useActiveTask = () =>
  useQuery(loadTaskKeys.active(), async () => loadTaskFromExcelService.getActiveTasks());

const JOB_STATUS_REFETCH_INTERVAL = 3000;

export const useJobStatus = (id: string) => {
  return useQuery(loadTaskKeys.status(id), async () => loadTaskFromExcelService.getJobStatus(id), {
    enabled: !!id,
    refetchInterval: (data) => (data?.status !== 'completed' ? JOB_STATUS_REFETCH_INTERVAL : false),
  });
};

import { EntityService } from 'utils/entityService';
import { RoleModel } from 'models';
import request, { Methods } from 'utils/request';

class RolesService extends EntityService {
  constructor() {
    super('roles');
  }

  getRolesList = async () =>
    request<RoleModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
    });
}

export const rolesService = new RolesService();

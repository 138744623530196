import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { FilterOptionModel, SmOrderTaskSummaryModel } from 'models';
import {
  LeoSelectFieldMulti,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import {
  useCompletedAfterDeadLineList,
  useExportCompletedAfterDeadLine,
} from 'features/completedAfterDeadline';
import { useValueSetList } from 'features/valueSets';
import { useWorkerListSearch } from 'features/workers';
import { RouteNames } from 'config/routeNames';
import { formatDate } from 'utils/dates';
import { getPageName } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import Yup from 'utils/yup';

const RegisterValidator = () => {
  return t('ERRORS.INVALID_DATE');
};

export const validationSchema = Yup.object().shape({
  statusDate: Yup.object()
    .nullable()
    .shape({
      from: Yup.mixed()
        .nullable()
        .required()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
      to: Yup.mixed()
        .nullable()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
    }),
});

const CompletedAfterDeadlinePage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: completedAfterDeadLineList,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useCompletedAfterDeadLineList(apiParams);
  const { data: workers } = useWorkerListSearch({ withDeleted: true });
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['TASK_STATUS', 'APPOINTMENT_TIME'],
  });

  const { accessor: smOrderStatusAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'TASK_STATUS')
  );

  const { accessor: appointmentTimeAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'APPOINTMENT_TIME')
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workers,
    'sapId',
    'name',
    true
  );

  const { isInitialLoading, isRefetching, refetch } = useExportCompletedAfterDeadLine(exportParams);

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const afterDeadLineFilters: FilterOptionModel[] = [
    {
      headerTitle: 'UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.STATUS_DATE',
      component: <LeoDateInterval name="statusDate" />,
      name: 'statusDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.STATUS_DATE'),
      filterIsRequired: true,
    },
    {
      headerTitle: 'UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.WORKER_CODE',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.WORKER_CODE"
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
    },
  ];

  const afterDeadlineFilterConfig = useFilterConfig({
    params,
    onFilter,
    filters: afterDeadLineFilters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK),
    validationSchema,
    isFetching,
  });

  const panels = [afterDeadlineFilterConfig];

  const { activePanel, closePanel, setPanel } = usePanelState();

  const deadlineColumns = (smOrderStatusAccess: any, appointmentTimeAccess: any) => [
    {
      key: 'orderId',
      header: <Trans i18nKey="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.ORDER_ID" />,
      accessor: 'orderId',
      sortable: true,
    },
    {
      key: 'taskStatus',
      header: <Trans i18nKey="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.TASK_STATUS" />,
      accessor: (item: SmOrderTaskSummaryModel) => smOrderStatusAccess[item.taskStatus],
      field: 'taskStatus',
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.WORKER_CODE" />,
      accessor: (item: SmOrderTaskSummaryModel) => item.worker?.sapId,
      field: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'appointmentDate',
      header: <Trans i18nKey="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.APPOINTMENT_DATE" />,
      accessor: (item: SmOrderTaskSummaryModel) =>
        `${formatDate(item.appointmentDate)} ${appointmentTimeAccess[item.appointmentTime] || ''}`,
      field: 'appointmentDate',
      sortable: true,
    },
    {
      key: 'statusDate',
      header: <Trans i18nKey="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.STATUS_TIME" />,
      accessor: (order: SmOrderTaskSummaryModel) => formatDate(order.statusDate),
      field: 'statusDate',
      sortable: true,
    },
    {
      key: 'readingFailureReason.value',
      header: <Trans i18nKey="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.READING_FAILURE_REASON_ID" />,
      accessor: 'readingFailureReason.value',
      sortable: true,
    },
    {
      key: 'readingFailureReasonComment',
      header: (
        <Trans i18nKey="UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.READING_FAILURE_REASON_COMMENT" />
      ),
      accessor: 'readingFailureReasonComment',
      sortable: true,
    },
  ];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={completedAfterDeadLineList?.data || []}
          total={completedAfterDeadLineList?.meta.total}
          timestamp={dataUpdatedAt}
          loading={isLoading}
          enableCheckbox={false}
          enableTableView={true}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={deadlineColumns(smOrderStatusAccessor, appointmentTimeAccessor)}
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};
export default CompletedAfterDeadlinePage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/material/styles';

import { MenuCard, Page } from 'components/index';
import { RouteNames } from 'config/routeNames';
import { menuConfig } from 'config/menuConfig';
import { findRoute } from 'utils/findRoute';
import { hasPermission } from 'utils/auth';
import { mergeSx } from 'utils/styles';
import { selectUserRoles } from 'store/profile';
import { MenuConfigModel, RoleModel } from 'models';

export interface MenuCardMockFilterPageNames {
  parentRoute: RouteNames;
  noWrapper?: boolean;
  sx?: SxProps;
}

const styles = {
  menuCardContainer: {
    '@media (min-width: 1600px)': {
      p: '64px 53px',
      gap: '32px',
    },
    '@media (max-width: 1599px)': {
      p: '50px 48px',
      gap: '24px',
    },
  },
};

export const getCardItems = (
  menuItem: MenuConfigModel | undefined,
  roles: RoleModel[] | undefined
) => menuItem?.children?.filter((item) => hasPermission(roles, item.allowedFor));

const MenuCardPage: FC<MenuCardMockFilterPageNames> = ({ parentRoute, noWrapper, sx }) => {
  const roles = useSelector(selectUserRoles);
  const menuItem = findRoute(parentRoute, menuConfig);

  const cardItems = getCardItems(menuItem, roles);

  const menuGrid = (
    <Grid container sx={mergeSx(styles.menuCardContainer, sx)}>
      {cardItems?.map((card) => {
        return (
          <Grid item key={card.route}>
            <MenuCard menuConfig={card} />
          </Grid>
        );
      })}
    </Grid>
  );

  return noWrapper ? (
    menuGrid
  ) : (
    <Page title={<Trans i18nKey={menuItem?.displayName} />}>{menuGrid}</Page>
  );
};
export default MenuCardPage;

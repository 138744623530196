import i18n from 'i18n';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showNotification } from 'components';
import { readerDocumentService } from 'services';
import { NotificationType } from 'models';
import { isAxiosError } from 'utils/errors';

export const uploadDocumentsKeys = {
  all: [{ scope: 'uploadDocuments' }] as const,
  lists: () => [{ ...uploadDocumentsKeys.all[0], entity: 'list' }] as const,
};

export const useUploadDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => readerDocumentService.upload(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: uploadDocumentsKeys.lists() });
    },
    onError: (e) => {
      if (isAxiosError(e) && e.response?.data.message) {
        showNotification({
          content: i18n.t(`ERRORS.${e.response.data.message}`),
          type: NotificationType.ERROR,
        });
      }
    },
  });
};

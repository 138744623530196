export interface CredentialsModel {
  email: string;
  password: string;
}

export interface SignUpCredentialsModel {
  email: string;
  firstName: string;
  lastName: string;
  consent?: boolean;
}

export interface ForgotPasswordCredentialsModel {
  email: string;
}

export interface PasswordQuery {
  email?: string;
  token?: string;
  lang?: string;
}

export interface SetPasswordCredentialsModel extends CredentialsModel {
  token: string;
  passwordConfirmation: string;
  lang: string;
}

export interface RefreshTokenModel {
  accessToken: string;
  refreshToken: string;
}

export enum LoginQueryParams {
  AZURE_REDIRECT_URL = 'redirectUrl',
  IN_APP_REDIRECT = 'redirectTo',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

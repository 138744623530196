import React, { ChangeEvent, FC, useState } from 'react';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import { Button, showNotification } from 'components';
import { NotificationType } from 'models';

const Notifications: FC = (props) => {
  const [type, setType] = useState<NotificationType>(NotificationType.ERROR);

  function show() {
    showNotification({ content: 'Notification content', type });
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setType(e.target.value as NotificationType);
  }

  return (
    <Box>
      <Typography variant="h6">Notification</Typography>
      <Box mb={2}>
        <TextField
          select
          value={type}
          variant="outlined"
          onChange={onChange}
          label="Notification type"
          fullWidth
          margin="dense"
        >
          {Object.entries(NotificationType).map(([key, value]) => (
            <MenuItem value={value} key={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box mr={2} display="flex">
        <Button onClick={show}>Show notification</Button>
      </Box>
    </Box>
  );
};

export default Notifications;

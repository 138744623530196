export function getFieldDataAndAccessor(
  array?: any[],
  keyField?: string,
  valueField?: string,
  concenate?: boolean,
  displayKeyField?: string,
  dataIsNum?: boolean
) {
  const arr = array || [];
  const key = keyField || 'key';
  const value = valueField || 'value';
  const isConcenate = concenate || false;
  const toNum = dataIsNum || false;

  const data = arr.reduce<string[]>(
    (acc, item) => (item[key] ? [...acc, toNum ? +item[key] : item[key].toString()] : acc),
    []
  );

  const accessor = arr.reduce<Record<number | string, string>>((acc, item) => {
    acc[item[key]] = isConcenate
      ? `${item[displayKeyField || key] || (displayKeyField && '')} - ${item[value]}`
      : `${item[value]}`;
    return acc;
  }, {});

  return { data, accessor };
}


/**
 * It is not a react hook just a plain function, thus the naming convention is off
 * Due to it is a highly reused function, the refactor at the moment is to have this as an alias on top of the correct function
 * In the long run this should be removed in the favor of `getFieldDataAndAccessor` function
 * @deprecated Replace with `getFieldDataAndAccessor`
 */
export function useFieldDataAndAccessor(
  array?: any[],
  keyField?: string,
  valueField?: string,
  concenate?: boolean,
  displayKeyField?: string,
  dataIsNum?: boolean
) {
  return getFieldDataAndAccessor(array, keyField, valueField, concenate, displayKeyField, dataIsNum);
}

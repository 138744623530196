import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authService } from 'services';
import { CredentialsModel } from 'models';
import { Env } from 'config/env';
import { getProfile, clearProfile } from 'store/profile';

export const setTokenInStorage = (token: string | null, storageKey: string) => {
  if (token) {
    localStorage.setItem(storageKey, token);
  } else {
    localStorage.removeItem(storageKey);
  }
};

export interface AuthState {
  loading: boolean;
  token: string | null;
  refreshToken: string | null;
  cookieConsent: boolean;
}

export interface TokenType {
  accessToken: string;
  refreshToken: string;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    refreshToken: null,
    error: null,
    loading: false,
    cookieConsent: localStorage.getItem('cookieConsent') === 'agreed',
  } as AuthState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      if (!Env.REACT_APP_USE_COOKIE) {
        state.token = action.payload;
        setTokenInStorage(action.payload, Env.API_TOKEN_KEY);
      }
    },
    setRefreshToken: (state, action: PayloadAction<string | null>) => {
      if (!Env.REACT_APP_USE_COOKIE) {
        state.refreshToken = action.payload;
        setTokenInStorage(action.payload, Env.REFRESH_TOKEN_KEY);
      }
    },
    initToken(state) {
      state.token = localStorage.getItem(Env.API_TOKEN_KEY);
    },
    setCookieConsent: (state, action: PayloadAction<string>) => {
      state.cookieConsent = true;
      localStorage.setItem('cookieConsent', action.payload);
    },
  },
});

const { actions } = authSlice;

export const authReducer = authSlice.reducer;
export const { setCookieConsent } = authSlice.actions;

export const initToken = () => async (dispatch: AppDispatch) => {
  if (!Env.REACT_APP_USE_COOKIE) {
    dispatch(actions.initToken());
  }
};

export const setToken = (token: string | null) => async (dispatch: AppDispatch) => {
  if (!Env.REACT_APP_USE_COOKIE) {
    dispatch(actions.setToken(token));
  }
};

export const setRefreshToken = (token: string | null) => async (dispatch: AppDispatch) => {
  if (!Env.REACT_APP_USE_COOKIE) {
    dispatch(actions.setRefreshToken(token));
  }
};

export const setTestToken = () => async (dispatch: AppDispatch) => {
  if (!Env.REACT_APP_USE_COOKIE) {
    dispatch(actions.setToken('test-token'));
  }
};

export const logout =
  (callLogoutApi = true) =>
  async (dispatch: AppDispatch) => {
    try {
      if (callLogoutApi) {
        await authService.logout();
      }
    } finally {
      dispatch(actions.setToken(null));
      dispatch(actions.setRefreshToken(null));
      dispatch(clearProfile());
    }
  };

export const login =
  ({
    credentials,
    provider,
    accessToken,
    firebase,
  }: {
    credentials?: CredentialsModel;
    provider?: string | null;
    accessToken?: string;
    firebase?: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    const token: TokenType | null | void = credentials
      ? await authService.login(credentials)
      : await authService.socialLogin(provider as string, accessToken as string, firebase);

    dispatch(actions.setToken(token?.accessToken || null));
    dispatch(actions.setRefreshToken(token?.refreshToken || null));

    await dispatch(getProfile());

    return token;
  };

/**
 * istanbuljs doesn't know how to handle coverage for nullish
 * coalescing and optional chaining at the time of writing
 * https://github.com/istanbuljs/istanbuljs/issues/526
 */
export const refreshToken = () => async (dispatch: AppDispatch) => {
  const data = await authService.refreshToken();

  if (!Env.REACT_APP_USE_COOKIE) {
    dispatch(actions.setToken(data?.accessToken || /* istanbul ignore next */ null));
    dispatch(actions.setRefreshToken(data?.refreshToken || /* istanbul ignore next */ null));
  }

  return data;
};

export const selectCookieConsent = (state: ApplicationState) => {
  return state.auth.cookieConsent;
};

export const selectIsLoggedIn = (state: ApplicationState) => {
  return Boolean(state.profile.profile);
};

export const selectToken = (state: ApplicationState) => {
  return state.auth.token;
};

export const selectRefreshToken = (state: ApplicationState) => {
  return state.auth.refreshToken;
};

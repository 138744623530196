import React, { FC } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Button, Dialog, useDialogState } from 'components';

const Modals: FC = () => {
  const { closeDialog: closeSimpleDialog, isOpen: isSimpleOpen, openDialog: openSimpleDialog } = useDialogState();
  const {
    closeDialog: closeDialogWithoutClose,
    isOpen: isDialogWithoutCloseOpen,
    openDialog: openDialogWithoutClose,
  } = useDialogState();
  const {
    closeDialog: closeDialogLoadingState,
    isOpen: isDialogLoadingStateOpen,
    openDialog: openDialogLoadingState,
  } = useDialogState();

  return (
    <Box>
      <Typography variant="h6">Modal</Typography>

      <Box display="flex">
        <Box mr={2}>
          <Button onClick={openSimpleDialog}>Simple modal</Button>
        </Box>
        <Box mr={2}>
          <Button onClick={openDialogWithoutClose}>Modal without close button</Button>
        </Box>
        <Box>
          <Button onClick={openDialogLoadingState}>Modal loading state</Button>
        </Box>
      </Box>

      <Dialog
        open={isSimpleOpen}
        onClose={closeSimpleDialog}
        actions={
          <>
            <Button onClick={closeSimpleDialog} variant="outlined">
              Close
            </Button>
            <Button onClick={closeSimpleDialog}>Save</Button>
          </>
        }
        title="Simple dialog"
      >
        Simple dialog
      </Dialog>

      <Dialog
        open={isDialogLoadingStateOpen}
        onClose={closeDialogLoadingState}
        loading
        actions={
          <>
            <Button onClick={closeDialogLoadingState} variant="outlined">
              Close
            </Button>
            <Button onClick={closeDialogLoadingState}>Save</Button>
          </>
        }
        title="Dialog with loading state"
      >
        Dialog with loading state
      </Dialog>

      <Dialog
        open={isDialogWithoutCloseOpen}
        onClose={closeDialogWithoutClose}
        hideCloseButton
        actions={
          <>
            <Button onClick={closeDialogWithoutClose} variant="outlined">
              Close
            </Button>
            <Button onClick={closeDialogWithoutClose}>Save</Button>
          </>
        }
        title="Dialog without close button in title"
      >
        Dialog without close button in title
      </Dialog>
    </Box>
  );
};

export default Modals;

import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { ReadingUnitOrderModel } from 'models';
import { BaseDialogProps, Button, Checkbox, Dialog } from 'components';
import { useDeleteReadingUnitOrder } from 'features/readingUnits';

export interface ReadingOrderDeleteProps extends BaseDialogProps {
  readingUnitOrders: ReadingUnitOrderModel | ReadingUnitOrderModel[] | null;
}

const ReadingOrderDeleteDialog: FC<ReadingOrderDeleteProps> = ({ readingUnitOrders, ...props }) => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { onClose } = props;

  const closeDialog = () => {
    setLoading(false);
    setIsConfirmed(false);
    onClose();
  };
  const { mutateAsync: deleteReadingUnitOrder } = useDeleteReadingUnitOrder(closeDialog);

  const getIds = () => {
    if (Array.isArray(readingUnitOrders)) {
      return readingUnitOrders?.reduce<string[]>(
        (acc, curr) => [...acc, curr.meterId.toString()],
        []
      );
    }
    return [readingUnitOrders?.meterId.toString() || ''];
  };

  const handleChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const onCancel = () => {
    setIsConfirmed(false);
    onClose();
  };

  const onDelete = async () => {
    setLoading(true);
    if (readingUnitOrders) {
      await deleteReadingUnitOrder(getIds());
    }
    closeDialog();
  };

  return (
    <Dialog
      title={<Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.DELETE_ORDER_TITLE" />}
      onSubmit={onDelete}
      actions={
        <Box mt={2} display="flex">
          <Box mr={2}>
            <Button variant="outlined" onClick={onCancel} disabled={loading}>
              {<Trans i18nKey="COMMON.CANCEL" />}
            </Button>
          </Box>
          <Button onClick={onDelete} loading={loading} disabled={loading || !isConfirmed}>
            {<Trans i18nKey="COMMON.DELETE" />}
          </Button>
        </Box>
      }
      {...props}
    >
      <Checkbox
        multilineLabel
        checked={isConfirmed}
        onChange={handleChange}
        label={
          <>
            <Typography>
              <Trans
                i18nKey="LEO_LEO_EGYSEG_RESZLETEK.CONFIRM_LABEL"
                values={{ db: getIds().length !== 0 ? getIds().length : 1 }}
              />
            </Typography>
            <Typography mt={4}>
              <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.CONFIRM_LABEL_EXTENSION" />
            </Typography>
          </>
        }
      />
    </Dialog>
  );
};

export default ReadingOrderDeleteDialog;

import React from 'react';

import { Box, TextFieldProps } from '@mui/material';

import { Field } from 'formik';
import { IInputComponentParams } from 'components/input-fields/helpers/interfaces';
import LeoAutoCompleteMulti from './LeoAutoCompleteMulti';

export const LeoSelectFieldMulti = ({
  name,
  data,
  accessor,
  disabled,
  maxLength,
  freeSolo,
  width,
  blankFilterOption,
}: IInputComponentParams & TextFieldProps) => {
  return (
    <Box sx={{ width: `${width}%` }}>
      <Field
        type="text"
        name={name}
        component={LeoAutoCompleteMulti}
        options={data}
        disabled={disabled}
        accessor={accessor}
        maxLength={maxLength}
        freeSolo={freeSolo}
        blankFilterOption={blankFilterOption}
      />
    </Box>
  );
};

export default LeoSelectFieldMulti;

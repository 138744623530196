import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Button, Menu, Box, Typography, MenuItem } from '@mui/material';
import { ReactComponent as SplitScreenIcon } from 'assets/icons/Titasz/Split-screen.svg';

import { PageHeaderProps } from 'components/page/PageHeader';
import { stringify } from 'utils/base64';
import { ColumnProps } from 'components';
import { primary } from 'theme/colors';

const styles = {
  button: {
    mr: 2,
  },
  settingsHeader: {
    paddingBottom: 3,
  },
  settingsAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 2,
  },
  menuWrapper: {
    width: '320px',
    padding: 2,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableCount: {
    backgroundColor: primary[400],
    padding: '2px 6px',
    borderRadius: '100px',
  },
  endIcon: {
    '& .MuiButton-endIcon >*:nth-of-type(1)': { fontSize: '10px' },
    whiteSpace: 'noWrap',
  },
};

const TableView = ({
  setColumnsState,
  validColumns,
  onTableColumnVisibilityChange,
}: PageHeaderProps<unknown>) => {
  const isAnyHidden = useCallback(
    () => validColumns?.some((column) => column.hidden),
    [validColumns]
  );
  const visibleColumns = validColumns?.reduce(
    (acc, column) => (column.hidden || column.customCheckboxCell ? acc : acc + 1),
    0
  );

  const [isAnyColumnHidden, setIsAnyColumnHidden] = useState(isAnyHidden);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setIsAnyColumnHidden(isAnyHidden);
  }, [validColumns, isAnyHidden]);

  const onTableViewHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onColumnHandler = (key: string) => {
    if (setColumnsState) {
      setColumnsState((prevState) => {
        const newColState = prevState.reduce((acc: (ColumnProps<unknown> | null)[], column) => {
          if (column?.key === key && !column?.cannotBeHidden) {
            acc = [...acc, { ...column, hidden: !column.hidden }];
          } else {
            acc = [...acc, column];
          }
          return acc;
        }, []);

        columnParamsUpdate(newColState);
        return newColState;
      });
    }
  };

  const onShowHandler = () => {
    const toggle = (show: boolean) => {
      if (setColumnsState) {
        setColumnsState((prev) => {
          const newState = prev.map((column) => {
            if (!column?.cannotBeHidden && !column?.customCheckboxCell) {
              if (column) {
                column.hidden = !show;
              }
            }
            return column;
          });

          columnParamsUpdate(newState);
          return newState;
        });
      }
    };

    toggle(!!isAnyColumnHidden);
  };

  const columnParamsUpdate = (colArray: (ColumnProps<unknown> | null)[]) => {
    const newColState = colArray.reduce((acc: (ColumnProps<unknown> | null)[], item) => {
      if (item?.hidden) {
        acc = [...acc, { key: item.key, hidden: item.hidden }];
      }
      return acc;
    }, []);

    const stringifiedColState = stringify(newColState);

    if (onTableColumnVisibilityChange) {
      onTableColumnVisibilityChange(newColState.length ? stringifiedColState : undefined);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={styles.endIcon}
        onClick={onTableViewHandler}
        id="basic-button"
        startIcon={<SplitScreenIcon />}
        endIcon={
          <Typography sx={styles.tableCount} variant="cellHeaderSmall">
            {`${visibleColumns}/${
              validColumns?.filter((item) => !item.customCheckboxCell)?.length
            }`}
          </Typography>
        }
        variant="contained"
      >
        <Trans i18nKey="TABLE.TABLE_VIEW" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClickAway}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box sx={styles.menuWrapper}>
          <Box sx={styles.settingsHeader}>
            <Typography variant="subtitle2">
              <Trans i18nKey="TABLE.TABLE_SETTINGS" />
            </Typography>
          </Box>
          <Box sx={styles.settingsAction}>
            <Typography variant="caption">
              <Trans i18nKey="TABLE.SHOWN_IN_THE_TABLE" />
            </Typography>
            <Button variant="text" onClick={onShowHandler}>
              <Typography sx={{ textTransform: 'none' }} variant="caption">
                {isAnyColumnHidden ? (
                  <Trans i18nKey="TABLE.SHOW_ALL" />
                ) : (
                  <Trans i18nKey="TABLE.HIDE_ALL" />
                )}
              </Typography>
            </Button>
          </Box>
          <Box>
            {validColumns?.map((column) => {
              const visibilityIcon = column.hidden ? (
                <VisibilityOffOutlinedIcon fontSize="small" color="disabled" />
              ) : (
                <VisibilityIcon fontSize="small" color="primary" />
              );

              return !column.customCheckboxCell ? (
                <MenuItem
                  onClick={() => onColumnHandler(column.key)}
                  key={column.key}
                  sx={styles.menuItem}
                >
                  <Typography variant="cellLabel">{column.header || null}</Typography>
                  {visibilityIcon}
                </MenuItem>
              ) : null;
            })}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default TableView;

import { useQuery } from '@tanstack/react-query';

import { modificationService } from 'services';
import { QueryContextFromKeys, QueryParamModel } from 'models';

type ModificationQueryContexts = QueryContextFromKeys<typeof modificationKeys>;

export const modificationKeys = {
  all: [{ scope: 'modification' }] as const,
  lists: () => [{ ...modificationKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...modificationKeys.lists()[0], params }] as const,
};

const fetchModificationList = async ({
  queryKey: [{ params }],
}: ModificationQueryContexts['list']) => modificationService.getModificationList(params);

const exportModificationList = async ({
  queryKey: [{ params }],
}: ModificationQueryContexts['list']) => modificationService.exportModifications(params);

export const useModificationList = (params?: QueryParamModel | null) =>
  useQuery(modificationKeys.list(params), fetchModificationList, {
    enabled: !!params?.filter,
  });

export const useExportModificationList = (params?: QueryParamModel | null) =>
  useQuery(modificationKeys.list(params), exportModificationList, { enabled: false });

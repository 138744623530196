import React, { FC, useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useQueryClient } from '@tanstack/react-query';

import { MessageRounded } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';

import {
  ColumnProps,
  Page,
  PageHeaderAction,
  showNotification,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { parse, stringify } from 'utils/base64';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import {
  useExportWorkers,
  useSyncTask,
  useSyncWorkers,
  useWorkerListSearch,
  useWorkerPageList,
  workersKeys,
} from 'features/workers';
import { FilterOptionModel, NotificationType, QueryParamModel, WorkerViewModel } from 'models';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoCheckBox from 'components/input-fields/leoCheckBox/LeoCheckBox';
import { useValueSetList } from 'features/valueSets';
import { formatAddress, getPageName } from 'utils/format';
import { formatDate } from 'utils/dates';
import { RouteNames } from 'config/routeNames';
import WorkerEditorDialog from 'pages/Kozos/Workers/WorkersEditorDialog';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { selectUserRoles } from 'store/profile';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

import SendMessageDialog from './SendMessageDialog';

const WorkersPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({
    order: stringify([{ orderBy: 'sapId' }]),
  });

  const apiParamsWithDeleted = (paramsToConvert: Partial<QueryParamModel>) => {
    const parsedFilter = parse(paramsToConvert?.filter);
    if (parsedFilter?.withDeleted && parsedFilter.withDeleted[0] === 'on') {
      return { ...paramsToConvert, filter: stringify({ ...parsedFilter, withDeleted: true }) };
    }
    return paramsToConvert;
  };

  const { isInitialLoading, isRefetching, refetch } = useExportWorkers(
    apiParamsWithDeleted(exportParams)
  );
  const {
    data: workersData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useWorkerPageList(apiParamsWithDeleted(apiParams));
  const { data: filterWorkersData } = useWorkerListSearch({ withDeleted: true });
  const { mutateAsync: syncWorkers, isLoading: isSyncLoading } = useSyncWorkers();
  const { mutateAsync: syncTask } = useSyncTask();
  const [isSyncTaskLoading, setIsSyncTaskLoading] = useState(false);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOKOZPONT'],
  });
  const queryClient = useQueryClient();

  const { data: filterWorkerData, accessor: filterWorkerAccessor } = useFieldDataAndAccessor(
    filterWorkersData,
    'sapId',
    'name',
    true
  );

  const { data: readingCenters, accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );

  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.WORKERS_PAGE_ACTIONS, roles);

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<WorkerViewModel>();

  const {
    isOpen: isMessageOpen,
    openDialog: openMessage,
    closeDialog: closeMessage,
    selected: selectedSendMessage,
  } = useDialogState<WorkerViewModel[]>();

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'MUNKAVEGZOK.SAP_ID',
      component: (
        <LeoSelectFieldMulti
          name="sapId"
          data={filterWorkerData}
          accessor={filterWorkerAccessor}
          label="MUNKAVEGZOK.SAPID"
          freeSolo
        />
      ),
      name: 'sapId',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'MUNKAVEGZOK.AD_USER',
      component: <LeoTextField name="email" label={<Trans i18nKey="MUNKAVEGZOK.AD_USER" />} />,
      name: 'email',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.AD_USER'),
    },
    {
      headerTitle: 'MUNKAVEGZOK.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenter"
          data={readingCenters}
          label="MUNKAVEGZOK.READING_CENTER"
        />
      ),
      name: 'readingCenter',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
    {
      headerTitle: 'MUNKAVEGZOK.INACTIVE_USERS',
      component: (
        <LeoCheckBox
          name="withDeleted"
          label={<Trans i18nKey="MUNKAVEGZOK.SHOW_INACTIVE_USERS" />}
        />
      ),
      name: 'withDeleted',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.INACTIVES'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_MUNKAVEGZOK),
    isFetching,
  });

  const panels = [filterConfig];

  const workersColumns: ColumnProps<WorkerViewModel>[] = [
    {
      key: 'readingCenter',
      header: <Trans i18nKey="MUNKAVEGZOK.READING_CENTER" />,
      accessor: 'readingCenter',
      tooltip: (item: WorkerViewModel) =>
        item.readingCenter && leoKozpontAccessor[item.readingCenter],
      sortable: true,
    },
    {
      key: 'sapId',
      header: <Trans i18nKey="MUNKAVEGZOK.SAP_ID" />,
      accessor: 'sapId',
      sortable: true,
    },
    {
      key: 'email',
      header: <Trans i18nKey="MUNKAVEGZOK.AD_USER" />,
      accessor: 'email',
      sortable: true,
    },
    {
      key: 'name',
      header: <Trans i18nKey="MUNKAVEGZOK.NAME" />,
      accessor: 'name',
      sortable: true,
    },
    {
      key: 'internalId',
      header: <Trans i18nKey="MUNKAVEGZOK.INTERNAL_ID" />,
      accessor: 'internalId',
      sortable: true,
    },
    {
      key: 'address',
      header: <Trans i18nKey="MUNKAVEGZOK.ADDRESS" />,
      accessor: (worker: WorkerViewModel) => formatAddress(worker.address),
      field: 'address.city',
      sortable: true,
    },
    {
      key: 'phone',
      header: <Trans i18nKey="MUNKAVEGZOK.PHONE" />,
      accessor: 'phone',
      sortable: true,
    },
    {
      key: 'employmentStartDate',
      header: <Trans i18nKey="MUNKAVEGZOK.EMPLOYMENT_START_DATE" />,
      accessor: (worker: WorkerViewModel) => formatDate(worker.employmentStartDate),
      field: 'employmentStartDate',
      sortable: true,
    },
    {
      key: 'employmentEndDate',
      header: <Trans i18nKey="MUNKAVEGZOK.EMPLOYMENT_END_DATE" />,
      accessor: (worker: WorkerViewModel) => formatDate(worker.employmentEndDate),
      field: 'employmentEndDate',
      sortable: true,
    },
  ];

  const syncById = useCallback(
    async (jobId: string) => {
      setIsSyncTaskLoading(true);
      const { status, failedReason } = await syncTask(jobId);
      if (failedReason) {
        showNotification({
          content: t('MUNKAVEGZOK.SYNC_FAIL'),
          type: NotificationType.ERROR,
        });
        setIsSyncTaskLoading(false);
        return;
      }

      if (status !== 'completed') {
        setTimeout(async () => syncById(jobId), 3000);
      } else {
        await queryClient.invalidateQueries({ queryKey: workersKeys.lists() });
        showNotification({
          content: t('MUNKAVEGZOK.SYNC_SUCCESS'),
          type: NotificationType.SUCCESS,
        });
        setIsSyncTaskLoading(false);
      }
    },
    [queryClient, syncTask]
  );

  const workerHeaderActions = useMemo<PageHeaderAction[]>(
    () => [
      {
        name: 'sync',
        label: 'MUNKAVEGZOK.SYNC_BUTTON',
        icon: <SyncIcon fill="currentColor" />,
        onClick: async () => {
          const { jobId } = await syncWorkers();
          await syncById(jobId);
        },
        isLoading: isSyncLoading || isSyncTaskLoading,
        visible,
      },
      {
        name: 'message',
        label: 'LEO_LEO_EGYSEGEK.BULK_SEND_MESSAGE',
        icon: <MessageRounded fontSize="small" />,
        onClick: (row) => openMessage(row),
        isBulkAction: true,
        visible,
      },
      {
        name: 'export',
        label: 'COMMON.EXPORT',
        icon: <Export fill="currentColor" />,
        onClick: () => refetch(),
        isLoading: isInitialLoading || isRefetching,
        disabledIf: !exportParams?.filter,
      },
    ],
    [
      isSyncLoading,
      isSyncTaskLoading,
      visible,
      isInitialLoading,
      isRefetching,
      exportParams?.filter,
      syncWorkers,
      syncById,
      openMessage,
      refetch,
    ]
  );

  return (
    <Page
      title={<Trans i18nKey="MUNKAVEGZOK.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
      panels={panels}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ leoKozpontAccessor }}
          panels={panels}
          setPanel={setPanel}
          activePanel={activePanel}
          timestamp={dataUpdatedAt}
          list={workersData?.data || []}
          total={workersData?.meta?.total}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={workersColumns}
          enableCheckbox={visible}
          actions={
            visible
              ? [
                  {
                    name: 'edit',
                    label: 'COMMON.EDIT',
                    icon: <EditIcon fontSize="small" />,
                    onClick: (worker) => openEditor(worker),
                  },
                  {
                    name: 'message',
                    label: 'MUNKAVEGZOK.SEND_MESSAGE',
                    icon: <MessageRounded fontSize="small" />,
                    onClick: (worker) => openMessage(worker),
                  },
                ]
              : []
          }
          pageHeaderActions={workerHeaderActions}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          onLoadQuery={onLoadQuery}
        />
      )}
      <WorkerEditorDialog open={isEditorOpen} selected={selectedEdit} onClose={closeEditor} />
      <SendMessageDialog
        open={isMessageOpen}
        selected={selectedSendMessage}
        onClose={closeMessage}
        isWorkerModel
      />
    </Page>
  );
};

export default WorkersPage;

import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';

import { CircularProgress } from '@mui/material';
import { Cached, MessageRounded, Panorama } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';

import { ReadingUnitOrderModel } from 'models';
import {
  DropdownMenuItem,
  Page,
  PageHeaderAction,
  Table,
  useDialogState,
  useTableQueryParams,
} from 'components';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { openPhotoInNewTab } from 'utils/getPhotoLink';
import { SortDirection, arrayify } from 'utils/arrays';
import { stringify } from 'utils/base64';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';
import { getFunctionRole, FunctionRoleName } from 'config/functionRole';
import {
  useExportReadingUnitOrders,
  useReadingUnit,
  useReadingUnitOrderList,
} from 'features/readingUnits';
import { useValueSetList } from 'features/valueSets';
import SendMessageDialog from 'pages/Kozos/Workers/SendMessageDialog';
import ReadingOrderDeleteDialog from 'pages/Leo/ReadingUnitDetails/ReadingOrderDeleteDialog';
import ReadingOrderDetailsDialog from 'pages/Leo/ReadingUnitDetails/ReadingOrderDetailsDialog';
import TransferTaskDialog, { TransferTaskType } from 'pages/Leo/ReadingUnits/TransferTaskDialog';
import { selectUserRoles } from 'store/profile';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { PhotoAccess } from 'config/photosConfig';

export const getYesOrNoLabel = (isTrue?: boolean) => (
  <Trans i18nKey={`COMMON.${isTrue ? 'YES' : 'NO'}`} />
);

const ReadingUnitDetailsPage: FC = () => {
  const { id, workerId } = useParams();
  const { t } = useTranslation();
  const roles = useSelector(selectUserRoles);

  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({
    order: stringify([{ orderBy: 'workerSapId', order: SortDirection.ASC }]),
    filter: stringify({ workerId: workerId && JSON.parse(workerId) }),
  });

  const { isOpen, openDialog, closeDialog, selected } = useDialogState<ReadingUnitOrderModel>();

  const {
    data: readingUnitOrders,
    isLoading,
    dataUpdatedAt,
  } = useReadingUnitOrderList(Number(id), omit(apiParams, ['page', 'pageSize']));

  const { data: readingUnit, isLoading: isReadingUnitLoading } = useReadingUnit(Number(id));

  const { isInitialLoading, isRefetching, refetch } = useExportReadingUnitOrders(
    Number(id),
    exportParams
  );

  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList();

  const visible = getFunctionRole(FunctionRoleName.READING_UNIT_PAGE_ACTIONS, roles);

  const readingUnitCode = useMemo(
    () =>
      t('LEO_LEO_EGYSEG_RESZLETEK.TITLE_WITH_ID', {
        id: readingUnit?.readingUnitCode,
      }),
    [readingUnit?.readingUnitCode, t]
  );

  const readingUnitOrderColumns = [
    {
      key: 'workerSapId',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.WORKER_ID" />,
      accessor: 'workerSapId',
      sortable: true,
    },
    {
      key: 'sapConsumptionPlaceId',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.CONSUMPTION_PLACE_ID" />,
      accessor: 'sapConsumptionPlaceId',
      sortable: true,
    },
    {
      key: 'bindingId',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.BINDING_ID" />,
      accessor: 'bindingId',
      sortable: true,
    },
    {
      key: 'orderId',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.ORDER_ID" />,
      accessor: 'orderId',
      sortable: true,
    },
    {
      key: 'deviceSerialNumber',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.DEVICE_SERIAL_NUMBER" />,
      accessor: 'deviceSerialNumber',
      sortable: true,
    },
    {
      key: 'readingResult',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.READING_RESULT" />,
      accessor: 'readingResult',
      sortable: true,
    },
    {
      key: 'readingFailureReason',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.FAILURE_REASON" />,
      accessor: 'readingFailureReason',
      tooltip: (readingUnitOrder: ReadingUnitOrderModel) =>
        readingUnitOrder.readingFailureReason &&
        getValueSetValues(valueSet, 'NLO', readingUnitOrder.readingFailureReason),
      sortable: true,
    },
    {
      key: 'revokedBySapAt',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.REVOKED_BY_SAP_AT" />,
      accessor: (readingUnitOrder: ReadingUnitOrderModel) =>
        formatDate(readingUnitOrder.revokedBySapAt, DATE_TIME_FORMAT),
      field: 'revokedBySapAt',
      sortable: true,
    },
    {
      key: 'readingMode',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.READING_TYPE" />,
      accessor: 'readingMode',
      tooltip: (readingUnitOrder: ReadingUnitOrderModel) =>
        readingUnitOrder.readingMode &&
        getValueSetValues(valueSet, 'LM', readingUnitOrder.readingMode),
      sortable: true,
    },
    {
      key: 'isSapSynced',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.IS_SAP_SYNCED" />,
      accessor: (readingUnitOrder: ReadingUnitOrderModel) =>
        getYesOrNoLabel(!!readingUnitOrder.isSapSynced),
      field: 'isSapSynced',
      sortable: true,
    },
    {
      key: 'address',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.CONSUMPTION_PLACE_ADDRESS" />,
      accessor: 'address',
      sortable: true,
    },
    {
      key: 'consumptionPlaceName',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.CONSUMPTION_PLACE_NAME" />,
      accessor: 'consumptionPlaceName',
      sortable: true,
    },
  ];

  const viewPhotoHandler = ({ orderId }: ReadingUnitOrderModel) =>
    openPhotoInNewTab(PhotoAccess.READING_UNIT_DETAILS, orderId);

  const {
    isOpen: isMessageOpen,
    selected: selectedSendMessage,
    openDialog: openMessage,
    closeDialog: closeMessage,
  } = useDialogState<ReadingUnitOrderModel[]>();

  const {
    isOpen: isTransferOpen,
    openDialog: openTransfer,
    closeDialog: closeTransfer,
    selected: selectedTransfer,
  } = useDialogState<ReadingUnitOrderModel[]>();

  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    selected: selectedDelete,
  } = useDialogState<ReadingUnitOrderModel[]>();

  const checkIfTransferDisabled = (rows: ReadingUnitOrderModel[] | ReadingUnitOrderModel) =>
    arrayify(rows)?.some((r) => r.readingResult !== null || r.revokedBySapAt);

  const headerActions = useMemo<PageHeaderAction[]>(() => {
    return [
      {
        name: 'export',
        label: 'COMMON.EXPORT',
        icon: <Export fill="currentColor" />,
        onClick: () => refetch(),
        isLoading: isInitialLoading || isRefetching,
      },
      {
        name: 'transfer',
        label: 'LEO_LEO_EGYSEGEK.BULK_TRANSFER',
        icon: <Cached fontSize="small" />,
        onClick: (row) => openTransfer(row),
        disabledIf: (rows: ReadingUnitOrderModel[]) => checkIfTransferDisabled(rows),
        isBulkAction: true,
        visible,
      },
      {
        name: 'message',
        label: 'LEO_LEO_EGYSEGEK.BULK_SEND_MESSAGE',
        icon: <MessageRounded fontSize="small" />,
        onClick: (row) => openMessage(row),
        isBulkAction: true,
        visible,
      },
      {
        name: 'delete',
        label: 'LEO_LEO_EGYSEG_RESZLETEK.BULK_DELETE',
        icon: <DeleteIcon fontSize="small" />,
        onClick: (row) => openDelete(row),
        isBulkAction: true,
        visible,
      },
    ];
  }, [openTransfer, openMessage, openDelete, refetch, isInitialLoading, isRefetching, visible]);

  const readingUnitDetailsActions = [
    {
      name: 'order',
      label: 'LEO_LEO_EGYSEG_RESZLETEK.ORDER_DETAILS_BUTTON',
      icon: <AssignmentIcon fontSize="small" />,
      onClick: (row) => openDialog(row),
    },
    {
      name: 'transfer',
      label: 'LEO_LEO_EGYSEGEK.TRANSFER',
      icon: <Cached fontSize="small" />,
      onClick: (row) => openTransfer(row),
      disabledIf: (row: ReadingUnitOrderModel) => checkIfTransferDisabled(row),
      visible,
    },
    {
      name: 'delete',
      label: 'LEO_LEO_EGYSEG_RESZLETEK.DELETE_TITLE',
      icon: <DeleteIcon fontSize="small" />,
      onClick: (row) => openDelete(row),
      visible,
    },
    {
      name: 'message',
      label: 'MUNKAVEGZOK.SEND_MESSAGE',
      icon: <MessageRounded fontSize="small" />,
      onClick: (row) => openMessage(row),
      visible,
    },
    {
      name: 'photo',
      label: 'LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PHOTO_BUTTON',
      icon: <Panorama fontSize="small" />,
      onClick: (row) => viewPhotoHandler(row),
      visible,
    },
  ] as DropdownMenuItem[];

  return (
    <Page
      breadcrumbCustomLabel={readingUnitCode}
      title={!isReadingUnitLoading ? readingUnitCode : <CircularProgress />}
      inScroll
    >
      {!isValueSetLoading && (
        <Table
          keyField="orderId"
          list={readingUnitOrders?.data || []}
          total={readingUnitOrders?.meta.total}
          timestamp={dataUpdatedAt}
          actions={readingUnitDetailsActions}
          pageHeaderActions={headerActions}
          loading={isLoading}
          hasDetailParam={id}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          columns={readingUnitOrderColumns}
          onRowDoubleClick={openDialog}
          disablePagination={true}
        />
      )}
      {selected && (
        <ReadingOrderDetailsDialog
          open={isOpen}
          id={id || ''}
          readingUnitOrder={selected}
          onClose={closeDialog}
          viewPhotoHandler={viewPhotoHandler}
        />
      )}
      <ReadingOrderDeleteDialog
        open={isDeleteOpen}
        readingUnitOrders={selectedDelete}
        onClose={closeDelete}
      />
      <SendMessageDialog
        open={isMessageOpen}
        selected={selectedSendMessage}
        onClose={closeMessage}
      />
      <TransferTaskDialog
        open={isTransferOpen}
        onClose={closeTransfer}
        selected={selectedTransfer}
        type={TransferTaskType.TRANSFER_CONSUMPTION_PLACE}
        defaultDownloadDate={new Date(Date.now())}
      />
    </Page>
  );
};

export default ReadingUnitDetailsPage;

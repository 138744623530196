import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  ColumnProps,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, ModificationsModel } from 'models';
import { formatAddress, getPageName } from 'utils/format';
import Yup from 'utils/yup';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { RouteNames } from 'config/routeNames';
import { useValueSetList } from 'features/valueSets';
import { useExportModificationList, useModificationList } from 'features/modification';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const DataModificationsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: modifications,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useModificationList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportModificationList(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['FHF'],
  });

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_ADATPONTOSITASOK.DATE',
      component: <LeoDateInterval name="date" />,
      name: 'date',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.DATE'),
    },
    {
      headerTitle: 'LEO_ADATPONTOSITASOK.ORDER_ID',
      component: (
        <LeoTextField name="orderId" label={<Trans i18nKey="LEO_ADATPONTOSITASOK.ORDER_ID" />} />
      ),
      name: 'orderId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.ORDERID'),
    },
    {
      headerTitle: 'LEO_ADATPONTOSITASOK.SAP_CP_ID',
      component: (
        <LeoTextField
          name="sapConsumptionPlaceId"
          label={<Trans i18nKey="LEO_ADATPONTOSITASOK.SAP_CP_ID" />}
        />
      ),
      name: 'sapConsumptionPlaceId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.CONSUMPTION_PLACE_ID'),
    },
    {
      headerTitle: 'LEO_ADATPONTOSITASOK.PARTNER_ID',
      component: (
        <LeoTextField
          name="partnerSapId"
          label={<Trans i18nKey="LEO_ADATPONTOSITASOK.PARTNER_ID" />}
        />
      ),
      name: 'partnerSapId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.PARTNER_ID'),
    },
    {
      headerTitle: 'LEO_ADATPONTOSITASOK.SERIAL_NUMBER',
      component: (
        <LeoTextField
          name="serialNumber"
          label={<Trans i18nKey="LEO_ADATPONTOSITASOK.SERIAL_NUMBER" />}
        />
      ),
      name: 'serialNumber',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.DEVICE_SERIAL_NUMBER'),
    },
  ];

  const validationSchema = Yup.object().shape({
    date: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_ADATPONTOSITASOK),
    isFetching,
    validationSchema,
  });

  const panels = [filterConfig];

  const columns: ColumnProps<ModificationsModel>[] = [
    {
      key: 'readingOrderId',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.READING_ORDER_ID" />,
      accessor: 'readingOrderId',
      sortable: true,
    },
    {
      key: 'sapSmOrderId',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.UCSE_ORDER_ID" />,
      accessor: 'sapSmOrderId',
      sortable: true,
    },
    {
      key: 'createdAt',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.UPDATED_AT" />,
      accessor: (item: ModificationsModel) =>
        item.createdAt && formatDate(item.createdAt, DATE_TIME_FORMAT),
      field: 'createdAt',
      sortable: true,
    },
    {
      key: 'sapConsumptionPlaceId',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.SAP_CP_ID" />,
      accessor: 'sapConsumptionPlaceId',
      sortable: true,
    },
    {
      key: 'address',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.ADDRESS" />,
      accessor: (item: ModificationsModel) => formatAddress(item.address),
      field: 'address.city',
      sortable: true,
    },
    {
      key: 'type',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.TYPE" />,
      accessor: (item: ModificationsModel) =>
        item.type && getValueSetValues(valueSet, 'FHF', item.type),
      field: 'type',
      sortable: true,
    },
    {
      key: 'partnerSapId',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.PARTNER_SAP_ID" />,
      accessor: 'partnerSapId',
      sortable: true,
    },
    {
      key: 'name',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.PARTNER_NAME" />,
      accessor: 'name',
      sortable: true,
    },
    {
      key: 'phones',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.PHONE_NUMBERS" />,
      accessor: (item: ModificationsModel) =>
        item.phones?.map((number, i) => (i !== 0 ? `, ${number}` : number)),
      field: 'phones',
      sortable: true,
    },
    {
      key: 'serialNumber',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.SERIAL_NUMBER" />,
      accessor: 'serialNumber',
      sortable: true,
    },
    {
      key: 'updatedSerialNumber',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.UPDATED_SERIAL_NUMBER" />,
      accessor: 'updatedSerialNumber',
      sortable: true,
    },
    {
      key: 'certificationYear',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.CERTIFICATION_YEAR" />,
      accessor: 'certificationYear',
      sortable: true,
    },
    {
      key: 'placement',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.PLACEMENT" />,
      accessor: 'placement',
      sortable: true,
    },
    {
      key: 'sequenceNumber',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.SEQUENCE_NUMBER" />,
      accessor: 'sequenceNumber',
      sortable: true,
    },
    {
      key: 'numberOfDigits',
      header: <Trans i18nKey="LEO_ADATPONTOSITASOK.NUMBER_OF_DIGITS" />,
      accessor: 'numberOfDigits',
      sortable: true,
    },
  ];

  const headerAction = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="LEO_ADATPONTOSITASOK.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
      panels={panels}
    >
      {!isValueSetLoading && (
        <Table
          keyField="rowId"
          panels={panels}
          setPanel={setPanel}
          activePanel={activePanel}
          timestamp={dataUpdatedAt}
          list={modifications?.data || []}
          total={modifications?.meta?.total}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
          pageHeaderActions={headerAction}
          enableCheckbox={false}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default DataModificationsPage;

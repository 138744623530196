import { useLocation } from 'react-router-dom';

const useHideBreadCrumbToolbar = (): boolean => {
  const { pathname } = useLocation();

  // list of routes where you want the breadcrumb toolbar to be hidden
  const hideBreadCrumbToolbarRoutes = ['', 'no-access', 'not-found'];

  return hideBreadCrumbToolbarRoutes.includes(pathname.substring(1));
};

export default useHideBreadCrumbToolbar;

export enum RouteNames {
  HOME = 'home',
  LEO = 'leolvasas',
  LEO_LEO_EGYSEGEK = 'leolvasas/leolvasasi-egysegek',
  LEO_LEO_RENDELESEK = 'leolvasas/leolvasasi-rendelesek',
  LEO_LEO_EGYSEG_RESZLETEK = 'leolvasas/leolvasasi-egysegek/:id',
  LEO_LEO_KOZPONT_ALAPERTEKEK = 'leolvasas/leo-kozpont-alapertekek',
  LEO_STATISZTIKAK = 'leolvasas/statisztikak',
  LEO_LEOLVASO_STATISZTIKA = 'leolvasas/statisztikak/leolvaso-statisztika',
  LEO_NEM_LEOLVASHATO_RENDELESEK = 'leolvasas/statisztikak/nem-leolvashato-rendelesek',
  LEO_JELENTESEKET_TARTALMAZO_RENDELESEK = 'leolvasas/statisztikak/jelenteseket-tartalmazo-rendelesek',
  LEO_BECSLESBOL_KIZARASOK = 'leolvasas/statisztikak/becslesbol-kizarasok',
  LEO_MUNKAVEDELMI_JELZESEK = 'leolvasas/statisztikak/munkavedelmi-jelzesek',
  LEO_FOTO_MEGHIUSULASOK_OKA = 'leolvasas/statisztikak/foto-meghiusulasok-oka',
  LEO_MONITORING = 'leolvasas/monitoring',
  LEO_JELENTES_KODOK = 'leolvasas/monitoring/jelentes-kodok',
  LEO_JELENTES_KODOK_LEOLVASONKENT = 'leolvasas/monitoring/jelentes-kodok-leolvasonkent',
  LEO_NEM_LEOLVASHATOSAGI_OKOK = 'leolvasas/monitoring/nem-leolvashatosagi-okok',
  LEO_LEOLVASOTTSAG = 'leolvasas/monitoring/leolvasottsag',
  LEO_LOGOK = 'leolvasas/logok',
  LEO_KOMMUNIKACIOS_LOGOK = 'leolvasas/logok/kommunikacios-logok',
  LEO_ERTESITO_JELZESEK = 'leolvasas/logok/ertesito-jelzesek',
  UCSE = 'ugyfelcsere',
  KOZOS_FUNKCIOK = 'kozos-funkciok',
  KOZOS_MUNKAVEGZOK = 'kozos-funkciok/munkavegzok',
  KOZOS_OPEM_FELHASZNALOK = 'kozos-funkciok/opem-felhasznalok',
  LEO_ADATPONTOSITASOK = 'kozos-funkciok/adatpontositasok',
  KOZOS_H_S_JELZESEK = 'kozos-funkciok/h-s-jelzesek',
  KOZOS_MUVELETI_NAPLO = 'kozos-funkciok/muveleti-naplo',
  KOZOS_TIME_LOG = 'kozos-funkciok/time-log',
  KOZOS_SAP_FELTOLTES_MANUALIS = 'kozos-funkciok/sap-feltoltes-manualis',
  KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK = 'kozos-funkciok/sap-leolvasasi-rendelesek',
  KOZOS_ERTEKKESZLET = 'kozos-funkciok/ertekkeszlet',
  KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE = 'kozos-funkciok/munkaszuneti-napok-feltoltese',
  KOZOS_DOKUMENTUM_FELTOLTES = 'kozos-funkciok/dokumentum-feltoltes',
  KOZOS_TELJESITMENYBER = 'kozos-funkciok/teljesitmenyber',
  KOZOS_TELJESITMENYBEREK = 'kozos-funkciok/teljesitmenyber/teljesitmenyberek',
  KOZOS_TELJESITMENYBER_SULYSZAMOK = 'kozos-funkciok/teljesitmenyber/sulyszamok',
  KOZOS_BERELEMEK = 'kozos-funkciok/teljesitmenyber/berelemek',
  KOZOS_TARIFAK = 'kozos-funkciok/teljesitmenyber/tarifak',
  KOZOS_TARIFAK_BERELEMEK = 'kozos-funkciok/teljesitmenyber/tarifak/berelemek',
  KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK = 'kozos-funkciok/teljesitmenyber/tarifak/nem-leolvashatosagi-ok',
  KOZOS_TARIFAK_JELENTESEK = 'kozos-funkciok/teljesitmenyber/tarifak/jelentesek',
  KOZOS_FELADAT_BETOLTES = 'kozos-funkciok/feladat-betoltes',
  KOZOS_FELADAT_IMPORT = 'kozos-funkciok/feladat-betoltes/feladat-import',
  KOZOS_FELADAT_BETOLTES_EXCELBOL = 'kozos-funkciok/feladat-betoltes/feladat-betoltes-excelbol',
  KOZOS_RENDELES_AZONOSITOK_CSEREJE = 'kozos-funkciok/feladat-betoltes/rendeles-azonositok-csereje',
  UCSE_RENDELESEK = 'ugyfelcsere/ucse-rendelesek',
  UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE = 'ugyfelcsere/korzetek-leolvasohoz-rendelese',
  UCSE_KORZETKIZARASOK = 'ugyfelcsere/korzetkizarasok',
  UCSE_RENDELKEZESRE_ALLAS = 'ugyfelcsere/ucse-rendelkezesre-allas',
  UCSE_STATISZTIKAK = 'ugyfelcsere/statisztikak',
  UCSE_IDOPONTFOGLALAS = 'ugyfelcsere/idopontfoglalas',
  UCSE_TELEPULES_LISTA_KAPACITAS = 'ugyfelcsere/statisztikak/telepules-lista-kapacitas',
  UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK = 'ugyfelcsere/statisztikak/hatarido-utan-teljesult-feladatok',
  UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK = 'ugyfelcsere/statisztikak/hataridore-nem-teljesult-feladatok',
}

import React, { ElementType, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, ButtonBase, Card, Typography, SvgIcon } from '@mui/material';

import { MenuConfigModel } from 'models';
import { mergeSx } from 'utils/styles';
import { CardLayout } from 'config/cardLayout';
import { selectUserRoles } from 'store/profile';
import { neutral, primary } from 'theme/colors';
import { ReactComponent as NavigateNextIcon } from 'assets/icons/Material/navigateNext.svg';
import { getCardItems } from './MenuCardPage/MenuCardPage';

interface ICard {
  menuConfig: MenuConfigModel;
}

const styles = {
  mainContent: {
    '@media (min-width: 1600px)': {
      display: 'flex',
      boxShadow: 'none',
      p: 4,
      border: `1px solid ${neutral[100]}`,
      borderRadius: 3,
    },
    '@media (max-width: 1600px)': {
      display: 'flex',
      boxShadow: 'none',
      p: 2.5,
      border: `1px solid ${neutral[100]}`,
      borderRadius: 3,
    },
    '.MuiTypography-h6': {
      '@media (max-width: 1599px)': {
        fontSize: '16px',
      },
    },
    '.MuiTypography-subtitle1': {
      '@media (max-width: 1599px)': {
        fontSize: '14px',
      },
    },
  },
  compactView: {
    alignItems: 'center',
    '@media (min-width: 1600px)': {
      width: '560px',
      height: '112px',
    },
    '@media (max-width: 1599px)': {
      width: '410px',
      height: '82px',
    },
  },
  largeView: {
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    '@media (min-width: 1600px)': {
      width: '371px',
      height: '250px',
    },
    '@media (max-width: 1599px)': {
      width: '297px',
      height: '200px',
    },
  },
  cardText: {
    fontWeight: 600,
    textAlign: 'left',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: primary[100],
    '@media (min-width: 1600px)': {
      width: '56px',
      height: '56px',
    },
    '@media (max-width: 1599px)': {
      width: '44px',
      height: '44px',
    },
  },
  iconBox: {
    '@media (min-width: 1600px)': {
      minWidth: 80,
    },
    '@media (max-width: 1599px)': {
      minWidth: 64,
    },
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    '@media (min-width: 1600px)': {
      minWidth: 410,
    },
    '@media (max-width: 1599px)': {
      minWidth: 300,
    },
  },
};

export const MenuCard: FC<ICard> = ({ menuConfig: menuConfigParam }) => {
  const navigate = useNavigate();
  const roles = useSelector(selectUserRoles);
  const cardItems = getCardItems(menuConfigParam, roles);
  const menuConfig: MenuConfigModel = { ...menuConfigParam, children: cardItems };
  const { displayName, cardLayout, route } = menuConfig;
  const compactView = cardLayout === CardLayout.COMPACT;

  const handleClick = () => {
    setTimeout(() => {
      navigate(`/${route}`);
    }, 200);
  };

  return (
    <ButtonBase sx={{ borderRadius: 3 }} onClick={handleClick}>
      <Card sx={mergeSx(styles.mainContent, compactView ? styles.compactView : styles.largeView)}>
        <>
          {menuConfig.icon && (
            <Box sx={mergeSx(styles.iconBox, compactView ? null : { mb: 4 })}>
              <Box sx={mergeSx(styles.icon, menuConfig.iconColor)}>
                <SvgIcon component={menuConfig.icon as ElementType<any>} />
              </Box>
            </Box>
          )}
          <Box sx={styles.textBox}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant={compactView ? 'h6' : 'h5'}
                sx={mergeSx(styles.cardText, compactView ? null : { mb: 1 })}
              >
                <Trans i18nKey={displayName} />
              </Typography>
              {menuConfig.children && (
                <Typography variant="subtitle1">
                  {menuConfig.children.length} <Trans i18nKey={'MENU_CARD.OPTION_LABEL'} />
                </Typography>
              )}
            </Box>
            {menuConfig.children && compactView && (
              <Box display="flex" ml="auto" pr={1}>
                <SvgIcon fontSize="small" component={NavigateNextIcon} />
              </Box>
            )}
          </Box>
        </>
      </Card>
    </ButtonBase>
  );
};

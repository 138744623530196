import { useCallback, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

import { usePrevious } from 'utils/usePrevious';

export interface AutoSubmitFormProps {
  timeout?: number | null;
  isActive?: boolean | null;
}

export const AutoSubmitForm = <V extends FormikValues = FormikValues>({
  timeout,
  isActive = true,
}: AutoSubmitFormProps) => {
  const { submitForm, values, initialValues, dirty } = useFormikContext<V>();
  const previousValues = usePrevious(values || initialValues);
  const [isChanged, setIsChanged] = useState(false);

  const debounceTimeout = timeout || 500;

  // Because the parameter of useCallback is the debounce function and the linter can't resolve its dependencies
  // eslint-disable-next-line
  const triggerSubmit = useCallback(
    debounce(() => submitForm(), debounceTimeout),
    [submitForm, debounceTimeout]
  );

  useEffect(() => {
    if (dirty && !isChanged) {
      setIsChanged(true);
    }
    // We only want to listen to dirty change
    // eslint-disable-next-line
  }, [dirty]);

  useEffect(() => {
    if (values !== previousValues && (dirty || isChanged) && isActive) {
      triggerSubmit();
    }
    // We only want to listen to values change
    // Example: https://github.com/formium/formik/blob/e51f09a318cba216a1ba3932da0906202df0b979/examples/DebouncedAutoSave.js
    // eslint-disable-next-line
  }, [values]);

  return null;
};

import React, { ReactNode, useCallback, useState, useEffect, useMemo, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { get, isFinite, isFunction, isNumber } from 'lodash';

import { Box, TableHead, TableBody, Typography } from '@mui/material';

import {
  AccessController,
  Accessor,
  Column,
  FilterTypes,
  KeyExtractor,
  PageHeader,
  PageHeaderProps,
} from 'components';
import { PanelConfigModel, QueryParamModel } from 'models';
import { mergeSx } from 'utils/styles';
import { arrayify, removeEmpty } from 'utils/arrays';
import theme from 'theme';
import { colorCodes, neutral } from 'theme/colors';
import { Days, SubCells, AreaIds } from 'pages/Kozos/Statistics/FreeCapacity/tableHelpers';

import { TableRoot, BaseTableRootProps } from './components/TableRoot';
import { TableCell } from './components/TableCell';
import { TableRow } from './components/TableRow';
import { TableHeaderCell } from './components/TableHeaderCell';

interface ColumnProps<T> extends Column<T> {
  cannotBeHidden?: boolean;
  accessor?: Accessor<T>;
  field?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sortable?: boolean;
  noWrap?: boolean;
  padding?: 'normal' | 'checkbox' | 'none';
  hideSortIcon?: boolean;
  customCheckboxCell?: boolean;
  subCell?: string;
  dayTitle?: boolean;
}

export interface CapacityTableProps<T> extends BaseTableRootProps, PageHeaderProps<T> {
  /**
   * The list of items that is rendered in the table.
   */
  list: T[] | [];
  /**
   * Set of columns.
   */
  columns: (ColumnProps<T> | null)[];
  /**
   * List of actions that the user can trigger for the list items.
   */
  keyField?: KeyExtractor<T>;
  /**
   * Set it to false, if you don't want to display the checkboxes.
   */
  enableTableView?: boolean;
  /**
   * Gets the panel list.
   */
  panels?: (PanelConfigModel | null)[];
  /**
   * Gets the active panel.
   */
  activePanel?: string | null;
  /**
   * Sets the panel.
   */
  setPanel?: (config?: PanelConfigModel | null) => void;
  onAdd?: (item: any) => void;
  /**
   * Add checkbox to table. Used for checkbox table.
   */
  liststate?: T[];
  /**
   * State of the columns handler. Used for checkbox table.
   */
  colorCodes?: ReactNode;
  /**
   * Use when use spinner in details page.
   */
  useDetailParams?: string;
  /**
   * Use when use filter chips on the page.
   */
  onLoadQuery?: (query?: Partial<QueryParamModel> | null | undefined) => QueryParamModel;
  /**
   * Use to show filter details.
   */
  filterDetailsData?: FilterTypes;
}

const styles = {
  root: {
    ...theme.mixins.tableContainer,
  },
  stickyHeader: {
    zIndex: 1,
    position: 'sticky',
    top: '0px',
  },
  placeholderCell: {
    height: 56,
    maxWidth: 10,
  },
  emptyTable: {
    p: 3,
  },
  dayContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  borderRight: {
    borderRight: `1px solid ${neutral[100]}`,
  },
  borderRightBottom: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${neutral[100]}`,
    },
  },
  xCell: {
    borderBottom: 0,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: colorCodes.selected,
    },
  },
};

enum Signs {
  YES = 'x',
  NO = '-',
  PERCENTAGE = '%',
}

export const CapacityTable = <T extends Record<string, any>>({
  columns,
  onAdd,
  emptyState,
  list,
  keyField,
  panels,
  enableTableView = true,
  activePanel,
  setPanel,
  onTableColumnVisibilityChange,
  pageHeaderActions,
  useDetailParams = undefined,
  loading,
  onLoadQuery,
  filterDetailsData,
  ...props
}: CapacityTableProps<T>) => {
  const [selected, setSelected] = useState<T[]>([]);
  const [columnsState, setColumnsState] = useState<(ColumnProps<T> | null)[]>(columns);
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const arrayList = useMemo(() => arrayify(list), [list]);
  const validColumns = removeEmpty(columnsState);
  const useFilterParams = props.params?.filter;
  const uniqueField = typeof keyField === 'string' ? keyField : 'id';

  const getFieldName = (column: ColumnProps<T>) => {
    if (!column.sortable) {
      return null;
    }

    return typeof column.accessor === 'string' ? column.accessor : column.field;
  };

  const getColumnHeader = (column: ColumnProps<T>) => (column.header ? column.header : null);

  useEffect(() => {
    const filteredArray = selected.reduce<T[]>(
      (acc, selectedItem) =>
        arrayList.find((x) => selectedItem[uniqueField] === x[uniqueField])
          ? [...acc, selectedItem]
          : acc,
      []
    );

    setSelected(filteredArray);
    // We have to remove "selected" from the dependency list, because it would cause an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayList]);

  const isLoading = useFilterParams || useDetailParams ? loading : !loading;

  const emptyMessage = useFilterParams
    ? emptyState || <Trans i18nKey="COMMON.TABLE_EMPTY_MESSAGE" />
    : '';

  return (
    <Box sx={styles.root}>
      <PageHeader
        filterDetailsData={filterDetailsData}
        panels={panels}
        activePanel={activePanel}
        setPanel={setPanel}
        onTableView={enableTableView}
        selectedItems={selected}
        pageHeaderActions={pageHeaderActions}
        validColumns={validColumns}
        setColumnsState={setColumnsState}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        onAdd={onAdd}
        onLoadQuery={onLoadQuery}
        setFilterHeight={setFilterHeight}
      />
      <TableRoot {...props} loading={isLoading} filterHeight={filterHeight}>
        <TableHead sx={styles.stickyHeader}>
          <TableRow>
            <TableHeaderCell
              align="center"
              padding="normal"
              sx={styles.borderRight}
              colSpan={validColumns.length + 1}
            ></TableHeaderCell>

            {arrayList.length
              ? arrayList[0].TableItems.map((item: T) => {
                  return (
                    <TableHeaderCell key={item.dayTitle.title} sx={styles.borderRight} colSpan={5}>
                      <Box>
                        <Box sx={styles.dayContainer}>{item.dayTitle.title}</Box>
                        <Box sx={styles.dayContainer}>{item.dayTitle.date}</Box>
                      </Box>
                    </TableHeaderCell>
                  );
                })
              : null}
          </TableRow>

          <TableRow>
            <TableCell sx={styles.placeholderCell} padding="checkbox" rowSpan={3} />

            {validColumns.map((column) =>
              !column.hidden && !column.customCheckboxCell && !column.dayTitle ? (
                <AccessController allowedFor={column.allowedFor} key={column.key}>
                  <TableHeaderCell
                    sx={styles.borderRight}
                    align={column.align}
                    padding={column.padding}
                    hideSortIcon={column.hideSortIcon}
                    field={getFieldName(column)}
                  >
                    {getColumnHeader(column)}
                  </TableHeaderCell>
                </AccessController>
              ) : null
            )}
            {arrayList.length
              ? (arrayList[0].TableItems as Days[]).map((item) => (
                  <Fragment key={item.dayTitle.date}>
                    <TableHeaderCell
                      sx={styles.borderRight}
                      key={item.dayTitle.title}
                    ></TableHeaderCell>
                    <TableHeaderCell sx={styles.borderRight} align="center">
                      <Typography variant="cellHeaderSmall">
                        <Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.CAPACITY" />
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell sx={styles.borderRight} align="center">
                      <Typography variant="cellHeaderSmall">
                        <Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.FREE" />
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell sx={styles.borderRight} align="center">
                      <Typography variant="cellHeaderSmall">
                        <Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.BOOKED" />
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell sx={styles.borderRight} align="center">
                      {Signs.PERCENTAGE}
                    </TableHeaderCell>
                  </Fragment>
                ))
              : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {arrayList.map((item, index) => (
            <Row
              key={String(
                isFunction(keyField) ? keyField(item, index) : get(item, keyField || 'id')
              )}
              uniqueField={uniqueField}
              item={item}
              index={index}
              columns={removeEmpty(columnsState)}
              selected={selected}
              setSelected={setSelected}
              arrayList={arrayList}
            />
          ))}

          {!arrayList.length ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h5" align="left" sx={styles.emptyTable}>
                  {emptyMessage}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </TableRoot>
    </Box>
  );
};

interface RowProps<T> {
  columns: ColumnProps<T>[];
  item: T;
  index: number;
  selected: T[];
  setSelected: React.Dispatch<React.SetStateAction<T[]>>;
  uniqueField: string;
  arrayList: T[];
}

const Row = <T extends Record<string, any>>({ columns, item, index, arrayList }: RowProps<T>) => {
  const getValue = useCallback(
    (accessor?: Accessor<T> | ReactNode) => {
      if (!accessor) {
        return null;
      }

      if (typeof accessor === 'string') {
        return get(item, accessor);
      }

      if (isFunction(accessor)) {
        return accessor(item, index);
      }

      return accessor;
    },
    [item, index]
  );

  return (
    <>
      <TableRow sx={styles.tableRow}>
        <TableCell sx={styles.placeholderCell} padding="checkbox" />
        {columns.map((column) => {
          return (
            <AccessController allowedFor={column.allowedFor} key={column.key}>
              {column.subCell ? (
                <TableCell className={'MuiTableCell-root-noPadding MuiTableCell-body-noPadding'}>
                  {(getValue(column.accessor) as SubCells[])?.map((subItem) => (
                    <Box key={subItem.id} sx={styles.borderRightBottom}>
                      <Box
                        key={subItem.id}
                        className={'MuiTableCell-root-checkboxData'}
                        sx={{ borderBottom: 0 }}
                      >
                        <Typography variant="cellName"> {subItem.name}</Typography>
                      </Box>
                    </Box>
                  ))}
                </TableCell>
              ) : (
                <TableCell sx={styles.borderRight} align="center" key={column.key}>
                  {getValue(column.accessor)}
                </TableCell>
              )}
            </AccessController>
          );
        })}

        {(arrayList[index].TableItems as Days[]).map((itemVal) => {
          let pColor = '';

          if (isNumber(itemVal.percentage)) {
            switch (true) {
              case itemVal.percentage >= 0 && itemVal.percentage <= 25:
                pColor = colorCodes.green;
                break;
              case itemVal.percentage >= 26 && itemVal.percentage <= 75:
                pColor = colorCodes.yellow;
                break;
              case itemVal.percentage >= 75 && itemVal.percentage <= 100:
                pColor = colorCodes.red;
                break;

              default:
                pColor = 'none';
                break;
            }
          }
          return (
            <Fragment key={itemVal.dayTitle.date}>
              <TableCell className={'MuiTableCell-root-noPadding MuiTableCell-body-noPadding'}>
                {itemVal.areaIds?.map((areaId: AreaIds) => {
                  return (
                    <Box key={areaId.id} sx={styles.borderRightBottom}>
                      <Box
                        className={
                          'MuiTableCell-body-center-square MuiTableCell-root MuiTableCell-body'
                        }
                        sx={styles.xCell}
                      >
                        <Typography variant="cellPrimary">
                          {areaId.booked ? Signs.YES : Signs.NO}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </TableCell>

              <TableCell
                align="center"
                sx={styles.borderRight}
                className={'MuiTableCell-body-center'}
              >
                {itemVal.capacity ?? Signs.NO}
              </TableCell>
              <TableCell
                align="center"
                sx={styles.borderRight}
                className={'MuiTableCell-body-center'}
              >
                {itemVal.free ?? Signs.NO}
              </TableCell>
              <TableCell
                align="center"
                sx={styles.borderRight}
                className={'MuiTableCell-body-center'}
              >
                {itemVal.booked ?? Signs.NO}
              </TableCell>
              <TableCell
                align="center"
                sx={mergeSx({ backgroundColor: ` ${pColor}` }, styles.borderRight)}
                className={'MuiTableCell-body-center'}
              >
                {isFinite(itemVal.percentage)
                  ? `${itemVal.percentage}${Signs.PERCENTAGE}`
                  : Signs.NO}
              </TableCell>
            </Fragment>
          );
        })}
      </TableRow>
    </>
  );
};

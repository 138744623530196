import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel, UserModel } from 'models';
import { userService } from 'services';

type UserQueryContexts = QueryContextFromKeys<typeof usersKeys>;

const usersKeys = {
  all: [{ scope: 'users' }] as const,
  lists: () => [{ ...usersKeys.all[0], entity: 'list' }] as const,
  usersList: (params?: QueryParamModel | null) => [{ ...usersKeys.lists()[0], params }] as const,
};

const fetchUsersListSearch = async ({ queryKey: [{ params }] }: UserQueryContexts['usersList']) =>
  userService.getUserListSearch(params);

const fetchUsersList = async ({ queryKey: [{ params }] }: UserQueryContexts['usersList']) =>
  userService.getUserList(params);

export const useUserListSearch = (params: QueryParamModel | null = null) =>
  useQuery(usersKeys.usersList(params), fetchUsersListSearch);

export const useUserList = (params: QueryParamModel | null = null) =>
  useQuery(usersKeys.usersList(params), fetchUsersList);

export const useEditUser = () => {
  const queryClient = useQueryClient();

  return useMutation(({ data }: { data: Partial<UserModel>[] }) => userService.editUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersKeys.lists() });
    },
  });
};

import { StringifyOptions } from 'query-string';

import { toBoolean } from 'utils/format';

export type InputVariant = 'outlined' | 'filled' | 'standard';
export type InputMargin = 'none' | 'dense' | 'normal';

const processEnv = {
  ...process.env,
  ...(window.__RUNTIME_ENV__ || {}),
};

export const Env = {
  API_TOKEN_KEY: 'API_TOKEN',
  REFRESH_TOKEN_KEY: 'REFRESH_TOKEN',
  QUERY_FORMAT: { arrayFormat: 'bracket' } as StringifyOptions,
  DEFAULT_INPUT_VARIANT: 'outlined' as InputVariant,
  DEFAULT_INPUT_MARGIN: 'dense' as InputMargin,
  REGEX_PASSWORD: /^.{8,}$/,
  MINIMUM_PASSWORD_LENGTH: 5,
  ROWS_PER_PAGE_OPTIONS: [25, 50, 100],
  DEFAULT_ROWS_PER_PAGE: 100,
  SEARCH_DELAY: 500,
  TOOLTIP_DELAY_SHORT: 500,
  TOOLTIP_DELAY: 1000,
  COOKIE_CONSENT_USED: false,
  TEST_ID_ATTRIBUTE_KEY: 'data-test-id',
  ...processEnv,
  REACT_APP_USE_API: toBoolean(processEnv.REACT_APP_USE_API),
  REACT_APP_USE_COOKIE: toBoolean(processEnv.REACT_APP_USE_COOKIE),
  REACT_APP_FILENET_URL: processEnv.REACT_APP_FILENET_URL,
};

// Be able to see the current configuration during development
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(Env);
}

import { useQuery } from '@tanstack/react-query';

import { QueryParamModel } from 'models';
import { rolesService } from 'services/role.service';

export const rolesKeys = {
  all: [{ scope: 'roles' }] as const,
  lists: () => [{ ...rolesKeys.all[0], entity: 'list' }] as const,
  rolesList: (params?: QueryParamModel | null) => [{ ...rolesKeys.lists()[0], params }] as const,
};

const fetchRolesList = async () => rolesService.getRolesList();

export const useRolesList = () => useQuery(rolesKeys.rolesList(), fetchRolesList);

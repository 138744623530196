import { EntityService } from 'utils/entityService';
import { SavedFilterModel } from 'models';
import request, { Methods } from 'utils/request';

class FilterService extends EntityService {
  constructor() {
    super('user-filters');
  }

  async getSavedFilters(pageName: string) {
    return request<SavedFilterModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}/resource/${pageName}`,
    });
  }

  async saveFilter(filterData: Pick<SavedFilterModel, 'filter' | 'name' | 'resource'>) {
    return request<SavedFilterModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data: filterData,
    });
  }

  async deleteFilter(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/${id}`,
    });
  }
}

export const filterService = new FilterService();

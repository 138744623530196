import { JobModel, ListResponseModel, QueryParamModel } from 'models';
import { EntityService } from 'utils/entityService';
import request, { Methods } from 'utils/request';

class ImportTaskService extends EntityService {
  constructor() {
    super('admin/upload-order');
  }

  async getTasks(params?: QueryParamModel | null) {
    return request<ListResponseModel<JobModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/jobs`,
      params,
    });
  }

  async getTaskById(id: ID) {
    return request<JobModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/jobs/${id}`,
    });
  }

  async upload(data: any) {
    const formData = new FormData();
    formData.append('file', data as File | string);

    return request<JobModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  }

  async retryUpload(id: ID) {
    return request<JobModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}/jobs/${id}/retry`,
    });
  }

  async deleteTask(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/jobs/${id}`,
    });
  }
}

export const importTaskService = new ImportTaskService();

import React, { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

import background from 'assets/images/cover.webp';
import { ReactComponent as Logo } from 'assets/logo/LogoHorizontalWhite.svg';

interface PublicFormLayoutProps {
  title?: ReactNode;
  children?: ReactNode;
}

const styles = {
  root: {
    height: '100vh',
    width: '100%',
  },
  container: {
    color: 'neutral.surface',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: (theme: Theme) => theme.spacing(10, 10, 22, 10),
  },
  image: {
    background: `url(${background})`,
    height: '100%',
    width: '100%',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  logo: {
    width: '267px',
    height: '96px',
    flex: 1,
  },
  childrenContainer: {
    width: '404px',
  },
  title: {
    mb: 5,
  },
};

export const PublicFormLayout: React.FC<PublicFormLayoutProps> = ({ children, title }) => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.image}>
        <Box sx={styles.container}>
          <Box sx={styles.logo}>
            <Logo />
          </Box>
          <Box sx={styles.childrenContainer}>
            <Typography variant="h5" sx={styles.title}>
              {title}
            </Typography>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type OrderWithReportsQueryContexts = QueryContextFromKeys<typeof orderWithReportsKeys>;

export const orderWithReportsKeys = {
  all: [{ scope: 'orderWithReportsKeys' }] as const,
  lists: () => [{ ...orderWithReportsKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...orderWithReportsKeys.lists()[0], params }] as const,
};

const fetchOrderWithReportsList = async ({
  queryKey: [{ params }],
}: OrderWithReportsQueryContexts['list']) => statisticsService.getOrderWithReportsList(params);

const exportOrderWithReports = async ({
  queryKey: [{ params }],
}: OrderWithReportsQueryContexts['list']) => statisticsService.exportOrderWithReports(params);

export const useOrderWithReportsList = (params?: QueryParamModel | null) => {
  return useQuery(orderWithReportsKeys.list(params), fetchOrderWithReportsList, {
    enabled: !!params?.filter,
  });
};

export const useExportOrderWithReports = (params?: QueryParamModel | null) => {
  return useQuery(orderWithReportsKeys.list(params), exportOrderWithReports, { enabled: false });
};

import React, { CSSProperties, FC } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';

import { mergeSx } from 'utils/styles';
import { SxProp } from 'models';

export interface LoadingOverlayProps extends SxProp {
  /**
   * If set to ```true```, the spinner shows up.
   */
  show?: boolean;
  /**
   * The class(es) (in case if you prefer classes over sx).
   */
  className?: string;
  /**
   * CSS properties applied to the wrapper element.
   */
  style?: CSSProperties;
  /**
   * If set to ```true```, the spinner is set to ```fixed``` position.
   */
  fixed?: boolean;
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    zIndex: 1000,
    background: (theme: Theme) => alpha(theme.palette.common.white, 0.9),
  },
  fixed: {
    position: 'fixed',
  },
};

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ show, className, style, fixed, sx }) => {
  if (!show) {
    return null;
  }

  return (
    <Box className={className} sx={mergeSx(styles.root, fixed ? styles.fixed : null, sx)} style={style}>
      <CircularProgress />
    </Box>
  );
};

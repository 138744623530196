import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PanelConfigModel } from 'models';
import { parse, stringify } from 'utils/query';
import { selectPanelState, setPanelState } from 'store/common';

export interface PanelState {
  activePanel: string | null;
  setPanel: (panel?: PanelConfigModel | null) => void;
  closePanel: () => void;
  closePanelWithFilter: () => void;
}

export function usePanelState(): PanelState {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const activePanel =
    useSelector(selectPanelState) || (parse(location.search).panel as string) || null;

  const setCurrentInUrl = useCallback(
    (key?: string | null) => {
      navigate({
        pathname: location.pathname,
        search: stringify({ ...parse(location.search), panel: key || undefined }),
      });
    },
    [navigate, location]
  );

  const closePanel = useCallback(() => {
    dispatch(setPanelState(null));
    setCurrentInUrl();
  }, [dispatch, setCurrentInUrl]);

  const closePanelWithFilter = useCallback(() => {
    dispatch(setPanelState(null));
  }, [dispatch]);

  const setPanel = useCallback(
    (panel?: PanelConfigModel | null) => {
      dispatch(setPanelState(panel?.key || null));
      setCurrentInUrl(panel?.key);
    },
    [dispatch, setCurrentInUrl]
  );

  return {
    activePanel,
    setPanel,
    closePanel,
    closePanelWithFilter,
  };
}

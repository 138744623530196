import React, { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { Article, Cached, Panorama } from '@mui/icons-material';

import {
  DropdownMenuItem,
  IDays,
  LeoCheckBox,
  Page,
  PageHeaderAction,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, OrderStatus, QueryParamModel, SmOrderModel } from 'models';
import { RouteNames } from 'config/routeNames';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { useExportUcseOrders, useUcseOrderList } from 'features/orders';
import { useValueSetList } from 'features/valueSets';
import { useWorkerListSearch } from 'features/workers';
import Yup from 'utils/yup';
import { parse, stringify } from 'utils/base64';
import { formatDate } from 'utils/dates';
import { SortDirection } from 'utils/arrays';
import { formatAddress, getPageName } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import OrderDetailsDialog from 'pages/Ucse/UcseOrders/UcseOrderDetailsDialog';
import { getYesOrNoLabel } from 'pages/Leo/ReadingUnitDetails/ReadingUnitDetailsPage';
import { selectUserRoles } from 'store/profile';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

import { PhotoAccess } from 'config/photosConfig';
import { openPhotoInNewTab } from 'utils/getPhotoLink';
import TransferTaskDialog from './TransferTaskDialog';

export const plusRelativeDays: IDays = { value: +10, unit: 'day' };
export const minusRelativeDays: IDays = { value: -10, unit: 'day' };
export const relativeToday: IDays = { value: 0, unit: 'day' };

const UcseOrdersPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    params,
    apiParams,
    exportParams,
    onFilter,
    onLoadQuery,
    onTableColumnVisibilityChange,
  } = useTableQueryParams(
    {
      order: stringify([{ orderBy: 'orderId', order: SortDirection.ASC }]),
    },
    { worker: null }
  );

  const apiParamsWithIsRescheduled = (paramsToConvert: Partial<QueryParamModel>) => {
    const parsedFilter = parse(paramsToConvert?.filter);
    if (parsedFilter?.isRescheduled && parsedFilter.isRescheduled[0] === 'on') {
      return { ...paramsToConvert, filter: stringify({ ...parsedFilter, isRescheduled: true }) };
    }
    return paramsToConvert;
  };

  const {
    data: smOrders,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useUcseOrderList(apiParamsWithIsRescheduled(apiParams));
  const { isInitialLoading, isRefetching, refetch } = useExportUcseOrders(
    apiParamsWithIsRescheduled(exportParams)
  );
  const { data: workers } = useWorkerListSearch({ withDeleted: true });
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['TASK_STATUS', 'SM_ORDER_STATUS', 'APPOINTMENT_TIME'],
  });
  const roles = useSelector(selectUserRoles);

  const { data: smOrderData, accessor: smOrderStatusAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'SM_ORDER_STATUS')
  );
  const { data: taskStatuses, accessor: taskStatusAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'TASK_STATUS')
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workers,
    'sapId',
    'name',
    true
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const doubleFilterPanel = true;

  const validationSchema = Yup.object().shape({
    appointmentDate: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    downloadabilityDate: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
  });

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'UCSE_MEGRENDELESEK.APPOINTMENTDATE',
      component: <LeoDateInterval name="appointmentDate" />,
      name: 'appointmentDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.APPOINTMENT_DATE'),
    },
    {
      headerTitle: 'UCSE_MEGRENDELESEK.DOWNLOADABILITYDATE',
      component: <LeoDateInterval name="downloadabilityDate" />,
      name: 'downloadabilityDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.DOWNLOADABILITY_DATE'),
    },

    {
      headerTitle: 'UCSE_MEGRENDELESEK.RENEGOTIATED',
      component: (
        <LeoCheckBox
          extraStyle={{ marginBottom: '38px' }}
          name="isRescheduled"
          label={<Trans i18nKey="UCSE_MEGRENDELESEK.RENEGOTIATED" />}
        />
      ),
      name: 'isRescheduled',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.RENEGOTIATED'),
    },
    {
      headerTitle: 'UCSE_MEGRENDELESEK.WORKER_CODE',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="UCSE_MEGRENDELESEK.WORKER"
          freeSolo
          blankFilterOption
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
    },
    {
      headerTitle: 'UCSE_MEGRENDELESEK.LOCATION',
      component: <LeoTextField name="city" label={<Trans i18nKey="UCSE_MEGRENDELESEK.CITY" />} />,
      name: 'city',
      panelFieldWidth: 12,
      divider: !doubleFilterPanel,
    },
    {
      headerTitle: 'UCSE_MEGRENDELESEK.ORDERID',
      component: (
        <LeoTextField name="orderId" label={<Trans i18nKey="UCSE_MEGRENDELESEK.ORDERID" />} />
      ),
      name: 'orderId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.ORDERID'),
    },
    {
      headerTitle: 'UCSE_MEGRENDELESEK.STATUS',
      component: (
        <LeoSelectField
          name="status"
          label="UCSE_MEGRENDELESEK.STATUS"
          data={smOrderData}
          accessor={smOrderStatusAccessor}
        />
      ),
      name: 'status',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.STATUS'),
    },
    {
      headerTitle: 'UCSE_MEGRENDELESEK.TASKSTATUS',
      component: (
        <LeoSelectField
          name="taskStatus"
          label="UCSE_MEGRENDELESEK.TASKSTATUS"
          data={taskStatuses}
          accessor={taskStatusAccessor}
        />
      ),
      name: 'taskStatus',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.TASK_STATUS'),
    },
  ];

  const orderColumns = [
    {
      key: 'orderId',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.ORDERID" />,
      accessor: 'orderId',
      sortable: true,
    },
    {
      key: 'downloadabilityDate',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.DOWNLOADABILITYDATE" />,
      accessor: (order: SmOrderModel) => formatDate(order.downloadabilityDate),
      field: 'downloadabilityDate',
      sortable: true,
    },
    {
      key: 'appointmentDate',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.APPOINTMENTDATE" />,
      accessor: (order: SmOrderModel) => formatDate(order.appointmentDate),
      field: 'appointmentDate',
      sortable: true,
    },
    {
      key: 'appointmentTime',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.APPOINTMENTTIME" />,
      accessor: (order: SmOrderModel) =>
        order.appointmentTime &&
        getValueSetValues(valueSet, 'APPOINTMENT_TIME', order.appointmentTime),
      field: 'appointmentTime',
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.WORKER_CODE" />,
      accessor: (order: SmOrderModel) => order.worker?.sapId,
      field: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'worker.name',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.WORKERNAME" />,
      accessor: (order: SmOrderModel) => order.worker?.name,
      field: 'worker.name',
      sortable: true,
    },
    {
      key: 'status',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.STATUS" />,
      accessor: (order: SmOrderModel) =>
        order.status && getValueSetValues(valueSet, 'SM_ORDER_STATUS', order.status),
      field: 'status',
      sortable: true,
    },
    {
      key: 'taskStatus',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.TASKSTATUS" />,
      accessor: (order: SmOrderModel) =>
        order.taskStatus && getValueSetValues(valueSet, 'TASK_STATUS', order.taskStatus),
      field: 'taskStatus',
      sortable: true,
    },
    {
      key: 'partner.name',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.PARTNERNAME" />,
      accessor: (order: SmOrderModel) => order.partner?.name,
      field: 'partner.name',
      sortable: true,
    },
    {
      key: 'partner.sapId',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.PARTNERSAPID" />,
      accessor: (order: SmOrderModel) => order.partner?.sapId,
      field: 'partner.sapId',
      sortable: true,
    },
    {
      key: 'address.city',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.ADDRESS" />,
      accessor: (order: SmOrderModel) => formatAddress(order.address),
      field: 'address.city',
      sortable: true,
    },
    {
      key: 'isUnauthorizedConsumption',
      header: <Trans i18nKey="UCSE_MEGRENDELESEK.UNAUTHORIZED_CONSUMPTION" />,
      accessor: (order: SmOrderModel) => getYesOrNoLabel(order.isUnauthorizedConsumption),
      field: 'isUnauthorizedConsumption',
      sortable: true,
    },
  ];

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.UCSE_RENDELESEK),
    validationSchema,
    doubleFilterPanel: true,
    isFetching,
  });

  const {
    isOpen: isTransferOpen,
    openDialog: openTransfer,
    closeDialog: closeTransfer,
    selected: selectedTransfer,
  } = useDialogState<SmOrderModel | SmOrderModel[]>();

  const {
    isOpen: isDetailsOpen,
    openDialog: openDetails,
    closeDialog: closeDetails,
    selected: selectedOrder,
  } = useDialogState<SmOrderModel>();

  const panels = [filterConfig];

  const onRowDoubleClick = (e: SmOrderModel) => {
    openDetails(e);
  };

  const orderHeaderActions = useMemo<PageHeaderAction[]>(
    () => [
      {
        name: 'export',
        label: 'COMMON.EXPORT',
        icon: <Export fill="currentColor" />,
        onClick: () => refetch(),
        isLoading: isInitialLoading || isRefetching,
        disabledIf: !exportParams?.filter,
      },
      {
        name: 'transfer',
        label: 'LEO_LEO_EGYSEGEK.BULK_TRANSFER',
        icon: <Cached fontSize="small" />,
        onClick: (row) => openTransfer(row),
        isBulkAction: true,
        visible: getFunctionRole(FunctionRoleName.SM_ORDERS_PAGE_TRANSFER_FUNCTION, roles),
      },
    ],
    [openTransfer, refetch, isInitialLoading, isRefetching, exportParams?.filter, roles]
  );

  const viewPhotoHandler = ({ orderId }: SmOrderModel) =>
    openPhotoInNewTab(PhotoAccess.UCSE_ORDERS, orderId);

  const orderActions = [
    {
      name: 'details',
      label: 'UCSE_MEGRENDELESEK.DETAILS',
      icon: <Article fontSize="small" />,
      onClick: (row) => openDetails(row),
    },
    {
      name: 'transfer',
      label: 'UCSE_MEGRENDELESEK.TRANSFER',
      icon: <Cached fontSize="small" />,
      onClick: (row) => openTransfer(row),
      visible: getFunctionRole(FunctionRoleName.SM_ORDERS_PAGE_TRANSFER_FUNCTION, roles),
      disabledIf: { status: OrderStatus.ELLENORZOTT },
    },
    {
      name: 'photo',
      label: 'KOZOS_H_S_JELZESEK.PHOTO_BUTTON',
      icon: <Panorama fontSize="small" />,
      onClick: (row) => viewPhotoHandler(row),
    },
  ] as DropdownMenuItem[];

  return (
    <Page
      title={<Trans i18nKey="UCSE_MEGRENDELESEK.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ smOrderStatusAccessor, taskStatusAccessor }}
          list={smOrders?.data || []}
          total={smOrders?.meta.total}
          timestamp={dataUpdatedAt}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={orderColumns}
          actions={orderActions}
          pageHeaderActions={orderHeaderActions}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          onRowDoubleClick={(e) => onRowDoubleClick(e)}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onLoadQuery={onLoadQuery}
          resetSelectedOnAction
        />
      )}
      <TransferTaskDialog
        open={isTransferOpen}
        onClose={closeTransfer}
        selected={selectedTransfer}
      />
      {selectedOrder && (
        <OrderDetailsDialog
          open={isDetailsOpen}
          orderId={selectedOrder.id}
          onClose={closeDetails}
          viewPhotoHandler={viewPhotoHandler}
        />
      )}
    </Page>
  );
};

export default UcseOrdersPage;

import React, { FC, forwardRef, Ref, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { FieldProps } from 'formik';
import { DateTime } from 'luxon';
import { isEmpty, isEqual, isPlainObject } from 'lodash';

import {
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';

import { Button, TextField } from 'components';
import {
  DATE_FORMAT,
  DATE_MASK,
  formatDate,
  isValidDate,
  YEAR_MONTH_DATE_FORMAT,
  YEAR_MONTH_DATE_MASK,
} from 'utils/dates';
import { setValue } from 'utils/form';

export interface DatePickerProps
  extends Omit<
      MuiDatePickerProps<DateTime | number | Date, DateTime>,
      'value' | 'renderInput' | 'onChange'
    >,
    Omit<TextFieldProps, 'inputRef' | 'onError' | 'onChange' | 'value'>,
    FieldProps {
  /**
   * Format string.
   */
  dateFormat?: string;
  /**
   * Set it true, if the datepicker is used to input intervals.
   */
  isInterval?: boolean;
  /**
   * Custom mask. Can be used to override generate from format.
   */
  dateMask?: string;
  /**
   * The variant to use.
   */
  inputVariant?: TextFieldProps['variant'];
  /**
   * Callback fired when the value (the selected date) changes.
   */
  onChange?: (value: DateTime | null) => void;
  /**
   * Set it true, if you want to disable the relative date Chips.
   */
  isRelativeDateDisabled?: boolean;
  /**
   * Set it true, if you want to display the year and month only.
   */
  isYearAndMonthOnly?: boolean;

  rootKey?: string;

  badInterval?: boolean;
}

export const styles = {
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingX: 3,
    paddingBottom: 2,
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingX: 3,
    paddingBottom: 3,
    marginBottom: 3,
  },
  customChip: {
    width: 80,
  },
  buttonContainer: {
    display: 'flex',
    marginX: 3,
  },
  popperProps: {
    width: '350px',
    '.MuiCalendarPicker-root': {
      width: '340px',
      ml: -1,
    },
    '.MuiCalendarPicker-viewTransitionContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
};

export interface IDays {
  value: number;
  unit: string;
}

export const plusRelativeDays: IDays = { value: +10, unit: 'day' };
export const minusRelativeDays: IDays = { value: -10, unit: 'day' };
export const relativeToday: IDays = { value: 0, unit: 'day' };

export const DatePicker: FC<DatePickerProps> = forwardRef(
  (
    {
      field,
      form,
      minDate,
      maxDate,
      required,
      isYearAndMonthOnly = false,
      dateFormat = isYearAndMonthOnly && YEAR_MONTH_DATE_FORMAT,
      dateMask = isYearAndMonthOnly && YEAR_MONTH_DATE_MASK,
      inputVariant,
      fullWidth = true,
      rootKey,
      meta,
      onChange: onChangeDefault,
      onClose,
      placeholder,
      badInterval = false,
      isInterval = false,
      isRelativeDateDisabled = false,
      ...props
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<number | null>(null);

    useEffect(() => {
      setSelected(field.value?.value);
    }, [field.value?.value]);

    useEffect(() => {
      if (badInterval) {
        form.setFieldValue('badInterval', false);
      } else {
        delete form.values.badInterval;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badInterval]);

    useEffect(() => {
      if (isEmpty(form.values[`${rootKey}`])) {
        delete form.values[`${rootKey}`];
      }
    }, [form.values, selected, rootKey]);

    const onKeyDown = (e: any) => {
      e.preventDefault();
    };

    const onChange = (date: DateTime | null) => {
      if (!isValidDate(date)) {
        setValue(form, field, 'Invalid Date');
        return;
      }

      if (date === null) {
        form.setFieldValue(`${field.name}`, undefined);
      } else {
        const formattedDate = isYearAndMonthOnly
          ? `${formatDate(date, 'yyyy-MM', { utc: false })}-01`
          : formatDate(date, 'yyyy-MM-dd', { utc: false });
        setValue(form, field, formattedDate, true);

        onChangeDefault?.(date);
        if (!isYearAndMonthOnly) {
          setIsOpen(false);
        }
      }
    };

    const getRenderInput = ({
      onChange: onChangeTextField,
      value,
      inputProps,
      ...renderProps
    }: any) => (
      <TextField
        onKeyDown={isYearAndMonthOnly ? onKeyDown : undefined}
        required={required}
        fullWidth={fullWidth}
        variant={inputVariant}
        field={field}
        form={form}
        meta={meta}
        inputProps={{ ...inputProps, placeholder: placeholder || dateMask || DATE_MASK }}
        onChange={onChangeTextField}
        {...renderProps}
      />
    );

    const relativeChangeHandler = (dayObj: IDays) => {
      form.setFieldValue(`${field.name}`, dayObj);
      setSelected(dayObj.value);
      setIsOpen(false);
    };

    const onClear = () => {
      form.setFieldValue(field.name, null);
      setSelected(null);
      setIsOpen(false);
    };

    const getPrimaryOrSecondary = (isPrimary: boolean) => (isPrimary ? 'primary' : 'secondary');

    const getCustomActionBar = () => {
      return (
        <>
          {isInterval && !isRelativeDateDisabled && (
            <>
              <Box sx={styles.textContainer}>
                <Trans i18nKey="DATE.RELATIVE_DATE" />
              </Box>
              <Box sx={styles.chipContainer}>
                <Chip
                  color={getPrimaryOrSecondary(field.value?.value === minusRelativeDays.value)}
                  label={
                    <Typography variant="subtitle2">
                      <Trans i18nKey="DATE.SUBSTRACT_TEN_DAYS" />
                    </Typography>
                  }
                  onClick={() => {
                    relativeChangeHandler(minusRelativeDays);
                  }}
                  sx={styles.customChip}
                />
                <Chip
                  color={getPrimaryOrSecondary(field.value?.value === relativeToday.value)}
                  label={
                    <Typography variant="subtitle2">
                      <Trans i18nKey="DATE.TODAY" />
                    </Typography>
                  }
                  onClick={() => {
                    relativeChangeHandler(relativeToday);
                  }}
                  sx={styles.customChip}
                />
                <Chip
                  color={getPrimaryOrSecondary(field.value?.value === plusRelativeDays.value)}
                  label={
                    <Typography variant="subtitle2">
                      <Trans i18nKey="DATE.ADD_TEN_DAYS" />
                    </Typography>
                  }
                  onClick={() => {
                    relativeChangeHandler(plusRelativeDays);
                  }}
                  sx={styles.customChip}
                />
              </Box>
            </>
          )}
          {isYearAndMonthOnly && (
            <Grid container spacing={1} p={1}>
              <Grid item sm={10}>
                <Button onClick={() => setIsOpen(false)} fullWidth>
                  <Trans i18nKey="COMMON.OK" />
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button color="error" onClick={onClear} buttonSx={{ minWidth: 0 }}>
                  x
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      );
    };

    const dateValue = () => {
      if (isPlainObject(field.value)) {
        const newDate = DateTime.now();

        if (isEqual(field.value, plusRelativeDays)) {
          return newDate.plus({ days: 10 });
        }
        if (isEqual(field.value, minusRelativeDays)) {
          return newDate.minus({ days: 10 });
        }
        if (isEqual(field.value, relativeToday)) {
          return newDate;
        }
        return null;
      }
      return field?.value || null;
    };

    return (
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div data-testid="date-picker">
          <MuiDatePicker
            closeOnSelect={false}
            onOpen={() => setIsOpen(true)}
            open={isOpen}
            components={{
              ActionBar: getCustomActionBar,
            }}
            ref={ref}
            value={dateValue()}
            minDate={minDate}
            maxDate={maxDate}
            desktopModeMediaQuery={theme.breakpoints.up('sm')}
            inputFormat={dateFormat || DATE_FORMAT}
            onChange={onChange}
            renderInput={getRenderInput}
            PopperProps={{
              sx: styles.popperProps,
              placement: isYearAndMonthOnly ? 'bottom' : 'right',
            }}
            views={isYearAndMonthOnly ? ['year', 'month'] : undefined}
            disableMaskedInput={false}
            {...props}
          />
        </div>
      </ClickAwayListener>
    );
  }
);

import { Env } from './env';

export const baseUrl = { baseUrl: Env.REACT_APP_FILENET_URL };

export type PageSettings = {
  [key in PhotoAccess]: QueryParams;
};

interface QueryParams {
  desktop: string;
  repositoryId: string;
  docid: string;
  templateName: string;
  propertyName: string;
  version: string;
  autorRunSearch: string;
  autoOpenItem: string;
}

export enum PhotoAccess {
  READING_UNIT_DETAILS = 'OPEM Leolvasasi Dokumentumok',
  HEALTH_AND_SAFETY = 'HS jelzesek',
  SM_ORDER_DETAILS = 'UCSE Rendeles reszletek',
  UCSE_ORDERS = 'UCSE rendelesek',
}

export const pageSettings: PageSettings = {
  [PhotoAccess.HEALTH_AND_SAFETY]: {
    desktop: 'desktop=opem',
    repositoryId: 'repositoryId=TITASZISU',
    docid: 'docid={A6208AC7-0880-CBDA-86BA-88E222200000}',
    templateName: 'template_name=StoredSearch',
    propertyName: 'opem_internal_id',
    version: 'version=released',
    autorRunSearch: 'autoRunSearch=true',
    autoOpenItem: 'autoOpenItem=true',
  },
  [PhotoAccess.READING_UNIT_DETAILS]: {
    desktop: 'desktop=opem',
    repositoryId: 'repositoryId=TITASZISU',
    docid: 'docid={BAEE1273-1D1A-CD9D-8703-88FBD0B00000}',
    templateName: 'template_name=StoredSearch',
    propertyName: 'opem_order_id',
    version: 'version=released',
    autorRunSearch: 'autoRunSearch=true',
    autoOpenItem: 'autoOpenItem=true',
  },
  [PhotoAccess.SM_ORDER_DETAILS]: {
    desktop: 'desktop=opem',
    repositoryId: 'repositoryId=TITASZISU',
    docid: 'docid={835282BA-3752-C676-8737-88FBD1500000}',
    templateName: 'template_name=StoredSearch',
    propertyName: 'opem_order_id',
    version: 'version=released',
    autorRunSearch: 'autoRunSearch=true',
    autoOpenItem: 'autoOpenItem=true',
  },
  [PhotoAccess.UCSE_ORDERS]: {
    desktop: 'desktop=opem',
    repositoryId: 'repositoryId=TITASZISU',
    docid: 'docid={835282BA-3752-C676-8737-88FBD1500000}',
    templateName: 'template_name=StoredSearch',
    propertyName: 'opem_order_id',
    version: 'version=released',
    autorRunSearch: 'autoRunSearch=true',
    autoOpenItem: 'autoOpenItem=true',
  },
};

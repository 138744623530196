import React, { FC, ReactNode } from 'react';

import Container from '@mui/material/Container';

import { Header, LoadingOverlay, Panel } from 'components';
import { PanelConfigModel, SxProp } from 'models';
import { mergeSx } from 'utils/styles';

import { PageContent } from './PageContent';

interface PanelConfigProps {
  /**
   * Panels that can slide into the page.
   */
  panels?: (PanelConfigModel | null)[] | null;
  /**
   * The currently active panel.
   */
  activePanel?: string | null;
  /**
   * Additional function for the panels close event.
   */
  closePanel?: () => void;
  /**
   * Sets the active panel.
   */
  setPanel?: (config?: PanelConfigModel | null) => void;
}

interface MockFilterPageNames extends PanelConfigProps, SxProp {
  /**
   * The title of the page.
   */
  title: ReactNode;
  /**
   * A custom dynamic breadcrumb label.
   */
  breadcrumbCustomLabel?: string;
  /**
   * The class(es) (in case if you prefer classes over sx).
   */
  className?: string;
  /**
   * If set to ```true```, the page becames scrollable, regardless of the amount of content.
   */
  inScroll?: boolean;
  /**
   * If set to ```true```, the spinner shows up.
   */
  isLoading?: boolean;
  children?: ReactNode;
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  inScroll: {
    height: '100vh',
    overflowY: 'auto',
  },
  panel: {
    '& .MuiDrawer-paper': {
      height: '100%',
    },
  },
  doublePanel: {
    '& .MuiDrawer-paper': {
      width: '986px',
    },
  },
};

export const Page: FC<MockFilterPageNames> = ({
  title,
  sx,
  className,
  children,
  inScroll,
  isLoading,
  panels,
  closePanel,
  activePanel,
  breadcrumbCustomLabel,
}) => {
  const hasPanels = Array.isArray(panels) && panels.length;
  const isPanelOpen = Boolean(activePanel);

  const panelStyle = panels?.[0]?.doubleFilterPanel ? styles.doublePanel : null;

  return (
    <Container
      maxWidth={false}
      className={className}
      sx={mergeSx(styles.root, inScroll ? styles.inScroll : null, sx)}
    >
      <LoadingOverlay show={isLoading} />
      <Header title={title} breadcrumbCustomLabel={breadcrumbCustomLabel} />

      {!isLoading ? (
        <PageContent inScroll={inScroll} isPanelOpen={isPanelOpen} hasPanels={Boolean(hasPanels)}>
          {children}
        </PageContent>
      ) : null}

      {hasPanels ? (
        <Panel
          isOpen={isPanelOpen}
          sx={mergeSx(styles.panel, panelStyle)}
          onClose={closePanel}
          variant="left"
        >
          {panels.find((panel) => panel?.key === activePanel)?.content}
        </Panel>
      ) : null}
    </Container>
  );
};

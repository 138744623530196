import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';

import { Box } from '@mui/material';

import { PerformanceBasedPayModel } from 'models';
import { BaseDialogProps, Button, Checkbox, Dialog } from 'components';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import {
  useApprovePerformanceBasedPay,
  useDeletePerformanceBasedPay,
  useRejectPerformanceBasedPay,
  useReopenPerformanceBasedPay,
  useRunPerformanceBasedPay,
  useSendToApprovePerformanceBasedPay,
} from 'features/performanceBasedPay';

export enum StatusChange {
  APPROVE = 'approve',
  DELETE = 'delete',
  REJECT = 'reject',
  REOPEN = 'reopen',
  SEND_TO_APPROVE = 'send-to-approve',
  RUN = 'run',
}

export interface ReadingOrderDeleteProps extends BaseDialogProps {
  items: PerformanceBasedPayModel[] | null;
  action: StatusChange;
}

const StatusChangeDialog: FC<ReadingOrderDeleteProps> = ({ items, action, ...props }) => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const { mutateAsync: approvePerformanceBasedPay, isLoading: isApproveLoading } =
    useApprovePerformanceBasedPay();
  const { mutateAsync: deletePerformanceBasedPay, isLoading: isDeleteLoading } =
    useDeletePerformanceBasedPay();
  const { mutateAsync: rejectPerformanceBasedPay, isLoading: isRejectLoading } =
    useRejectPerformanceBasedPay();
  const { mutateAsync: reopenPerformanceBasedPay, isLoading: isReopenLoading } =
    useReopenPerformanceBasedPay();
  const { mutateAsync: sendToApprovePerformanceBasedPay, isLoading: isSendToApproveLoading } =
    useSendToApprovePerformanceBasedPay();
  const { mutateAsync: runPerformanceBasedPay, isLoading: isRunoading } =
    useRunPerformanceBasedPay();

  const isLoading =
    isApproveLoading ||
    isDeleteLoading ||
    isRejectLoading ||
    isReopenLoading ||
    isSendToApproveLoading ||
    isRunoading;

  const { onClose } = props;

  const { data: pbpIds } = useFieldDataAndAccessor(items || [], 'id');

  const handleChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const onCancel = () => {
    setIsConfirmed(false);
    onClose();
  };

  const onSubmit = async () => {
    if (!items) {
      setIsConfirmed(false);
      onClose();
      return;
    }

    switch (action) {
      case StatusChange.APPROVE:
        await approvePerformanceBasedPay({ ids: pbpIds });
        break;
      case StatusChange.DELETE:
        await deletePerformanceBasedPay({ ids: pbpIds });
        break;
      case StatusChange.REJECT:
        await rejectPerformanceBasedPay({ ids: pbpIds });
        break;
      case StatusChange.REOPEN:
        await reopenPerformanceBasedPay({ ids: pbpIds });
        break;
      case StatusChange.SEND_TO_APPROVE:
        await sendToApprovePerformanceBasedPay({ ids: pbpIds });
        break;
      case StatusChange.RUN:
        await runPerformanceBasedPay(pbpIds[0]);
        break;
      default:
        break;
    }

    setIsConfirmed(false);
    onClose();
  };

  const getTitle = () => {
    switch (action) {
      case StatusChange.APPROVE:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.APPROVE_TITLE" />;
      case StatusChange.DELETE:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.DELETE_TITLE" />;
      case StatusChange.REJECT:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.REJECT_TITLE" />;
      case StatusChange.REOPEN:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.REOPEN_TITLE" />;
      case StatusChange.SEND_TO_APPROVE:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.SEND_TO_APPROVE_TITLE" />;
      case StatusChange.RUN:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.RUN_TITLE" />;
      default:
        return '';
    }
  };

  const getCheckBoxLabel = () => {
    switch (action) {
      case StatusChange.APPROVE:
        return (
          <Trans
            i18nKey="KOZOS_TELJESITMENYBEREK.CONFIRM_APPROVE"
            values={{ db: pbpIds.length !== 0 ? pbpIds.length : 1 }}
          />
        );
      case StatusChange.DELETE:
        return (
          <Trans
            i18nKey="KOZOS_TELJESITMENYBEREK.CONFIRM_DELETE"
            values={{ db: pbpIds.length !== 0 ? pbpIds.length : 1 }}
          />
        );
      case StatusChange.REJECT:
        return (
          <Trans
            i18nKey="KOZOS_TELJESITMENYBEREK.CONFIRM_REJECT"
            values={{ db: pbpIds.length !== 0 ? pbpIds.length : 1 }}
          />
        );
      case StatusChange.REOPEN:
        return (
          <Trans
            i18nKey="KOZOS_TELJESITMENYBEREK.CONFIRM_REOPEN"
            values={{ db: pbpIds.length !== 0 ? pbpIds.length : 1 }}
          />
        );
      case StatusChange.SEND_TO_APPROVE:
        return (
          <Trans
            i18nKey="KOZOS_TELJESITMENYBEREK.CONFIRM_SEND_TO_APPROVE"
            values={{ db: pbpIds.length !== 0 ? pbpIds.length : 1 }}
          />
        );
      case StatusChange.RUN:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.CONFIRM_RUN" />;
      default:
        return '';
    }
  };

  const getSubmitLabel = () => {
    switch (action) {
      case StatusChange.APPROVE:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.APPROVE" />;
      case StatusChange.DELETE:
        return <Trans i18nKey="COMMON.DELETE" />;
      case StatusChange.REJECT:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.REJECT" />;
      case StatusChange.REOPEN:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.REOPEN" />;
      case StatusChange.SEND_TO_APPROVE:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.SEND_TO_APPROVE" />;
      case StatusChange.RUN:
        return <Trans i18nKey="KOZOS_TELJESITMENYBEREK.RUN_CONFIRM_LABEL" />;
      default:
        return <Trans i18nKey="COMMON.SUBMIT" />;
    }
  };

  const getActions = () => (
    <Box mt={2} display="flex">
      <Box mr={2}>
        <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
          {<Trans i18nKey="COMMON.CANCEL" />}
        </Button>
      </Box>
      <Button onClick={onSubmit} loading={isLoading} disabled={isLoading || !isConfirmed}>
        {getSubmitLabel()}
      </Button>
    </Box>
  );

  return (
    <Dialog title={getTitle()} onSubmit={onSubmit} actions={getActions()} {...props}>
      <Checkbox
        sx={{ mt: 2 }}
        checked={isConfirmed}
        onChange={handleChange}
        label={getCheckBoxLabel()}
      />
    </Dialog>
  );
};

export default StatusChangeDialog;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';

import { Box, Card } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

import {
  Button,
  ColumnProps,
  FileUpload,
  LeoSelectField,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, JobModel } from 'models';
import { useImportTask, useRetryImportTask, useTaskList } from 'features/importTask';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import Yup from 'utils/yup';
import { useValueSetList } from 'features/valueSets';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';

const styles = {
  importCard: {
    p: 2,
    borderRadius: '8px',
    '@media (min-width: 1600px)': {
      width: '35%',
    },
    '@media (max-width: 1599px)': {
      width: '45%',
    },
  },
  table: {
    '.MuiPaper-root': {
      '@media (min-height: 900px)': {
        maxHeight: '450px',
      },
      '@media (max-height: 899px)': {
        maxHeight: '265px',
      },
    },
  },
};

export const commonJobColumns = [
  {
    key: 'attempts',
    header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.ATTEMPTS" />,
    accessor: 'attempts',
    sortable: false,
  },
  {
    key: 'failedReason',
    header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.REASON" />,
    accessor: 'failedReason',
    sortable: false,
  },
  {
    key: 'processedOn',
    header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.PROCESS_TIME" />,
    accessor: (item: JobModel) =>
      item.processedOn && formatDate(DateTime.fromMillis(item.processedOn), DATE_TIME_FORMAT),
    field: 'processedOn',
    sortable: false,
  },
  {
    key: 'finishedOn',
    header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.FINISH_TIME" />,
    accessor: (item: JobModel) =>
      item.finishedOn && formatDate(DateTime.fromMillis(item.finishedOn), DATE_TIME_FORMAT),
    field: 'finishedOn',
    sortable: false,
  },
];

const ImportTaskPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const { data: importTaskListData, isLoading, isFetching, dataUpdatedAt } = useTaskList(apiParams);
  const { mutateAsync: importTask } = useImportTask();
  const { mutateAsync: retryImportTask } = useRetryImportTask();
  const { activePanel, closePanel, setPanel } = usePanelState();
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['BACKGROUND_JOB_STATUS'],
  });

  const { data: execStatus, accessor: execAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'BACKGROUND_JOB_STATUS')
  );

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_FELADAT_IMPORT.STATUS',
      component: (
        <LeoSelectField
          name="status"
          data={execStatus}
          accessor={execAccessor}
          label="KOZOS_FELADAT_IMPORT.STATUS"
        />
      ),
      name: 'status',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.STATUS'),
      filterIsRequired: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    status: Yup.mixed().required(),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_FELADAT_IMPORT),
    validationSchema,
    isFetching,
  });

  const panels = [filterConfig];

  const columns: ColumnProps<JobModel>[] = [
    {
      key: 'id',
      header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.ID" />,
      accessor: 'id',
      sortable: false,
    },
    {
      key: 'data.originalName',
      header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.FILE_NAME" />,
      accessor: 'data.originalName',
      sortable: false,
    },
    ...commonJobColumns,
    {
      key: 'status',
      header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.STATUS" />,
      accessor: (item: JobModel) =>
        item.status && getValueSetValues(valueSet, 'BACKGROUND_JOB_STATUS', item.status),
      field: 'status',
      sortable: false,
    },
  ];

  const actions = [
    {
      name: 'retry',
      label: 'KOZOS_FELADAT_IMPORT.RETRY',
      icon: <ReplayIcon fontSize="small" />,
      onClick: (job: JobModel) => retryImportTask(job.id),
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_FELADAT_IMPORT.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {process.env.REACT_APP_ENV !== 'production' && (
        <Card elevation={1} sx={styles.importCard}>
          <Formik
            initialValues={{ file: null }}
            onSubmit={(values, { resetForm }) => {
              importTask(values?.file?.[0]);
              resetForm();
            }}
          >
            {({ values, resetForm }) => (
              <Form>
                <Field
                  label={<Trans i18nKey="KOZOS_FELADAT_IMPORT.FILE_IMPORT_TITLE" />}
                  component={FileUpload}
                  multiple={false}
                  onDropRejected={resetForm}
                  name="file"
                  accept={{ 'application/xml': ['.xml'] }}
                />
                <Box mt={2}>
                  <Button type="submit" disabled={!values.file}>
                    <Trans i18nKey="KOZOS_FELADAT_IMPORT.IMPORT_BUTTON" />
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card>
      )}
      <Box sx={styles.table}>
        {!isValueSetLoading && (
          <Table
            setPanel={setPanel}
            activePanel={activePanel}
            list={importTaskListData?.data || []}
            total={importTaskListData?.meta.total}
            timestamp={dataUpdatedAt}
            loading={isLoading}
            params={params}
            onSort={onSort}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            enableCheckbox={false}
            columns={columns}
            onTableColumnVisibilityChange={onTableColumnVisibilityChange}
            disableDivider={true}
            onLoadQuery={onLoadQuery}
            actions={actions}
            panels={panels}
          />
        )}
      </Box>
    </Page>
  );
};

export default ImportTaskPage;

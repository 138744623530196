import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type NotifiedReadingResultsQueryContexts = QueryContextFromKeys<typeof notifiedReadingResultKeys>;

export const notifiedReadingResultKeys = {
  all: [{ scope: 'notifiedReadingResults' }] as const,
  lists: () => [{ ...notifiedReadingResultKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...notifiedReadingResultKeys.lists()[0], params }] as const,
};

const fetchNotifiedReadingResultsList = async ({
  queryKey: [{ params }],
}: NotifiedReadingResultsQueryContexts['list']) =>
  statisticsService.getNotifiedReadingResultList(params);

const exportNotifiedReadingResults = async ({
  queryKey: [{ params }],
}: NotifiedReadingResultsQueryContexts['list']) =>
  statisticsService.exportNotifiedReadingResults(params);

export const useNotifiedReadingResultsList = (params?: QueryParamModel | null) => {
  return useQuery(notifiedReadingResultKeys.list(params), fetchNotifiedReadingResultsList, {
    enabled: !!params?.filter,
  });
};

export const useExportNotifiedReadingResults = (params?: QueryParamModel | null) => {
  return useQuery(notifiedReadingResultKeys.list(params), exportNotifiedReadingResults, {
    enabled: false,
  });
};

import { DateTime } from 'luxon';

export const DATE_FORMAT = 'yyyy.MM.dd.';
export const YEAR_MONTH_DATE_FORMAT = 'yyyy/MM';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_MASK = '____.__.__';
export const YEAR_MONTH_DATE_MASK = '____/__';

export type InputDateType = DateTime;

interface Options {
  utc?: boolean;
}

export function formatDate(
  date?: InputDateType | string | null,
  formatString: string = DATE_FORMAT,
  { utc }: Options = { utc: false }
) {
  const newDate = typeof date === 'string' ? DateTime.fromISO(date) : date;

  if (!newDate || !isValidDate(newDate)) {
    return '';
  }

  const zonedTime = utc ? newDate.toUTC() : newDate;

  return zonedTime.toFormat(formatString);
}

export function getRawDateValue(value?: InputDateType | null) {
  if (isValidDate(value)) {
    return value?.toJSDate() || '';
  }

  return '';
}

export function isValidDate(value?: InputDateType | null) {
  if (!value) {
    return true;
  }

  return value.isValid || false;
}

export function toUtcIsoString(date?: DateTime | null) {
  if (!isValidDate(date)) {
    return '';
  }
  return date?.toUTC()?.toISO() || '';
}

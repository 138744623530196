import React, { SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Form, Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormHelperText } from '@mui/material';

import { BaseDialogProps, Dialog, DialogProps, showNotification } from 'components';
import { NotificationType } from 'models';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';

export interface BaseEditorDialogProps<Data> extends BaseDialogProps {
  /**
   * The selected entity.
   */
  selected?: Data | null;
  /**
   * Additional submit function.
   */
  onSave?: () => void | Promise<any>;
  /**
   * Pass function to execute.
   */
  callbackFn?: () => void;
  /**
   * Show fetching status.
   */
  isFetching?: boolean;
}

export interface EditorDialogProps<Data>
  extends BaseEditorDialogProps<Data>,
    Omit<DialogProps, 'onSubmit'> {
  /**
   * Initial values for Formik.
   */
  initialValues: FormikConfig<Data>['initialValues'];
  /**
   * Function, that triggers on the save button click event.
   */
  onSubmit: FormikConfig<Data>['onSubmit'];
  /**
   * Validation schema for Formik.
   */
  validationSchema: FormikConfig<Data>['validationSchema'];
  /**
   * Determines if the component is in edit or create mode.
   */
  isEdit?: boolean;
  /**
   * The notification message on a succesful edit.
   */
  editSuccessNotification?: string;
  /**
   * The notification message on a succesful create.
   */
  createSuccessNotification?: string;
  showSuccessNotification?: boolean;
  buttonsPositionRight?: boolean;
  submitText?: string;
  submitDisabled?: boolean;
  submitClick?: () => void;
  disableDialogButtons?: boolean;
}

export function EditorDialog<Data>({
  initialValues,
  buttonsPositionRight,
  onSubmit,
  validationSchema,
  children,
  onClose,
  onSave,
  isEdit,
  editSuccessNotification,
  createSuccessNotification,
  showSuccessNotification = false,
  submitText = 'COMMON.SAVE',
  submitDisabled = false,
  submitClick,
  isFetching,
  disableDialogButtons,
  ...props
}: EditorDialogProps<Data>) {
  const ref = useRef<FormikProps<Data> | null>(null);
  const [err, setErr] = useState<string>();
  const { t } = useTranslation();

  const successMessage = useMemo(() => {
    if (isEdit) {
      return editSuccessNotification || t('COMMON.OPERATION_SUCCESSFUL');
    }

    return createSuccessNotification || t('COMMON.OPERATION_SUCCESSFUL');
  }, [isEdit, editSuccessNotification, createSuccessNotification, t]);

  const handleClose = useCallback(
    (e?: SyntheticEvent<any>, reason?: 'backdropClick' | 'escapeKeyDown') => {
      onClose?.(e, reason);
      ref.current?.resetForm();
      setErr(undefined);
    },
    [onClose]
  );

  const handleSubmit = useCallback(
    async (values: Data, formikHelpers: FormikHelpers<Data>) => {
      setErr(undefined);
      try {
        await onSubmit(values, formikHelpers);

        if (onSave) {
          await onSave();
        }

        if (showSuccessNotification) {
          showNotification({ content: successMessage, type: NotificationType.SUCCESS });
        }

        handleClose();
      } catch (error: any) {
        setErr(error?.response?.data?.message);
      }
    },
    [onSubmit, onSave, showSuccessNotification, handleClose, successMessage]
  );

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Dialog
          onClose={handleClose}
          component={Form}
          actions={
            <DialogFooterButtons
              buttonsPositionRight={buttonsPositionRight}
              cancelClick={handleClose}
              submitClick={submitClick}
              submitDisabled={!submitDisabled && (!isValid || !dirty)}
              submitText={submitText}
              isLoading={isSubmitting}
              disableDialogButtons={disableDialogButtons}
            />
          }
          {...props}
        >
          {children}

          {err === 'WORKER_ALREADY_EXISTS_WITH_SAME_SAP_ID' && (
            <FormHelperText error role="error">
              {t('ERRORS.WORKER_ALREADY_EXISTS_WITH_SAME_SAP_ID')}
            </FormHelperText>
          )}
        </Dialog>
      )}
    </Formik>
  );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import { showNotification } from 'components';
import i18n from 'i18n';

import { NotificationType, QueryContextFromKeys, QueryParamModel } from 'models';
import {
  AvailableAppointmentWorkerRequest,
  BookAppointmentRequest,
} from 'models/ucseAppointments.model';
import { CustomEvent } from 'pages/Ucse/Appointment/AppointmentPage';
import { ucseAppointmentsService } from 'services/ucseAppointment.service';

type UcseAppointmentsQueryContexts = QueryContextFromKeys<typeof ucseAppointmentsKeys>;

export const ucseAppointmentsKeys = {
  all: [{ scope: 'sm-order-appointment' }] as const,
  lists: () => [{ ...ucseAppointmentsKeys.all[0], entity: 'list' }] as const,
  ucseAppointmentsList: (params?: QueryParamModel | null) =>
    [{ ...ucseAppointmentsKeys.lists()[0], params }] as const,
  ucseAvailableWorker: (params?: AvailableAppointmentWorkerRequest | null) =>
    [{ ...ucseAppointmentsKeys.lists()[0], params }] as const,
};

const fetchUcseAppointmentsList = async ({
  queryKey: [{ params }],
}: UcseAppointmentsQueryContexts['ucseAppointmentsList']) =>
  ucseAppointmentsService.getAppointment(params);

export const useUcseAppointmentsList = (
  params?: QueryParamModel | null,
  requiredParam?: string
) => {
  return useQuery(ucseAppointmentsKeys.ucseAppointmentsList(params), fetchUcseAppointmentsList, {
    enabled: !!params?.filter && !!requiredParam,
  });
};

const fetchAppointmentAvailableWorker = async ({
  queryKey: [{ params }],
}: UcseAppointmentsQueryContexts['ucseAvailableWorker']) =>
  ucseAppointmentsService.getAppointmentAvailableWorker(params);

export const useAppointmentAvailableWorker = (
  params?: AvailableAppointmentWorkerRequest | null,
  selected?: CustomEvent | null
) => {
  return useQuery(
    ucseAppointmentsKeys.ucseAvailableWorker(params),
    fetchAppointmentAvailableWorker,
    {
      enabled: !!params && !!selected,
      cacheTime: 0,
    }
  );
};

const showCommonError = () =>
  showNotification({
    content: i18n.t('COMMON.OPERATION_UNSUCCESSFUL'),
    type: NotificationType.ERROR,
  });

export const useBookAppointment = () => {
  return useMutation(
    ({ data }: { data: BookAppointmentRequest }) => ucseAppointmentsService.bookAppointment(data),
    {
      onError: () => showCommonError(),
    }
  );
};

export const useRemoveAppointment = () => {
  return useMutation(
    ({ data }: { data: BookAppointmentRequest }) => ucseAppointmentsService.removeAppointment(data),
    {
      onError: () => showCommonError(),
    }
  );
};

import i18n from 'i18n';

import { useQuery } from '@tanstack/react-query';

import { showNotification } from 'components';
import { NotificationType } from 'models';
import { seedService } from 'services';

export const seedKeys = {
  all: [{ scope: 'seed' }] as const,
  lists: () => [{ ...seedKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...seedKeys.lists()[0] }] as const,
};

const seedValueSet = async () => seedService.seedValueSet();

export const useSeed = () =>
  useQuery(seedKeys.list(), seedValueSet, {
    enabled: false,
    onSuccess: () => {
      showNotification({
        content: i18n.t('COMMON.OPERATION_SUCCESSFUL'),
        type: NotificationType.SUCCESS,
      });
    },
    onError: () => {
      showNotification({
        content: i18n.t('COMMON.OPERATION_UNSUCCESSFUL'),
        type: NotificationType.ERROR,
      });
    },
  });

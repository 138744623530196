import { EntityService } from 'utils/entityService';
import request, { Methods } from 'utils/request';

class SeedService extends EntityService {
  constructor() {
    super('seed');
  }

  async seedValueSet() {
    return request({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
    });
  }
}

export const seedService = new SeedService();

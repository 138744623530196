import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, NotifiedReadingResultModel } from 'models';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { getPageName } from 'utils/format';
import { formatDate } from 'utils/dates';
import Yup from 'utils/yup';
import { RouteNames } from 'config/routeNames';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import {
  useExportNotifiedReadingResults,
  useNotifiedReadingResultsList,
} from 'features/notifiedReadingResults';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

export const readingDateValidationSchema = Yup.object().shape({
  plannedReadingDate: Yup.object()
    .nullable()
    .shape({
      from: Yup.mixed()
        .nullable()
        .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      to: Yup.mixed()
        .nullable()
        .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
    }),
  readingResultDate: Yup.object()
    .nullable()
    .shape({
      from: Yup.mixed()
        .nullable()
        .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      to: Yup.mixed()
        .nullable()
        .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
    }),
  badInterval: Yup.bool().oneOf([true], 'bad interval'),
});

const NotifyLogsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: notifiedReadingResults,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useNotifiedReadingResultsList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportNotifiedReadingResults(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOKOZPONT', 'NLO'],
  });
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });

  const { activePanel, closePanel, setPanel } = usePanelState();

  const { data: leoKozpontData, accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const doubleFilterPanel = true;

  const notifyLogFilters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      name: 'plannedReadingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
    },
    {
      headerTitle: 'NOTIFY_LOGS.READING_RESULT_DATE',
      component: <LeoDateInterval name="readingResultDate" />,
      name: 'readingResultDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.READING_RESULT_DATE'),
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.WORKER',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="LEO_LEO_EGYSEGEK.WORKER"
          freeSolo
          maxLength={5}
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
    },
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.BUNDLE',
      component: (
        <LeoTextField
          name="bundle.from"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.FROM" />}
          maxLength={10}
        />
      ),
      name: 'bundle.from',
      panelFieldWidth: 6,
    },
    {
      component: (
        <LeoTextField
          name="bundle.to"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.TO" />}
          maxLength={10}
        />
      ),
      name: 'bundle.to',
      panelFieldWidth: 6,
      divider: !doubleFilterPanel,
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.WIRING',
      component: (
        <LeoTextField
          name="bindingId"
          label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.WIRING" />}
        />
      ),
      name: 'bindingId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.WIRING'),
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.READING_UNIT',
      component: (
        <LeoTextField
          name="readingUnitCode"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.READING_UNIT" />}
          maxLength={10}
        />
      ),
      name: 'readingUnitCode',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.READING_UNIT'),
    },
    {
      headerTitle: 'LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenter"
          data={leoKozpontData}
          label="LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER"
        />
      ),
      name: 'readingCenter',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
  ];

  const headerAction = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters: notifyLogFilters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_ERTESITO_JELZESEK),
    validationSchema: readingDateValidationSchema,
    doubleFilterPanel: true,
    isFetching,
  });

  const notfiyLogColumns = [
    {
      key: 'readingResultDate',
      header: <Trans i18nKey="NOTIFY_LOGS.READING_RESULT_DATE" />,
      accessor: (order: NotifiedReadingResultModel) => formatDate(order.readingResultDate),
      field: 'readingResultDate',
      sortable: true,
    },
    {
      key: 'bundle',
      header: <Trans i18nKey="NOTIFY_LOGS.BUNDLE" />,
      accessor: 'bundle',
      sortable: true,
    },
    {
      key: 'plannedReadingDate',
      header: <Trans i18nKey="NOTIFY_LOGS.PLANNED_READING_DATE" />,
      accessor: (order: NotifiedReadingResultModel) => formatDate(order.plannedReadingDate),
      field: 'plannedReadingDate',
      sortable: true,
    },
    {
      key: 'readingUnitCode',
      header: <Trans i18nKey="NOTIFY_LOGS.READING_UNIT_CODE" />,
      accessor: 'readingUnitCode',
      sortable: true,
    },
    {
      key: 'readingCenter',
      header: <Trans i18nKey="NOTIFY_LOGS.READING_CENTER" />,
      accessor: (order: NotifiedReadingResultModel) => order.readingCenter,
      tooltip: (order: NotifiedReadingResultModel) =>
        order.readingCenter && leoKozpontAccessor[order.readingCenter],
      field: 'readingCenter',
      sortable: true,
    },
    {
      key: 'workerSapId',
      header: <Trans i18nKey="NOTIFY_LOGS.WORKER_ID" />,
      accessor: 'workerSapId',
      sortable: true,
    },
    {
      key: 'bindingId',
      header: <Trans i18nKey="NOTIFY_LOGS.BINDING_ID" />,
      accessor: 'bindingId',
      sortable: true,
    },
    {
      key: 'serialNumber',
      header: <Trans i18nKey="NOTIFY_LOGS.SERIAL_NUMBER" />,
      accessor: 'serialNumber',
      sortable: true,
    },
    {
      key: 'meterReadingOrderId',
      header: <Trans i18nKey="NOTIFY_LOGS.ORDER_ID" />,
      accessor: 'meterReadingOrderId',
      sortable: true,
    },
    {
      key: 'readingFailureReasonKey',
      header: <Trans i18nKey="NOTIFY_LOGS.NOTIFICATION" />,
      accessor: (order: NotifiedReadingResultModel) => order.readingFailureReasonKey,
      tooltip: (order: NotifiedReadingResultModel) =>
        order.readingFailureReasonKey &&
        getValueSetValues(valueSet, 'NLO', order.readingFailureReasonKey),
      field: 'readingFailureReasonKey',
      sortable: true,
    },
  ];

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="NOTIFY_LOGS.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ leoKozpontAccessor }}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={notifiedReadingResults?.data || []}
          total={notifiedReadingResults?.meta.total}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={notfiyLogColumns}
          pageHeaderActions={headerAction}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default NotifyLogsPage;

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';

import {
  ColumnProps,
  DeleteDialog,
  LeoDatePicker,
  LeoSelectFieldMulti,
  Page,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, PerformanceMetricUnitPriceModel } from 'models';
import Yup from 'utils/yup';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { stringify } from 'utils/base64';
import { SortDirection } from 'utils/arrays';
import { YEAR_MONTH_DATE_FORMAT, formatDate } from 'utils/dates';
import { selectUserRoles } from 'store/profile';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { usePerformanceMetricList } from 'features/performanceMetric';
import {
  useDeletePerformanceMetricUnitPrice,
  useExportPerformanceMetricUnitPriceList,
  usePerformanceMetricUnitPriceList,
} from 'features/performanceMetricUnitPrice';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

import TariffsEditorDialog from './TariffsEditorDialog';

const PerformanceTariffsPage: FC = () => {
  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<PerformanceMetricUnitPriceModel>();

  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    selected: selectedDelete,
  } = useDialogState<PerformanceMetricUnitPriceModel>();

  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({
    order: stringify([{ orderBy: 'value', order: SortDirection.ASC }]),
  });

  const {
    data: performanceTariffsData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = usePerformanceMetricUnitPriceList(apiParams);

  const { data: performanceMetricData } = usePerformanceMetricList({
    order: stringify([{ orderBy: 'value' }]),
    filter: stringify({ isTariffDisabled: false }),
  });

  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.TARIFFS_PAGE_ACTIONS, roles);

  const { activePanel, closePanel, setPanel } = usePanelState();

  const { data: wageItemsData, accessor: wageItemsAccessor } = useFieldDataAndAccessor(
    performanceMetricData?.data,
    'id',
    'value'
  );

  const { mutateAsync: deletePerformanceTariff } = useDeletePerformanceMetricUnitPrice();

  const { isInitialLoading, isRefetching, refetch } =
    useExportPerformanceMetricUnitPriceList(exportParams);

  const onDelete = async () => {
    if (selectedDelete?.id) {
      await deletePerformanceTariff(selectedDelete.id);
    }
  };

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_TARIFAK_BERELEMEK.VALID_FROM',
      component: (
        <LeoDatePicker
          name="validFrom"
          label={<Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.VALID_FROM" />}
          isYearAndMonthOnly
        />
      ),
      divider: true,
      name: 'validFrom',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      abbrName: t('FILTERABBR.VALID_FROM'),
    },
    {
      name: 'performanceMetricIds',
      headerTitle: 'KOZOS_TARIFAK_BERELEMEK.VALUE',
      component: (
        <LeoSelectFieldMulti
          name="performanceMetricIds"
          data={wageItemsData}
          accessor={wageItemsAccessor}
          label="KOZOS_TARIFAK_BERELEMEK.VALUE"
          readOnly
        />
      ),
      panelFieldWidth: 12,
    },
  ];

  const validationSchema = Yup.object().shape({
    validFrom: Yup.date().nullable(),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_TARIFAK),
    validationSchema,
    isFetching,
  });

  const TariffsColumns: ColumnProps<PerformanceMetricUnitPriceModel>[] = [
    {
      key: 'value',
      header: <Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.VALUE" />,
      accessor: (item: PerformanceMetricUnitPriceModel) =>
        item.performanceMetric?.value || item.value,
      field: 'value',
      sortable: true,
    },
    {
      key: 'validFrom',
      header: <Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.VALID_FROM" />,
      accessor: (item: PerformanceMetricUnitPriceModel) =>
        formatDate(item.validFrom, YEAR_MONTH_DATE_FORMAT),
      field: 'validFrom',
      sortable: true,
    },
    {
      key: 'unitPriceForPower',
      header: <Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.UNIT_PRICE_FOR_POWER" />,
      accessor: 'unitPriceForPower',
      sortable: true,
    },
    {
      key: 'unitPriceForGas',
      header: <Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.UNIT_PRICE_FOR_GAS" />,
      accessor: 'unitPriceForGas',
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: refetch,
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const actions = [
    {
      name: 'edit',
      label: 'COMMON.EDIT',
      icon: <EditIcon fontSize="small" />,
      onClick: (tariff: PerformanceMetricUnitPriceModel) => openEditor(tariff),
      visible,
    },
    {
      name: 'delete',
      label: 'COMMON.DELETE',
      icon: <Delete fontSize="small" />,
      onClick: (tariff: PerformanceMetricUnitPriceModel) => openDelete(tariff),
      visible,
    },
  ];

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="KOZOS_TARIFAK_BERELEMEK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Box>
        <Table
          filterDetailsData={{ performanceMetricData }}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={performanceTariffsData?.data || []}
          total={performanceTariffsData?.meta.total}
          timestamp={dataUpdatedAt}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={TariffsColumns}
          enableCheckbox={false}
          pageHeaderActions={headerActions}
          actions={visible ? actions : undefined}
          onAdd={
            visible ? (tariff: PerformanceMetricUnitPriceModel) => openEditor(tariff) : undefined
          }
          onLoadQuery={onLoadQuery}
        />
      </Box>
      <TariffsEditorDialog
        open={isEditorOpen}
        wageItemsData={wageItemsData}
        wageItemsAccessor={wageItemsAccessor}
        selected={selectedEdit}
        onClose={closeEditor}
      />
      <DeleteDialog
        open={isDeleteOpen}
        selected={selectedDelete}
        onClose={closeDelete}
        onSubmit={onDelete}
        deleteTitleAccessor="value"
      />
    </Page>
  );
};

export default PerformanceTariffsPage;

import i18n from 'i18n';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { workersService } from 'services';
import { showNotification } from 'components';
import { NotificationType, QueryContextFromKeys, QueryParamModel, WorkerViewModel } from 'models';
import { SendMessageFormProps } from 'pages/Kozos/Workers/SendMessageDialog';
import { REACT_QUERY_OPTIONS } from 'config/reactQueryConfig';

type WorkersQueryContexts = QueryContextFromKeys<typeof workersKeys>;

export const workersKeys = {
  all: [{ scope: 'workers' }] as const,
  lists: () => [{ ...workersKeys.all[0], entity: 'list' }] as const,
  workersList: (params?: QueryParamModel | null) =>
    [{ ...workersKeys.lists()[0], params }] as const,
  workerSearch: (params?: QueryParamModel) => [{ ...workersKeys.lists()[0], params }] as const,
  exportList: (params?: QueryParamModel | null) => [{ ...workersKeys.lists()[0], params }] as const,
};

const exportWorkers = async ({ queryKey: [{ params }] }: WorkersQueryContexts['exportList']) =>
  workersService.exportWorkers(params);

const fetchWorkersList = async ({ queryKey: [{ params }] }: WorkersQueryContexts['workersList']) =>
  workersService.getWorkersList(params);

const fetchWorkerSearch = async ({
  queryKey: [{ params }],
}: WorkersQueryContexts['workerSearch']) => workersService.searchWorkers(params);

export const useWorkerPageList = (params?: QueryParamModel | null) => {
  return useQuery(workersKeys.workersList(params), fetchWorkersList, {
    enabled: !!params?.filter,
  });
};

export const useWorkerList = (params: QueryParamModel | null = null) => {
  return useQuery(workersKeys.workersList(params), fetchWorkersList);
};

export const useWorkerListSearch = (params?: QueryParamModel) => {
  return useQuery(workersKeys.workerSearch(params), fetchWorkerSearch);
};

export const useSendMessage = () => {
  return useMutation(({ data }: { data: SendMessageFormProps }) =>
    workersService.sendMessage(data)
  );
};

export const useSyncTask = () => {
  return useMutation((jobId: string) => workersService.getSyncTaskById(jobId), {
    meta: {
      [REACT_QUERY_OPTIONS.SKIP_DEFAULT_SUCCESS_CALLBACK]: true,
    },
  });
};

export const useSyncWorkers = () => {
  return useMutation(
    () => {
      return workersService.syncWorkers();
    },
    {
      onSuccess: async (response: { jobId: string }) => {
        showNotification({
          content: i18n.t('MUNKAVEGZOK.SYNC_WAIT'),
          type: NotificationType.WARNING,
        });
      },
    }
  );
};

export const useExportWorkers = (params?: QueryParamModel | null) => {
  return useQuery(workersKeys.exportList(params), exportWorkers, { enabled: false });
};

export const useEditWorker = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<WorkerViewModel> }) =>
      workersService.editWorker(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: workersKeys.lists() });
      },
    }
  );
};

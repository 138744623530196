import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';
import { emptyResponse } from 'features/helpers';
import { parse } from 'utils/base64';

type ReportCodesQueryContexts = QueryContextFromKeys<typeof ReportCodesKeys>;

export const ReportCodesKeys = {
  all: [{ scope: 'reportCodes' }] as const,
  lists: () => [{ ...ReportCodesKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...ReportCodesKeys.lists()[0], params }] as const,
};

const fetchReportCodesList = async ({
  queryKey: [{ params }],
}: ReportCodesQueryContexts['list']) => {
  const { plannedReadingDate: requiredParam } = parse(params?.filter);

  return requiredParam ? statisticsService.getReportCodesList(params) : emptyResponse;
};

const exportReportCodes = async ({ queryKey: [{ params }] }: ReportCodesQueryContexts['list']) =>
  statisticsService.exportReportCodes(params);

export const useReportCodesList = (params?: QueryParamModel | null) => {
  return useQuery(ReportCodesKeys.list(params), fetchReportCodesList, {
    enabled: !!params?.filter,
  });
};

export const useExportReportCodes = (params?: QueryParamModel | null) => {
  return useQuery(ReportCodesKeys.list(params), exportReportCodes, { enabled: false });
};

import React, { FC, ReactElement } from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Button, ConditionalWrapper, Tooltip } from 'components';
import { PanelConfigModel } from 'models';
import { removeEmpty } from 'utils/arrays';
import { mergeSx } from 'utils/styles';

export interface PanelIconsProps {
  active?: string | null;
  panels?: (PanelConfigModel | null)[];
  onClick?: (config?: PanelConfigModel | null) => void;
  color?: 'primary' | 'secondary';
}

const styles = {
  activeIcon: {
    backgroundColor: 'action.selected',
    '&:hover': {
      backgroundColor: 'action.selectedHover',
    },
  },
  wrapperStyle: {
    marginRight: 2,
    display: 'inline',
  },
  badge: {
    verticalAlign: 'top',

    '& .MuiBadge-badge': { padding: 0, height: '10px', minWidth: '10px' },
  },
};

export const PanelIcons: FC<PanelIconsProps> = ({ panels, active, onClick, color }) => {
  const handleClick = (panel: PanelConfigModel) => () => {
    if (onClick) {
      onClick(active === panel.key ? null : panel);
    }
  };

  return (
    <>
      {removeEmpty(panels).map((panelConfig) => {
        const { icon: Icon, key, title, badge, isButton } = panelConfig;

        const activeIcon = active === key ? styles.activeIcon : null;

        const getWrapperChildren = (children: ReactElement) =>
          isButton ? <>{children}</> : <Tooltip title={title}>{children}</Tooltip>;

        return (
          <Box key={key} sx={styles.wrapperStyle}>
            <ConditionalWrapper
              condition={Boolean(title)}
              wrapper={(children: ReactElement) => getWrapperChildren(children)}
            >
              {isButton ? (
                <Button
                  variant="outlined"
                  color={color || 'primary'}
                  onClick={handleClick(panelConfig)}
                  startIcon={<Icon color="inherit" />}
                >
                  {title}
                </Button>
              ) : (
                <IconButton
                  onClick={handleClick(panelConfig)}
                  color={color || 'inherit'}
                  sx={mergeSx(activeIcon)}
                  size="large"
                >
                  <Badge badgeContent={badge} color="primary">
                    <Icon color="inherit" />
                  </Badge>
                </IconButton>
              )}
              {isButton && !!badge && (
                <Badge sx={styles.badge} badgeContent={badge && ''} color="error" />
              )}
            </ConditionalWrapper>
          </Box>
        );
      })}
    </>
  );
};

import { useQuery } from '@tanstack/react-query';

import { statisticsService } from 'services';
import { QueryContextFromKeys, QueryParamModel } from 'models';
import { parse } from 'utils/base64';
import { emptyResponse } from 'features/helpers';

type CompletedAfterDeadLineContexts = QueryContextFromKeys<typeof completedAfterDeadlineKeys>;

export const completedAfterDeadlineKeys = {
  all: [{ scope: 'completedAfterDeadLine' }] as const,
  lists: () => [{ ...completedAfterDeadlineKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...completedAfterDeadlineKeys.lists()[0], params }] as const,
};

const fetchCompletedAfterDeadLineList = async ({
  queryKey: [{ params }],
}: CompletedAfterDeadLineContexts['list']) => {
  const { statusDate: requiredParam } = parse(params?.filter);

  return requiredParam ? statisticsService.getCompletedAfterDeadLineList(params) : emptyResponse;
};

const exportCompletedAfterDeadLine = async ({
  queryKey: [{ params }],
}: CompletedAfterDeadLineContexts['list']) =>
  statisticsService.exportCompletedAfterDeadLineStatistics(params);

export const useCompletedAfterDeadLineList = (params?: QueryParamModel | null) =>
  useQuery(completedAfterDeadlineKeys.list(params), fetchCompletedAfterDeadLineList, {
    enabled: !!params?.filter,
  });

export const useExportCompletedAfterDeadLine = (params?: QueryParamModel | null) => {
  return useQuery(completedAfterDeadlineKeys.list(params), exportCompletedAfterDeadLine, {
    enabled: false,
  });
};

import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import omit from 'lodash/omit';

import { ReportUnitPriceModel } from 'models';
import { BaseEditorDialogProps, EditorDialog, LeoDatePicker } from 'components';
import { useEditReportUnitPrice, usePutReportUnitPrice } from 'features/reportUnitPrice';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import { replaceDecimal } from 'utils/format';

export interface TariffEditorDialogProps extends BaseEditorDialogProps<ReportUnitPriceModel> {
  wageItemsData?: string[];
  wageItemsAccessor?: Record<string | number, string>;
}

const TariffsEditorDialog: FC<TariffEditorDialogProps> = ({
  wageItemsData,
  wageItemsAccessor,
  selected,
  ...props
}) => {
  const { t } = useTranslation();
  const isEdit = Boolean(selected && selected.id);
  const { mutateAsync: createTariffItem } = useEditReportUnitPrice();
  const { mutateAsync: editTariffItem } = usePutReportUnitPrice();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const onSubmit = async (values: Partial<ReportUnitPriceModel>) => {
    const transformedValues = {
      ...values,
      unitPriceForPower: values.unitPriceForPower && replaceDecimal(values.unitPriceForPower),
      unitPriceForGas: values.unitPriceForGas && replaceDecimal(values.unitPriceForGas),
    };

    if (values?.id) {
      await editTariffItem({
        id: values.id,
        data: omit(transformedValues, 'performanceMetric'),
      });
    } else if (values) {
      await createTariffItem({
        data: transformedValues,
      });
    }
  };

  const initVal = isEdit
    ? selected
    : {
        reportValueSetSubgroupId: undefined,
        validFrom: undefined,
        unitPriceForPower: '0',
        unitPriceForGas: '0',
      };

  const hyphen = selected?.key && selected?.value ? '-' : '';

  const dialogTitle = isEdit
    ? `${t('KOZOS_TARIFAK_BERELEMEK.EDIT')} ${hyphen} ${selected?.key ?? ''} ${hyphen} ${
        selected?.value ?? ''
      }`
    : t('KOZOS_TARIFAK_BERELEMEK.NEW');

  return (
    <EditorDialog
      initialValues={{ ...initVal }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        reportValueSetSubgroupId: Yup.string().nullable().required(),
        validFrom: Yup.date().nullable().required(),
        unitPriceForPower: Yup.number()
          .transform((_, value) => +replaceDecimal(value))
          .min(0)
          .max(99999)
          .required(),
        unitPriceForGas: Yup.number()
          .transform((_, value) => +replaceDecimal(value))
          .min(0)
          .max(99999)
          .required(),
      })}
      title={dialogTitle}
      {...props}
    >
      {!isEdit && (
        <LeoSelectField
          name="reportValueSetSubgroupId"
          data={wageItemsData}
          accessor={wageItemsAccessor}
          label="KOZOS_TARIFAK_JELENTESEK.REPORT"
        />
      )}
      <LeoDatePicker
        name="validFrom"
        label={<Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.VALID_FROM" />}
        isYearAndMonthOnly
      />
      <LeoTextField
        name="unitPriceForPower"
        label={<Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.UNIT_PRICE_FOR_POWER" />}
      />
      <LeoTextField
        name="unitPriceForGas"
        label={<Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.UNIT_PRICE_FOR_GAS" />}
      />
    </EditorDialog>
  );
};

export default TariffsEditorDialog;

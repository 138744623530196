import { QueryParamModel } from 'models';
import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';
import {
  AvailableAppointmentWorkerRequest,
  AvailableAppointmentWorkerResponse,
  BookAppointmentRequest,
  UcseAppointmentsModel,
} from 'models/ucseAppointments.model';
import { stringify } from 'utils/base64';

class UcseAppointmentsService extends EntityService {
  constructor() {
    super('sm-order-appointment');
  }

  async getAppointment(params?: QueryParamModel | null) {
    return request<UcseAppointmentsModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async getAppointmentAvailableWorker(param: AvailableAppointmentWorkerRequest | null | undefined) {
    return request<AvailableAppointmentWorkerResponse>({
      method: Methods.GET,
      resource: `v1/${this.resource}/available-worker?filter=${stringify(param)}`,
    });
  }

  async bookAppointment(data?: BookAppointmentRequest) {
    return request({
      method: Methods.POST,
      resource: `v1/${this.resource}/book`,
      data,
    });
  }

  async removeAppointment(data?: BookAppointmentRequest) {
    return request({
      method: Methods.POST,
      resource: `v1/${this.resource}/remove`,
      data,
    });
  }
}

export const ucseAppointmentsService = new UcseAppointmentsService();

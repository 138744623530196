import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';
import { emptyResponse } from 'features/helpers';
import { parse } from 'utils/base64';

type ReadingFailureReasonsQueryContexts = QueryContextFromKeys<typeof readingFailureReasonsKeys>;

export const readingFailureReasonsKeys = {
  all: [{ scope: 'readingFailureReasons' }] as const,
  lists: () => [{ ...readingFailureReasonsKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...readingFailureReasonsKeys.lists()[0], params }] as const,
};

const fetchReadingFailureReasonsList = async ({
  queryKey: [{ params }],
}: ReadingFailureReasonsQueryContexts['list']) => {
  const { plannedReadingDate: requiredParam } = parse(params?.filter);

  return requiredParam ? statisticsService.getReadingFailureReasonsList(params) : emptyResponse;
};

const exportReadingFailureReasons = async ({
  queryKey: [{ params }],
}: ReadingFailureReasonsQueryContexts['list']) =>
  statisticsService.exportReadingFailureReasons(params);

export const useReadingFailureReasonsList = (params?: QueryParamModel | null) => {
  return useQuery(readingFailureReasonsKeys.list(params), fetchReadingFailureReasonsList, {
    enabled: !!params?.filter,
  });
};

export const useExportReadingFailureReasons = (params?: QueryParamModel | null) => {
  return useQuery(readingFailureReasonsKeys.list(params), exportReadingFailureReasons, {
    enabled: false,
  });
};

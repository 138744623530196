import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  ColumnProps,
  DeleteDialog,
  LeoDatePicker,
  LeoSelectFieldMulti,
  Page,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, ReportUnitPriceModel } from 'models';
import { selectUserRoles } from 'store/profile';
import Yup from 'utils/yup';
import { stringify } from 'utils/base64';
import { getPageName } from 'utils/format';
import { SortDirection } from 'utils/arrays';
import { YEAR_MONTH_DATE_FORMAT, formatDate } from 'utils/dates';
import { RouteNames } from 'config/routeNames';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { useReportSubgroupKeys, useValueSetList } from 'features/valueSets';
import {
  useDeleteReportUnitPrice,
  useExportReportUnitPriceList,
  useReportUnitPriceList,
} from 'features/reportUnitPrice';
import { getReportValueSetData } from 'pages/Leo/Monitoring/ReportCodes/ReportCodesPage';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

import TariffsEditorDialog from './TariffsEditorDialog';

const PerformanceTariffsReportsPage: FC = () => {
  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<ReportUnitPriceModel>();

  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    selected: selectedDelete,
  } = useDialogState<ReportUnitPriceModel>();

  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({
    order: stringify([{ orderBy: 'key', order: SortDirection.ASC }]),
  });

  const { data: reportSubgroupKeys, isLoading: isReportSubgroupKeysLoading } =
    useReportSubgroupKeys();

  const {
    data: reportTariffsData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReportUnitPriceList(apiParams);
  const { mutateAsync: deleteReportPerformanceTariff } = useDeleteReportUnitPrice();
  const { isInitialLoading, isRefetching, refetch } = useExportReportUnitPriceList(exportParams);
  const { data: valueSet } = useValueSetList(
    {
      code: reportSubgroupKeys,
    },
    !isReportSubgroupKeysLoading
  );

  const { data: reportCodes, accessor: performanceTariffsReportAccessors } = getReportValueSetData(
    valueSet,
    reportSubgroupKeys,
    'id',
    'value',
    true,
    'key'
  );

  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.TARIFFS_PAGE_ACTIONS, roles);

  const { activePanel, closePanel, setPanel } = usePanelState();

  const onDelete = async () => {
    if (selectedDelete?.id) {
      await deleteReportPerformanceTariff(selectedDelete.id);
    }
  };

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_TARIFAK_JELENTESEK.VALID_FROM',
      component: (
        <LeoDatePicker
          name="validFrom"
          label={<Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.VALID_FROM" />}
          isYearAndMonthOnly
        />
      ),
      divider: true,
      name: 'validFrom',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      abbrName: t('FILTERABBR.VALID_FROM'),
    },
    {
      name: 'reportValueSetSubgroupIds',
      headerTitle: 'KOZOS_TARIFAK_JELENTESEK.REPORT',
      component: (
        <LeoSelectFieldMulti
          name="reportValueSetSubgroupIds"
          data={reportCodes}
          accessor={performanceTariffsReportAccessors}
          label="KOZOS_TARIFAK_JELENTESEK.REPORT"
          readOnly
        />
      ),
      panelFieldWidth: 12,
    },
  ];

  const validationSchema = Yup.object().shape({
    validFrom: Yup.date().nullable(),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_TARIFAK_JELENTESEK),
    validationSchema,
    isFetching,
  });

  const reportTariffsColumns: ColumnProps<ReportUnitPriceModel>[] = [
    {
      key: 'key',
      header: <Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.REPORT" />,
      accessor: (item: ReportUnitPriceModel) => item.reportValueSetSubgroup?.key || item.key,
      field: 'key',
      tooltip: (item: ReportUnitPriceModel) => item.reportValueSetSubgroup?.value || item.value,
      sortable: true,
    },
    {
      key: 'validFrom',
      header: <Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.VALID_FROM" />,
      accessor: (item: ReportUnitPriceModel) => formatDate(item.validFrom, YEAR_MONTH_DATE_FORMAT),
      field: 'validFrom',
      sortable: true,
    },
    {
      key: 'unitPriceForPower',
      header: <Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.UNIT_PRICE_FOR_POWER" />,
      accessor: 'unitPriceForPower',
      sortable: true,
    },
    {
      key: 'unitPriceForGas',
      header: <Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.UNIT_PRICE_FOR_GAS" />,
      accessor: 'unitPriceForGas',
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: refetch,
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const actions = [
    {
      name: 'edit',
      label: 'COMMON.EDIT',
      icon: <EditIcon fontSize="small" />,
      onClick: (tariff: ReportUnitPriceModel) => openEditor(tariff),
      visible,
    },
    {
      name: 'delete',
      label: 'COMMON.DELETE',
      icon: <DeleteIcon fontSize="small" />,
      onClick: (tariff: ReportUnitPriceModel) => openDelete(tariff),
      visible,
    },
  ];

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="KOZOS_TARIFAK_JELENTESEK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Box>
        <Table
          filterDetailsData={{
            performanceTariffsReportAccessors,
          }}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={reportTariffsData?.data || []}
          total={reportTariffsData?.meta.total}
          timestamp={dataUpdatedAt}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={reportTariffsColumns}
          enableCheckbox={false}
          pageHeaderActions={headerActions}
          actions={visible ? actions : undefined}
          onAdd={visible ? (tariff: ReportUnitPriceModel) => openEditor(tariff) : undefined}
          onLoadQuery={onLoadQuery}
        />
      </Box>
      <TariffsEditorDialog
        open={isEditorOpen}
        wageItemsData={reportCodes}
        wageItemsAccessor={performanceTariffsReportAccessors}
        selected={selectedEdit}
        onClose={closeEditor}
      />
      <DeleteDialog
        open={isDeleteOpen}
        selected={selectedDelete}
        onClose={closeDelete}
        onSubmit={onDelete}
        deleteTitleAccessor={(e) => e?.value || ''}
      />
    </Page>
  );
};

export default PerformanceTariffsReportsPage;

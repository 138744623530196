import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import {
  FileUpload,
  LeoDatePicker,
  LeoSelectFieldMulti,
  Page,
  PageHeaderAction,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, PerformanceBasedPayModel, PerformanceMetricModel } from 'models';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { RouteNames } from 'config/routeNames';
import { selectUserRoles } from 'store/profile';
import {
  useDownloadPerformanceBasedPay,
  useExportPayData,
  useExportPerformanceBasedPayList,
  useImportTask,
  usePerformanceBasedPayList,
} from 'features/performanceBasedPay';
import { usePerformanceMetricList } from 'features/performanceMetric';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { YEAR_MONTH_DATE_FORMAT, formatDate } from 'utils/dates';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { getPageName } from 'utils/format';
import Yup from 'utils/yup';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { ReactComponent as Import } from 'assets/icons/Material/fileUpload.svg';

import NewPerformancePayDialog from './NewPerformancePayDialog';
import NewPerformanceEditorDialog from './NewPerformanceEditorDialog';
import StatusChangeDialog, { StatusChange } from './StatusChangeDialog';

export interface MetricSubColumns {
  isParentCell: boolean;
  key: string;
  header: string;
  field: string;
  sortable: boolean;
  subCells: SubCellProps[];
}

interface SubCellProps {
  key: string;
  header: JSX.Element;
  accessor: (row: PerformanceBasedPayModel) => number | undefined;
  field: string;
  sortable: boolean;
  noWrap?: boolean;
}

const styles = {
  table: {
    '.MuiPaper-root': {
      '@media (min-height: 900px)': {
        maxHeight: '600px',
      },
      '@media (max-height: 899px)': {
        maxHeight: '380px',
      },
    },
  },
};

const PerformanceBasedPayPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: performanceBasedPayData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = usePerformanceBasedPayList(apiParams);
  const { isInitialLoading, isRefetching, refetch } =
    useExportPerformanceBasedPayList(exportParams);
  const { mutateAsync: downloadPerformanceBasedPay, isLoading: isDownloadLoading } =
    useDownloadPerformanceBasedPay();

  const { mutateAsync: importTask, isLoading: importIsLoading } = useImportTask();

  const {
    isInitialLoading: isPayDataInitLoading,
    isRefetching: isPayDataRefetching,
    refetch: refetchPayData,
  } = useExportPayData(exportParams);
  const { data: metricData, isLoading: isMetricLoading } = usePerformanceMetricList();
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );
  const { data: valueSetData, isLoading: isValueSetLoading } = useValueSetList({
    code: ['TBS'],
  });
  const { data: tbsList, accessor: tbsAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSetData, 'TBS'),
    'key',
    'value'
  );

  const roles = useSelector(selectUserRoles);
  const importButtonRef = useRef<HTMLInputElement>();
  const visible = getFunctionRole(FunctionRoleName.PERFORMANCEBASEDPAY_PAGE_ACTIONS, roles);
  const visibleAdminPerformanceCoord = getFunctionRole(
    FunctionRoleName.PERFORMANCEBASEDPAY_PAGE_ADMIN_COORD_PERFORMANCE_RULES,
    roles
  );

  const {
    isOpen: isApproveDialogOpen,
    openDialog: openApproveDialog,
    closeDialog: closeApproveDialog,
    selected: selectedApprove,
  } = useDialogState<PerformanceBasedPayModel[]>();
  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    selected: selectedDelete,
  } = useDialogState<PerformanceBasedPayModel[]>();
  const {
    isOpen: isRejectDialogOpen,
    openDialog: openRejectDialog,
    closeDialog: closeRejectDialog,
    selected: selectedReject,
  } = useDialogState<PerformanceBasedPayModel[]>();
  const {
    isOpen: isReopenDialogOpen,
    openDialog: openReopenDialog,
    closeDialog: closeReopenDialog,
    selected: selectedReopen,
  } = useDialogState<PerformanceBasedPayModel[]>();
  const {
    isOpen: isSendToApproveDialogOpen,
    openDialog: openSendToApproveDialog,
    closeDialog: closeSendToApproveDialog,
    selected: selectedSendToApprove,
  } = useDialogState<PerformanceBasedPayModel[]>();
  const {
    isOpen: isRunDialogOpen,
    openDialog: openRunDialog,
    closeDialog: closeRunDialog,
    selected: selectedRun,
  } = useDialogState<PerformanceBasedPayModel[]>();

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<PerformanceBasedPayModel>();

  const generateSubCells = (item: PerformanceMetricModel) => {
    const withoutUnitPrice = item.key === 'NOT_READABLE_METER' || item.key === 'REPORTS';

    const header =
      item.key === 'CP_VISITED' ? (
        <Trans i18nKey="KOZOS_TELJESITMENYBEREK.MAR_NUMBER" />
      ) : (
        <Trans i18nKey="KOZOS_TELJESITMENYBEREK.COMPLETED" />
      );

    const subCells = [
      {
        key: `${item.value}.count`,
        header,
        accessor: (row: PerformanceBasedPayModel) =>
          row.performanceBasedPayPerformanceMetrics.find((p) => p.performanceMetricId === item.id)
            ?.count,
        field: 'performanceBasedPayPerformanceMetrics.count',
        sortable: false,
      },
      {
        key: `${item.value}.unitPrice`,
        header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.UNIT_PRICE" />,
        accessor: (row: PerformanceBasedPayModel) =>
          row.performanceBasedPayPerformanceMetrics.find((p) => p.performanceMetricId === item.id)
            ?.unitPrice,
        field: 'performanceBasedPayPerformanceMetrics.unitPrice',
        sortable: false,
      },
      {
        key: `${item.value}.toBePaid`,
        header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.TO_BE_PAID" />,
        accessor: (row: PerformanceBasedPayModel) =>
          row.performanceBasedPayPerformanceMetrics.find((p) => p.performanceMetricId === item.id)
            ?.toBePaid,
        field: 'performanceBasedPayPerformanceMetrics.toBePaid',
        sortable: false,
        noWrap: true,
      },
    ];

    if (withoutUnitPrice) {
      subCells.splice(1, 1);
    }

    return [
      {
        isParentCell: true,
        key: item.key,
        header: item.value,
        field: item.key,
        sortable: false,
        subCells,
      },
    ];
  };

  const notReadableMeterMetricColumn: MetricSubColumns[] | undefined = metricData?.data
    .map((item: PerformanceMetricModel) => {
      if (item.key === 'NOT_READABLE_METER') {
        return generateSubCells(item);
      }
      return [];
    })
    .flat();

  const reportMetricColumn: MetricSubColumns[] | undefined = metricData?.data
    .map((item: PerformanceMetricModel) => {
      if (item.key === 'REPORTS') {
        return generateSubCells(item);
      }
      return [];
    })
    .flat();

  const otherAutoCountedMetricColumns: MetricSubColumns[] | undefined = metricData?.data
    .map((item: PerformanceMetricModel) => {
      if (item.key !== 'NOT_READABLE_METER' && item.key !== 'REPORTS' && item.isAutoCounted) {
        return generateSubCells(item);
      }
      return [];
    })
    .flat()
    .slice()
    .sort((a, b) => a.header.toString().localeCompare(b.header.toString()));

  const otherNotAutoCountedMetricColumns: MetricSubColumns[] | undefined = metricData?.data
    .map((item: PerformanceMetricModel) => {
      if (item.key !== 'NOT_READABLE_METER' && item.key !== 'REPORTS' && !item.isAutoCounted) {
        return generateSubCells(item);
      }
      return [];
    })
    .flat()
    .slice()
    .sort((a, b) => a.header.toString().localeCompare(b.header.toString()));

  const fileUploadHandler = () => importButtonRef.current?.click();

  const onDownloadClick = useCallback(
    async (items: PerformanceBasedPayModel[]) => {
      const pbpIds = items.reduce<number[]>((acc: number[], item: PerformanceBasedPayModel) => {
        if (item.id) {
          acc.push(item.id);
        }
        return acc;
      }, []);

      await downloadPerformanceBasedPay({ ids: pbpIds });
    },
    [downloadPerformanceBasedPay]
  );

  const headerActions = useMemo<PageHeaderAction[]>(
    () => [
      {
        name: 'import',
        label: 'COMMON.IMPORT',
        icon: <Import fill="currentColor" />,
        onClick: fileUploadHandler,
        isLoading: importIsLoading,
        visible,
      },
      {
        name: 'export',
        label: 'COMMON.EXPORT',
        icon: <Export fill="currentColor" />,
        onClick: () => refetch(),
        isLoading: isInitialLoading || isRefetching,
        disabledIf: !exportParams?.filter,
      },
      {
        name: 'export-pay-data',
        label: 'KOZOS_TELJESITMENYBEREK.EXPORT_PAY_DATA',
        icon: <Export fill="currentColor" />,
        onClick: () => refetchPayData(),
        isLoading: isPayDataInitLoading || isPayDataRefetching,
        disabledIf: !exportParams?.filter,
        visible,
      },
      {
        name: 'download',
        label: 'KOZOS_TELJESITMENYBEREK.DOWNLOAD_DATA_SHEET',
        icon: <Export fill="currentColor" />,
        onClick: (items: PerformanceBasedPayModel[]) => onDownloadClick(items),
        isBulkAction: true,
        isLoading: isDownloadLoading,
        disabledIf: !exportParams?.filter,
        visible: visibleAdminPerformanceCoord,
      },
      {
        name: 'send-to-approve',
        label: 'KOZOS_TELJESITMENYBEREK.SEND_TO_APPROVE',
        icon: <ForwardToInboxIcon fontSize="small" />,
        onClick: (items: PerformanceBasedPayModel[]) => openSendToApproveDialog(items),
        disabledIf: (items: PerformanceBasedPayModel[]) =>
          items.some((item) => item.status !== '1' && item.status !== '3'),
        isBulkAction: true,
        visible,
      },
      {
        name: 'approve',
        label: 'KOZOS_TELJESITMENYBEREK.APPROVE',
        icon: <ThumbUpIcon fontSize="small" />,
        onClick: (items: PerformanceBasedPayModel[]) => openApproveDialog(items),
        disabledIf: (items: PerformanceBasedPayModel[]) =>
          items.some((item) => item.status !== '2'),
        isBulkAction: true,
      },
      {
        name: 'reject',
        label: 'KOZOS_TELJESITMENYBEREK.REJECT',
        icon: <ThumbDownIcon fontSize="small" />,
        onClick: (items: PerformanceBasedPayModel[]) => openRejectDialog(items),
        disabledIf: (items: PerformanceBasedPayModel[]) =>
          items.some((item) => item.status !== '2'),
        isBulkAction: true,
      },
      {
        name: 'reopen',
        label: 'KOZOS_TELJESITMENYBEREK.REOPEN',
        icon: <UndoIcon fontSize="small" />,
        onClick: (items: PerformanceBasedPayModel[]) => openReopenDialog(items),
        disabledIf: (items: PerformanceBasedPayModel[]) =>
          items.some((item) => item.status !== '4'),
        isBulkAction: true,
        visible,
      },
      {
        name: 'delete',
        label: 'COMMON.DELETE',
        icon: <DeleteIcon fontSize="small" />,
        onClick: (items: PerformanceBasedPayModel[]) => openDeleteDialog(items),
        disabledIf: (items: PerformanceBasedPayModel[]) =>
          items.some((item) => item.status !== '1' && item.status !== '3'),
        isBulkAction: true,
        visible,
      },
    ],
    [
      refetch,
      refetchPayData,
      openDeleteDialog,
      openSendToApproveDialog,
      openApproveDialog,
      openReopenDialog,
      openRejectDialog,
      onDownloadClick,
      isInitialLoading,
      isRefetching,
      isPayDataInitLoading,
      isPayDataRefetching,
      isDownloadLoading,
      exportParams?.filter,
      visible,
      visibleAdminPerformanceCoord,
      importIsLoading,
    ]
  );

  const actions = [
    {
      name: 'edit',
      label: 'COMMON.EDIT',
      icon: <EditIcon fontSize="small" />,
      onClick: (item: PerformanceBasedPayModel) => openEditor(item),
      disabledIf: (item: PerformanceBasedPayModel) => !(item.status === '1' || item.status === '3'),
      visible,
    },
    {
      name: 'download',
      label: 'KOZOS_TELJESITMENYBEREK.DOWNLOAD_DATA_SHEET',
      icon: <Export fill="currentColor" />,
      onClick: (item: PerformanceBasedPayModel) => onDownloadClick([item]),
      visible: visibleAdminPerformanceCoord,
    },
    {
      name: 'run',
      label: 'KOZOS_TELJESITMENYBEREK.RUN',
      icon: <AutorenewIcon fontSize="small" />,
      onClick: (item: PerformanceBasedPayModel) => openRunDialog([item]),
      disabledIf: (item: PerformanceBasedPayModel) => !(item.status === '1' || item.status === '3'),
      visible,
    },
    {
      name: 'send-to-approve',
      label: 'KOZOS_TELJESITMENYBEREK.SEND_TO_APPROVE',
      icon: <ForwardToInboxIcon fontSize="small" />,
      onClick: (item: PerformanceBasedPayModel) => openSendToApproveDialog([item]),
      disabledIf: (item: PerformanceBasedPayModel) => !(item.status === '1' || item.status === '3'),
      visible,
    },
    {
      name: 'approve',
      label: 'KOZOS_TELJESITMENYBEREK.APPROVE',
      icon: <ThumbUpIcon fontSize="small" />,
      onClick: (item: PerformanceBasedPayModel) => openApproveDialog([item]),
      disabledIf: (item: PerformanceBasedPayModel) => item.status !== '2',
      visible: visibleAdminPerformanceCoord,
    },
    {
      name: 'reject',
      label: 'KOZOS_TELJESITMENYBEREK.REJECT',
      icon: <ThumbDownIcon fontSize="small" />,
      onClick: (item: PerformanceBasedPayModel) => openRejectDialog([item]),
      disabledIf: (item: PerformanceBasedPayModel) => item.status !== '2',
      visible: visibleAdminPerformanceCoord,
    },
    {
      name: 'reopen',
      label: 'KOZOS_TELJESITMENYBEREK.REOPEN',
      icon: <UndoIcon fontSize="small" />,
      onClick: (item: PerformanceBasedPayModel) => openReopenDialog([item]),
      disabledIf: (item: PerformanceBasedPayModel) => item.status !== '4',
      visible,
    },
    {
      name: 'delete',
      label: 'COMMON.DELETE',
      icon: <DeleteIcon fontSize="small" />,
      onClick: (item: PerformanceBasedPayModel) => openDeleteDialog([item]),
      disabledIf: (item: PerformanceBasedPayModel) => !(item.status === '1' || item.status === '3'),
      visible,
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_TELJESITMENYBEREK.MONTH',
      component: <LeoDatePicker name="date" isYearAndMonthOnly />,
      name: 'date',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
    },
    {
      headerTitle: 'KOZOS_TELJESITMENYBEREK.STATUS',
      component: (
        <LeoSelectFieldMulti
          name="status"
          data={tbsList}
          accessor={tbsAccessor}
          label="KOZOS_TELJESITMENYBEREK.STATUS"
          readOnly
        />
      ),
      name: 'status',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'KOZOS_H_S_JELZESEK.SAP_ID',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="KOZOS_H_S_JELZESEK.SAP_ID"
          readOnly
        />
      ),
      name: 'worker',
      panelFieldWidth: 12,
    },
  ];

  const validationSchema = Yup.object().shape({
    date: Yup.date().nullable(),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_TELJESITMENYBEREK),
    validationSchema,
    isFetching,
  });

  const panels = [filterConfig];

  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogState();

  const { activePanel, closePanel, setPanel } = usePanelState();

  const columns = [
    {
      key: 'date',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.MONTH" />,
      accessor: (item: PerformanceBasedPayModel) => formatDate(item.date, YEAR_MONTH_DATE_FORMAT),
      field: 'date',
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.WORKER_ID" />,
      tooltip: (item: PerformanceBasedPayModel) => item.worker.name,
      accessor: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'worker.internalId',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.INTERNAL_ID" />,
      accessor: 'worker.internalId',
      sortable: true,
    },
    {
      key: 'status',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.STATUS" />,
      accessor: (item: PerformanceBasedPayModel) =>
        item.status && getValueSetValues(valueSetData, 'TBS', item.status),
      field: 'status',
      sortable: true,
    },
    {
      key: 'createdAt',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.CREATED_AT" />,
      accessor: (item: PerformanceBasedPayModel) => formatDate(item.createdAt),
      field: 'createdAt',
      sortable: true,
    },
    {
      key: 'modifiedAt',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.MODIFIED_AT" />,
      accessor: (item: PerformanceBasedPayModel) => formatDate(item.modifiedAt),
      field: 'modifiedAt',
      sortable: true,
    },
    {
      key: 'approvedAt',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.APPROVED_AT" />,
      accessor: (item: PerformanceBasedPayModel) => formatDate(item.approvedAt),
      field: 'approvedAt',
      sortable: true,
    },
    {
      key: 'approvedBy.name',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.APPROVER" />,
      accessor: 'approvedBy.name',
      sortable: true,
    },
    ...(notReadableMeterMetricColumn || []),
    ...(reportMetricColumn || []),
    ...(otherAutoCountedMetricColumns || []),
    ...(otherNotAutoCountedMetricColumns || []),
    {
      key: 'otherPerformanceBasedPay',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.OTHER_PBP" />,
      accessor: 'otherPerformanceBasedPay',
      sortable: true,
    },
    {
      key: 'hoursSpentWithOtherActivities',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.HOURS_SPENT_WITH_OTHER" />,
      accessor: 'hoursSpentWithOtherActivities',
      sortable: true,
    },
    {
      key: 'toBePaidSum',
      header: <Trans i18nKey="KOZOS_TELJESITMENYBEREK.TO_BE_PAID_SUM" />,
      accessor: 'toBePaidSum',
      sortable: true,
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_TELJESITMENYBEREK.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <FileUpload
        importTask={importTask}
        importButtonRef={importButtonRef}
        accept={{ 'application/xlsx': ['.xlsx'] }}
      />
      {!isValueSetLoading && !isMetricLoading && (
        <Box sx={styles.table}>
          <Table
            filterDetailsData={{ tbsAccessor }}
            panels={panels}
            setPanel={setPanel}
            activePanel={activePanel}
            list={performanceBasedPayData?.data || []}
            total={performanceBasedPayData?.meta.total}
            timestamp={dataUpdatedAt}
            loading={isLoading}
            params={params}
            onSort={onSort}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            actions={actions}
            pageHeaderActions={headerActions}
            columns={columns}
            onAdd={
              getFunctionRole(FunctionRoleName.PERFORMANCEBASEDPAY_PAGE_ACTIONS, roles)
                ? openCreateDialog
                : undefined
            }
            addLabel={<Trans i18nKey="NEW_PERFORMANCE_PAY_DIALOG.TITLE" />}
            onTableColumnVisibilityChange={onTableColumnVisibilityChange}
            onLoadQuery={onLoadQuery}
            resetSelectedOnAction
          />
        </Box>
      )}
      <NewPerformancePayDialog open={isCreateOpen} onClose={closeCreateDialog} />
      <NewPerformanceEditorDialog
        open={isEditorOpen}
        selected={selectedEdit}
        onClose={closeEditor}
      />
      <StatusChangeDialog
        action={StatusChange.APPROVE}
        open={isApproveDialogOpen}
        items={selectedApprove}
        onClose={closeApproveDialog}
      />
      <StatusChangeDialog
        action={StatusChange.DELETE}
        open={isDeleteDialogOpen}
        items={selectedDelete}
        onClose={closeDeleteDialog}
      />
      <StatusChangeDialog
        action={StatusChange.REJECT}
        open={isRejectDialogOpen}
        items={selectedReject}
        onClose={closeRejectDialog}
      />
      <StatusChangeDialog
        action={StatusChange.REOPEN}
        open={isReopenDialogOpen}
        items={selectedReopen}
        onClose={closeReopenDialog}
      />
      <StatusChangeDialog
        action={StatusChange.SEND_TO_APPROVE}
        open={isSendToApproveDialogOpen}
        items={selectedSendToApprove}
        onClose={closeSendToApproveDialog}
      />
      <StatusChangeDialog
        action={StatusChange.RUN}
        open={isRunDialogOpen}
        items={selectedRun}
        onClose={closeRunDialog}
      />
    </Page>
  );
};

export default PerformanceBasedPayPage;

import React, { FC } from 'react';
import { FieldProps } from 'formik';

import FormHelperText from '@mui/material/FormHelperText';

import { SxProp } from 'models';
import { getErrorMessage, isTouched } from 'utils/form';
import { mergeSx } from 'utils/styles';

interface ErrorMessageProps extends Partial<FieldProps>, SxProp {
  /**
   * Determines if the error message is visible or not.
   */
  show?: boolean;
  /**
   * The variant to use.
   */
  variant?: 'standard' | 'outlined' | 'filled';
  /**
   * The class(es) (in case if you prefer classes over sx).
   */
  className?: string;
  /**
   * Fallback message, in case the error messages from the formik context is not available.
   */
  message?: string | null;
  /**
   * True if call from SelectField. Touched works properly
   */
  selectFieldHelper?: boolean;
}

const styles = {
  root: {
    marginTop: -0.5,
  },
};

export const ErrorMessage: FC<ErrorMessageProps> = ({
  variant,
  form,
  field,
  sx,
  className,
  message,
  selectFieldHelper = false,
  show = false,
}) => {
  if (!form || !field) {
    return null;
  }

  const touched = selectFieldHelper ? true : isTouched(form, field);
  const errorMessage = getErrorMessage(form, field);
  const empty = <span data-testid="error-message-empty-state">&nbsp;</span>;
  const helperMessage = touched ? errorMessage || message || empty : empty;

  return show ? (
    <FormHelperText
      variant={variant}
      error
      className={className}
      sx={mergeSx(styles.root, sx)}
      role="error"
    >
      {helperMessage}
    </FormHelperText>
  ) : null;
};

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type CommunicationLogQueryContexts = QueryContextFromKeys<typeof communicationLogKeys>;

export const communicationLogKeys = {
  all: [{ scope: 'communicationLog' }] as const,
  lists: () => [{ ...communicationLogKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...communicationLogKeys.lists()[0], params }] as const,
  export: (params?: QueryParamModel | null) => [{ ...communicationLogKeys.lists()[0], params }] as const,
};

const fetchCommunicationLogList = async ({
  queryKey: [{ params }],
}: CommunicationLogQueryContexts['list']) => statisticsService.getCommunicationLogList(params);

const exportCommunicationLog = async ({
  queryKey: [{ params }],
}: CommunicationLogQueryContexts['list']) => statisticsService.exportCommunicationLog(params);

const exportCommunicationLogAsyncExcel = async ({ queryKey: [{ params }] }: CommunicationLogQueryContexts['export']) =>
  statisticsService.exportCommunicationLogAsyncExcel(params);

export const useCommunicationLogList = (params?: QueryParamModel | null) => {
  return useQuery(communicationLogKeys.list(params), fetchCommunicationLogList, {
    enabled: !!params?.filter,
  });
};

export const useExportCommunicationLog = (params?: QueryParamModel | null) => {
  return useQuery(communicationLogKeys.list(params), exportCommunicationLog, {
    enabled: false,
  });
};

export const useCommunicationLogAsyncExcelExport = (params?: QueryParamModel | null) => {
  return useQuery(communicationLogKeys.export(params), exportCommunicationLogAsyncExcel, {
    enabled: false,
    staleTime: Infinity
  });
};
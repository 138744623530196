import React, { useCallback, useMemo, useRef, useState } from 'react';

import { t } from 'i18next';
import { clone, get, pick } from 'lodash';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  ClickAwayListener,
  TextFieldProps,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { StyleProp, TextField } from 'components';
import { FieldInputProps, FieldProps, FormikProps, FormikState } from 'formik';
import { IInputComponentParams } from 'components/input-fields/helpers/interfaces';
import { Empty, isBlank } from './common';

const styles = {
  chips: {
    '& > :not(:last-child)': { mr: 1 },
    transition: '2s',
  },
  chip: {
    marginBottom: 2,
    transition: '2s',
  },
};

type Tobj = {
  [key: string]: string[];
};

const LeoAutoCompleteMulti = ({
  form,
  field,
  meta,
  options,
  accessor,
  maxLength,
  disabled = false,
  freeSolo = false,
  blankFilterOption = false,
  ...props
}: IInputComponentParams & TextFieldProps & FieldProps) => {
  const [autoFocus, setAutoFocus] = useState(false);
  const inputRef = useRef<React.RefObject<HTMLInputElement>>(null);

  const getData = useCallback(() => {
    const newData = clone(options);
    if (blankFilterOption && newData) {
      newData.unshift(Empty.VALUE);
    }

    return newData || [];
  }, [blankFilterOption, options]);

  const renderInput = useCallback(
    (params: FieldProps<any, any> | AutocompleteRenderInputParams) => {
      const fieldProps = pick(field, 'name', 'value') as FieldInputProps<any>;

      return (
        <TextField
          {...props}
          {...params}
          field={fieldProps}
          form={form}
          meta={meta}
          placeholder={t('COMMON.CHOOSE')}
          inputRef={inputRef}
          selectFieldHelper={true}
          showError
          autoFocus={autoFocus}
          extraInputProps={{ maxLength } as StyleProp}
        />
      );
    },
    [autoFocus, field, form, meta, props, maxLength]
  );

  const onClickAway = () => {
    setAutoFocus(false);
  };

  const onChange = (
    _event: React.SyntheticEvent<Element>,
    items: string[],
    _reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<string> | undefined
  ) => {
    const hasBlank = items.find((item) => isBlank(item));

    form.setFieldValue(
      `${field.name}`,
      items.filter((item) => {
        if (hasBlank && details) {
          return isBlank(details.option) ? isBlank(item) : !isBlank(item);
        }
        return item.toString().trim();
      })
    );
  };

  const getOptionLabel = useCallback(
    (option: string) => {
      const newAccessor = clone(accessor);
      if (blankFilterOption && newAccessor) {
        Object.assign(newAccessor, { blank: t('COMMON.BLANK') });
      }
      return newAccessor ? get(newAccessor, option) || option || '' : option?.toString().trim();
    },
    [accessor, blankFilterOption]
  );

  const onDelete = (title: string, formParam: FormikProps<any>, fieldName: string) => () => {
    formParam.setFormikState((prevState: FormikState<Tobj>) => {
      const newObj = prevState.values[fieldName].filter((item: string) => item !== title);
      formParam.setFieldValue(`${fieldName}`, newObj);

      return { ...prevState, ...newObj };
    });
  };

  const getChipContent = (formArg: FormikProps<any>, fieldNameArg: string) => (
    <Box sx={styles.chips}>
      {getValue()?.map((valueItem) => (
        <Chip
          sx={styles.chip}
          color="primary"
          key={valueItem}
          label={<Typography variant="subtitle2">{getOptionLabel(valueItem)}</Typography>}
          onDelete={onDelete(valueItem, formArg, fieldNameArg)}
        />
      ))}
    </Box>
  );

  const commonProps = useMemo(
    () => ({
      renderTags: () => null,
      options: getData(),
      getOptionLabel,
      renderInput,
      disabled,
      freeSolo,
    }),
    [getData, renderInput, getOptionLabel, disabled, freeSolo]
  );

  const getValue: () => string[] = () => form.values[`${field.name}`] || [];

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <Autocomplete
          multiple
          value={getValue()}
          onChange={onChange}
          popupIcon={<KeyboardArrowDownIcon />}
          forcePopupIcon
          {...commonProps}
        />
      </ClickAwayListener>
      {getChipContent(form, field.name)}
    </>
  );
};

export default LeoAutoCompleteMulti;

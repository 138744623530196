import i18n from 'i18n';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { NotificationType, QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService, timeLogService } from 'services';
import { showNotification } from 'components';
import { emptyResponse } from 'features/helpers';
import { isAxiosError } from 'utils/errors';
import { parse } from 'utils/base64';

type TimeLogQueryContexts = QueryContextFromKeys<typeof timeLogKeys>;

export const timeLogKeys = {
  all: [{ scope: 'time-log' }] as const,
  lists: () => [{ ...timeLogKeys.all[0], entity: 'list' }] as const,
  timeLogList: (params?: QueryParamModel | null) =>
    [{ ...timeLogKeys.lists()[0], params }] as const,
};

const fetchTimeLogList = async ({
  queryKey: [{ params }],
}: TimeLogQueryContexts['timeLogList']) => {
  const { logDate: requiredParam } = parse(params?.filter);

  return requiredParam ? statisticsService.getTimeLogList(params) : emptyResponse;
};

const exportTimeLogList = async ({ queryKey: [{ params }] }: TimeLogQueryContexts['timeLogList']) =>
  statisticsService.exportTimeLogList(params);

export const useTimeLogList = (params?: QueryParamModel | null) => {
  return useQuery(timeLogKeys.timeLogList(params), fetchTimeLogList, {
    enabled: !!params?.filter,
  });
};

export const useExportTimeLogList = (params?: QueryParamModel | null) => {
  return useQuery(timeLogKeys.timeLogList(params), exportTimeLogList, {
    enabled: false,
  });
};

export const useImportTimeLog = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => timeLogService.upload(data.file, data.date), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: timeLogKeys.lists() });
    },
    onError: (e) => {
      if (isAxiosError(e) && (e.response?.status === 400 || e.response?.status === 404)) {
        showNotification({
          content: i18n.t('ERRORS.TIME_LOG_INVALID_FILE'),
          type: NotificationType.ERROR,
        });
      }
    },
  });
};

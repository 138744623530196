import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type EmergencyCodeQueryContexts = QueryContextFromKeys<typeof emergencyCodeKeys>;

export const emergencyCodeKeys = {
  all: [{ scope: 'emergencyCode' }] as const,
  lists: () => [{ ...emergencyCodeKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...emergencyCodeKeys.lists()[0], params }] as const,
};

const fetchEmergencyCodeList = async ({
  queryKey: [{ params }],
}: EmergencyCodeQueryContexts['list']) => statisticsService.getEmergencyCodeStatistics(params);

const exportEmergencyCode = async ({
  queryKey: [{ params }],
}: EmergencyCodeQueryContexts['list']) => statisticsService.exportEmergencyCodeStatistics(params);

export const useEmergencyCodeList = (params?: QueryParamModel | null) => {
  return useQuery(emergencyCodeKeys.list(params), fetchEmergencyCodeList, {
    enabled: !!params?.filter,
  });
};

export const useExportEmergencyCode = (params?: QueryParamModel | null) => {
  return useQuery(emergencyCodeKeys.list(params), exportEmergencyCode, { enabled: false });
};

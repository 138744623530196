import omit from 'lodash/omit';

import {
  BulkSmOrderAvailabilityModel,
  ListResponseModel,
  QueryParamModel,
  SmOrderAvailabilityPatchModel,
  SmOrderAvailabilityModel,
  SmOrderAvailabilityPostModel,
} from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';
import { parse, stringify } from 'utils/base64';

const transformAvailabilityFilter = (filter?: string) => {
  let parsedFilter = parse(filter);
  const timeslot = [];
  if (parsedFilter.morning) {
    timeslot.push('1');
    parsedFilter = omit(parsedFilter, ['morning']);
  }
  if (parsedFilter.afternoon) {
    timeslot.push('2');
    parsedFilter = omit(parsedFilter, ['afternoon']);
  }
  if (parsedFilter.evening) {
    timeslot.push('3');
    parsedFilter = omit(parsedFilter, ['evening']);
  }
  return timeslot.length ? stringify({ ...parsedFilter, timeslot }) : stringify(parsedFilter);
};

class UcseAvailabilityService extends EntityService {
  constructor() {
    super('ucse-availability');
  }

  async getUcseAvailabilityList(params?: QueryParamModel | null) {
    return request<ListResponseModel<SmOrderAvailabilityModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params: { ...params, filter: transformAvailabilityFilter(params?.filter) },
    });
  }

  async createUcseAvailability(data: Partial<SmOrderAvailabilityPostModel>) {
    return request<SmOrderAvailabilityModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  async editUcseAvailability(id: ID, data: Partial<SmOrderAvailabilityPatchModel>) {
    return request<SmOrderAvailabilityModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/${id}`,
      data,
    });
  }

  async bulkUcseAvailability(data: Partial<BulkSmOrderAvailabilityModel>) {
    return request<SmOrderAvailabilityModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}/bulk`,
      data,
    });
  }

  async exportUcseAvailability(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params: { ...params, filter: transformAvailabilityFilter(params?.filter) },
    });

    exportFile(response);
    return response;
  }
}

export const ucseAvailabilityService = new UcseAvailabilityService();

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AnyAction, Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { userSettingsService } from 'services';
import { QueryContextFromKeys, QueryParamModel, UserModel } from 'models';
import { UserSettingsModel } from 'models/userSettings.model';
import { RouteNames } from 'config/routeNames';
import { selectFilterParams } from 'store/common';
import { selectProfile, setProfileSettings } from 'store/profile';
import { REACT_QUERY_OPTIONS } from 'config/reactQueryConfig';

type userSettingsQueryContexts = QueryContextFromKeys<typeof userSettingsKeys>;

export const userSettingsKeys = {
  all: [{ scope: 'user-settings' }] as const,
  lists: () => [{ ...userSettingsKeys.all[0], entity: 'list' }] as const,
  userSettingsList: (params?: QueryParamModel | null) =>
    [{ ...userSettingsKeys.lists()[0], params }] as const,
  byId: (id: ID) => [{ ...userSettingsKeys.lists()[0], id }] as const,
  byScope: (scope: RouteNames) => [{ ...userSettingsKeys.lists()[0], scope }] as const,
};

const fetchUserSettingsList = async ({
  queryKey: [{ params }],
}: userSettingsQueryContexts['userSettingsList']) => userSettingsService.getUserSettingsList();

export const useUserSettingsList = (params?: QueryParamModel | null) => {
  return useQuery(userSettingsKeys.userSettingsList(params), fetchUserSettingsList);
};

const fetchUserSettingsById = async ({ queryKey: [{ id }] }: userSettingsQueryContexts['byId']) =>
  userSettingsService.getUserSettingsById(+id);

export const useUserSettingsById = (id: ID) =>
  useQuery(userSettingsKeys.byId(id), fetchUserSettingsById);

const fetchUserSettingsByScope = async ({
  queryKey: [{ scope }],
}: userSettingsQueryContexts['byScope']) => userSettingsService.getUserSettingsByScope(scope);

export const useUserSettingsByScope = (scope: RouteNames) =>
  useQuery(userSettingsKeys.byScope(scope), fetchUserSettingsByScope);

export const useDeleteUserSettings = () => {
  const filterParams = useSelector(selectFilterParams);
  const userProfile = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((id: ID) => userSettingsService.deleteUserSettings(id), {
    meta: {
      [REACT_QUERY_OPTIONS.SKIP_DEFAULT_SUCCESS_CALLBACK]: true,
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userSettingsKeys.lists() });
      deleteLocalSettings(userProfile, filterParams, dispatch);
    },
  });
};

export const useEditUserSettings = () => {
  const filterParams = useSelector(selectFilterParams);
  const userProfile = useSelector(selectProfile);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<UserSettingsModel> }) =>
      userSettingsService.editUserSettings(id, data),
    {
      meta: {
        [REACT_QUERY_OPTIONS.SKIP_DEFAULT_SUCCESS_CALLBACK]: true,
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: userSettingsKeys.lists() });
        updateLocalSettings(userProfile, filterParams, dispatch, data.value);
      },
    }
  );
};

export const useCreateUserSettings = () => {
  const queryClient = useQueryClient();
  const filterParams = useSelector(selectFilterParams);
  const userProfile = useSelector(selectProfile);
  const dispatch = useDispatch();

  return useMutation(
    (data: Partial<UserSettingsModel>) => userSettingsService.createUserSettings(data),
    {
      meta: {
        [REACT_QUERY_OPTIONS.SKIP_DEFAULT_SUCCESS_CALLBACK]: true,
      },
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries({ queryKey: userSettingsKeys.lists() });
        updateLocalSettings(userProfile, filterParams, dispatch, data.value);
      },
    }
  );
};

const deleteLocalSettings = (
  userProfile: UserModel | null,
  filterParams: QueryParamModel,
  dispatch: Dispatch<AnyAction>
) => {
  const settings = { ...userProfile?.settings };
  delete settings[`${filterParams.from}`];
  dispatch(setProfileSettings(settings));
};

const updateLocalSettings = (
  userProfile: UserModel | null,
  filterParams: QueryParamModel,
  dispatch: Dispatch<AnyAction>,
  pageSize: number
) => {
  const newPageSize = { [`${filterParams.from}`]: { pageSize } };
  const newSettings = { ...userProfile?.settings, ...newPageSize };
  dispatch(setProfileSettings(newSettings));
};

import { parse, stringify } from 'utils/base64';

export const transformColumns = (cols?: string | null) => {
  if (!cols) {
    return null;
  }

  const transformedColumns: Record<string, boolean> = {};

  parse(cols).forEach((item: { key: string | number; hidden: boolean }) => {
    transformedColumns[item.key] = !item.hidden;
  });

  return stringify(transformedColumns);
};

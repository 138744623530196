import { ListResponseModel, QueryParamModel, ReadingCenterModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

class ReadingCenterService extends EntityService {
  constructor() {
    super('reading-center');
  }

  async getReadingCenterList(params?: QueryParamModel | null) {
    return request<ListResponseModel<ReadingCenterModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async createReadingCenter(data: Partial<ReadingCenterModel>) {
    return request<ReadingCenterModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data: { leaderName: data.leaderName, readingCenterId: data.readingCenterId },
    });
  }

  async editReadingCenter(id: ID, data: Partial<ReadingCenterModel>) {
    return request<ReadingCenterModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/${id}`,
      data: { leaderName: data.leaderName, readingCenterId: data.readingCenterId },
    });
  }

  async deleteReadingCenter(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/${id}`,
    });
  }

  async exportReadingCenters(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });

    exportFile(response);
    return response;
  }
}

export const readingCenterService = new ReadingCenterService();

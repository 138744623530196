import { EffectCallback, useLayoutEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export function useWindowResize(effect: EffectCallback, wait = 150) {
  const [initialized, setInitialized] = useState(false);
  const handleResize = throttle(effect, wait);

  useLayoutEffect(() => {
    if (!initialized) {
      effect();
      setInitialized(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [effect, handleResize, initialized]);
}

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';

import { ColumnProps, Page, Table, useDialogState, useTableQueryParams } from 'components';
import { ReadingTimeModel } from 'models';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { selectUserRoles } from 'store/profile';
import { useReadingTime } from 'features/readingTime';

import ReadingTimeEditorDialog from './ReadingTimeEditorDialog';

const ReadingTimePage: FC = () => {
  const { onPageChange, onRowsPerPageChange, onLoadQuery, params, onTableColumnVisibilityChange } =
    useTableQueryParams();

  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.READING_TIME_PAGE_ACTIONS, roles);

  const {
    data: readingTimeData,
    isLoading: readingTimeIsLoading,
    dataUpdatedAt,
  } = useReadingTime();

  const readingtimelist = readingTimeData ? [readingTimeData] : [];

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<ReadingTimeModel>();

  const readingTimeColumns: ColumnProps<ReadingTimeModel>[] = [
    {
      key: 'repeatedReading',
      header: <Trans i18nKey="READINGTIME.REPEATED_READING" />,
      accessor: 'repeatedReading',
      sortable: false,
    },
    {
      key: 'controlReading',
      header: <Trans i18nKey="READINGTIME.CONTROL_READING" />,
      accessor: 'controlReading',
      sortable: false,
    },
    {
      key: 'dealerChange',
      header: <Trans i18nKey="READINGTIME.DEALER_READING" />,
      accessor: 'dealerChange',
      sortable: false,
    },
  ];

  return (
    <Page title={<Trans i18nKey="READINGTIME.TITLE" />} inScroll>
      <Box>
        <Table
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={readingtimelist || []}
          timestamp={dataUpdatedAt}
          enableTableView={true}
          loading={!readingTimeIsLoading}
          params={params}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={readingTimeColumns}
          enableCheckbox={false}
          actions={
            visible
              ? [
                  {
                    name: 'edit',
                    label: 'COMMON.EDIT',
                    icon: <EditIcon fontSize="small" />,
                    onClick: (readingTime) => openEditor(readingTime),
                  },
                ]
              : []
          }
          onLoadQuery={onLoadQuery}
        />
      </Box>
      <ReadingTimeEditorDialog open={isEditorOpen} selected={selectedEdit} onClose={closeEditor} />
    </Page>
  );
};
export default ReadingTimePage;

import React, { ReactNode, useMemo } from 'react';
import { Trans } from 'react-i18next';
import isFunction from 'lodash/isFunction';

import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';

export interface DeleteDialogProps<Data> extends ConfirmDialogProps {
  /**
   * The selected entity.
   */
  selected?: Data | null;
  /**
   * Title key of the selected entity.
   */
  deleteTitleAccessor: keyof Data | ((item?: Data | null) => string);
  /**
   * Custom delete question for the dialog.
   */
  deleteQuestion?: ReactNode;
}

export function DeleteDialog<Data>({
  title,
  onClose,
  onSubmit,
  selected,
  deleteQuestion,
  deleteTitleAccessor,
  ...props
}: DeleteDialogProps<Data>) {
  const onDelete = async () => {
    await onSubmit();

    onClose();
  };

  const deleteTitle = useMemo(() => {
    if (!deleteTitleAccessor) {
      return '';
    }

    if (isFunction(deleteTitleAccessor)) {
      return deleteTitleAccessor(selected);
    }

    if (deleteTitleAccessor && selected) {
      return selected[deleteTitleAccessor];
    }

    return '';
  }, [deleteTitleAccessor, selected]);

  return (
    <ConfirmDialog
      title={title || <Trans i18nKey="COMMON.DELETE" />}
      onClose={onClose}
      onSubmit={onDelete}
      {...props}
    >
      {deleteQuestion || <Trans i18nKey="COMMON.DELETE_QUESTION" values={{ item: deleteTitle }} />}
    </ConfirmDialog>
  );
}

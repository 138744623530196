import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Image, MenuCard, Page } from 'components';
import logo from 'assets/images/react-icon.png';
import { menuConfig } from 'config/menuConfig';
import Buttons from './Buttons';
import Tooltips from './Tooltips';
import Notifications from './Notifications';
import Modals from './Modals';
import Spinners from './Spinners';
import FormFields from './FormFields';

const ComponentsPage: FC = () => {
  const items = menuConfig[0].children?.map((item) => item);

  return (
    <Page title={<Trans i18nKey="COMPONENTS.TITLE" />}>
      {/* demo  wrapper start */}
      <div>
        <MenuCard menuConfig={menuConfig[0]} />
        {items?.map((item) => (
          <MenuCard key={item.displayName} menuConfig={item} />
        ))}
      </div>
      {/* demo  wrapper end */}
      <Buttons />
      <Tooltips />
      <Notifications />
      <Modals />
      <Spinners />
      <FormFields />

      <Box mb={5}>
        <Typography variant="h1">H1/Montserrat/SemiBold/96px</Typography>
        <Typography variant="h2">H2/Montserrat/SemiBold/60px</Typography>
        <Typography variant="h3">H3/Montserrat/SemiBold/48px</Typography>
        <Typography variant="h4">H4/Montserrat/SemiBold/34px</Typography>
        <Typography variant="h5">H5/Montserrat/SemiBold/24px</Typography>
        <Typography variant="h6">H6/Montserrat/Medium/20px</Typography>
        <Typography variant="subtitle1">Subtitle 1/Montserrat/Medium/16px</Typography>
        <Typography variant="subtitle2">Subtitle 2/Montserrat/Medium/14px</Typography>
        <Typography variant="body1">Body 1/Montserrat/Regular/16px</Typography>
        <Typography variant="body2">Body 2/Montserrat/Regular/14px</Typography>
        <Typography variant="body2">Body 3/Montserrat/Regular/12px</Typography>
        <Typography variant="button" display="block">
          BUTTON/Montserrat/medium/14px
        </Typography>
        <Typography variant="caption" display="block">
          Caption/Montserrat/SemiBold/12px
        </Typography>
        <Typography variant="overline" display="block">
          OVERLINE/Montserrat/Regular/10px
        </Typography>
        <Typography variant="cellHeaderLarge" display="block">
          Cellheader Large - Roboto Condensed Medium 14/16
        </Typography>
        <Typography variant="cellHeaderSmall" display="block">
          Cellheader Small - Roboto Condensed Medium 11/16
        </Typography>
        <Typography variant="cellPrimary" display="block">
          CellPrimary - Roboto Condensed Medium 12/16
        </Typography>
        <Typography variant="cellName" display="block">
          CellName - Roboto Condensed Regular 14/16
        </Typography>
        <Typography variant="cellLabel" display="block">
          CellLabel - Roboto Condensed Regular 14/20
        </Typography>
        <Typography variant="cellButton" display="block">
          CellButton - Roboto Condensed Bold 14/16
        </Typography>
      </Box>

      <Box mb={5}>
        <Box mb={2}>
          <Typography variant="h6">Image</Typography>
        </Box>
        <Box mb={2}>
          Without valid <code>src</code>
          <div style={{ width: '25%', display: 'inline-block' }}>
            <Image />
          </div>
        </Box>
        <Box mb={2}>
          With valid <code>src</code>
          <div style={{ width: '25%', display: 'inline-block' }}>
            <Image src={logo} />
          </div>
        </Box>
      </Box>
    </Page>
  );
};

export default ComponentsPage;

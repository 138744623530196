import React, { FC, useEffect, useState } from 'react';
import { FieldProps } from 'formik';

import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { Box, FormGroup } from '@mui/material';

import { ErrorMessage, useTestId } from 'components';

interface CheckboxProps
  extends Omit<FormControlLabelProps, 'form' | 'control'>,
    Partial<FieldProps> {
  /**
   * The color of the component. It supports both default and custom theme colors, which can be added as shown in the palette customization guide. https://mui.com/material-ui/customization/palette/#adding-new-colors
   */
  color?: MuiCheckboxProps['color'];
  /**
   * The props for the Mui Checkbox component. Learn about the available props and the CSS API: https://mui.com/material-ui/api/checkbox/.
   */
  checkBoxProps?: MuiCheckboxProps;
  /**
   * If set to ```true```, an error message is going to be visible under the field, in case of an error.
   */
  showError?: boolean;
  /**
   * For testing purposes, you can set a testid for the checkbox.
   */
  customTestId?: string;
  multilineLabel?: boolean;
  daySlot?: boolean;
  setCheckBox?: React.Dispatch<React.SetStateAction<boolean[]>>;
  checkBoxIndex?: number;
}

const styles = {
  multilineLabel: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  multilineError: {
    ml: 4.25,
  },
};

export const Checkbox: FC<CheckboxProps> = ({
  label,
  color,
  showError,
  checkBoxProps,
  form,
  field,
  name,
  onChange,
  onBlur,
  checked,
  disabled,
  customTestId,
  multilineLabel,
  daySlot,
  setCheckBox,
  checkBoxIndex,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(field?.value || checked));
  const fieldName = field?.name || name;
  const { testIdObject } = useTestId({
    name: fieldName,
    testId: customTestId,
    componentName: 'Checkbox',
  });

  const onChangeFunction = (e: React.SyntheticEvent<Element, Event>, c: boolean) => {
    setIsChecked(!isChecked);
    field?.onChange(e);
    onChange?.(e, c);
  };

  useEffect(() => {
    if (setCheckBox && checkBoxIndex !== undefined) {
      setCheckBox((array) => {
        const res = [...array];
        res[checkBoxIndex] = !!field?.value?.length;
        return res;
      });
    }
    if ((!field?.value && !checked) || field?.value[0] === 'on') {
      setIsChecked(Boolean(field?.value));
    }
  }, [field?.value, checked, setCheckBox, checkBoxIndex]);

  return (
    <FormGroup>
      <FormControlLabel
        sx={multilineLabel ? styles.multilineLabel : {}}
        label={multilineLabel ? <Box>{label}</Box> : label}
        name={field?.name || name}
        onChange={onChangeFunction}
        onBlur={field?.onBlur || onBlur}
        disabled={disabled}
        control={<MuiCheckbox disabled={disabled} color={color} {...(checkBoxProps || {})} />}
        checked={isChecked}
        {...testIdObject}
        {...props}
      />
      <ErrorMessage
        form={form}
        field={field}
        show={showError}
        sx={multilineLabel ? styles.multilineError : {}}
      />
    </FormGroup>
  );
};

Checkbox.defaultProps = {
  color: 'primary',
  showError: false,
};

import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { selectIsLoggedIn } from 'store/auth';
import { parseRedirectLink } from 'utils/redirectLink';
import { LoginQueryParams } from 'models';
import { getItemFromStorage } from 'utils/localStorage';

interface AuthRouteProps {
  children: ReactNode;
}

export function AuthRoute({ children }: AuthRouteProps) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const parsedLocation = parseRedirectLink(location);
  const storedLocation = parseRedirectLink(getItemFromStorage(LoginQueryParams.IN_APP_REDIRECT));
  const redirect = parsedLocation ?? storedLocation ?? '/';
  localStorage.removeItem(LoginQueryParams.IN_APP_REDIRECT);

  return isLoggedIn ? <Navigate to={redirect} /> : <>{children}</>;
}

import React, { FC, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import {
  Calendar,
  Event,
  View,
  NavigateAction,
  Navigate as navigate,
  EventProps,
  dateFnsLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse as fnsParse, startOfWeek, getDay } from 'date-fns';
import { hu as localeHu } from 'date-fns/locale';

import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import { Formik, FormikProps } from 'formik';

import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

import {
  LeoDatePicker,
  LeoSelectField,
  Page,
  PageHeader,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, ValueSetModel } from 'models';
import { RouteNames } from 'config/routeNames';
import { useAreaList } from 'features/area';
import { useUcseAppointmentsList } from 'features/ucseAppointments';
import { useValueSetList } from 'features/valueSets';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { parse, stringify } from 'utils/base64';
import { getPageName } from 'utils/format';
import {
  CapacityTimeSlot,
  OrderAppointmentFilterQuery,
  UcseAppointmentsModel,
} from 'models/ucseAppointments.model';
import { colorCodes, neutral, neutralVariant, primary } from 'theme/colors';
import Yup from 'utils/yup';
import { removeFalsy } from 'utils/objects';
import { useSelector } from 'react-redux';
import { selectUserRoles } from 'store/profile';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import AppointmentEditorDialog from './AppointmentEditorDialog';

const pageHeight = 1055;
const calendarRowHeight = 126;

const styles = {
  calendarBox: {
    '.rbc-month-view': {
      borderRadius: '0.8em 0.8em 0 0',
      backgroundColor: primary[50],
    },
    '.rbc-date-cell': {
      pr: 0,
      textAlign: 'center',
    },
    '.rbc-now': {
      'button:first-of-type': {
        borderRadius: '50%',
        width: '22px',
        height: '22px',
        paddingTop: '2px',
        backgroundColor: primary[600],
        color: primary[50],
      },
    },
    '.rbc-off-range-bg': {
      backgroundColor: colorCodes.reactCalendarBackground,
    },
    '.rbc-month-row': {
      minHeight: `${calendarRowHeight}px`,
    },
    '.rbc-button-link': {
      cursor: 'default',
      fontWeight: '700',
    },
  },
  enabledCheckboxColor: {
    color: colorCodes.checkboxGreen,
  },
  disabledCheckboxColor: {
    color: colorCodes.checkboxRed,
  },
  checkboxLabelStyle: {
    color: neutral[900],
    fontSize: 12,
  },
  weekendSlotBackgroundColor: {
    backgroundColor: neutralVariant[200],
  },
  workdaySlotBackgroundColor: {
    backgroundColor: 'transparent',
  },
  eventCellStyle: {
    backgroundColor: 'transparent',
    borderRadius: '0',
    padding: '0px 0px 0px 10px',
    margin: '0',
    border: '0',
    color: neutral[900],
    display: 'block',
  },
  footer: {
    paddingTop: 1,
    backgroundColor: primary[50],
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderRadius: '0 0 0.8em 0.8em',
    borderBottom: `1px solid ${colorCodes.footerBorder}`,
    borderLeft: `1px solid ${colorCodes.footerBorder}`,
    borderRight: `1px solid ${colorCodes.footerBorder}`,
  },
  freeSlotStyle: {
    paddingRight: 2,
  },
  reservedSlotStyle: {
    paddingLeft: 2,
  },
  alignToCenter: {
    display: 'flex',
    alignItems: 'center',
  },
};

interface EventExtraProps {
  enabled?: boolean;
  timeSlot?: string;
  timeSlotDate?: string;
}

interface AppointmentValues {
  id: number;
  key: string;
  value: string;
}

export interface CustomEvent extends Event, EventExtraProps {}

export interface DialogArgs {
  isOpen: boolean;
  openDialog: (event: CustomEvent) => void;
  closeDialog: () => void;
  selected: CustomEvent | null;
}

export type SubmitArgs = (
  values: any,
  helpers: FormikProps<any> | undefined,
  filterAndClose: boolean
) => void;

enum TimeSlot {
  MORNING = '1',
  AFTERNOON = '2',
  EVENING = '3',
}

const locales = {
  hu: localeHu,
};

const AppointmentPage: FC = () => {
  const Weekends = { SATURDAY: 6, SUNDAY: 7 };

  const localizer = dateFnsLocalizer({
    format,
    parse: fnsParse,
    startOfWeek: () => startOfWeek(new Date(), { locale: localeHu, weekStartsOn: 1 }),
    getDay,
    locales,
  });

  const { activePanel, closePanel, setPanel } = usePanelState();
  const { onLoadQuery, onFilter, params, apiParams } = useTableQueryParams(
    undefined,
    undefined,
    true
  );

  const [myEvents, setEvents] = useState<CustomEvent[]>();
  const [modalDate, setModalDate] = useState<string>('');
  const [modalSlot, setModalSlot] = useState<string>('');
  const [calendarRowNumber, setCalendarRowNumber] = useState<number>();

  const goToNextRef = useRef<() => void>();
  const goTodayRef = useRef<() => void>();
  const goToBackRef = useRef<() => void>();
  const submitRef = useRef<SubmitArgs>();
  const formikProps = useRef<FormikProps<any>>();
  const submitHelperRef = useRef<(val: OrderAppointmentFilterQuery) => void>();

  const roles = useSelector(selectUserRoles);
  const actionsVisible = getFunctionRole(FunctionRoleName.APPOINTMENT_PAGE_ACTIONS, roles);

  const parsedDate = parse(apiParams.filter).date;
  const datefirst = new Date();
  const initialDate = parsedDate
    ? new Date(parsedDate)
    : new Date(datefirst.getFullYear(), datefirst.getMonth(), 1);

  const [currentDate, setCurrentDate] = useState<Date>(initialDate);
  const requiredCommonParam: string = parse(params?.filter).area;

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  }: DialogArgs = useDialogState();

  const { data: ucseAppointmentsData, refetch: refetchAppointmentData } = useUcseAppointmentsList(
    apiParams,
    requiredCommonParam
  );

  const { data: appointmentTimeValueSet } = useValueSetList({
    code: ['APPOINTMENT_TIME'],
  });

  useEffect(() => {
    const calendar = document.getElementsByClassName('rbc-calendar')[0]?.clientHeight;
    setCalendarRowNumber(Math.floor(calendar / calendarRowHeight));
  }, [currentDate]);

  useEffect(() => {
    if (appointmentTimeValueSet) {
      setEvents(getCalendarEvents(ucseAppointmentsData, appointmentTimeValueSet));
    }

    if (selectedEdit?.start && selectedEdit?.timeSlot && ucseAppointmentsData) {
      setModalDate(DateTime.fromJSDate(selectedEdit?.start).toFormat('yyyy-MM-dd'));
      setModalSlot(getTimeSlotDesc(selectedEdit?.timeSlot, appointmentTimeValueSet));
    }
  }, [selectedEdit, ucseAppointmentsData, appointmentTimeValueSet]);

  const { data: areaData } = useAreaList();

  const { data: areaHookData, accessor: areaHookAccessor } = useFieldDataAndAccessor(
    areaData,
    'area',
    'area'
  );

  const handleSelectEvent = (e: CustomEvent) => {
    if (actionsVisible) {
      openEditor(e);
    }
  };

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'UCSE_IDOPONTFOGLALAS.AREA',
      component: (
        <LeoSelectField
          name="area"
          data={[...areaHookData].sort()}
          accessor={areaHookAccessor}
          label={'UCSE_IDOPONTFOGLALAS.CITY'}
        />
      ),
      name: 'area',
      panelFieldWidth: 12,
      filterIsRequired: true,
      abbrName: t('FILTERABBR.AREA'),
    },
    {
      component: (
        <LeoDatePicker
          name="date"
          label={<Trans i18nKey="UCSE_IDOPONTFOGLALAS.DATE" />}
          formikProps={formikProps}
        />
      ),
      name: 'date',
      hidden: true,
    },
  ];

  const currentFilter = parse(params.filter);
  const updatedFilter = stringify({
    ...currentFilter,
    ...{ date: DateTime.fromJSDate(currentDate).toFormat('yyyy-MM-dd') },
  });

  const updatedParams = { ...params, ...{ filter: updatedFilter } };

  const stateHandler = (formikValues: FormikProps<any> | undefined) =>
    setCurrentDate(new Date(formikValues?.values.date));

  const validationSchema = Yup.object().shape({
    area: Yup.string().nullable().required(),
  });

  const filterConfig = useFilterConfig({
    params: updatedParams,
    onFilter,
    filters,
    validationSchema,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.UCSE_IDOPONTFOGLALAS),
    submitRef,
    stateHandler,
  });

  const panels = [filterConfig];

  const formats = {
    weekdayFormat: (date: any, culture: any, loc: any) => loc.format(date, 'ccc', culture),
    monthHeaderFormat: (date: any, culture: any, loc: any) =>
      loc.format(date, 'yyyy. MMMM', culture),
    dateFormat: (date: any, culture: any, loc: any) => {
      const actualDate = DateTime.fromJSDate(date);
      const dateString = loc.format(date, actualDate.day === 1 ? 'MMM d.' : 'd', culture);

      return actualDate.day === 1
        ? (dateString as string).charAt(0).toUpperCase() + dateString.slice(1)
        : dateString;
    },
  };

  const getCheckBox = (checked?: boolean) => (
    <Checkbox
      checked={checked}
      disabled
      sx={{
        '& .MuiSvgIcon-root': {
          fontSize: 14,
          color: checked ? styles.enabledCheckboxColor : styles.disabledCheckboxColor,
        },
      }}
      icon={<DisabledByDefaultIcon />}
      checkedIcon={<CheckBoxIcon />}
    />
  );

  const event = (prop: EventProps & { event: CustomEvent }) => (
    <FormGroup>
      <FormControlLabel
        control={getCheckBox(prop.event.enabled)}
        label={<Typography style={styles.checkboxLabelStyle}>{prop.title}</Typography>}
      />
    </FormGroup>
  );

  const getEventStyle = () => ({
    style: styles.eventCellStyle,
  });

  const getDayProp = (date: Date) =>
    ([Weekends.SATURDAY, Weekends.SUNDAY].includes(
      DateTime.fromISO(date.toISOString()).weekday
    ) && {
      style: styles.weekendSlotBackgroundColor,
    }) || {
      style: styles.workdaySlotBackgroundColor,
    };

  const toolbar = ({
    onNavigate,
  }: {
    onView: (view: View) => void;
    onNavigate: (navigate: NavigateAction, date?: Date) => void;
    label: string;
  }) => {
    const date = DateTime.fromISO(currentFilter.date);

    function submitValues(newDate: DateTime) {
      const newFilter = { ...currentFilter, ...{ date: newDate.toFormat('yyyy-MM-dd') } };
      if (submitRef.current) {
        submitRef?.current(newFilter, formikProps.current, false);
      } else if (submitHelperRef.current) {
        submitHelperRef?.current(newFilter);
      }
    }

    const goToBackHandler = () => {
      const newDate = date.minus({ month: 1 });
      submitValues(newDate);
      onNavigate(navigate.PREVIOUS);
    };

    const goToNextHandler = () => {
      const newDate = date.plus({ month: 1 });
      submitValues(newDate);
      onNavigate(navigate.NEXT);
    };

    const goToTodayHandler = () => {
      const newDate = DateTime.now();
      submitValues(newDate);
      onNavigate(navigate.TODAY);
    };

    goToBackRef.current = goToBackHandler;
    goToNextRef.current = goToNextHandler;
    goTodayRef.current = goToTodayHandler;

    return <></>;
  };

  const getMinHeight = () => {
    return calendarRowNumber === 6 ? `${pageHeight}px` : `${pageHeight - calendarRowHeight}px`;
  };

  return (
    <Formik
      initialValues={filters}
      onSubmit={() => undefined}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {(props) => {
        const submitHelper = (val: OrderAppointmentFilterQuery) => {
          props?.submitForm();
          props?.validateForm().then((e: any) => {
            if (isEmpty(e)) {
              const nextFilter = stringify(removeFalsy(val));
              onFilter({ filter: nextFilter });
            }
          });
        };

        if (submitHelperRef) {
          submitHelperRef.current = submitHelper;
        }

        return (
          <Page
            panels={panels}
            activePanel={activePanel}
            title={<Trans i18nKey="UCSE_IDOPONTFOGLALAS.TITLE" />}
            inScroll
            closePanel={closePanel}
            setPanel={setPanel}
            sx={{ minHeight: getMinHeight() }}
          >
            <Box sx={{ mt: -3 }}>
              <PageHeader
                panels={panels}
                activePanel={activePanel}
                setPanel={setPanel}
                onTableView={false}
                onLoadQuery={onLoadQuery}
                dateNavigators={{
                  goToBackRef,
                  goTodayRef,
                  goToNextRef,
                  currentDate,
                  currentFilter,
                }}
                disableDivider
              />
              <Box sx={styles.calendarBox}>
                <Calendar
                  events={myEvents}
                  localizer={localizer}
                  culture={'hu'}
                  defaultDate={currentDate}
                  date={currentDate}
                  onSelectEvent={handleSelectEvent}
                  selectable
                  views={['month']}
                  formats={formats}
                  components={{ event, toolbar }}
                  eventPropGetter={getEventStyle}
                  dayPropGetter={getDayProp}
                  tooltipAccessor={() => ''}
                  onNavigate={(newDate: Date) => setCurrentDate(newDate)}
                />
                <Box sx={styles.footer}>
                  <Typography variant="caption" sx={[styles.freeSlotStyle, styles.alignToCenter]}>
                    {getCheckBox(true)}
                    <Trans i18nKey="UCSE_IDOPONTFOGLALAS.FREESLOT" />
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={[styles.reservedSlotStyle, styles.alignToCenter]}
                  >
                    {getCheckBox(false)}
                    <Trans i18nKey="UCSE_IDOPONTFOGLALAS.RESERVEDSLOT" />
                  </Typography>
                </Box>
              </Box>
            </Box>
            <AppointmentEditorDialog
              open={isEditorOpen}
              selected={selectedEdit}
              onClose={closeEditor}
              requiredParam={requiredCommonParam}
              modalDate={modalDate}
              modalSlot={modalSlot}
              refetchAppointmentData={refetchAppointmentData}
            />
          </Page>
        );
      }}
    </Formik>
  );
};
export default AppointmentPage;

const getCalendarEvents = (
  ucseAppointmentsData: UcseAppointmentsModel[] | undefined,
  appointmentTimeValueSet: ValueSetModel[] | undefined
): CustomEvent[] | undefined => {
  const eventsObjects = ucseAppointmentsData?.map((event: UcseAppointmentsModel) => {
    return event.capacityByTimeslot?.map((slot: CapacityTimeSlot) =>
      getEventObject(slot, event, appointmentTimeValueSet)
    );
  });

  return eventsObjects?.flatMap((event) => event);
};

const getTimeSlotDesc = (
  timeslot: string,
  appointmentTimeValueSet: ValueSetModel[] | undefined
) => {
  const [data] = appointmentTimeValueSet || [];
  const title =
    (data.values.find((valueItem) => valueItem.key === timeslot) as AppointmentValues) || '';

  switch (timeslot) {
    case TimeSlot.MORNING:
      return `${t('COMMON.MORNING')} (${title.value})`;
    case TimeSlot.AFTERNOON:
      return `${t('COMMON.AFTERNOON')} (${title.value})`;
    case TimeSlot.EVENING:
      return `${t('COMMON.EVENING')} (${title.value})`;
    default:
      return '';
  }
};

const getEventObject = (
  slot: CapacityTimeSlot,
  item: UcseAppointmentsModel,
  appointmentTimeValueSet: ValueSetModel[] | undefined
): CustomEvent => {
  const isoDate = new Date(item.date);
  const year = isoDate.getFullYear();
  const month = isoDate.getMonth();
  const day = isoDate.getDate();

  const [data] = appointmentTimeValueSet || [];

  const title =
    (data.values.find((valueItem) => valueItem.key === slot.timeslot) as AppointmentValues) || '';

  const fromHour = +title.value.slice(0, title.value.indexOf(':'));
  const fromMinute = +title.value.slice(title.value.indexOf(':') + 1, title.value.indexOf('-'));

  const toHour = +title.value.slice(title.value.indexOf('-') + 1, title.value.lastIndexOf(':'));
  const toMinute = +title.value.slice(title.value.lastIndexOf(':') + 1, title.value.length + 1);

  return {
    title: `${title.value}`,
    start: new Date(year, month, day, fromHour, fromMinute, 0),
    end: new Date(year, month, day, toHour, toMinute, 0),
    enabled: !!slot.having_capacity,
    timeSlot: slot.timeslot,
    timeSlotDate: item.date,
  };
};

import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';
import { Methods, requestFile } from 'utils/request';

interface TimeLogExportQuery {
  from?: string;
  to?: string;
}

class TimeLogService extends EntityService {
  constructor() {
    super('time-log');
  }

  async upload(file: File, date: TimeLogExportQuery) {
    const formData = new FormData();
    const blob = new Blob([file], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    formData.append('file', blob, file.name);
    formData.append('date', JSON.stringify(date));

    const response = await requestFile<File | string>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    await exportFile(response);
    return response;
  }
}

export const timeLogService = new TimeLogService();

import { MenuConfigModel } from 'models';

const findRouteInChildren = (route: string, children?: MenuConfigModel[]) =>
  children?.map((child) => child.children?.find((x) => x.route === route)).filter((result) => result)[0];

export const findRoute = (route: string, menuConfig: MenuConfigModel[]) =>
  menuConfig?.find((x) => x.route === route) ||
  findRouteInChildren(route, menuConfig) ||
  findRouteInChildren(route, menuConfig.map((i) => i.children)[0]) ||
  menuConfig
    .flatMap((i) => i.children?.flatMap((x) => findRouteInChildren(route, x.children)))
    .filter((result) => result)[0];

import { EntityService } from 'utils/entityService';
import { QueryParamModel } from 'models';
import request, { Methods } from 'utils/request';
import { AreaModel } from 'models/area.model';

class AreaService extends EntityService {
  constructor() {
    super('area');
  }

  getAreaList = async (params?: QueryParamModel | null) => {
    return request<AreaModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}/search`,
      params,
    });
  };
}

export const areaService = new AreaService();

import React, { FC, useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, SvgIcon, CircularProgress } from '@mui/material';
import { Article, Cached, DoNotDisturb, Edit, MessageRounded } from '@mui/icons-material';

import { FilterOptionModel, ReadingUnitSummaryModel } from 'models';
import {
  DropdownMenuItem,
  Page,
  PageHeaderAction,
  Table,
  Tooltip,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { RouteNames } from 'config/routeNames';
import { getFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import Yup from 'utils/yup';
import { SortDirection, arrayify } from 'utils/arrays';
import { getPageName } from 'utils/format';
import { formatDate } from 'utils/dates';
import { stringify } from 'utils/base64';
import {
  useExportReadingUnits,
  useReadingUnitCacheMetadata,
  useReadingUnitList,
  useReadingUnitWorkers,
} from 'features/readingUnits';
import { useValueSetList } from 'features/valueSets';
import { useWorkerListSearch } from 'features/workers';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import TransferTaskDialog, { TransferTaskType } from 'pages/Leo/ReadingUnits/TransferTaskDialog';
import ExcludeFromEstimationDialog from 'pages/Leo/ReadingUnits/ExcludeFromEstimationDialog';
import ReadingUnitEditorDialog from 'pages/Leo/ReadingUnits/ReadingUnitsEditorDialog';
import SendMessageDialog from 'pages/Kozos/Workers/SendMessageDialog';
import TransferErrorDialog, { TransferErrorType } from 'pages/Leo/ReadingUnits/TransferErrorDialog';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/Titasz/Group.svg';

import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { selectUserRoles } from 'store/profile';
import { selectSelectedRecord, SelectedRecord, setSelectedRecord } from 'store/common';

const styles = {
  table: {
    '.MuiTableCell-root': {
      '@media (max-width: 1599px)': {
        padding: '4px !important',
      },
      a: {
        color: 'primary.main',
      },
    },
  },
  groupIcon: {
    mt: 1,
    ml: 1,
  },
  gridContainerStyle: {
    marginBottom: '60px',
  },
};

interface WorkerSapIdCellProps {
  item: ReadingUnitSummaryModel;
}

function WorkerSapIdCell({ item }: WorkerSapIdCellProps) {
  const [isHovered, setIsHovered] = useState(false);
  const workers = useReadingUnitWorkers(item.id, isHovered && item.isMultipleWorkers);
  const workerSapIds = workers.data?.map((worker) => worker.workerSapId);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  return (
    <Box display="flex" alignItems="center" onMouseEnter={onMouseEnter}>
      {item.workerSapId}
      {item.isMultipleWorkers && (
        <Tooltip
          title={
            workers.isFetching ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              <Trans
                i18nKey="LEO_LEO_EGYSEGEK.MULTIPLE_WORKERS_TOOLTIP"
                values={{
                  workerSapIds,
                }}
              />
            )
          }
        >
          <SvgIcon component={GroupIcon} sx={styles.groupIcon} />
        </Tooltip>
      )}
    </Box>
  );
}

const ReadingUnitsPage: FC = () => {
  const roles = useSelector(selectUserRoles);
  const lastSelectedRecord = useSelector(selectSelectedRecord<ReadingUnitSummaryModel>);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    params,
    apiParams,
    exportParams,
    onFilter,
    onLoadQuery,
    onTableColumnVisibilityChange,
  } = useTableQueryParams(
    {
      order: stringify([{ orderBy: 'plannedReadingDate', order: SortDirection.ASC }]),
    },
    { worker: null }
  );

  const {
    data: readingUnitList,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReadingUnitList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportReadingUnits(exportParams);
  const { data: cacheMetadata } = useReadingUnitCacheMetadata();
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList();

  const { data: leoEgysStatus, accessor: leoEgysStatusAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEO_EGYS_STATUS')
  );
  const { data: taskStatuses, accessor: taskStatusAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'TASK_STATUS')
  );
  const { data: leoKozpontData, accessor: leoKozpontAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: workerData, accessor: workerAccessor } = getFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const { data: loData, accessor: loAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LO'),
    'key',
    'value'
  );

  const [transferErrorType, setTransferErrorType] = useState<TransferErrorType>();

  const memoizedRecord =
    !!lastSelectedRecord &&
    lastSelectedRecord?.clickedItem !== undefined &&
    readingUnitList?.data.includes(lastSelectedRecord?.clickedItem);

  const visible = getFunctionRole(FunctionRoleName.READING_UNIT_PAGE_ACTIONS, roles);

  const doubleFilterPanel = true;

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      name: 'plannedReadingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.WORKER',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="LEO_LEO_EGYSEGEK.WORKER"
          freeSolo
          maxLength={5}
          blankFilterOption
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.READING_UNIT',
      component: (
        <LeoTextField
          name="readingUnit"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.READING_UNIT" />}
          maxLength={10}
        />
      ),
      name: 'readingUnit',
      panelFieldWidth: 12,
      divider: true,
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.LOCATIONS',
      component: (
        <LeoTextField
          name="city"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.CITY" />}
          maxLength={20}
        />
      ),
      name: 'city',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenter"
          data={leoKozpontData}
          label="LEO_LEO_EGYSEGEK.READING_CENTER"
        />
      ),
      name: 'readingCenter',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.BUNDLE',
      component: (
        <LeoTextField
          name="bundle.from"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.FROM" />}
          maxLength={10}
        />
      ),
      name: 'bundle.from',
      panelFieldWidth: 6,
    },
    {
      component: (
        <LeoTextField
          name="bundle.to"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.TO" />}
          maxLength={10}
        />
      ),
      name: 'bundle.to',
      panelFieldWidth: 6,
      extraStyle: {
        gridContainerStyle: styles.gridContainerStyle,
      },
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.READING_UNIT_STATUS',
      component: (
        <LeoSelectField
          name="status"
          data={leoEgysStatus}
          accessor={leoEgysStatusAccessor}
          label="LEO_LEO_EGYSEGEK.READING_UNIT_STATUS"
        />
      ),
      name: 'status',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.FIELD_DEVICE_STATUS',
      component: (
        <LeoSelectField
          name="taskStatus"
          data={taskStatuses}
          accessor={taskStatusAccessor}
          label="LEO_LEO_EGYSEGEK.FIELD_DEVICE_STATUS"
        />
      ),
      name: 'taskStatus',
      panelFieldWidth: 12,
      divider: true,
      panelFieldOffset: 12,
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.SAP_BATCH_EXECUTION_ID_LAUFI',
      component: (
        <LeoTextField
          name="executionId"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.SAP_LAUFI" />}
          maxLength={20}
        />
      ),
      name: 'executionId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.LAUFI'),
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.SAP_BATCH_EXECUTION_ID_LAUFD',
      component: <LeoDateInterval name="executionDate" />,
      name: 'executionDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.LAUFD'),
    },
    {
      headerTitle: 'LEO_LEO_EGYSEGEK.READING_REASON',
      component: (
        <LeoSelectFieldMulti
          name="readingReason"
          data={loData}
          accessor={loAccessor}
          label="LEO_LEO_EGYSEGEK.READING_REASON"
        />
      ),
      name: 'readingReason',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      abbrName: t('FILTERABBR.READING_REASON'),
    },
  ];

  const renderSapIdCell = (item: ReadingUnitSummaryModel) => <WorkerSapIdCell item={item} />;

  const getDetailsPath = (item: ReadingUnitSummaryModel) => {
    const path = generatePath(RouteNames.LEO_LEO_EGYSEG_RESZLETEK, {
      id: item.id.toString(),
    });

    return `/${path}`;
  };

  const navigateToDetails = (item: ReadingUnitSummaryModel) => {
    const path = getDetailsPath(item);

    const detailsParams = {
      workerId: item.workerId || null,
    };

    navigate(`${path}?filter=${stringify(detailsParams)}`);
  };

  const scrollPositionEmitter = ({ clickedItem, scrollToPosition }: SelectedRecord) =>
    dispatch(setSelectedRecord({ clickedItem, scrollToPosition }));

  const readingUnitColumns = [
    {
      key: 'readingUnit',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.READING_UNIT" />,
      accessor: 'readingUnit',
      sortable: true,
    },
    {
      key: 'readingReason',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.READING_REASON" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        readingUnit.readingReason && getValueSetValues(valueSet, 'LO', readingUnit.readingReason),
      field: 'readingReason',
      sortable: true,
    },
    {
      key: 'status',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.STATUS" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        readingUnit.status && getValueSetValues(valueSet, 'LEO_EGYS_STATUS', readingUnit.status),
      field: 'status',
      sortable: true,
    },
    {
      key: 'taskStatus',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.FIELD_DEVICE_STATUS" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        readingUnit.taskStatus &&
        getValueSetValues(valueSet, 'TASK_STATUS', readingUnit.taskStatus),
      field: 'taskStatus',
      sortable: true,
    },
    {
      key: 'bundle',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.BUNDLE" />,
      accessor: 'bundle',
      sortable: true,
    },
    {
      key: 'plannedReadingDate',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.PLANNED_READING_DATE" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        formatDate(readingUnit.plannedReadingDate),
      field: 'plannedReadingDate',
      sortable: true,
    },
    {
      key: 'currentEstimationDate',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.CURRENT_ESTIMATION_DATE" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        formatDate(readingUnit.currentEstimationDate),
      field: 'currentEstimationDate',
      sortable: true,
    },
    {
      key: 'previousEstimationDate',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.PREVIOUS_ESTIMATION_DATE" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        formatDate(readingUnit.previousEstimationDate),
      field: 'previousEstimationDate',
      sortable: true,
    },
    {
      key: 'plannedEstimationDate',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.PLANNED_ESTIMATION_DATE" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        formatDate(readingUnit.plannedEstimationDate),
      field: 'plannedEstimationDate',
      sortable: true,
    },
    {
      key: 'workerSapId',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.WORKER" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) => renderSapIdCell(readingUnit),
      field: 'workerSapId',
      sortable: true,
    },
    {
      key: 'downloadabilityDate',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.DOWNLOADABILTY_DATE" />,
      accessor: (readingUnit: ReadingUnitSummaryModel) =>
        formatDate(readingUnit.downloadabilityDate),
      field: 'downloadabilityDate',
      sortable: true,
    },
    {
      key: 'orderCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.ORDER_COUNT" />,
      accessor: 'orderCount',
      sortable: true,
    },
    {
      key: 'orderReadCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.ORDER_READ_COUNT" />,
      accessor: 'orderReadCount',
      sortable: true,
    },
    {
      key: 'orderDictatedCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.ORDER_DICTATED_COUNT" />,
      accessor: 'orderDictatedCount',
      sortable: true,
    },
    {
      key: 'revokedOrderCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.REVOKED_ORDER_COUNT" />,
      accessor: 'revokedOrderCount',
      sortable: true,
    },
    {
      key: 'orderDismantledCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.ORDER_DISMANTLED_COUNT" />,
      accessor: 'orderDismantledCount',
      sortable: true,
    },
    {
      key: 'orderUnreadableCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.ORDER_UNREADABLE_COUNT" />,
      accessor: 'orderUnreadableCount',
      sortable: true,
    },
    {
      key: 'orderUnhandledCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.ORDER_UNHANDLED_COUNT" />,
      accessor: 'orderUnhandledCount',
      sortable: true,
    },
    {
      key: 'city',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.CITY" />,
      accessor: 'city',
      sortable: true,
    },
    {
      key: 'readingCenter',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.READING_CENTER" />,
      accessor: 'readingCenter',
      tooltip: (readingUnit: ReadingUnitSummaryModel) =>
        readingUnit.readingCenter && leoKozpontAccessor[readingUnit.readingCenter],
      sortable: true,
    },
    {
      key: 'consumptionPlaceCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.CONSUMPTION_PLACE_COUNT" />,
      accessor: 'consumptionPlaceCount',
      sortable: true,
    },
    {
      key: 'bindingCount',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.BINDING_COUNT" />,
      accessor: 'bindingCount',
      sortable: true,
    },
    {
      key: 'readingTime',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.MAR_NUMBER" />,
      accessor: 'readingTime',
      sortable: true,
    },
    {
      key: 'executionId',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.SAP_LAUFI" />,
      accessor: 'executionId',
      sortable: true,
    },
    {
      key: 'executionDate',
      header: <Trans i18nKey="LEO_LEO_EGYSEGEK.SAP_LAUFD" />,
      accessor: (item: ReadingUnitSummaryModel) => formatDate(item.executionDate),
      field: 'executionDate',
      sortable: true,
    },
  ];

  const {
    isOpen: isTransferOpen,
    openDialog: openTransfer,
    closeDialog: closeTransfer,
    selected: selectedTransfer,
  } = useDialogState<ReadingUnitSummaryModel>();

  const {
    isOpen: isBulkTransferOpen,
    openDialog: openBulkTransfer,
    closeDialog: closeBulkTransfer,
    selected: selectedBulkTransfer,
  } = useDialogState<ReadingUnitSummaryModel[]>();

  const {
    isOpen: isExcludeFromEstimationOpen,
    openDialog: openExcludeFromEstimation,
    closeDialog: closeExcludeFromEstimation,
    selected: selectedExcludeFromEstimation,
  } = useDialogState<ReadingUnitSummaryModel[]>();

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<ReadingUnitSummaryModel>();

  const {
    isOpen: isMessageOpen,
    openDialog: openMessage,
    closeDialog: closeMessage,
    selected: selectedSendMessage,
  } = useDialogState<ReadingUnitSummaryModel[]>();

  const {
    isOpen: isTransferErrorDialogOpen,
    openDialog: openTransferErrorDialog,
    closeDialog: closeTransferErrorDialog,
  } = useDialogState();

  const { activePanel, closePanel, setPanel } = usePanelState();

  const valid = Yup.object().shape({
    plannedReadingDate: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_LEO_EGYSEGEK),
    validationSchema: valid,
    doubleFilterPanel,
    isFetching,
  });

  const panels = [filterConfig];

  const hasEstimationDatePassed = (row: ReadingUnitSummaryModel | ReadingUnitSummaryModel[]) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return arrayify(row).some((item) => {
      const inputDate = new Date(item.currentEstimationDate);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    });
  };

  const hasBeenRevokedBySap = (row: ReadingUnitSummaryModel | ReadingUnitSummaryModel[]) => {
    return arrayify(row).some((item) => item.orderCount === item.revokedOrderCount);
  };

  const readingUnitHeaderActions = useMemo<PageHeaderAction[]>(
    () => [
      {
        name: 'export',
        label: 'COMMON.EXPORT',
        icon: <Export fill="currentColor" />,
        onClick: () => refetch(),
        isLoading: isInitialLoading || isRefetching,
        disabledIf: !exportParams?.filter,
      },
      {
        name: 'transfer',
        label: 'LEO_LEO_EGYSEGEK.BULK_TRANSFER',
        icon: <Cached fontSize="small" />,
        onClick: (row) => handleTransferDialog(row, openBulkTransfer),
        isBulkAction: true,
        visible,
      },
      {
        name: 'excludeFromEstimation',
        label: 'LEO_LEO_EGYSEGEK.BULK_OUT_OF_ESTIMATION',
        icon: <DoNotDisturb fontSize="small" />,
        onClick: (row) => openExcludeFromEstimation(row),
        isBulkAction: true,
        disabledIf: { status: 3 },
        visible,
      },
      {
        name: 'message',
        label: 'LEO_LEO_EGYSEGEK.BULK_SEND_MESSAGE',
        icon: <MessageRounded fontSize="small" />,
        onClick: (row) => openMessage(row),
        isBulkAction: true,
        visible,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      openExcludeFromEstimation,
      openMessage,
      openBulkTransfer,
      isInitialLoading,
      isRefetching,
      exportParams?.filter,
    ]
  );

  const readingUnitActions = [
    {
      name: 'edit',
      label: 'COMMON.EDIT',
      icon: <Edit fontSize="small" />,
      onClick: (row: ReadingUnitSummaryModel | null | undefined) => openEditor(row),
      disabledIf: (row: ReadingUnitSummaryModel) => !row.workerId || hasEstimationDatePassed(row),
      visible,
    },
    {
      name: 'details',
      label: 'LEO_LEO_EGYSEG_RESZLETEK.TITLE',
      icon: <Article fontSize="small" />,
      onClick: (row) => navigateToDetails(row),
    },
    {
      name: 'transfer',
      label: 'LEO_LEO_EGYSEGEK.TRANSFER',
      icon: <Cached fontSize="small" />,
      onClick: (row) => handleTransferDialog(row, openTransfer),
      visible,
    },
    {
      name: 'excludeFromEstimation',
      label: 'LEO_LEO_EGYSEGEK.OUT_OF_ESTIMATION',
      icon: <DoNotDisturb fontSize="small" />,
      onClick: (row) => openExcludeFromEstimation(row),
      disabledIf: (row: ReadingUnitSummaryModel) =>
        row.status === 3 || hasEstimationDatePassed(row),
      visible,
    },
    {
      name: 'message',
      label: 'MUNKAVEGZOK.SEND_MESSAGE',
      icon: <MessageRounded fontSize="small" />,
      onClick: (row) => openMessage(row),
      disabledIf: (row: ReadingUnitSummaryModel) => !row.workerId || hasEstimationDatePassed(row),
      visible,
    },
  ] as DropdownMenuItem[];

  const isTransferableReadingUnit = (data: ReadingUnitSummaryModel | ReadingUnitSummaryModel[]) => {
    if (hasEstimationDatePassed(data)) {
      setTransferErrorType(TransferErrorType.ESTIMATION_DATE_PASSED);
      return false;
    }

    if (hasBeenRevokedBySap(data)) {
      setTransferErrorType(TransferErrorType.REVOKED_BY_SAP);
      return false;
    }

    return true;
  };

  const handleTransferDialog = (
    row: ReadingUnitSummaryModel | ReadingUnitSummaryModel[],
    openTransferDialog: (data?: any) => void
  ) => {
    if (isTransferableReadingUnit(row)) {
      openTransferDialog(row);
    } else {
      openTransferErrorDialog(row);
    }
  };

  return (
    <Page
      title={<Trans i18nKey="LEO_LEO_EGYSEGEK.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Box sx={styles.table}>
          <Table
            scrollPositionEmitter={scrollPositionEmitter}
            memoizedRecord={memoizedRecord}
            filterDetailsData={{
              leoEgysStatusAccessor,
              taskStatusAccessor,
              leoKozpontAccessor,
              loAccessor,
            }}
            list={readingUnitList?.data || []}
            total={readingUnitList?.meta.total}
            timestamp={dataUpdatedAt}
            lastUpdated={!cacheMetadata?.isDisabled ? cacheMetadata?.lastSuccessAt : null}
            nextUpdate={!cacheMetadata?.isDisabled ? cacheMetadata?.nextRunAt : null}
            loading={isLoading}
            params={params}
            onSort={onSort}
            keyField="uniqueId"
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            columns={readingUnitColumns}
            actions={readingUnitActions}
            pageHeaderActions={readingUnitHeaderActions}
            onTableColumnVisibilityChange={onTableColumnVisibilityChange}
            onRowDoubleClick={navigateToDetails}
            panels={panels}
            activePanel={activePanel}
            setPanel={setPanel}
            onLoadQuery={onLoadQuery}
            resetSelectedOnAction
          />
        </Box>
      )}
      <TransferTaskDialog
        open={isTransferOpen}
        onClose={closeTransfer}
        selected={selectedTransfer}
        type={TransferTaskType.TRANSFER_TASK}
        defaultDownloadDate={
          selectedTransfer?.downloadabilityDate
            ? new Date(selectedTransfer?.downloadabilityDate)
            : null
        }
      />
      <TransferTaskDialog
        open={isBulkTransferOpen}
        onClose={closeBulkTransfer}
        selected={selectedBulkTransfer}
        type={TransferTaskType.TRANSFER_TASK}
        defaultDownloadDate={new Date(Date.now())}
      />
      <ExcludeFromEstimationDialog
        open={isExcludeFromEstimationOpen}
        onClose={closeExcludeFromEstimation}
        selected={selectedExcludeFromEstimation}
      />
      <ReadingUnitEditorDialog open={isEditorOpen} selected={selectedEdit} onClose={closeEditor} />
      <SendMessageDialog
        open={isMessageOpen}
        selected={selectedSendMessage}
        onClose={closeMessage}
      />
      <TransferErrorDialog
        open={isTransferErrorDialogOpen}
        type={transferErrorType}
        onClose={closeTransferErrorDialog}
      />
    </Page>
  );
};

export default ReadingUnitsPage;

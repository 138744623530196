import { omit } from 'lodash';

import { JobModel, ListResponseModel, QueryParamModel, WorkerViewModel } from 'models';
import { SendMessageFormProps } from 'pages/Kozos/Workers/SendMessageDialog';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

class WorkersService extends EntityService {
  constructor() {
    super('worker');
  }

  async sendMessage(data: SendMessageFormProps) {
    return request<WorkerViewModel>({
      method: Methods.POST,
      resource: `v1/message/send`,
      data,
    });
  }

  async syncWorkers() {
    return request<{ jobId: string }>({
      method: Methods.POST,
      resource: `v1/admin/worker-sync`,
    });
  }

  async getSyncTaskById(jobId: ID) {
    return request<JobModel>({
      method: Methods.GET,
      resource: `v1/admin/worker-sync/jobs/${jobId}`,
    });
  }

  async getWorkersList(params?: QueryParamModel | null) {
    return request<ListResponseModel<WorkerViewModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async searchWorkers(params?: QueryParamModel) {
    return request<WorkerViewModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}/search`,
      params,
    });
  }

  async editWorker(id: ID, data: Partial<WorkerViewModel>) {
    const updatedData = omit(data, ['id', 'isActive']);

    return request<WorkerViewModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/${id}`,
      data: updatedData,
    });
  }

  async exportWorkers(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });

    exportFile(response);
    return response;
  }
}

export const workersService = new WorkersService();

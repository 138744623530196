import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Button, Page } from 'components';

const styles = {
  root: {
    width: '80%',
    height: '70vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export const NotFound: FC = () => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    navigate(`/`);
  };

  return (
    <Page title={<Trans i18nKey="HOME.PAGE_TITLE" />}>
      <Box sx={styles.root}>
        <Typography variant="h4" paddingBottom={1}>
          <Trans i18nKey="NOT_FOUND.TITLE" />
        </Typography>
        <Typography>
          <Trans i18nKey="NOT_FOUND.DESCRIPTION" />
        </Typography>
        <Button sx={{ pt: 4 }} onClick={handleClick} variant="contained">
          <Trans i18nKey="NO_ACCESS.BACK_TO_HOMEPAGE" />
        </Button>
      </Box>
    </Page>
  );
};

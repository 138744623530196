import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { Env } from 'config/env';

import supportedLanguages from './supported-languages.json';

export function extract(key: string, defaultValue?: string) {
  return defaultValue || key;
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?${Env?.REACT_APP_VERSION}`,
    },
    load: 'languageOnly',
    supportedLngs: supportedLanguages.SUPPORTED_LANGUAGES,
    fallbackLng: supportedLanguages.DEFAULT_LANGUAGE,
    lng: 'hu',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    detection: {
      caches: ['localStorage'],
    },
  });

export default i18n;

import * as React from 'react';

import { Field } from 'formik';
import { Box } from '@mui/material';

import { TextField } from 'components/form/text-field/TextField';
import { IInputComponentParams } from 'components/input-fields/helpers/interfaces';

interface InputProps {
  maxLength?: number;
  value?: string;
}

export const LeoTextField: React.FC<IInputComponentParams> = ({
  name,
  maxLength,
  variant,
  disableunderline = false,
  staticvalue,
  ...props
}) => {
  const inputProps: InputProps = { maxLength };
  if (staticvalue) {
    inputProps.value = staticvalue;
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Field
        type={'text'}
        id={name}
        name={name}
        component={TextField}
        variant={variant || 'outlined'}
        inputProps={inputProps}
        InputProps={disableunderline ? { disableUnderline: true } : undefined}
        {...props}
      />
    </Box>
  );
};

export default LeoTextField;

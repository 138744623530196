import { PhotoAccess, pageSettings, PageSettings, baseUrl } from 'config/photosConfig';

export const openPhotoInNewTab = (pageName: PhotoAccess, orderId: string) => {
  window.open(getPhotoLink(orderId, pageSettings, pageName).link, '_blank', 'noopener,noreferrer');
};

export const createLink = (
  baseUrlParam: { baseUrl: string },
  name: PhotoAccess,
  id: string,
  page: PageSettings
) => {
  const searchCriteria = `[{"property":"${page[name].propertyName}","value":"${id}"}]`;

  return `${baseUrlParam.baseUrl}
    ?${page[name].desktop}
    &${page[name].repositoryId}
    &${page[name].docid}
    &${page[name].templateName}
    &${page[name].version}
    &searchCriteria=${searchCriteria}
    &${page[name].autorRunSearch}
    &${page[name].autoOpenItem}`.replace(/\s+/gm, '');
};

export const getPhotoLink = (orderId: string, page: PageSettings, pageName?: PhotoAccess) => {
  let link = '';

  switch (pageName) {
    case PhotoAccess.READING_UNIT_DETAILS:
      link = createLink(baseUrl, pageName, orderId, page);
      break;

    case PhotoAccess.HEALTH_AND_SAFETY:
      link = createLink(baseUrl, pageName, orderId, page);
      break;

    case PhotoAccess.SM_ORDER_DETAILS:
      link = createLink(baseUrl, pageName, orderId, page);
      break;

    case PhotoAccess.UCSE_ORDERS:
      link = createLink(baseUrl, pageName, orderId, page);
      break;

    default:
      link = '_blank';
      break;
  }

  return { link };
};

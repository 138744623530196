import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cloneDeep, differenceWith, get, isEqual, pick } from 'lodash';

import SaveIcon from '@mui/icons-material/Save';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

import {
  ColumnProps,
  ConfirmDialog,
  Page,
  PageHeaderAction,
  Table,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { PerformanceMetricModel } from 'models/performanceMetric.model';
import {
  useEditPerformanceMetric,
  useExportPerformanceMetricList,
  usePerformanceMetricList,
} from 'features/performanceMetric';
import { isChangesHasOccured } from 'pages/Kozos/OpemUsers/OpemUsersPage';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { selectUserRoles } from 'store/profile';

type TRecord = Record<string, any>;

const WageItemsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({});

  const [WageItemsListState, setWageItemsListState] = useState<PerformanceMetricModel[]>([]);
  const [selectedModel, setSelectedModel] = useState<PerformanceMetricModel>();
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const { activePanel, closePanel, setPanel } = usePanelState();
  const {
    data: wageItemsData,
    isLoading: wageItemsIsLoading,
    dataUpdatedAt,
  } = usePerformanceMetricList(apiParams);

  const { isInitialLoading, isRefetching, refetch } = useExportPerformanceMetricList(exportParams);

  const { mutateAsync: editPerformanceWage, isLoading: patchIsLoading } =
    useEditPerformanceMetric();

  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.WAGEITEMS_PAGE_ACTIONS, roles);

  useEffect(() => {
    const defaultState = wageItemsData?.data;
    const newState = WageItemsListState;

    if (!wageItemsIsLoading && selectedModel) {
      setIsTouched(isChangesHasOccured(defaultState, newState, 'id'));
    }
  }, [wageItemsData, WageItemsListState, selectedModel, wageItemsIsLoading]);

  useEffect(() => {
    if (wageItemsData?.data) {
      setWageItemsListState(wageItemsData?.data || []);
    }
  }, [wageItemsData?.data]);

  const getCheckboxFieldHandler = <T extends TRecord>(
    listState: PerformanceMetricModel[] | undefined,
    item: T,
    column: ColumnProps<T>
  ): 1 | 0 =>
    column.field &&
    get(
      listState?.find((stateItem) => stateItem.id === item.id),
      column.field,
      0
    )
      ? 1
      : 0;

  const getCheckboxFieldClickHandler =
    <T extends TRecord>(listState: PerformanceMetricModel[] | undefined, item: T) =>
    (prevState: PerformanceMetricModel[]) => {
      if (!visible) {
        return prevState;
      }
      const clonedState = cloneDeep(prevState);
      const selected = listState?.find((listItem) => listItem.id === item.id);
      const newSettings = !selected?.isEnabled;

      const updatedRow = {
        ...selected,
        isEnabled: newSettings,
      };
      const filteredRow = clonedState.filter((stateItem) => stateItem.id !== selected?.id);
      setSelectedModel(selected);
      return [...filteredRow, updatedRow];
    };

  const columns: ColumnProps<PerformanceMetricModel>[] = [
    {
      key: 'value',
      header: <Trans i18nKey="KOZOS_BERELEMEK.WAGE_ITEM" />,
      accessor: (data) => data.value,
      field: 'value',
      sortable: true,
      noWrap: true,
    },
    {
      key: 'isAutoCounted',
      header: <Trans i18nKey="KOZOS_BERELEMEK.CALCULATED_BY_ALGORITHM" />,
      accessor: (userData) => userData.isAutoCounted,
      field: 'isAutoCounted',
      align: 'center',
      sortable: false,
      noWrap: true,
      checkboxFieldHandler: getCheckboxFieldHandler,
      checkboxCell: true,
    },
    {
      key: 'isEnabled',
      header: <Trans i18nKey="KOZOS_BERELEMEK.IS_ENABLED" />,
      accessor: (userData) => userData.isEnabled,
      field: 'isEnabled',
      align: 'center',
      sortable: false,
      noWrap: true,
      checkboxClickHandler: getCheckboxFieldClickHandler,
      checkboxFieldHandler: getCheckboxFieldHandler,
      checkboxCell: true,
    },
  ];

  const sendPerformanceWageList = useCallback(() => {
    const difference = differenceWith(WageItemsListState, wageItemsData?.data || [], isEqual);
    const updatedData = difference.map((diff) => pick(diff, ['id', 'isEnabled']));

    editPerformanceWage({ data: updatedData });
  }, [WageItemsListState, wageItemsData?.data, editPerformanceWage]);

  const headerActions = useMemo<PageHeaderAction[]>(() => {
    const onSaveClickHandler = () => {
      setSaveDialog(true);
    };

    const onCancelClickHandler = () => {
      setConfirmDialog(true);
    };

    return [
      {
        name: 'save',
        label: 'COMMON.SAVE',
        icon: <SaveIcon fill="currentColor" />,
        onClick: onSaveClickHandler,
        disabledIf: !isTouched,
        visible,
      },
      {
        name: 'cancel',
        label: 'COMMON.DISCARD_CHANGES',
        icon: <HighlightOffIcon fill="currentColor" />,
        onClick: onCancelClickHandler,
        disabledIf: !isTouched,
        visible,
      },
      {
        name: 'export',
        label: 'COMMON.EXPORT',
        icon: <Export fill="currentColor" />,
        onClick: () => refetch(),
        isLoading: isInitialLoading || isRefetching,
      },
    ];
  }, [isTouched, isInitialLoading, isRefetching, refetch, visible]);

  const onSaveDialogHandler = () => {
    sendPerformanceWageList();
    resetSettings();
  };

  const onCancelDialogHandler = () => {
    setWageItemsListState(wageItemsData?.data || []);
    resetSettings();
  };

  const resetSettings = () => {
    setIsTouched(false);
    setSelectedModel(undefined);
  };

  return (
    <Page
      title={<Trans i18nKey="KOZOS_BERELEMEK.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Table
        listState={WageItemsListState}
        setListState={setWageItemsListState}
        checkBoxTable={true}
        enableCheckbox={false}
        setPanel={setPanel}
        activePanel={activePanel}
        timestamp={dataUpdatedAt}
        list={wageItemsData?.data || []}
        total={wageItemsData?.meta.total}
        loading={wageItemsIsLoading}
        params={params}
        onSort={onSort}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        pageHeaderActions={headerActions}
        columns={columns}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        setIsTouched={setIsTouched}
        onLoadQuery={onLoadQuery}
        dataLoadImmediately={true}
      />
      <ConfirmDialog
        confirmTitle={<Trans i18nKey="COMMON.OK" />}
        cancelTitle={<Trans i18nKey="COMMON.CANCEL" />}
        onSubmit={onSaveDialogHandler}
        open={saveDialog}
        onClose={() => setSaveDialog(false)}
        loading={patchIsLoading}
      >
        <Trans i18nKey="COMMON.SAVE_QUESTION" />
      </ConfirmDialog>
      <ConfirmDialog
        confirmTitle={<Trans i18nKey="COMMON.OK" />}
        onSubmit={onCancelDialogHandler}
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
      >
        <Trans i18nKey="COMMON.EDIT_QUESTION" />
      </ConfirmDialog>
    </Page>
  );
};

export default WageItemsPage;

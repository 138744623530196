import React, { FC } from 'react';
import { FieldProps } from 'formik';

import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import { ErrorMessage, useTestId } from 'components';

interface SwitchProps extends Omit<FormControlLabelProps, 'form' | 'control'>, Partial<FieldProps> {
  /**
   * The color of the component. It supports both default and custom theme colors, which can be added as shown in the palette customization guide: https://mui.com/material-ui/customization/palette/#adding-new-colors
   */
  color?: MuiSwitchProps['color'];
  /**
   * The props for the Mui Switch component. Learn about the available props and the CSS API: https://mui.com/material-ui/api/switch/.
   */
  switchProps?: MuiSwitchProps;
  /**
   * If set to ```true```, an error message is going to be visible under the field, in case of an error.
   */
  showError?: boolean;
  /**
   * For testing purposes, you can set a testid for the switch.
   */
  customTestId?: string;
}

export const Switch: FC<SwitchProps> = ({
  label,
  color,
  showError,
  switchProps,
  form,
  field,
  name,
  onChange,
  onBlur,
  checked,
  disabled,
  customTestId,
  ...props
}) => {
  const fieldName = field?.name || name;
  const { testIdObject } = useTestId({ name: fieldName, testId: customTestId, componentName: 'Switch' });

  return (
    <div>
      <FormControlLabel
        label={label}
        name={field?.name || name}
        onChange={field?.onChange || onChange}
        onBlur={field?.onBlur || onBlur}
        disabled={disabled}
        control={<MuiSwitch disabled={disabled} color={color} {...(switchProps || {})} />}
        checked={Boolean(field?.value || checked)}
        {...testIdObject}
        {...props}
      />
      {showError ? <ErrorMessage form={form} field={field} /> : null}
    </div>
  );
};

Switch.defaultProps = {
  color: 'primary',
  showError: false,
};

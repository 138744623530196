export interface WaitOptions<T> {
  data?: T;
  ms?: number;
}

export async function wait<T>({ data, ms = 500 }: WaitOptions<T> = {}) {
  return new Promise<T | null>((resolve) => {
    setTimeout(() => {
      resolve(data || null);
    }, ms);
  });
}

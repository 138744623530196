import React, { FC, forwardRef, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { indexOf } from 'lodash';

import { Box, Grid, IconButtonProps, SelectProps, Typography } from '@mui/material';
import MuiTablePagination, {
  LabelDisplayedRowsArgs,
  TablePaginationBaseProps,
} from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import { VersionsDisplay } from 'components';
import { formatNumber } from 'utils/format';
import { Env } from 'config/env';
import { neutralVariant } from 'theme/colors';

export type OnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  page: number
) => void;
export type OnRowsPerPageChange = React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;

export interface TablePaginationProps extends TablePaginationBaseProps {
  timestamp: number | undefined | false;
  lastUpdated?: string | null;
  nextUpdate?: string | null;
  innerRef?: React.Ref<any>;
  ActionsComponent?: React.ElementType<TablePaginationActionsProps>;
  backIconButtonProps?: Partial<IconButtonProps>;
  count?: number;
  labelDisplayedRows?: (paginationInfo: LabelDisplayedRowsArgs) => ReactNode;
  labelRowsPerPage?: string;
  nextIconButtonProps?: Partial<IconButtonProps>;
  onPageChange: OnPageChange;
  onRowsPerPageChange?: OnRowsPerPageChange;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[] | { value: number; label: string }[];
  SelectProps?: Partial<SelectProps>;
  component?: React.ComponentType<any> | string;
  colorCodes?: ReactNode;
  disablePagination?: boolean;
}

function getDisplayedRowsLabel({ from, to, count }: LabelDisplayedRowsArgs) {
  return `${formatNumber(from) || 0} - ${formatNumber(to) || 0} / ${formatNumber(count) || 0}`;
}

const styles = {
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${neutralVariant[200]}`,
  },
};

export const defaultPageSize =
  Env.ROWS_PER_PAGE_OPTIONS[indexOf(Env.ROWS_PER_PAGE_OPTIONS, Env.DEFAULT_ROWS_PER_PAGE) || 0];

export const TablePagination: FC<TablePaginationProps> = forwardRef(
  (
    {
      labelRowsPerPage,
      labelDisplayedRows,
      rowsPerPageOptions,
      page,
      rowsPerPage,
      count,
      timestamp,
      colorCodes,
      lastUpdated,
      nextUpdate,
      disablePagination = false,
      ...props
    },
    ref
  ) => {
    const adjustedPage = (page || 1) - 1;

    return (
      <Grid container sx={styles.footerContainer}>
        <Grid item sm={colorCodes ? 4 : 5.5}>
          <VersionsDisplay
            timestamp={timestamp}
            lastUpdated={lastUpdated}
            nextUpdate={nextUpdate}
          />
        </Grid>
        <Grid item sm={colorCodes ? 4 : 0}>
          <Box>{colorCodes}</Box>
        </Grid>
        <Grid item sm={colorCodes ? 4 : 6.5}>
          {disablePagination ? (
            <Typography variant="cellHeaderSmall">
              <Trans i18nKey="COMMON.ROW_COUNT" />: {count}
            </Typography>
          ) : (
            <MuiTablePagination
              ref={ref}
              rowsPerPageOptions={rowsPerPageOptions || Env.ROWS_PER_PAGE_OPTIONS}
              count={count || 0}
              page={!count && adjustedPage > 0 ? 0 : adjustedPage}
              rowsPerPage={rowsPerPage || defaultPageSize}
              labelRowsPerPage={labelRowsPerPage || <Trans i18nKey="COMMON.ROWS_PER_PAGE" />}
              labelDisplayedRows={labelDisplayedRows || getDisplayedRowsLabel}
              {...props}
            />
          )}
        </Grid>
      </Grid>
    );
  }
);

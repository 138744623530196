import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const Spinners: FC = () => {
  return (
    <Box>
      <Typography variant="h6">Spinners</Typography>
      <Typography variant="subtitle1">Circular</Typography>
      <CircularProgress />
      <Typography variant="subtitle1">Linear</Typography>
      <LinearProgress />
      <Box mb={1} mt={2}>
        <Link href="https://material-ui.com/components/progress/" target="_blank" rel="noopener" underline="hover">
          More examples
        </Link>
      </Box>
    </Box>
  );
};

export default Spinners;

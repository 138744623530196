import { useLocation } from 'react-router-dom';
import { Env } from 'config/env';

export interface TestIdParams {
  name?: string;
  testId?: string;
  componentName?: string;
  componentVariant?: string;
}

export function useTestId({ name, componentVariant, testId, componentName }: TestIdParams) {
  const location = useLocation();

  const getPage = (): string => {
    const splitLocation = location.pathname.split('/');
    return `${splitLocation.pop()}-page`;
  };

  const getTestId = () => {
    const nameToSet = name ? `_${name}` : '';
    const variantToSet = componentVariant ? `_${componentVariant}` : '';
    return testId || `${getPage()}_${componentName}${nameToSet}${variantToSet}`;
  };

  return {
    testId: getTestId(),
    testIdObject: { [Env.TEST_ID_ATTRIBUTE_KEY]: getTestId() },
  };
}

import {
  ListResponseModel,
  QueryParamModel,
  ReadingUnitOrderDetailsModel,
  ReadingUnitModel,
  ReadingUnitOrderModel,
  ReadingUnitSummaryModel,
  ReadingFailureReasonModel,
  ReadingUnitWorkersModel,
  ReadingUnitCacheMetadata,
} from 'models';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';
import request, { Methods, requestFile } from 'utils/request';
import { TransferTaskFormProps } from 'pages/Leo/ReadingUnits/TransferTaskDialog';
import { ExcludeFromEstimationFormProps } from 'pages/Leo/ReadingUnits/ExcludeFromEstimationDialog';

class ReadingUnitService extends EntityService {
  constructor() {
    super('reading-unit');
  }

  async getReadingUnitList(params?: QueryParamModel | null) {
    return request<ListResponseModel<ReadingUnitSummaryModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async getReadingUnit(id: ID) {
    return request<ReadingUnitModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}`,
    });
  }

  async getReadingUnitWorkers(id: ID) {
    return request<ReadingUnitWorkersModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}/workers`,
    });
  }

  async getReadingUnitReadingFailureReasons(id: ID[]) {
    return request<ReadingFailureReasonModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}/reading-failure-reasons`,
      params: { id },
    });
  }

  async saveTransferTask(selected: number[], data: TransferTaskFormProps) {
    return request<ListResponseModel<ReadingUnitOrderModel>>({
      method: Methods.POST,
      resource: `v1/task/transfer/${this.resource}`,
      data: { readingUnitIds: selected, ...data },
    });
  }

  async saveTransferConsumptionPlace(selected: number[], data: TransferTaskFormProps) {
    return request<ListResponseModel<ReadingUnitOrderModel>>({
      method: Methods.POST,
      resource: `v1/task/transfer/${this.resource}/consumption-place`,
      data: { consumptionPlaceIds: selected, ...data },
    });
  }

  async excludeFromEstimation(selected: number[], data: ExcludeFromEstimationFormProps) {
    return request<ListResponseModel<any>>({
      method: Methods.POST,
      resource: `v1/${this.resource}/exclude`,
      data: { readingUnits: selected, ...data },
    });
  }

  async editReadingUnit(id: ID, data: Partial<ReadingUnitSummaryModel>) {
    return request<ReadingUnitSummaryModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/${id}`,
      data: { downloadabilityDate: data.downloadabilityDate },
    });
  }

  async exportReadingUnits(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });

    exportFile(response);
    return response;
  }

  async exportReadingUnitOrders(id: number, params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}/details/export`,
      params,
    });

    exportFile(response);
    return response;
  }

  async getReadingUnitOrderList(id: number, params?: QueryParamModel | null) {
    return request<ListResponseModel<ReadingUnitOrderModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}/details`,
      params,
    });
  }

  async getReadingUnitOrderDetails(id: string, orderId?: string) {
    return request<ReadingUnitOrderDetailsModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}/details/${orderId}`,
    });
  }

  async deleteReadingUnit(id: string[]) {
    const localResource = 'reading-order';
    const idString = id.reduce((acc: any, curr: any, index) => {
      acc += `${index === 0 ? '' : '&'}id=${curr}`;
      return acc;
    }, '?');
    return request({
      method: Methods.DELETE,
      resource: `v1/${localResource}${idString}`,
    });
  }

  async getCacheMetadata() {
    return request<ReadingUnitCacheMetadata>({
      method: Methods.GET,
      resource: `v1/${this.resource}/cache/metadata`,
    });
  }
}

export const readingUnitService = new ReadingUnitService();

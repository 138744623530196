import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import { SnackbarContent } from 'components';

import { usePrevious } from 'utils/usePrevious';
import { EventEmitter } from 'utils/eventEmitter';
import { NotificationModel } from 'models';

const NOTIFICATION_DURATION = 6000;
const notificationEventEmitter = new EventEmitter();

enum NotificationEvents {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
}

export const showNotification = (data: Omit<NotificationModel, 'id'>) => {
  notificationEventEmitter.dispatch(NotificationEvents.SHOW_NOTIFICATION, data);
};

export interface NotificationProps {
  /**
   * How long the notification shows up in ms.
   */
  autoHideDuration?: number;
}

export const Notification: FC<NotificationProps> = ({
  autoHideDuration = NOTIFICATION_DURATION,
}) => {
  const [notification, setNotification] = useState<NotificationModel | null>(null);
  const [open, setOpen] = useState(false);

  const timeout = useRef<number | null>(null);
  const prevNotification = usePrevious(notification);

  useEffect(() => {
    const unsubscribe = notificationEventEmitter.subscribe(
      NotificationEvents.SHOW_NOTIFICATION,
      (data) => {
        setNotification(data);
        setOpen(true);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const close = useCallback(() => {
    setOpen(false);
    clearTime();
  }, []);

  const onClose = useCallback(
    (event: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      close();
    },
    [close]
  );

  const startTimeout = useCallback(() => {
    timeout.current = setTimeout(onClose, autoHideDuration);
  }, [autoHideDuration, onClose]);

  const show = useCallback(() => {
    setOpen(true);
    startTimeout();
  }, [startTimeout]);

  const clearTime = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  const restartTimeout = useCallback(() => {
    clearTime();
    startTimeout();
  }, [startTimeout]);

  const onMouseEnter = () => {
    clearTime();
  };

  const onMouseLeave = () => {
    startTimeout();
  };

  const onRemoveNotification = () => {
    setNotification(null);
  };

  useEffect(() => {
    if (!prevNotification && notification) {
      show();
    }

    if (
      prevNotification &&
      notification &&
      prevNotification.content !== notification.content &&
      timeout
    ) {
      restartTimeout();
    }

    return () => {
      clearTimeout();
    };
  }, [notification, prevNotification, restartTimeout, show]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        TransitionProps={{ onExited: onRemoveNotification }}
        onClose={onClose}
        TransitionComponent={Slide}
      >
        {notification ? (
          <SnackbarContent
            variant={notification.type}
            message={notification.content}
            onClose={onClose}
          />
        ) : undefined}
      </Snackbar>
    </>
  );
};

export default Notification;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Box, Card, Divider, Grid, Link, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

import { MeterReadingResultModel, ReadingUnitOrderModel } from 'models';
import { BaseDialogProps, Button, Dialog, LoadingOverlay, Tooltip } from 'components';
import { neutral, neutralVariant, primary } from 'theme/colors';
import { useReadingUnitOrderDetails } from 'features/readingUnits';
import { useValueSetList } from 'features/valueSets';
import { formatDate } from 'utils/dates';
import { formatAddress } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';

import { ReactComponent as OrderDetails } from 'assets/icons/Titasz/OrderDetails.svg';

export interface ReadingOrderDetailsProps extends BaseDialogProps {
  id: string;
  readingUnitOrder: ReadingUnitOrderModel | null;
  viewPhotoHandler?: any;
}

const styles = {
  root: {
    '.MuiDialog-paper': {
      backgroundColor: neutral[100],
      maxWidth: 1648,
    },
  },
  bottomPanelCell: {
    p: 1,
    display: 'flex',
    alignItems: 'center',
    borderTop: `solid 1px ${neutralVariant[200]}`,
    borderRight: `solid 1px ${neutralVariant[200]}`,
  },
  coordinates: {
    color: primary[600],
    fontWeight: 600,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: primary[100],
    width: '48px',
    height: '48px',
  },
  orderCard: {
    px: 2.5,
    py: 3,
    my: 2,
    mr: 2,
    overflow: 'auto',
  },
  firstColumn: {
    p: 2,
    my: 2,
    mr: 2,
    minHeight: 525,
    maxHeight: 525,
    overflow: 'auto',
  },
  secondColumn: {
    p: 2,
    my: 2,
    mr: 2,
    minHeight: 650,
    maxHeight: 650,
    overflow: 'auto',
  },
  thirdColumn: {
    p: 2,
    my: 2,
    minHeight: 650,
    maxHeight: 650,
    overflow: 'auto',
  },
  bottomCard: {
    mb: 1,
  },
  commentBox: {
    pt: 0.5,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  emergencyCommentBox: {
    pt: 0.5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  photoContainer: {
    paddingTop: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const ReadingOrderDetailsDialog: FC<ReadingOrderDetailsProps> = ({
  id,
  readingUnitOrder,
  viewPhotoHandler,
  ...props
}) => {
  const { data: order, isLoading } = useReadingUnitOrderDetails(id, readingUnitOrder?.orderId);
  const { data: valueSet } = useValueSetList();

  const getLatestReading = (results?: MeterReadingResultModel[]) => {
    return results?.sort(
      (a, b) => Number(new Date(b.readingResultDate)) - Number(new Date(a.readingResultDate))
    )[0];
  };

  return (
    <Dialog
      sx={styles.root}
      title={
        <Typography variant="h6" component="span">
          <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.TITLE_WITH_ID" values={{ id }} />
        </Typography>
      }
      {...props}
    >
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <Grid container>
            <Grid item sm={3.5}>
              <Card elevation={0} sx={styles.orderCard}>
                <Grid container>
                  <Grid item sm={2} display="flex" alignItems="center">
                    <Box sx={styles.icon}>
                      <SvgIcon component={OrderDetails} />
                    </Box>
                  </Grid>
                  <Grid item sm={10}>
                    <Typography variant="subtitle1">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.ORDER_ID" />
                    </Typography>
                    <Typography variant="h6">{order?.orderId}</Typography>
                  </Grid>
                </Grid>
              </Card>
              <Card elevation={0} sx={styles.firstColumn}>
                <Grid container>
                  <Grid item sm={12}>
                    <Typography variant="subtitle2">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.DEVICE_DATA_TITLE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={12} py={1}>
                    <Divider />
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.DEVICE_SERIAL" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.device?.serialNumber}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.DEVICE_TYPE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.device?.type}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.MATERIAL_NUMBER" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.device?.materialNumber}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CERT_DATE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.device?.certificationYear}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.MANUFACTURE_DATE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.device?.manufacturedYear}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.DIGIT_NUMBER" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.meter?.numberOfDigits}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PLACEMENT" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="cellName">{order?.device?.placement}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item sm={5}>
              <Card elevation={0} sx={styles.secondColumn}>
                <Grid container>
                  <Grid item sm={12}>
                    <Typography variant="subtitle2">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CONSUMPTION_PLACE_TITLE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={12} py={1}>
                    <Divider />
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.COMPANY" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.consumptionPlace?.company}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CP_ID" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">
                      {order?.consumptionPlace?.sapConsumptionPlaceId}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CP_NAME" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.consumptionPlace?.name}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.GPS_LATITUDE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Link
                      variant="cellName"
                      underline="none"
                      fontWeight="bold"
                      target="_blank"
                      href={`https://maps.google.com?q=${order?.consumptionPlace.latitude},${order?.consumptionPlace.longitude}`}
                    >
                      {order?.consumptionPlace?.latitude}
                    </Link>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.GPS_LONGITUDE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Link
                      variant="cellName"
                      underline="none"
                      fontWeight="bold"
                      target="_blank"
                      href={`https://maps.google.com?q=${order?.consumptionPlace.latitude},${order?.consumptionPlace.longitude}`}
                    >
                      {order?.consumptionPlace?.longitude}
                    </Link>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CP_TYPE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">
                      {order?.consumptionPlace?.type &&
                        getValueSetValues(valueSet, 'FHF', order?.consumptionPlace.type)}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CP_COMMENT" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">
                      <Tooltip title={order?.comment}>
                        <Box sx={styles.commentBox}>{order?.comment}</Box>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.BINDING_ID" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.device?.bindingId}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PARTNER_NAME" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.partner?.name}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.READING_CENTER" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.readingUnit?.readingCenter}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.REPORT" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">
                      {order?.device?.reports?.map((report, i) => {
                        return (
                          <Tooltip key={report?.id} title={report?.subgroup?.value}>
                            <>{i !== 0 ? `, ${report?.subgroup?.key}` : report?.subgroup?.key}</>
                          </Tooltip>
                        );
                      })}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CP_ADDRESS" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{formatAddress(order?.address)}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PARTNER_ID" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.partner?.sapId}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PARTNER_PHONE_NUMBERS" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">
                      {order?.partner?.phoneNumbers?.map((number, i) =>
                        i !== 0 ? `, ${number.phone}` : number.phone
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CLASS" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">
                      {order?.consumptionPlace?.classification}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.READING_TYPE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">{order?.meter?.readingType}</Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.EMERGENCY_COMMENT" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} mb={1}>
                    <Typography variant="cellName">
                      <Tooltip title={order?.emergencyComment}>
                        <Box sx={styles.emergencyCommentBox}>{order?.emergencyComment}</Box>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.EMERGENCY_CODE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    {order?.consumptionPlace?.emergencyCodeStatistics &&
                      order?.consumptionPlace?.emergencyCodeStatistics.map((code) => (
                        <Box key={code.id} mb={1}>
                          <Typography variant="cellName">
                            {getValueSetValues(valueSet, 'VES', code.code)}
                          </Typography>
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item sm={3.5}>
              <Card elevation={0} sx={styles.thirdColumn}>
                <Grid container>
                  <Grid item sm={12}>
                    <Typography variant="subtitle2">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.METER_TITLE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={12} py={1}>
                    <Divider />
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.UPPER_BOUND" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">{order?.meter?.upperBound}</Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.LOWER_BOUND" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">{order?.meter?.lowerBound}</Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.ESTIMATED_VALUE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">{order?.meter?.estimatedValue}</Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.METER_SEQUENCE_NUMBER" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">{order?.meter?.sequenceNumber}</Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.METER_CODE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">{order?.meter?.code}</Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.READING_NUMBER" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">
                      {getLatestReading(order?.meter?.results)?.readingResult}
                    </Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.TARIFF_TYPE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">
                      {order?.consumptionPlace?.tariffType}
                    </Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.TARIFF_KIND" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">{order?.meter?.tariffKind}</Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.FAILURE_REASON" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">
                      <Tooltip
                        title={
                          getLatestReading(order?.meter.results)?.reason?.key &&
                          getValueSetValues(
                            valueSet,
                            'NLO',
                            getLatestReading(order?.meter.results)?.reason?.key
                          )
                        }
                      >
                        <>{getLatestReading(order?.meter.results)?.reason?.key}</>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.READING_DATE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">
                      {formatDate(getLatestReading(order?.meter?.results)?.readingResultDate)}
                    </Typography>
                  </Grid>
                  <Grid item sm={8} mb={1}>
                    <Typography variant="cellHeaderLarge">
                      <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.READING_MODE" />
                    </Typography>
                  </Grid>
                  <Grid item sm={4} mb={1}>
                    <Typography variant="cellName">
                      <Tooltip
                        title={
                          getLatestReading(order?.meter.results)?.readingMode &&
                          getValueSetValues(
                            valueSet,
                            'LM',
                            getLatestReading(order?.meter.results)?.readingMode
                          )
                        }
                      >
                        <>{getLatestReading(order?.meter?.results)?.readingMode}</>
                      </Tooltip>
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Card elevation={1} sx={styles.bottomCard}>
            <Grid container p={1}>
              <Typography variant="subtitle2">
                <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.READING_UNIT_TITLE" />
              </Typography>
            </Grid>
            <Grid container>
              <Grid item sm={1.6} sx={styles.bottomPanelCell}>
                <Typography variant="cellHeaderLarge" pr={1.5}>
                  <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.READING_UNIT_ID" />
                </Typography>
                <Typography variant="cellName">{order?.readingUnit?.readingUnitCode}</Typography>
              </Grid>
              <Grid item sm={1} sx={styles.bottomPanelCell}>
                <Typography variant="cellHeaderLarge" pr={1.5}>
                  <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.BUNDLE" />
                </Typography>
                <Typography variant="cellName">{order?.readingUnit?.bundle}</Typography>
              </Grid>
              <Grid item sm={1} sx={styles.bottomPanelCell}>
                <Typography variant="cellHeaderLarge" pr={1.5}>
                  <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.MAR_NUMBER" />
                </Typography>
                <Typography variant="cellName">{order?.consumptionPlace?.readingTime}</Typography>
              </Grid>
              <Grid item sm={2.1} sx={styles.bottomPanelCell}>
                <Typography variant="cellHeaderLarge" pr={1.5}>
                  <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PLANNED_READING_DATE" />
                </Typography>
                <Typography variant="cellName">
                  {formatDate(order?.readingUnit?.plannedReadingDate)}
                </Typography>
              </Grid>
              <Grid item sm={2.1} sx={styles.bottomPanelCell}>
                <Typography variant="cellHeaderLarge" pr={1.5}>
                  <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.CURRENT_ESTIMATION_DATE" />
                </Typography>
                <Typography variant="cellName">
                  {formatDate(order?.readingUnit?.currentEstimationDate)}
                </Typography>
              </Grid>
              <Grid item sm={2.1} sx={styles.bottomPanelCell}>
                <Typography variant="cellHeaderLarge" pr={1.5}>
                  <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PREVIOUS_ESTIMATION_DATE" />
                </Typography>
                <Typography variant="cellName">
                  {formatDate(order?.readingUnit?.previousEstimationDate)}
                </Typography>
              </Grid>
              <Grid item sm={2.1} sx={styles.bottomPanelCell}>
                <Typography variant="cellHeaderLarge" pr={1.5}>
                  <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PLANNED_ESTIMATION_DATE" />
                </Typography>
                <Typography variant="cellName">
                  {formatDate(order?.readingUnit?.plannedEstimationDate)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Box sx={styles.photoContainer}>
            <Button onClick={() => viewPhotoHandler(order)}>
              <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PHOTO_BUTTON" />
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default ReadingOrderDetailsDialog;

import React from 'react';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import clone from 'lodash/clone';

import { Box, Chip, Tooltip, Typography } from '@mui/material';

import {
  FilterOptionModel,
  ListResponseModel,
  PanelConfigModel,
  PerformanceMetricModel,
  QueryParamModel,
  UserModel,
  WorkerViewModel,
  AreaModel,
} from 'models';
import { Empty } from 'components/input-fields/leoSelectField/common';
import { typeAccessor } from 'config/typeRecord';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { parse, stringify } from 'utils/base64';
import { RouteNames } from 'config/routeNames';
import { pageNameIs } from 'utils/format';
import { formatDate } from 'utils/dates';

import { showReqFilterOptNotification } from './FilterContainer';

const styles = {
  chipTypography: {
    maxWidth: 390,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  chip: {
    '.MuiChip-label': {
      p: 0,
      mx: 1.5,
    },
  },
  chipItem: {
    pl: 1,
    pb: 1,
  },
};

interface Relative {
  value: number;
  unit: string;
}

type DateObjSubtype = undefined | string | Relative;

interface DateObj {
  from: DateObjSubtype;
  to: DateObjSubtype;
}

type FilterObjSubtype = string | string[] | DateObj;

interface FilterObj {
  [index: string]: FilterObjSubtype;
}

enum Mark {
  HYPHEN = '-',
  COLON = ':',
  DOT = '.',
}

enum Value {
  CHECKBOX = 'on',
}

type ValueSet = Record<string | number, string> | undefined;

export interface FilterTypes {
  performanceMetricData?: ListResponseModel<PerformanceMetricModel>;
  useWorkersData?: WorkerViewModel[];
  usersData?: UserModel[];
  areaData?: AreaModel[];
  performanceTariffsReportAccessors?: ValueSet;
  readingInterfaceLogAccessor?: ValueSet;
  backgroundJobStatusAccessor?: ValueSet;
  opemEventLogTypeAccessor?: ValueSet;
  leoEgysStatusAccessor?: ValueSet;
  smOrderStatusAccessor?: ValueSet;
  eventLogTypeAccessor?: ValueSet;
  leoKozpontAccessor?: ValueSet;
  taskStatusAccessor?: ValueSet;
  leoRegioAccessor?: ValueSet;
  tbsAccessor?: ValueSet;
  nloAccessor?: ValueSet;
  vesAccessor?: ValueSet;
  fotAccessor?: ValueSet;
  crdAccessor?: ValueSet;
  loAccessor?: ValueSet;
}

export const FilterDetails = ({
  panels,
  onLoadQuery,
  filterDetailsData,
}: {
  panels?: (PanelConfigModel | null)[];
  onLoadQuery?: (query?: Partial<QueryParamModel> | null | undefined) => QueryParamModel;
  filterDetailsData?: FilterTypes;
}) => {
  const {
    performanceTariffsReportAccessors,
    readingInterfaceLogAccessor,
    backgroundJobStatusAccessor,
    opemEventLogTypeAccessor,
    performanceMetricData,
    leoEgysStatusAccessor,
    smOrderStatusAccessor,
    eventLogTypeAccessor,
    leoKozpontAccessor,
    taskStatusAccessor,
    leoRegioAccessor,
    useWorkersData,
    tbsAccessor,
    nloAccessor,
    loAccessor,
    vesAccessor,
    crdAccessor,
    fotAccessor,
    usersData,
    areaData,
  } = filterDetailsData || {};

  const query = (panels && panels[0]?.params) || undefined;
  const filters = panels && panels[0]?.filters;
  const pageName = panels && panels[0]?.pageName;
  const parsedQueryFilter: FilterObj | undefined = parse(query?.filter);

  const { accessor: userAccessor } = useFieldDataAndAccessor(usersData, 'id', 'name');

  const { accessor: areaAccessor } = useFieldDataAndAccessor(areaData, 'id', 'area');

  const { accessor: wageItemsAccessor } = useFieldDataAndAccessor(
    performanceMetricData?.data,
    'id',
    'value',
    false,
    '',
    true
  );

  const getType = (filterValue: FilterObjSubtype) => {
    let value: string[] | undefined = [];

    if (
      pageNameIs(pageName, RouteNames.LEO_NEM_LEOLVASHATOSAGI_OKOK) ||
      pageNameIs(pageName, RouteNames.LEO_LEOLVASOTTSAG)
    ) {
      return crdAccessor ? crdAccessor[filterValue as string] : '';
    }
    if (pageNameIs(pageName, RouteNames.KOZOS_MUVELETI_NAPLO)) {
      value = (filterValue as string[]).map((item) =>
        opemEventLogTypeAccessor ? opemEventLogTypeAccessor[`${item}`] : ''
      );
    }
    if (pageNameIs(pageName, RouteNames.KOZOS_TIME_LOG)) {
      value = (filterValue as string[]).map((item) => typeAccessor[`${item}`]);
    }

    return addSpace(value);
  };

  const cutDayFromDate = (filterValue: FilterObjSubtype) =>
    `${DateTime.fromISO(filterValue as string).toFormat('yyyy-MM')}`;

  const getStatus = (filterValue: FilterObjSubtype) => {
    let value: string | undefined = '';

    if (
      pageNameIs(pageName, RouteNames.KOZOS_FELADAT_BETOLTES_EXCELBOL) ||
      pageNameIs(pageName, RouteNames.KOZOS_RENDELES_AZONOSITOK_CSEREJE) ||
      pageNameIs(pageName, RouteNames.KOZOS_FELADAT_IMPORT) ||
      pageNameIs(pageName, RouteNames.KOZOS_SAP_FELTOLTES_MANUALIS)
    ) {
      value = backgroundJobStatusAccessor
        ? `${backgroundJobStatusAccessor[filterValue as string]}`
        : '';
    }
    if (pageNameIs(pageName, RouteNames.UCSE_RENDELESEK)) {
      value = smOrderStatusAccessor ? `${smOrderStatusAccessor[filterValue as string]}` : '';
    }
    if (pageNameIs(pageName, RouteNames.LEO_LEO_EGYSEGEK)) {
      value = leoEgysStatusAccessor ? `${leoEgysStatusAccessor[filterValue as string]}` : '';
    }
    if (pageNameIs(pageName, RouteNames.KOZOS_TELJESITMENYBEREK)) {
      if (Array.isArray(filterValue)) {
        value = `${addSpace(filterValue.map((item) => (tbsAccessor ? tbsAccessor[item] : '')))}`;
      }
    }

    return value;
  };

  const getArea = (filterValue: any) => {
    if (pageNameIs(pageName, RouteNames.UCSE_IDOPONTFOGLALAS)) {
      return filterValue;
    }

    return `${addSpace(
      (filterValue as string[]).map((item) => (+item ? areaAccessor[item] : item))
    )}`;
  };
  const getFilterText = (
    parsedQueryFilterParam: FilterObj,
    filterKey: string,
    filterOptions: FilterOptionModel[] | undefined
  ) => {
    let value = '';

    const filterValue = parsedQueryFilterParam[`${filterKey}`];

    const isCheckBox = isArray(filterValue)
      ? filterValue.find((item) => item === Value.CHECKBOX)
      : false;
    const filterItem = filterOptions?.find(
      (item) => filterKey === item.name || item.name.includes(filterKey)
    );
    const hasAbbrProperty = Object.prototype.hasOwnProperty.call(filterItem, 'abbrName');
    const hyphen = (filterItem?.abbrName && !isCheckBox && `${Mark.COLON} `) || '';
    const prefix = (hasAbbrProperty && filterItem?.abbrName) || '';

    if (isObject(filterValue)) {
      const hasFromProperty = Object.prototype.hasOwnProperty.call(filterValue, 'from');
      const hasToProperty = Object.prototype.hasOwnProperty.call(filterValue, 'to');

      if (hasFromProperty || hasToProperty) {
        return getFormattedFilterObject(filterValue as DateObj, prefix, filterKey);
      }
    }

    switch (filterKey) {
      case 'withDeleted':
      case 'isRescheduled':
      case 'morning':
      case 'afternoon':
      case 'evening':
        value = ' ';
        break;
      case 'startDate':
        value = formatDate(filterValue as string);
        break;
      case 'region':
        value = leoRegioAccessor ? `${leoRegioAccessor[filterValue as string]}` : '';
        break;
      case 'readingCenter':
        value = leoKozpontAccessor ? `${leoKozpontAccessor[filterValue as string]}` : '';
        break;
      case 'direction':
        value = readingInterfaceLogAccessor
          ? `${readingInterfaceLogAccessor[filterValue as string]}`
          : '';
        break;
      case 'photoNotTakenReason':
        value = fotAccessor ? `${fotAccessor[filterValue as string]}` : '';
        break;
      case 'taskStatus':
        value = taskStatusAccessor ? `${taskStatusAccessor[filterValue as string]}` : '';
        break;
      case 'status':
        value = getStatus(filterValue);
        break;
      case 'code':
        value = vesAccessor
          ? `${addSpace((filterValue as string[]).map((item) => vesAccessor[item]))}`
          : '';
        break;
      case 'area':
        value = getArea(filterValue);
        break;
      case 'user':
        value = `${addSpace((filterValue as string[]).map((item) => userAccessor[item]))}`;
        break;
      case 'workerId':
      case 'worker':
        value = getWorker(filterValue, useWorkersData);
        break;
      case 'mba':
      case 'sapId':
        value = addSpace(filterValue);
        break;
      case 'eventType':
        value = eventLogTypeAccessor ? `${eventLogTypeAccessor[filterValue as string]}` : '';
        break;
      case 'cyclicOrderType':
        value = crdAccessor ? `${crdAccessor[filterValue as string]}` : '';
        break;
      case 'type':
        value = getType(filterValue);
        break;
      case 'performanceMetricIds':
      case 'readingFailureReasonId':
        value = `${addSpace((filterValue as string[]).map((item) => wageItemsAccessor[item]))}`;
        break;
      case 'readingFailureReasonIds':
        value = `${addSpace(
          (filterValue as string[]).map((item) => (nloAccessor ? nloAccessor[item] : ''))
        )}`;
        break;
      case 'reportValueSetSubgroupIds':
        value = `${addSpace(
          (filterValue as string[]).map((item) =>
            performanceTariffsReportAccessors ? performanceTariffsReportAccessors[item] : ''
          )
        )}`;
        break;
      case 'date':
      case 'validFrom':
        value = cutDayFromDate(filterValue);
        break;
      case 'readingReason':
        value = `${addSpace(
          (filterValue as string[]).map((item) => (loAccessor ? loAccessor[item] : ''))
        )}`;
        break;
      default: {
        value = `${filterValue}`;
      }
    }

    return `${prefix}${hyphen}${value}`;
  };

  const chipDeleteClickHandler = (
    parsedQueryFilterParam: FilterObj,
    item: string,
    queryParam: QueryParamModel | undefined
  ) => {
    if (item) {
      const newQueryFilter = clone(parsedQueryFilterParam);
      delete newQueryFilter[`${item}`];

      const newParam = {
        ...queryParam,
        filter: stringify(newQueryFilter),
      };

      if (onLoadQuery) {
        const filterList =
          panels && panels[0]?.filters?.filter((filterItem) => filterItem.name === item);
        onLoadQuery(newParam);
        showReqFilterOptNotification(filterList, t);
      }
    }
  };

  return (
    <>
      {parsedQueryFilter &&
        Object.keys(parsedQueryFilter).map((filterItem) => {
          const filterText = getFilterText(parsedQueryFilter, filterItem, filters);

          if (
            !filterText ||
            (filterItem === 'date' && pageNameIs(pageName, RouteNames.UCSE_IDOPONTFOGLALAS))
          ) {
            return undefined;
          }
          return (
            <Box key={filterItem} sx={styles.chipItem}>
              <Chip
                sx={styles.chip}
                color="primary"
                onDelete={() => chipDeleteClickHandler(parsedQueryFilter, filterItem, query)}
                label={
                  <Tooltip title={filterText}>
                    <Typography variant="subtitle2" sx={styles.chipTypography}>
                      {filterText}
                    </Typography>
                  </Tooltip>
                }
              />
            </Box>
          );
        })}
    </>
  );
};

function getWorker(filterValue: FilterObjSubtype, workersData?: WorkerViewModel[]): string {
  if ((filterValue as string[])[0] === Empty.VALUE) return t('COMMON.BLANK');

  return addSpace(filterValue, workersData);
}

function addSpace(filterValue: FilterObjSubtype, workersData?: WorkerViewModel[]): string {
  if (isArray(filterValue)) {
    return filterValue.map((item, index) => `${index ? ' ' : ''}${item}`).toString();
  }

  const result = workersData?.filter((item) => item.id.toString() === filterValue.toString());

  if (result && result[0].sapId) return result[0].sapId;

  return '';
}

function getFormattedFilterObject(filterValue: DateObj, prefix: string, filterKey: string) {
  const { from, to } = filterValue;
  const toVal = getVal(to);
  const fromVal = getVal(from);
  const dateHyphen = ` ${Mark.HYPHEN} `;
  const prefixHyphen = prefix ? `${Mark.COLON} ` : '';

  if (filterKey === 'bundle') prefix = `${t('INPUT.BATCH')}: `;

  return `${prefix}${prefixHyphen}${getFormattedDatePart(
    fromVal
  )}${dateHyphen}${getFormattedDatePart(toVal)}`;
}

function getVal(way: string | Relative | undefined = '') {
  if (isObject(way)) {
    return `${!way.value ? t('COMMON.TODAY') : way.value.toString()} ${t('COMMON.DAY')}`;
  }
  return way.replaceAll(Mark.HYPHEN, Mark.DOT);
}

function getFormattedDatePart(wayVal: string | number | undefined) {
  return `${wayVal && wayVal.toString()}`;
}

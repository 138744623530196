import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, ReadingUnitOrderWithFailureReasonModel } from 'models';
import { RouteNames } from 'config/routeNames';
import { formatDate } from 'utils/dates';
import { formatAddress, getPageName } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import { useExportUnreadableOrders, useUnreadableOrderList } from 'features/unreadableOrders';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { plannedReadingDateValidationSchema } from 'pages/Leo/Monitoring/ReadingResults/ReadingResultStatisticsPage';

const UnreadableOrdersPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: unreadableOrders,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useUnreadableOrderList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportUnreadableOrders(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOKOZPONT', 'NLO'],
  });
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });

  const { activePanel, closePanel, setPanel } = usePanelState();

  const { data: leoKozpontData, accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      name: 'plannedReadingDate',
      headerTitle: 'LEO_LEO_EGYSEGEK.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
    },
    {
      name: 'worker',
      headerTitle: 'LEO_LEO_EGYSEGEK.WORKER',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="LEO_LEO_EGYSEGEK.WORKER"
          freeSolo
          maxLength={5}
        />
      ),
      divider: true,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
    },
    {
      name: 'bundle.from',
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.BUNDLE',
      component: (
        <LeoTextField
          name="bundle.from"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.FROM" />}
          maxLength={10}
        />
      ),
      panelFieldWidth: 6,
    },
    {
      name: 'bundle.to',
      component: (
        <LeoTextField
          name="bundle.to"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.TO" />}
          maxLength={10}
        />
      ),
      panelFieldWidth: 6,
      divider: true,
    },
    {
      name: 'readingCenter',
      headerTitle: 'LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenter"
          data={leoKozpontData}
          label="LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER"
        />
      ),
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
  ];

  const unreadableOrdersFilterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_NEM_LEOLVASHATO_RENDELESEK),
    validationSchema: plannedReadingDateValidationSchema,
    isFetching,
  });

  const panels = [unreadableOrdersFilterConfig];

  const columns = [
    {
      key: 'readingUnit.bundle',
      header: <Trans i18nKey="NOTIFY_LOGS.BUNDLE" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.readingUnit?.bundle,
      field: 'readingUnit.bundle',
      sortable: true,
    },
    {
      key: 'readingUnit.plannedReadingDate',
      header: <Trans i18nKey="NOTIFY_LOGS.PLANNED_READING_DATE" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) =>
        formatDate(order.readingUnit?.plannedReadingDate),
      field: 'readingUnit.plannedReadingDate',
      sortable: true,
    },
    {
      key: 'readingUnit.readingUnitCode',
      header: <Trans i18nKey="NOTIFY_LOGS.READING_UNIT_CODE" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) =>
        order.readingUnit?.readingUnitCode,
      field: 'readingUnit.readingUnitCode',
      sortable: true,
    },
    {
      key: 'readingUnit.readingCenter',
      header: <Trans i18nKey="NOTIFY_LOGS.READING_CENTER" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.readingUnit?.readingCenter,
      field: 'readingUnit.readingCenter',
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="NOTIFY_LOGS.WORKER_ID" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.worker?.sapId,
      field: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'partner.sapId',
      header: <Trans i18nKey="NOTIFY_LOGS.PARTNER" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.partner?.sapId,
      field: 'partner.sapId',
      sortable: true,
    },
    {
      key: 'partner.name',
      header: <Trans i18nKey="NOTIFY_LOGS.NAME" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.partner?.name,
      field: 'partner.name',
      sortable: true,
    },
    {
      key: 'consumptionPlace.address.city',
      header: <Trans i18nKey="NOTIFY_LOGS.ADDRESS" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) =>
        formatAddress(order.consumptionPlace?.address),
      field: 'consumptionPlace.address.city',
      sortable: true,
    },
    {
      key: 'device.bindingId',
      header: <Trans i18nKey="NOTIFY_LOGS.BINDING_ID" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.device?.bindingId,
      field: 'device.bindingId',
      sortable: true,
    },
    {
      key: 'device.serialNumber',
      header: <Trans i18nKey="NOTIFY_LOGS.SERIAL_NUMBER" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.device?.serialNumber,
      field: 'device.serialNumber',
      sortable: true,
    },
    {
      key: 'meter.readingOrderId',
      header: <Trans i18nKey="NOTIFY_LOGS.ORDER_ID" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.meter?.readingOrderId,
      field: 'meter.readingOrderId',
      sortable: true,
    },
    {
      key: 'readingFailureReason.key',
      header: <Trans i18nKey="LEO_LEO_EGYSEG_RESZLETEK.FAILURE_REASON" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) => order.readingFailureReason?.key,
      tooltip: (order: ReadingUnitOrderWithFailureReasonModel) =>
        order.readingFailureReason?.key &&
        getValueSetValues(valueSet, 'NLO', order.readingFailureReason.key),
      field: 'readingFailureReason.key',
      sortable: true,
    },
    {
      key: 'readingFailureReasonComment',
      header: <Trans i18nKey="COMMON.COMMENT" />,
      accessor: 'readingFailureReasonComment',
      sortable: true,
    },
    {
      key: 'readingResultDate',
      header: <Trans i18nKey="NOTIFY_LOGS.READING_RESULT_DATE" />,
      accessor: (order: ReadingUnitOrderWithFailureReasonModel) =>
        formatDate(order.readingResultDate),
      field: 'readingResultDate',
      sortable: true,
    },
  ];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_NEM_LEOLVASHATO_RENDELESEK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ leoKozpontAccessor }}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={unreadableOrders?.data || []}
          total={unreadableOrders?.meta.total}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default UnreadableOrdersPage;

import { createTheme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createTypography';

import { error, neutral, neutralVariant, primary, secondary, success, warning } from 'theme/colors';
import {
  Montserrat,
  MontserratBold,
  MontserratSemiBold,
  RobotoCondensed,
  RobotoCondensedBold,
} from './fonts';

const defaultTheme = createTheme();

const cellPrimary: CSSProperties = {
  fontFamily: "'Roboto Condensed', 'sans-serif'",
  fontWeight: 600,
  fontSize: 12,
};

const cellName: CSSProperties = {
  fontFamily: "'Roboto Condensed', 'sans-serif'",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '16px',
};

const theme = createTheme({
  mixins: {
    sideBar: {
      width: 300,
    },
    closedSideBar: {
      width: 56,
    },
    panel: {
      width: 493,
    },
    tableContainer: {
      '.MuiTablePagination-select': {
        backgroundColor: neutral[200],
        borderRadius: 1,
      },
      '.MuiTableCell-root': {
        p: 1,
        lineHeight: 0,
        whiteSpace: 'normal',
      },
      '.MuiTableCell-root-checkboxData': {
        p: 1,
        lineHeight: 0,
        whiteSpace: 'nowrap',
      },
      '.MuiTableCell-root-noPadding': { padding: 0 },
      '.MuiTableSortLabel-icon': {
        m: 0,
        p: '1px',
      },
      '.MuiTableCell-body': {
        pr: 2,
      },
      '.MuiTableCell-body-noPadding': {
        pr: 0,
        borderRight: `1px solid ${neutral[100]}`,
      },
      '.MuiTableCell-body-center': {
        pr: 1,
        pl: 1,
      },
      '.MuiTableCell-body-center-square': {
        pr: 2,
        pl: 2,
      },
      '.MuiTableCell-paddingCheckbox': {
        p: 1,
        px: 0,
      },
      '@media(-webkit-min-device-pixel-ratio: 1.25)': {
        '.MuiTypography-root': {
          fontSize: 10,
        },
      },
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      mobileBreakpoint: 900,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: defaultTheme.spacing(2),
        },
        colorPrimary: {
          backgroundColor: secondary[100],
          color: primary[600],
          '&.MuiChip-clickable:hover': {
            backgroundColor: secondary[500],
          },
        },
        colorSecondary: {
          backgroundColor: 'transparent',
          border: 1,
          borderStyle: 'solid',
          borderColor: secondary[100],
          color: primary[600],
          '&.MuiChip-clickable:hover': {
            backgroundColor: secondary[500],
          },
        },
        deleteIconColorPrimary: {
          color: primary[600],
        },
        deleteIconMedium: {
          fontSize: 15,
          marginRight: 8.5,
        },
        deleteIcon: {
          color: 'unset',
          '&:hover': {
            color: 'unset',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          padding: [10, 8, 10, 8],
          borderRadius: 4,
          letterSpacing: 1.25,
        },
        containedPrimary: {
          '&.MuiButton-root:hover': {
            backgroundColor: primary[500],
          },
        },
        containedSecondary: {
          '&.MuiButton-root:hover': {
            backgroundColor: secondary[500],
          },
        },
        containedSuccess: {
          '&.MuiButton-root:hover': {
            backgroundColor: success[500],
          },
        },
        containedError: {
          '&.MuiButton-root:hover': {
            backgroundColor: error[500],
          },
        },
        containedWarning: {
          '&.MuiButton-root:hover': {
            backgroundColor: warning[200],
          },
        },
        outlined: {
          '&.Mui-disabled': {
            border: `1px solid ${defaultTheme.palette.action.disabledBackground}`,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: neutralVariant[100],
          fontFamily: `${Montserrat}
          ${MontserratSemiBold}
          ${MontserratBold}
          ${RobotoCondensed}
          ${RobotoCondensedBold}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: defaultTheme.spacing(3),
          paddingRight: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(2),
          paddingTop: defaultTheme.spacing(0),
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: cellPrimary,
        selectRoot: { ...cellName, '.MuiSelect-select.MuiSelect-select': { minHeight: 'auto' } },
        menuItem: cellName,
        displayedRows: cellPrimary,
        actions: { color: neutral[600] },
        toolbar: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(1),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          backgroundColor: defaultTheme.palette.common.white,
          paddingLeft: `${defaultTheme.spacing(1)}px!important`,
          paddingRight: `${defaultTheme.spacing(1)}px!important`,
        },
        formControl: {
          '& + .MuiInput-root': {
            marginTop: defaultTheme.spacing(2),
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: primary[600],
      primary: primary[600],
      onPrimary: primary[50],
      primaryContainer: primary[100],
      onPrimaryContainer: primary[900],
      primaryButtonBase: primary[600],
      primaryButtonHover: primary[500],
      primaryButtonFocused: primary[300],
    },
    secondary: {
      main: secondary[600],
      contrastText: secondary[50],
      secondary: secondary[600],
      onSecondary: secondary[50],
      secondaryContainer: secondary[400],
      onSecondaryContainer: secondary[900],
      secondaryButtonBase: secondary[600],
      secondaryButtonHover: secondary[500],
      secondaryButtonFocused: secondary[300],
    },
    background: {
      default: neutral[50],
    },
    error: {
      main: error[600],
      error: error[600],
      onError: error[50],
      errorContainer: error[100],
      onErrorContainer: error[900],
    },
    warning: {
      main: warning[300],
      contrastText: warning[50],
      warning: warning[300],
      onWarning: warning[900],
      warningContainer: warning[100],
      onWarningContainer: warning[800],
    },
    success: {
      main: success[600],
      success: success[600],
      onSuccess: success[50],
      successContainer: success[200],
      onSuccessContainer: success[900],
    },
    neutral: {
      secondaryTypeColor: neutral[600],
      inverseOnSurface: neutral[200],
      surface: neutral[50],
      onSurface: neutral[900],
    },
    neutralVariant: {
      surfaceVariant: neutralVariant[100],
      onSurfaceVariant: neutralVariant[700],
      outline: neutralVariant[500],
      outlineVariant: neutralVariant[200],
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Montserrat","Helvetica","Arial",sans-serif',
    fontWeightMedium: 500,
    h1: {
      fontSize: 96,
      fontWeight: 600,
    },
    h2: {
      fontSize: 60,
      fontWeight: 600,
    },
    h3: {
      fontSize: 48,
      fontWeight: 600,
    },
    h4: {
      fontSize: 34,
      fontWeight: 600,
    },
    h5: {
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: '0.18px',
    },
    h6: {
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '0.25px',
    },
    body3: {
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: '1.25px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
    cellHeaderLarge: {
      fontFamily: "'Roboto Condensed', 'sans-serif'",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
    },
    cellHeaderSmall: {
      fontFamily: "'Roboto Condensed', 'sans-serif'",
      fontWeight: 600,
      fontSize: 11,
      lineHeight: '16px',
    },
    cellPrimary: {
      fontFamily: "'Roboto Condensed', 'sans-serif'",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
    },
    cellName: {
      fontFamily: "'Roboto Condensed', 'sans-serif'",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
    },
    cellLabel: {
      fontFamily: "'Roboto Condensed', 'sans-serif'",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
    },
    cellButton: {
      fontFamily: "'Roboto Condensed', 'sans-serif'",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '16px',
    },
  },
});

// Let the theme object be available quickly during development
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(theme);
}

export default theme;

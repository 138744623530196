import React, { FC, useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';
import { Field } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import {
  BaseEditorDialogProps,
  Checkbox,
  EditorDialog,
  LeoDateInterval,
  LeoSelectFieldMulti,
  TextField,
} from 'components';
import { BulkSmOrderAvailabilityModel, BulkSmOrderAvailabilityValuesModel } from 'models';
import { useWorkerListSearch } from 'features/workers';
import { useBulkUcseAvailabilty } from 'features/ucseAvailability';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import DayPart from 'components/input-fields/dayPart/DayPart';
import { ErrorMessage } from 'components/util/ErrorMessage';

export interface UcseAvailabilityMassEditDialogProps extends BaseEditorDialogProps<any> {}

const styles = {
  mainHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 1,
  },
  headerText: {
    paddingTop: 1,
    paddingBottom: 3,
  },

  daysContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  },
  errorContainer: {
    paddingLeft: 2,
    paddingTop: '7px',
    paddingBottom: '6px',
  },
  errorContainerPlaceholder: {
    paddingTop: '14px',
    paddingBottom: '14px',
  },
  timeslotContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  dayContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '55%',
    paddingTop: 20,
    paddingBottom: 10,
  },
  notesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 20,
  },
  note: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
};

interface Slot {
  [key: string]: { value: string; label: string; quantityLabel?: string };
}

const Days: Slot = {
  MONDAY: { value: 'hetfo', label: 'H' },
  TUESDAY: { value: 'kedd', label: 'K' },
  WEDNESDAY: { value: 'szerda', label: 'SZ' },
  THURSDAY: { value: 'csutortok', label: 'CS' },
  FRIDAY: { value: 'pentek', label: 'P' },
  SATURDAY: { value: 'szombat', label: 'SZ' },
  SUNDAY: { value: 'vasarnap', label: 'V' },
};

const DaySlots: Slot = {
  MORNING: { value: 'morning', label: 'COMMON.MORNING' },
  AFTERNOON: { value: 'afternoon', label: 'COMMON.AFTERNOON' },
  EVENING: { value: 'evening', label: 'COMMON.EVENING' },
};

const UcseAvailabilityMassEditDialog: FC<UcseAvailabilityMassEditDialogProps> = ({
  selected,
  ...props
}) => {
  const [daySlots, setDaySlots] = useState<boolean[]>([]);
  const [days, setDays] = useState<boolean[]>([]);
  const [showGroupError, setShowGroupError] = useState<boolean>(false);
  const { mutateAsync: bulkSmOrderAvailabilty } = useBulkUcseAvailabilty();

  useEffect(() => {
    if (!props.open) setShowGroupError(false);
  }, [props.open]);

  const { data: workersData } = useWorkerListSearch({ withDeleted: true });

  const { data: workerHookData, accessor: workerHookAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const onSubmit = async (values?: BulkSmOrderAvailabilityValuesModel) => {
    const getFilteredArray = (array: boolean[]) =>
      array.map((day, index) => day && index + 1).filter((day) => day) as number[];

    const getCapacity = (
      daySlotsArr: boolean[],
      formikValues: BulkSmOrderAvailabilityValuesModel | undefined
    ) => {
      const resultObj: { [key: string]: number } = {};

      getFilteredArray(daySlotsArr).forEach((element) => {
        const getDb = (el: number) => {
          switch (el) {
            case 1:
              return formikValues?.morningdb ?? 0;
            case 2:
              return formikValues?.afternoondb ?? 0;
            case 3:
              return formikValues?.eveningdb ?? 0;
            default:
              return 0;
          }
        };
        resultObj[element] = +getDb(element);
      });
      return resultObj;
    };

    const data: BulkSmOrderAvailabilityModel = {
      from: values?.bulk_interval_date?.from,
      to: values?.bulk_interval_date?.to,
      workerId: values?.workerId?.map(
        (item) => workersData?.find((worker) => worker.sapId === item)?.id
      ),
      weekday: getFilteredArray(days),
      capacity: getCapacity(daySlots, values),
      comment: values?.comment,
    };

    await bulkSmOrderAvailabilty({ data });
  };

  const initVal = {
    workerId: [],
    bulk_interval_date: {
      from: '',
      to: '',
    },
    capacity: {},
    weekday: [],
    comment: undefined,
  };

  const checkboxValidation = useCallback((arr: boolean[]) => arr.includes(true), []);

  const getErrorObj = useCallback(
    (state: boolean[], label: string) => ({
      form: { touched: { error: true } } as any,
      field: { name: 'error' } as any,
      message: !showGroupError || checkboxValidation(state) ? '' : t(label),
      show: true,
    }),
    [showGroupError, checkboxValidation]
  );

  const getParamList = useCallback(
    (state: Slot) => Object.values(state).map((stateItem) => stateItem.value),
    []
  );

  const checkBoxGroupValidation = useCallback(
    (...items: string[]) => !items.find((item) => item?.length),
    []
  );

  const slotValidation = useCallback(
    (param: undefined | string[], schema: any) =>
      param && param[0] === 'on' ? schema.required(t('ERRORS.FIELD_REQUIRED')) : schema,
    []
  );

  const smOrderAvailabilitySchema = Yup.object().shape({
    workerId: Yup.array().min(1).required(),
    bulk_interval_date: Yup.object().nullable().shape({
      from: Yup.mixed().nullable().required(),
      to: Yup.mixed().nullable().required(),
    }),
    morningdb: Yup.number().min(1).max(255).when(DaySlots.MORNING.value, slotValidation),
    afternoondb: Yup.number().min(1).max(255).when(DaySlots.AFTERNOON.value, slotValidation),
    eveningdb: Yup.number().min(1).max(255).when(DaySlots.EVENING.value, slotValidation),
    daySlotsCheckboxGroup: Yup.number().when(getParamList(DaySlots), {
      is: checkBoxGroupValidation,
      then: (schema) => schema.required(),
    }),
    dayCheckboxGroup: Yup.number().when(getParamList(Days), {
      is: checkBoxGroupValidation,
      then: (schema) => schema.required(),
    }),
  });

  return (
    <EditorDialog
      submitClick={() => setShowGroupError(true)}
      submitDisabled
      submitText={'COMMON.SUBMIT'}
      buttonsPositionRight
      initialValues={{ ...initVal }}
      maxWidth="lg"
      onSubmit={onSubmit}
      validationSchema={smOrderAvailabilitySchema}
      title={
        <Box component="span" sx={styles.mainHeader}>
          <Typography variant="h6" component="span">
            <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.BULK_EDIT" />
          </Typography>
          <Typography variant="subtitle2" component="span" sx={styles.headerText}>
            <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.FILL_DATA" />
          </Typography>
        </Box>
      }
      {...props}
    >
      <Box mb={2}>
        <Typography variant="subtitle2">
          <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.WORKER_CODE" />
        </Typography>
      </Box>
      <LeoSelectFieldMulti
        width={50}
        name="workerId"
        data={workerHookData}
        accessor={workerHookAccessor}
        label={<Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.WORKER_ID" />}
      />
      <LeoDateInterval
        name="bulk_interval_date"
        width={31}
        label={<Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.DATE_INTERVAL" />}
      />
      <Divider />
      <Box style={styles.daysContainer as React.CSSProperties}>
        <Typography variant="subtitle2">
          <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.TIMESLOT" />
        </Typography>
        <Box style={styles.timeslotContainer}>
          {Object.values(DaySlots).map((slot, index) => (
            <DayPart
              key={slot.value}
              name={slot.value}
              label={<Trans i18nKey={slot.label} />}
              setCheckBox={setDaySlots}
              checkBoxIndex={index}
            />
          ))}
        </Box>
        {
          <ErrorMessage
            sx={styles.errorContainer}
            {...getErrorObj(daySlots, 'UCSE_RENDELKEZESRE_ALLAS.DAYSLOT_ERROR')}
          />
        }
      </Box>
      <Divider />
      <Box style={styles.daysContainer as React.CSSProperties}>
        <Typography variant="subtitle2">
          <Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.DAY" />
        </Typography>
        <Box style={styles.dayContainer}>
          {Object.values(Days).map((day, index) => (
            <Field
              key={day.value}
              setCheckBox={setDays}
              checkBoxIndex={index}
              component={Checkbox}
              name={day.value}
              label={day.label}
            />
          ))}
        </Box>
        {
          <ErrorMessage
            sx={styles.errorContainer}
            {...getErrorObj(days, 'UCSE_RENDELKEZESRE_ALLAS.DAY_ERROR')}
          />
        }
      </Box>
      <Divider />
      <Box style={styles.notesContainer as React.CSSProperties}>
        <Typography variant="subtitle2">
          <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.COMMENT" />
        </Typography>
        <Box style={styles.note}>
          <Field
            component={TextField}
            name="comment"
            label={<Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.NOTE_TEXT" />}
          />
        </Box>
        <Divider />
      </Box>
    </EditorDialog>
  );
};

export default UcseAvailabilityMassEditDialog;

import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { PrivateRoute } from 'components';

export const styles = {
  mainContent: (theme: Theme) => ({
    marginLeft: {
      mobileBreakpoint: `${theme.mixins.closedSideBar.width}px`,
    },
    transition: {
      mobileBreakpoint: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
  mainContentShift: (theme: Theme) => ({
    marginLeft: {
      mobileBreakpoint: `${theme.mixins.sideBar.width}px`,
    },
    transition: {
      mobileBreakpoint: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
};

export interface PageModifier {
  noAccess: boolean;
  notFound: boolean;
}

export const PrivateLayout: FC<PageModifier> = ({ noAccess, notFound }) => {
  // SideBar logic goes here if needed (check git history)
  return (
    <PrivateRoute noAccess={noAccess} notFound={notFound}>
      <Box>
        <Outlet />
      </Box>
    </PrivateRoute>
  );
};

import { JobModel } from 'models';
import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';

class ReaderDocumentService extends EntityService {
  constructor() {
    super('reader-document');
  }

  async upload(data: any) {
    const formData = new FormData();
    formData.append('file', data as File | string);

    return request<JobModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  }
}

export const readerDocumentService = new ReaderDocumentService();

import { removeFalsy } from 'utils/objects';
import { parse } from 'utils/base64';
import { QueryParamModel } from 'models';
import { pageNameIs } from 'utils/format';
import { RouteNames } from 'config/routeNames';

type UseFilterCount = (params?: QueryParamModel | null, pageName?: string) => number;

export const useFilterCount: UseFilterCount = (params, pageName) => {
  const filters = parse(params?.filter);

  if (pageNameIs(pageName, RouteNames.UCSE_IDOPONTFOGLALAS)) {
    delete filters.date;
  }

  const activeFilters = removeFalsy(filters, (filter, prop) => {
    if (Array.isArray(filter[prop]) && filter[prop].length) {
      return true;
    }

    return !Array.isArray(filter[prop]) && (filter[prop] || filter[prop] === 0);
  });

  return Object.keys(activeFilters).length;
};

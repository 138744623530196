import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { filterService } from 'services';
import { SavedFilterModel } from 'models';

const keys = {
  all: [{ scope: 'filters' }] as const,
  lists: () => [{ ...keys.all[0], entity: 'list' }] as const,
  list: (pageName: string) => [{ ...keys.lists()[0], page: pageName }] as const,
};

const fetchFilterList = async (pageName: string) => filterService.getSavedFilters(pageName);

export const useFilterList = (pageName: string) =>
  useQuery(keys.list(pageName), () => fetchFilterList(pageName));

export const useDeleteFilter = () => {
  const queryClient = useQueryClient();

  return useMutation((id: ID) => filterService.deleteFilter(id), {
    onSuccess: (data) => {
      return queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useSaveFilter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Pick<SavedFilterModel, 'filter' | 'name' | 'resource'>) =>
      filterService.saveFilter(data),
    {
      onSuccess: (data, variables, context) => {
        return queryClient.invalidateQueries({ queryKey: keys.lists() });
      },
    }
  );
};

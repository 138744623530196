import React, { FC, ChangeEvent, useRef } from 'react';
import { Trans } from 'react-i18next';

import Yup from 'utils/yup';
import { Field, Form, Formik, FormikConfig, FormikProps } from 'formik';
import { Divider, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@mui/material';

import { BaseDialogProps, Dialog, LeoDatePicker } from 'components';
import { NewPerformacePayModel } from 'models';
import { useWorkerListSearch } from 'features/workers';
import { useCreatePerformanceBasedPay } from 'features/performanceBasedPay';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';

const styles = {
  radioGroup: {
    '.MuiFormLabel-root': {
      mb: 1,
      color: 'black',
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: 'black',
    },
  },
};

const NewPerformancePayDialog: FC<BaseDialogProps> = ({ open, onClose, ...props }) => {
  const formikRef = useRef<FormikProps<NewPerformacePayModel> | null>(null);
  const { data: workersData } = useWorkerListSearch({ withDeleted: false });
  const { data: workerIds, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'id',
    'name',
    true,
    'sapId'
  );

  const { mutateAsync: createNewPerformancePay, isLoading } = useCreatePerformanceBasedPay();

  const initialValues: FormikConfig<NewPerformacePayModel>['initialValues'] = {
    workerIds: [],
    allActiveWorkers: 'every',
    date: undefined,
  };

  const validationSchema: FormikConfig<NewPerformacePayModel>['validationSchema'] =
    Yup.object().shape({
      allActiveWorkers: Yup.string().nullable().required(),
      workerIds: Yup.mixed().when('allActiveWorkers', {
        is: (value: string) => value === 'selected',
        then: Yup.array().min(1),
        otherwise: Yup.mixed().nullable(),
      }),
      date: Yup.date().nullable().required(),
    });

  const handleSubmit = async (data: NewPerformacePayModel) => {
    if (formikRef.current?.values && !isLoading) {
      await createNewPerformancePay(data);
      handleClose();
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    onClose();
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors, touched }) => {
        const onRadioGroupChange = (_e: ChangeEvent<HTMLInputElement>, value: string) => {
          setFieldValue('workerIds', []);
          setFieldValue('allActiveWorkers', value);
        };

        return (
          <Dialog
            title={<Trans i18nKey="NEW_PERFORMANCE_PAY_DIALOG.TITLE" />}
            component={Form}
            open={open}
            onClose={handleClose}
            actions={
              <DialogFooterButtons
                cancelClick={handleClose}
                submitText="NEW_PERFORMANCE_PAY_DIALOG.SUBMIT"
                isLoading={isLoading}
              />
            }
            {...props}
          >
            <Grid container mt={2}>
              <Grid item xs={12} sx={styles.radioGroup}>
                <Field
                  component={RadioGroup}
                  name="allActiveWorkers"
                  label={<Trans i18nKey="NEW_PERFORMANCE_PAY_DIALOG.RADIO_GROUP_QUESTION" />}
                  value={values.allActiveWorkers}
                  onChange={(e: ChangeEvent<HTMLInputElement>, v: string) =>
                    onRadioGroupChange(e, v)
                  }
                >
                  <FormControlLabel
                    value="every"
                    control={<Radio />}
                    label={<Trans i18nKey="NEW_PERFORMANCE_PAY_DIALOG.FOR_EVERYONE" />}
                  />
                  <FormControlLabel
                    value="selected"
                    control={<Radio />}
                    label={<Trans i18nKey="NEW_PERFORMANCE_PAY_DIALOG.FOR_SELECTED" />}
                  />
                  {touched.allActiveWorkers && errors.allActiveWorkers && (
                    <FormHelperText error role="error">
                      {errors.allActiveWorkers}
                    </FormHelperText>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} mx={4}>
                <LeoSelectFieldMulti
                  disabled={values.allActiveWorkers !== 'selected'}
                  name="workerIds"
                  data={workerIds}
                  accessor={workerAccessor}
                  readOnly
                  label="MUNKAVEGZOK.WORKER"
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <Divider />
              </Grid>
              <Grid item xs={12} mb={2}>
                <Field
                  component={LeoDatePicker}
                  name="date"
                  label={<Trans i18nKey="NEW_PERFORMANCE_PAY_DIALOG.PERIOD" />}
                  isYearAndMonthOnly
                />
              </Grid>
            </Grid>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default NewPerformancePayDialog;

import React from 'react';

import { Trans } from 'react-i18next';
import { DateTime } from 'luxon';
import { t } from 'i18next';

import { ChevronLeft, ChevronRight, Today } from '@mui/icons-material';
import { Box, Button as MuiButton } from '@mui/material';

import { OrderAppointmentFilterQuery } from 'models/ucseAppointments.model';
import { Button, showNotification } from 'components';
import { NotificationType } from 'models';
import { colorCodes } from 'theme/colors';

enum Navigator {
  BACK,
  FORWARD,
  CURRENT,
}

const borderProp = `1px ${colorCodes.commonButtonBorderColor} solid`;

const styles = {
  root: {
    mt: 2,
    justifyContent: 'space-between',
  },
  button: {
    mr: 2,
  },
  filters: {
    display: 'flex',
    pt: 2,
    pb: 2,
    minHeight: '74.5px',
  },
  filterButton: {
    display: 'flex',
  },
  chips: {
    pt: '2.5px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  views: {
    marginLeft: 'auto',
  },
  headerButton: {
    mb: 2,
    mr: 2,
  },
  navigateButtonsWrapper: {
    display: 'flex',
    paddingRight: 1,
  },
  multiButtonStyle: {
    height: '36.5px',
  },
  commonBorderProp: {
    borderTop: borderProp,
    borderBottom: borderProp,
  },
  commonButtonProp: {
    paddingLeft: '0',
    paddingRight: '0',
    minWidth: '30px',
    'span:first-of-type': {
      margin: '0 auto',
    },
  },
  leftButton: {
    borderLeft: borderProp,
    borderRadius: '0.3em 0 0 0.3em',
  },
  centerButton: {
    minWidth: '170px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: 0,
    '&:hover': {
      cursor: 'auto',
      background: 'none',
    },
  },
  rightButton: {
    borderRadius: '0 0.3em 0.3em 0',
    borderRight: borderProp,
  },
};

export interface DateNavigatorObject {
  goToBackRef: React.MutableRefObject<(() => void) | undefined>;
  goTodayRef: React.MutableRefObject<(() => void) | undefined>;
  goToNextRef: React.MutableRefObject<(() => void) | undefined>;
  currentFilter: OrderAppointmentFilterQuery;
  currentDate: Date;
}

const DateNavigators = ({ dateNavigators }: { dateNavigators: DateNavigatorObject }) => {
  const getActualDate = () =>
    `${dateNavigators?.currentDate?.getFullYear()}.${dateNavigators?.currentDate
      ?.toLocaleString('default', { month: 'long' })
      .toUpperCase()}`;

  const enablePrevMonth = () => {
    const startDate = DateTime.fromJSDate(new Date(dateNavigators?.currentDate));
    const currentDate = DateTime.local().startOf('month');

    return startDate.minus({ months: 1 }).startOf('day') >= currentDate.startOf('day');
  };

  const navigator = (Direction: Navigator, dateNavigator: DateNavigatorObject) => {
    if (!dateNavigator.currentFilter.area) {
      const content = `${t('COMMON.REQUIREDFILTER')} ${t('UCSE_IDOPONTFOGLALAS.AREA')}`;
      showNotification({ content, type: NotificationType.WARNING });
    } else {
      switch (Direction) {
        case Navigator.CURRENT:
          if (dateNavigator?.goTodayRef.current) {
            dateNavigator.goTodayRef.current();
          }
          break;

        case Navigator.BACK:
          if (dateNavigator?.goToBackRef.current) {
            dateNavigator.goToBackRef.current();
          }
          break;

        case Navigator.FORWARD:
          if (dateNavigator?.goToNextRef.current) {
            dateNavigator.goToNextRef.current();
          }
          break;

        default:
          break;
      }
    }
  };

  return (
    <Box sx={styles.navigateButtonsWrapper}>
      <Box pr={2}>
        <Button
          variant="outlined"
          onClick={() => navigator(Navigator.CURRENT, dateNavigators)}
          disabled={!enablePrevMonth()}
          endIcon={<Today />}
        >
          <Trans i18nKey="UCSE_IDOPONTFOGLALAS.TODAY" />
        </Button>
      </Box>
      <Box>
        <MuiButton
          variant="text"
          sx={[
            styles.multiButtonStyle,
            styles.leftButton,
            styles.commonButtonProp,
            styles.commonBorderProp,
          ]}
          id="next-btn-icon"
          disabled={!enablePrevMonth()}
          onClick={() => navigator(Navigator.BACK, dateNavigators)}
          startIcon={<ChevronLeft />}
        ></MuiButton>
        <MuiButton
          disableRipple
          variant="text"
          sx={[styles.multiButtonStyle, styles.centerButton, styles.commonBorderProp]}
          id="next-btn-icon"
        >
          {getActualDate()}
        </MuiButton>
        <MuiButton
          variant="text"
          sx={[
            styles.multiButtonStyle,
            styles.rightButton,
            styles.commonButtonProp,
            styles.commonBorderProp,
          ]}
          id="next-btn-icon"
          onClick={() => navigator(Navigator.FORWARD, dateNavigators)}
          endIcon={<ChevronRight />}
        ></MuiButton>
      </Box>
    </Box>
  );
};

export default DateNavigators;

import React, { ComponentType, ReactNode, useMemo } from 'react';
import { Trans } from 'react-i18next';

import FilterIcon from '@mui/icons-material/FilterList';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { ObjectSchema } from 'yup';
import Yup from 'utils/yup';

import { FilterOptionModel, QueryParamModel } from 'models';
import { SubmitArgs } from 'pages/Ucse/Appointment/AppointmentPage';
import { FormikProps } from 'formik';
import { FilterPanelContent } from './FilterPanelContent';
import { useFilterCount } from './useFilterCount';

export interface UsePanelConfigOptions {
  params?: QueryParamModel | null;
  onFilter?: (params?: QueryParamModel | null) => void;
  filters?: FilterOptionModel[];
  title?: ReactNode;
  icon?: ComponentType<SvgIconProps>;
  showBadge?: boolean;
  key?: string;
  onLoad?: (params?: QueryParamModel | null) => void;
  isButton?: boolean;
  pageName?: string;
  validationSchema?: ObjectSchema<any>;
  doubleFilterPanel?: boolean;
  isFetching?: boolean;
  submitRef?: React.MutableRefObject<SubmitArgs | undefined>;
  stateHandler?: (formikValues: FormikProps<any> | undefined) => void;
  hidden?: boolean;
  defaultFilter?: { [key: string]: unknown };
}

export interface UseFilterConfigOptions extends UsePanelConfigOptions {}

export function useFilterConfig({
  params,
  onFilter,
  filters,
  title,
  icon,
  showBadge = true,
  key,
  onLoad,
  isButton,
  pageName = '',
  validationSchema = Yup.object().shape({}),
  doubleFilterPanel = false,
  isFetching,
  submitRef,
  hidden = false,
  stateHandler,
  defaultFilter,
}: UseFilterConfigOptions = {}) {
  const badge = useFilterCount(params, pageName);

  return useMemo(
    () => ({
      key: key || 'filter',
      icon: icon || FilterIcon,
      content: (
        <FilterPanelContent
          params={params}
          onFilter={onFilter}
          filters={filters}
          onLoad={onLoad}
          pageName={pageName}
          validationSchema={validationSchema}
          doubleFilterPanel={doubleFilterPanel}
          isFetching={isFetching}
          submitRef={submitRef}
          stateHandler={stateHandler}
          defaultValues={defaultFilter}
        />
      ),
      title: title || <Trans i18nKey="COMMON.FILTER" />,
      badge: showBadge ? badge : 0,
      isInitiallyOpen: Boolean(badge),
      isButton: isButton || false,
      pageName,
      params,
      filters,
      doubleFilterPanel,
    }),
    [
      key,
      icon,
      params,
      onFilter,
      filters,
      onLoad,
      title,
      showBadge,
      badge,
      isButton,
      pageName,
      validationSchema,
      doubleFilterPanel,
      isFetching,
      submitRef,
      stateHandler,
      defaultFilter,
    ]
  );
}

import i18n from 'i18n';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { showNotification } from 'components';
import {
  NotificationType,
  QueryContextFromKeys,
  QueryParamModel,
  ReportUnitPriceModel,
} from 'models';
import { reportUnitPriceService } from 'services';
import { isAxiosError } from 'utils/errors';
import { unitPriceCreateOnError, unitPriceEditOnError } from 'features/performanceMetricUnitPrice';

type ReportUnitPriceQueryContexts = QueryContextFromKeys<typeof reportUnitPriceKeys>;

enum Errors {
  DELETE = 'CAN_NOT_BE_DELETED_PERFORMANCE_BASED_PAY_ALREADY_EXISTS_USING_SPECIFIED_VALID_FROM_DATE',
  EDIT = 'PERFORMANCE_BASED_PAY_ALREADY_EXISTS_AFTER_SPECIFIED_VALID_FROM_DATE',
  PUT = 'CAN_NOT_BE_MODIFIED_PERFORMANCE_BASED_PAY_ALREADY_EXISTS_USING_SPECIFIED_VALID_FROM_DATE',
  DUPLICATION = 'REPORT_VALUE_SET_UNIT_PRICE_ALREADY_EXISTS_IN_SPECIFIED_MONTH',
}

const reportUnitPriceKeys = {
  all: [{ scope: 'report-unit-price' }] as const,
  lists: () => [{ ...reportUnitPriceKeys.all[0], entity: 'list' }] as const,
  reportUnitPriceList: (params?: QueryParamModel | null) =>
    [{ ...reportUnitPriceKeys.lists()[0], params }] as const,
};

const fetchReportUnitPriceList = async ({
  queryKey: [{ params }],
}: ReportUnitPriceQueryContexts['reportUnitPriceList']) =>
  reportUnitPriceService.getReportUnitPriceList(params);

export const useReportUnitPriceList = (params: QueryParamModel | null = null) => {
  return useQuery(reportUnitPriceKeys.reportUnitPriceList(params), fetchReportUnitPriceList, {
    enabled: !!params?.filter,
  });
};

export const useEditReportUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: { data: Partial<ReportUnitPriceModel> }) =>
      reportUnitPriceService.editReportUnitPrice(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: reportUnitPriceKeys.lists() });
      },
      onError: (e) => unitPriceEditOnError(e, Errors.DUPLICATION),
    }
  );
};

export const usePutReportUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<ReportUnitPriceModel> }) =>
      reportUnitPriceService.putReportUnitPrice(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: reportUnitPriceKeys.lists() });
      },
      onError: (e) => unitPriceCreateOnError(e, Errors.DUPLICATION),
    }
  );
};

export const useDeleteReportUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation((id: ID) => reportUnitPriceService.deleteReportUnitPrice(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: reportUnitPriceKeys.lists() });
    },
    onError: (e) => {
      if (
        isAxiosError(e) &&
        e.response?.status === 500 &&
        e.response.data.message === Errors.DELETE
      ) {
        showNotification({
          content: i18n.t(`ERRORS.${Errors.DELETE}`),
          type: NotificationType.ERROR,
        });
      }
    },
  });
};

const exportReportUnitPriceList = async ({
  queryKey: [{ params }],
}: ReportUnitPriceQueryContexts['reportUnitPriceList']) =>
  reportUnitPriceService.exportReportUnitPrice(params);

export const useExportReportUnitPriceList = (params?: QueryParamModel | null) => {
  return useQuery(reportUnitPriceKeys.reportUnitPriceList(params), exportReportUnitPriceList, {
    enabled: false,
  });
};

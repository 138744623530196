import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type ReadingStatisticsQueryContexts = QueryContextFromKeys<typeof readingStatisticsKeys>;

export const readingStatisticsKeys = {
  all: [{ scope: 'readingStatistics' }] as const,
  lists: () => [{ ...readingStatisticsKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...readingStatisticsKeys.lists()[0], params }] as const,
};

const fetchReadingStatisticsList = async ({
  queryKey: [{ params }],
}: ReadingStatisticsQueryContexts['list']) => statisticsService.getReadingStatisticsList(params);

const exportReadingStatistics = async ({
  queryKey: [{ params }],
}: ReadingStatisticsQueryContexts['list']) => statisticsService.exportReadingStatistics(params);

export const useReadingStatisticsList = (params?: QueryParamModel | null) => {
  return useQuery(readingStatisticsKeys.list(params), fetchReadingStatisticsList, {
    enabled: !!params?.filter,
  });
};

export const useExportReadingStatistics = (params?: QueryParamModel | null) => {
  return useQuery(readingStatisticsKeys.list(params), exportReadingStatistics, { enabled: false });
};

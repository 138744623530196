import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { importTaskService } from 'services';
import { QueryContextFromKeys, QueryParamModel } from 'models';
import { emptyResponse } from 'features/helpers';
import { parse } from 'utils/base64';

type ImportTaskQueryContexts = QueryContextFromKeys<typeof importTaskKeys>;

export const importTaskKeys = {
  all: [{ scope: 'importTask' }] as const,
  lists: () => [{ ...importTaskKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...importTaskKeys.lists()[0], params }] as const,
  task: (id: ID) => [{ ...importTaskKeys.lists()[0], id }] as const,
};

const fetchTaskList = async ({ queryKey: [{ params }] }: ImportTaskQueryContexts['list']) => {
  const { status: requiredParam } = parse(params?.filter);

  return requiredParam ? importTaskService.getTasks(params) : emptyResponse;
};

const fetchTaskById = async ({ queryKey: [{ id }] }: ImportTaskQueryContexts['task']) =>
  importTaskService.getTaskById(id);

export const useTaskList = (params?: QueryParamModel | null) =>
  useQuery(importTaskKeys.list(params), fetchTaskList, {
    enabled: !!params?.filter,
  });

export const useTask = (id: ID) => useQuery(importTaskKeys.task(id), fetchTaskById);

export const useImportTask = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => importTaskService.upload(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: importTaskKeys.lists() });
    },
  });
};

export const useRetryImportTask = () => {
  const queryClient = useQueryClient();

  return useMutation((jobId: string) => importTaskService.retryUpload(jobId), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: importTaskKeys.lists() });
    },
  });
};

export const useDeleteTask = () => {
  const queryClient = useQueryClient();

  return useMutation((id: ID) => importTaskService.deleteTask(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: importTaskKeys.lists() });
    },
  });
};

import request, { Methods } from 'utils/request';

class VersionService {
  async getServerVersion() {
    return request<{ version: string; env: string }>({
      method: Methods.GET,
      resource: `v1`,
    });
  }
}

export const versionService = new VersionService();

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type ReadingResultStatisticsQueryContexts = QueryContextFromKeys<
  typeof readingResultStatisticsKeys
>;

export const readingResultStatisticsKeys = {
  all: [{ scope: 'readingResultStatistics' }] as const,
  lists: () => [{ ...readingResultStatisticsKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...readingResultStatisticsKeys.lists()[0], params }] as const,
};

const fetchReadingResultStatisticsList = async ({
  queryKey: [{ params }],
}: ReadingResultStatisticsQueryContexts['list']) =>
  statisticsService.getReadingResultStatisticsList(params);

const exportReadingResultStatistics = async ({
  queryKey: [{ params }],
}: ReadingResultStatisticsQueryContexts['list']) =>
  statisticsService.exportReadingResultStatistics(params);

export const useReadingResultStatisticsList = (params?: QueryParamModel | null) => {
  return useQuery(readingResultStatisticsKeys.list(params), fetchReadingResultStatisticsList, {
    enabled: !!params?.filter,
  });
};

export const useExportReadingResultStatistics = (params?: QueryParamModel | null) => {
  return useQuery(readingResultStatisticsKeys.list(params), exportReadingResultStatistics, {
    enabled: false,
  });
};

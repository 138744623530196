import { t } from 'i18next';
import { DateTime } from 'luxon';
import { ListResponseModel } from 'models';
import {
  SmOrderCapacityByAreaResponseModel,
  SmOrderCapacityByAreaResponseDays,
  SmOrderRequestModel,
} from 'models/smOrderCapacityByArea.model';
import { TableHelper } from './UcseFreeCapacity';

enum Date {
  VALUE = 'yyyy-MM-dd',
  DATE = 'yyyy.MM.dd',
  DATEWITHDAYNAME = 'yyyy.MM.dd cccc',
}

export interface AreaIds {
  id: number;
  booked: boolean;
}

interface DayTitle {
  title: string;
  date: string;
}

export interface Days {
  dayTitle: DayTitle;
  areaIds: AreaIds[];
  capacity: number | null;
  free: number | null;
  booked: number | null;
  percentage: number | null;
}

export interface SubCells {
  id: string;
  name: string;
}

export interface FreeCapacityRecords {
  id: number;
  sapId: string;
  areaList: SubCells[];
  regionList: SubCells[];
  TableItems: Days[];
}

export const addDataToHeader = (
  tableData: ListResponseModel<SmOrderCapacityByAreaResponseModel>,
  tableHeaders: TableHelper[]
) =>
  tableData?.data.map((item, index) => ({
    id: index,
    sapId: item.sapId,
    areaList: item.areas.map(
      (area, areaIndex) =>
        ({
          id: `${area.id}${areaIndex}`,
          name: area.area,
        } as SubCells)
    ),
    regionList: item.areas.map(
      (region, regionIndex) =>
        ({
          id: `${region.id}${regionIndex}`,
          name: region.region,
        } as SubCells)
    ),
    TableItems: tableHeaders.map((day) => {
      const selectedDays = getDateSlot(item, day);

      return {
        dayTitle: {
          title: day.title,
          date: `${day.date}`,
        },
        areaIds: getAreaIds(item, selectedDays),
        capacity: selectedDays?.capacity,
        free: selectedDays?.available,
        booked: selectedDays?.booked,
        percentage: getPercentageValue(selectedDays),
      };
    }),
  }));

export const getDate = (
  reqDate: SmOrderRequestModel,
  isDay: boolean,
  index: number,
  format: string
) =>
  DateTime.fromISO(reqDate.startDate?.toString())
    .plus(isDay ? { day: index } : { week: index })
    .setLocale('hu')
    .toFormat(format);

export const getHeader = (dateParam: SmOrderRequestModel, isDay = true): TableHelper[] => {
  const { dayCount, weekCount } = dateParam;
  const count = isDay ? dayCount : weekCount;

  return count
    ? Array.from({ length: count ?? 0 }, (_, index) => ({
        date: isDay
          ? getDate(dateParam, isDay, index, Date.DATEWITHDAYNAME)
          : `${getDate(dateParam, isDay, index, Date.DATE)}-${getDate(
              dateParam,
              isDay,
              index + 1,
              Date.DATE
            )}`,
        dayValue: isDay ? getDate(dateParam, isDay, index, Date.VALUE) : undefined,
        weekValue: isDay ? undefined : getDate(dateParam, isDay, index, Date.VALUE),
        title: `${index + 1}. ${t(isDay ? 'COMMON.DAY' : 'COMMON.WEEK')}`,
      }))
    : [];
};

const getDateSlot = (item: SmOrderCapacityByAreaResponseModel, day: TableHelper) =>
  item.days.filter((dayData) => dayData.date === day.dayValue)[0] ||
  item.weeks.filter((dayData) => dayData.weekStart === day.weekValue)[0];

const getPercentageValue = (selectedDays: SmOrderCapacityByAreaResponseDays): number =>
  parseInt(selectedDays?.bookedPercentage.replaceAll('%', ''), 10);

const getAreaIds = (
  item: SmOrderCapacityByAreaResponseModel,
  selectedDays: SmOrderCapacityByAreaResponseDays
): { id: number; booked: boolean }[] =>
  item.areas.map((area) => ({
    id: area.id,
    booked: selectedDays?.availableAreas.some((availableArea) => availableArea.id === area.id),
  }));

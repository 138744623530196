import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, OrdersWithReports } from 'models';
import { RouteNames } from 'config/routeNames';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import { formatDate } from 'utils/dates';
import { formatAddress, getPageName } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import { useExportOrderWithReports, useOrderWithReportsList } from 'features/orderWithReports';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { plannedReadingDateValidationSchema } from 'pages/Leo/Monitoring/ReadingResults/ReadingResultStatisticsPage';

const OrderWithReports: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: orderWithReportsData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useOrderWithReportsList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportOrderWithReports(exportParams);
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOKOZPONT', 'JEL', 'JEC'],
  });

  const { data: leoKozpontData, accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: workerHookData, accessor: workerHookAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      name: 'plannedReadingDate',
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
      headerTitle: 'LEO_TOBBSZOROS_JELENTES.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
    },
    {
      name: 'worker',
      headerTitle: 'LEO_TOBBSZOROS_JELENTES.SAP_ID',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerHookData}
          accessor={workerHookAccessor}
          label="LEO_TOBBSZOROS_JELENTES.SAP_ID"
        />
      ),
      divider: true,
      panelFieldWidth: 12,
    },
    {
      name: 'bundle.from',
      headerTitle: 'LEO_TOBBSZOROS_JELENTES.BUNDLE',
      panelFieldWidth: 6,
      component: (
        <LeoTextField
          name="bundle.from"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.FROM" />}
          maxLength={10}
        />
      ),
    },
    {
      name: 'bundle.to',
      panelFieldWidth: 6,
      component: (
        <LeoTextField
          name="bundle.to"
          label={<Trans i18nKey="LEO_LEO_EGYSEGEK.TO" />}
          maxLength={10}
        />
      ),
      divider: true,
    },
    {
      name: 'readingCenter',
      headerTitle: 'LEO_TOBBSZOROS_JELENTES.READING_CENTER',
      panelFieldWidth: 12,
      component: (
        <LeoSelectField
          name="readingCenter"
          data={leoKozpontData}
          label={'LEO_TOBBSZOROS_JELENTES.READING_CENTER'}
        />
      ),
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
  ];

  const orderWithReportsFilterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_JELENTESEKET_TARTALMAZO_RENDELESEK),
    validationSchema: plannedReadingDateValidationSchema,
    isFetching,
  });

  const OrderWithReportsColumns = [
    {
      key: 'createdAt',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.CREATED_AT" />,
      accessor: (orderWithReportsItem: OrdersWithReports) =>
        formatDate(orderWithReportsItem.createdAt),
      field: 'createdAt',
      sortable: true,
    },
    {
      key: 'readingUnit.bundle',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.BUNDLE" />,
      accessor: (orderWithReportsItem: OrdersWithReports) =>
        orderWithReportsItem.readingUnit?.bundle,
      field: 'readingUnit.bundle',
      sortable: true,
    },
    {
      key: 'readingUnit.plannedReadingDate',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.PLANNED_READING_DATE" />,
      accessor: (orderWithReportsItem: OrdersWithReports) =>
        formatDate(orderWithReportsItem.readingUnit?.plannedReadingDate),
      field: 'readingUnit.plannedReadingDate',
      sortable: true,
    },
    {
      key: 'readingUnit.readingCenter',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.READING_CENTER" />,
      accessor: (orderWithReportsItem: OrdersWithReports) =>
        orderWithReportsItem.readingUnit?.readingCenter,
      field: 'readingUnit.readingCenter',
      sortable: true,
    },
    {
      key: 'readingUnit.readingUnitCode',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.READING_UNIT_CODE" />,
      accessor: (orderWithReportsItem: OrdersWithReports) =>
        orderWithReportsItem.readingUnit?.readingUnitCode,
      field: 'readingUnit.readingUnitCode',
      sortable: true,
    },
    {
      key: 'partner.sapId',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.PARTNER_SAP_ID" />,
      accessor: (orderWithReportsItem: OrdersWithReports) => orderWithReportsItem.partner?.sapId,
      field: 'partner.sapId',
      sortable: true,
    },
    {
      key: 'partner.name',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.NAME" />,
      accessor: (orderWithReportsItem: OrdersWithReports) => orderWithReportsItem.partner?.name,
      field: 'partner.name',
      sortable: true,
    },
    {
      key: 'consumptionPlace.address.city',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.CONSUMPTION_PLACE_ADDRESS" />,
      accessor: (orderWithReportsItem: OrdersWithReports) =>
        formatAddress(orderWithReportsItem.consumptionPlace?.address),
      field: 'consumptionPlace.address.city',
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.SAP_ID" />,
      accessor: (orderWithReportsItem: OrdersWithReports) => orderWithReportsItem.worker?.sapId,
      field: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'device.bindingId',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.BINDING_ID" />,
      accessor: (orderWithReportsItem: OrdersWithReports) => orderWithReportsItem.device?.bindingId,
      field: 'device.bindingId',
      sortable: true,
    },
    {
      key: 'device.serialNumber',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.SERIAL_NUMBER" />,
      accessor: (orderWithReportsItem: OrdersWithReports) =>
        orderWithReportsItem.device?.serialNumber,
      field: 'device.serialNumber',
      sortable: true,
    },
    {
      key: 'subgroup.key',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.REPORT_CODE" />,
      accessor: 'subgroup.key',
      sortable: true,
    },
    {
      key: 'subgroup.value',
      header: <Trans i18nKey="LEO_TOBBSZOROS_JELENTES.REPORT_DESCRIPTION" />,
      accessor: 'subgroup.value',
      sortable: true,
    },
    {
      key: 'comment',
      header: <Trans i18nKey="COMMON.COMMENT" />,
      accessor: 'comment',
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const panels = [orderWithReportsFilterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_TOBBSZOROS_JELENTES.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ leoKozpontAccessor }}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={orderWithReportsData?.data || []}
          total={orderWithReportsData?.meta.total}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={OrderWithReportsColumns}
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default OrderWithReports;

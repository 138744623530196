import React, { HTMLAttributes, ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import { FieldProps } from 'formik';
import get from 'lodash/get';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { Autocomplete as MuiAutocomplete, TextFieldProps } from '@mui/material';

import { Env } from 'config/env';
import { TextField } from 'components';
import { inErrorState, setValue } from 'utils/form';

export interface AutoCompleteProps<T> extends FieldProps {
  /**
   * If true, value must be an array and the menu will support multiple selections.
   */
  multiple?: boolean;
  /**
   * Array of options.
   */
  options: T[];
  /**
   * The key used for the value property in the options.
   */
  valueKey: string;
  /**
   * The key used for displaying the option in the field.
   */
  displayKey: string;
  /**
   * If set to ```true```, an error message is going to be visible under the field, in case of an error.
   */
  showError?: boolean;
  /**
   * If ```true```, the component becomes readonly. It is also supported for multiple tags where the tag cannot be deleted.
   */
  readOnly?: boolean;
  /**
   * Text to display when there are no options.
   */
  noOptionsText: ReactNode;
  label: ReactNode;
  /**
   * If ```true```, the input can't be cleared.
   */
  disableClearable?: boolean;
}

export function Autocomplete<T>({
  multiple = false,
  field,
  form,
  fullWidth,
  showError = true,
  readOnly,
  valueKey = 'value',
  variant = Env.DEFAULT_INPUT_VARIANT,
  options,
  displayKey = 'label',
  noOptionsText,
  disableClearable,
  disabled,
  sx,
  ...props
}: AutoCompleteProps<T> & TextFieldProps) {
  const handleChangeSingle = (e: SyntheticEvent, value: T | null) => {
    if (value === null) {
      setValue(form, field, null);
      return;
    }
    const selectedValue = options.find((option) => get(option, displayKey) === get(value, displayKey)) || null;

    form.setFieldValue(field.name, selectedValue);
  };

  const handleChangeMulti = (e: SyntheticEvent, value: T[]) => {
    if (value === null) {
      setValue(form, field, []);
      return;
    }
    setValue(form, field, intersectionWith(options, value, isEqual));
  };

  const checkEqualToValue = useCallback(
    (optionEqual: T, valueEqual: T) => {
      if (!valueEqual || !optionEqual) {
        return false;
      }
      return get(optionEqual, valueKey) === get(valueEqual, valueKey);
    },
    [valueKey]
  );

  const renderList = useCallback(
    (propsRender: HTMLAttributes<HTMLLIElement>, option: T) => <li {...propsRender}>{get(option, displayKey)}</li>,
    [displayKey]
  );

  const renderTextfield = useCallback(
    (params: TextFieldProps) => (
      <TextField
        {...props}
        {...params}
        {...field}
        form={form}
        field={field}
        meta={props.meta}
        fullWidth={fullWidth}
        error={inErrorState(form, field)}
        variant={variant}
        showError={showError}
      />
    ),
    [field, form, fullWidth, props, showError, variant]
  );

  const commonProps = useMemo(
    () => ({
      fullWidth,
      noOptionsText,
      options,
      readOnly,
      disabled,
      isOptionEqualToValue: (optionEqual: T, valueEqual: T) => checkEqualToValue(optionEqual, valueEqual),
      getOptionLabel: (option: T) => get(option, displayKey),
      renderOption: renderList,
      renderInput: renderTextfield,
      disableClearable,
      sx,
    }),
    [
      checkEqualToValue,
      disableClearable,
      displayKey,
      disabled,
      fullWidth,
      noOptionsText,
      options,
      readOnly,
      renderList,
      renderTextfield,
      sx,
    ]
  );

  const singleEmptyValue = isEmpty(field?.value) ? null : field?.value;
  const multiEmptyValue = isEmpty(field?.value) ? [] : (field?.value as T[]);

  return !multiple ? (
    <MuiAutocomplete
      onChange={handleChangeSingle}
      value={singleEmptyValue}
      ChipProps={{ color: 'primary' }}
      {...commonProps}
    />
  ) : (
    <MuiAutocomplete
      onChange={handleChangeMulti}
      value={multiEmptyValue}
      ChipProps={{ color: 'primary' }}
      multiple
      limitTags={3}
      {...commonProps}
    />
  );
}

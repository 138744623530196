import i18n from 'i18n';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  QueryContextFromKeys,
  QueryParamModel,
  AreaWorkerPostModel,
  NotificationType,
} from 'models';
import { showNotification } from 'components';
import { areaWorkerService } from 'services';
import { isAxiosError } from 'utils/errors';

type AreaWorkerQueryContexts = QueryContextFromKeys<typeof areaWorkerKeys>;

export const areaWorkerKeys = {
  all: [{ scope: 'area-worker' }] as const,
  lists: () => [{ ...areaWorkerKeys.all[0], entity: 'list' }] as const,
  areaWorkerList: (params?: QueryParamModel | null) =>
    [{ ...areaWorkerKeys.lists()[0], params }] as const,
};

const runOnError = async (e: any) => {
  if (
    isAxiosError(e) &&
    e.response?.status === 400 &&
    e.response.data.message === 'AREA_WORKER_ALREADY_EXISTS'
  ) {
    showNotification({
      content: i18n.t('ERRORS.AREA_WORKER_ALREADY_EXISTS'),
      type: NotificationType.ERROR,
    });
  }
};

const fetchAreaWorkerList = async ({
  queryKey: [{ params }],
}: AreaWorkerQueryContexts['areaWorkerList']) => areaWorkerService.getAreaWorker(params);

export const useAreaWorkerList = (params?: QueryParamModel | null) => {
  return useQuery(areaWorkerKeys.areaWorkerList(params), fetchAreaWorkerList, {
    enabled: !!params?.filter,
  });
};

const exportAreaWorkerList = async ({
  queryKey: [{ params }],
}: AreaWorkerQueryContexts['areaWorkerList']) => areaWorkerService.exportArea(params);

export const useExportAreaWorkerList = (params?: QueryParamModel | null) => {
  return useQuery(areaWorkerKeys.areaWorkerList(params), exportAreaWorkerList, { enabled: false });
};

export const useDeleteAreaWorker = () => {
  const queryClient = useQueryClient();

  return useMutation((id: ID) => areaWorkerService.deleteAreaWorker(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: areaWorkerKeys.lists() });
    },
  });
};

export const useEditAreaWorker = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: AreaWorkerPostModel }) =>
      areaWorkerService.editAreaWorker(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: areaWorkerKeys.lists() });
      },
      onError: runOnError,
    }
  );
};

export const useCreateAreaWorker = () => {
  const queryClient = useQueryClient();

  return useMutation((data: AreaWorkerPostModel) => areaWorkerService.createAreaWorker(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: areaWorkerKeys.lists() });
    },
    onError: runOnError,
  });
};

import i18n from 'i18n';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showNotification } from 'components';
import { hrCalendarService } from 'services';
import { NotificationType } from 'models';
import { isAxiosError } from 'utils/errors';

export const uploadHolidaysKeys = {
  all: [{ scope: 'uploadHolidays' }] as const,
  lists: () => [{ ...uploadHolidaysKeys.all[0], entity: 'list' }] as const,
};

export const useUploadHolidays = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => hrCalendarService.upload(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: uploadHolidaysKeys.lists() });
    },
    onError: (e) => {
      if (isAxiosError(e) && e.response?.data.message) {
        showNotification({
          content: i18n.t(`ERRORS.${e.response.data.message}`),
          type: NotificationType.ERROR,
        });
      }
    },
  });
};

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { areaService } from 'services';

type AreaQueryContexts = QueryContextFromKeys<typeof areaKeys>;

export const areaKeys = {
  all: [{ scope: 'area' }] as const,
  lists: () => [{ ...areaKeys.all[0], entity: 'list' }] as const,
  areaList: (params?: QueryParamModel | null) => [{ ...areaKeys.lists()[0], params }] as const,
};

const fetchAreaList = async ({ queryKey: [{ params }] }: AreaQueryContexts['areaList']) =>
  areaService.getAreaList(params);

export const useAreaList = (params?: QueryParamModel | null) => {
  return useQuery(areaKeys.areaList(params), fetchAreaList);
};

import {
    QueryParamModel,
    JobModel,
} from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

class AsyncExportService extends EntityService {
    constructor() {
        super('async-excel');
    }

    private fetchAsyncExcelStatus = async (api: string, jobId: string, params?: QueryParamModel | null) => {
        return request<JobModel>({
            method: Methods.GET,
            resource: `v1/admin/${this.resource}/${jobId}`,
            params,
        });
    };

    private asyncExcelDownload = async (api: string, jobId: string, params?: QueryParamModel | null) => {
        const response = await requestFile<BlobPart>({
            method: Methods.GET,
            resource: `v1/admin/${this.resource}/${jobId}/download`,
            params,
        });
        await exportFile(response);
        return response;
    };

    getAsyncExcelStatus = (jobId: string | undefined, params?: QueryParamModel | null) =>
        jobId && this.fetchAsyncExcelStatus('async-excel', jobId, params);

    downloadAsyncExcel = (jobId: string | undefined, params?: QueryParamModel | null) =>
        jobId && this.asyncExcelDownload('async-excel', jobId, params);
}

export const asyncExportService = new AsyncExportService();

import { useQuery } from '@tanstack/react-query';

import { statisticsService } from 'services';
import { QueryContextFromKeys, QueryParamModel } from 'models';

type EventLogQueryContexts = QueryContextFromKeys<typeof eventLogKeys>;

export const eventLogKeys = {
  all: [{ scope: 'eventLog' }] as const,
  lists: () => [{ ...eventLogKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...eventLogKeys.lists()[0], params }] as const,
};

const fetchEventLogList = async ({ queryKey: [{ params }] }: EventLogQueryContexts['list']) =>
  statisticsService.getEventLogList(params);

const exportEventLogList = async ({ queryKey: [{ params }] }: EventLogQueryContexts['list']) =>
  statisticsService.exportEventLogs(params);

export const useEventLogList = (params?: QueryParamModel | null) => {
  return useQuery(eventLogKeys.list(params), fetchEventLogList, {
    enabled: !!params?.filter,
  });
};

export const useExportEventLogList = (params?: QueryParamModel | null) => {
  return useQuery(eventLogKeys.list(params), exportEventLogList, { enabled: false });
};

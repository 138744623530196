import { EntityService } from 'utils/entityService';
import { ListResponseModel, QueryParamModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import {
  AreaWorkerAvailability,
  EditAreaAvailabilityModel,
} from 'models/areaWorkerAvailability.model';
import { exportFile } from 'utils/exportFile';

class AreaWorkerAvailabilityService extends EntityService {
  constructor() {
    super('area-worker-availability');
  }

  getAreaWorkerAvailabilityList = async (params?: QueryParamModel | null) => {
    return request<ListResponseModel<AreaWorkerAvailability>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  };

  private exportAreaWorkerAvailabilityList = async (params?: QueryParamModel | null) => {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });
    await exportFile(response);
    return response;
  };

  AreaWorkerAvailability = (params?: QueryParamModel | null) =>
    this.exportAreaWorkerAvailabilityList(params);

  async editAreaWorkerAvailabilityList(data: EditAreaAvailabilityModel[]) {
    return request<EditAreaAvailabilityModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}`,
      data: { data },
    });
  }
}

export const areaWorkerAvailabilityService = new AreaWorkerAvailabilityService();

import React, { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

export interface PanelContentProps {
  withHeader?: boolean;
  children: ReactNode;
}

const styles = {
  header: (theme: Theme) => ({
    ...theme.mixins.toolbar,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  }),
  content: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
};

export const PanelContent: FC<PanelContentProps> = ({ withHeader = false, children }) => {
  return (
    <>
      {withHeader && <Box sx={styles.header} />}
      <Box sx={styles.content}>{children}</Box>
    </>
  );
};

import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import { BookAppointmentRequest, UcseAppointmentsModel } from 'models/ucseAppointments.model';
import {
  useAppointmentAvailableWorker,
  useBookAppointment,
  useRemoveAppointment,
} from 'features/ucseAppointments';
import { BaseDialogProps, Button, EditorDialog } from 'components';
import { neutralVariant, primary } from 'theme/colors';
import { selectUserRoles } from 'store/profile';
import { hasPermission } from 'utils/auth';
import { Role } from 'config/roles';
import { CustomEvent } from './AppointmentPage';

const styles = {
  wrapper: {
    paddingBottom: 0,
  },
  textField: {
    borderRadius: '6px',
    background: neutralVariant[100],
    marginBottom: '35px',
    'label:first-of-type': {
      color: neutralVariant[700],
    },
    'input:first-of-type': {
      color: primary[600],
      '&:hover': {
        cursor: 'auto',
      },
    },
    'p:first-of-type': {
      display: 'none',
    },
  },
  textFieldWrapper: {
    ':not(:last-child)': {
      borderBottom: `1px solid ${neutralVariant[200]}`,
    },
    paddingTop: '8px',
    marginLeft: '12px',
    marginRight: '8px',
  },
  spinner: {
    marginTop: '14px',
    marginBottom: '13px',
  },
};

interface AppointmentEditorDialogProps {
  selected: CustomEvent | null;
  requiredParam: string;
  modalDate: string;
  modalSlot: string;
  refetchAppointmentData: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UcseAppointmentsModel[], unknown>>;
}

interface CommonEditorDialogProps extends AppointmentEditorDialogProps, BaseDialogProps {}

const AppointmentEditorDialog: FC<CommonEditorDialogProps> = ({
  selected,
  requiredParam,
  onClose,
  modalDate,
  modalSlot,
  refetchAppointmentData,
  ...props
}) => {
  const role = useSelector(selectUserRoles);
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const { mutateAsync: bookAppointment, isLoading: bookAppointmentIsLoading } =
    useBookAppointment();
  const { mutateAsync: removeAppointment, isLoading: removeAppointmentIsLoading } =
    useRemoveAppointment();

  const { data: availableWorkerData, isLoading: availableWorkerIsLoading } =
    useAppointmentAvailableWorker(
      {
        area: requiredParam,
        timeslot: selected?.timeSlot,
        date: selected?.timeSlotDate,
      },
      selected
    );

  const workerId = useMemo(() => {
    return availableWorkerData?.availableWorker?.worker
      ? `${availableWorkerData?.availableWorker?.worker.sapId} - ${availableWorkerData?.availableWorker?.worker.name}`
      : `-`;
  }, [availableWorkerData?.availableWorker?.worker]);

  const bookOrRemoveAppointment: (option: boolean) => BookAppointmentRequest = (option) => {
    return {
      workerId: option
        ? availableWorkerData?.availableWorker?.workerId
        : availableWorkerData?.notAvailableWorker?.workerId,
      timeslot: option
        ? availableWorkerData?.availableWorker?.timeslot
        : availableWorkerData?.notAvailableWorker?.timeslot,
      date: option
        ? availableWorkerData?.availableWorker?.date
        : availableWorkerData?.notAvailableWorker?.date,
    };
  };

  const loading =
    availableWorkerIsLoading || bookAppointmentIsLoading || removeAppointmentIsLoading;

  const handleBookAppointment = async () => {
    await bookAppointment({ data: bookOrRemoveAppointment(true) });
    refetchAppointmentData();
    onClose();
  };

  const handleRemoveAppointment = async () => {
    await removeAppointment({ data: bookOrRemoveAppointment(false) });
    refetchAppointmentData();
    onClose();
  };

  const disableReserveOption = () => {
    if (availableWorkerData) {
      return availableWorkerData?.availableWorker === null;
    }
    return true;
  };

  const disableDisengagementOption = () => {
    if (availableWorkerData) {
      return availableWorkerData?.notAvailableWorker === null || !hasPermission(role, Role.ADMIN);
    }
    return true;
  };

  return (
    <EditorDialog
      initialValues={undefined}
      onSubmit={() => undefined}
      validationSchema={undefined}
      contentSx={styles.wrapper}
      onClose={onClose}
      maxWidth="sm"
      disableDialogButtons
      title={t('UCSE_IDOPONTFOGLALAS.DIALOG_TITLE')}
      {...props}
    >
      <Box sx={styles.textField}>
        <Box sx={styles.textFieldWrapper}>
          <LeoTextField
            disableunderline
            staticvalue={modalDate}
            readOnly
            variant="standard"
            name="unitPriceForPower"
            label={<Trans i18nKey="UCSE_IDOPONTFOGLALAS.DATE" />}
          />
        </Box>
        <Box sx={styles.textFieldWrapper}>
          <LeoTextField
            disableunderline
            staticvalue={modalSlot}
            readOnly
            variant="standard"
            name="unitPriceForPower"
            label={<Trans i18nKey="UCSE_IDOPONTFOGLALAS.DAYSLOT" />}
          />
        </Box>
        <Box sx={styles.textFieldWrapper}>
          {availableWorkerIsLoading ? (
            <CircularProgress sx={styles.spinner} size={26} />
          ) : (
            <LeoTextField
              disableunderline
              staticvalue={workerId}
              readOnly
              variant="standard"
              name="unitPriceForPower"
              label={<Trans i18nKey="UCSE_IDOPONTFOGLALAS.WORKER_CODE" />}
            />
          )}
        </Box>
      </Box>

      <Box pb={2}>
        <Button
          key="cancel"
          variant="contained"
          onClick={handleBookAppointment}
          disabled={disableReserveOption()}
          fullWidth
          loading={loading}
        >
          <Trans i18nKey={'UCSE_IDOPONTFOGLALAS.APPOINTMENT_RESERVE'} />
        </Button>
      </Box>
      <Box pb={2}>
        <Button
          key="cancel"
          variant="contained"
          onClick={handleRemoveAppointment}
          fullWidth
          disabled={disableDisengagementOption()}
          loading={loading}
        >
          <Trans i18nKey={'UCSE_IDOPONTFOGLALAS.APPOINTMENT_DISCARD'} />
        </Button>
      </Box>
    </EditorDialog>
  );
};

export default AppointmentEditorDialog;

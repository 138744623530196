import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { EditAreaAvailabilityModel } from 'models/areaWorkerAvailability.model';

import { areaWorkerAvailabilityService } from 'services';

type AreaWorkerAvailabilityQueryContexts = QueryContextFromKeys<typeof areaWorkerAvailabilityKeys>;

export const areaWorkerAvailabilityKeys = {
  all: [{ scope: 'area-worker-availability' }] as const,
  lists: () => [{ ...areaWorkerAvailabilityKeys.all[0], entity: 'list' }] as const,
  areaWorkerAvailabilityList: (params?: QueryParamModel | null) =>
    [{ ...areaWorkerAvailabilityKeys.lists()[0], params }] as const,
};

const exportAreaWorkerAvailabilityList = async ({
  queryKey: [{ params }],
}: AreaWorkerAvailabilityQueryContexts['areaWorkerAvailabilityList']) =>
  areaWorkerAvailabilityService.AreaWorkerAvailability(params);

export const useExportAreaWorkerAvailabilityList = (params?: QueryParamModel | null) => {
  return useQuery(
    areaWorkerAvailabilityKeys.areaWorkerAvailabilityList(params),
    exportAreaWorkerAvailabilityList,
    {
      enabled: false,
    }
  );
};

const fetchAreaWorkerAvailabilityList = async ({
  queryKey: [{ params }],
}: AreaWorkerAvailabilityQueryContexts['areaWorkerAvailabilityList']) =>
  areaWorkerAvailabilityService.getAreaWorkerAvailabilityList(params);

export const useAreaWorkerAvailabilityList = (params?: QueryParamModel | null) => {
  return useQuery(
    areaWorkerAvailabilityKeys.areaWorkerAvailabilityList(params),
    fetchAreaWorkerAvailabilityList,
    { enabled: !!params?.filter }
  );
};

export const useEditAreaWorkerAvailability = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: EditAreaAvailabilityModel[]) =>
      areaWorkerAvailabilityService.editAreaWorkerAvailabilityList(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: areaWorkerAvailabilityKeys.lists() });
      },
    }
  );
};

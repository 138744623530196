import { isArray, isObject, trim } from 'lodash';

export type RemoveFalsyPredicate = <P extends Record<string, any>>(o: P, k: keyof P) => boolean;

export function removeFalsy<P extends Record<string, any>>(
  obj: P,
  predicate?: RemoveFalsyPredicate
) {
  const newObj = {} as P;
  const keys = Object.keys(obj) as Array<keyof P>;

  const isTruthy = predicate || ((o: P, k: keyof P) => o[k] || o[k] === 0);

  const nestedObjectAction = (prop: keyof P, key: string) => {
    if (/^\s*$/.test(obj[prop][key])) {
      delete newObj[prop][key];
    } else {
      newObj[prop][key] =
        typeof obj[prop][key] === 'string'
          ? (trim(obj[prop][key].toString()) as P[keyof P])
          : obj[prop][key];
    }
  };

  keys.forEach((prop) => {
    if (isTruthy(obj, prop)) {
      if (!isObject(obj[prop]) && !/^\s*$/.test(obj[prop])) {
        newObj[prop] =
          typeof obj[prop] === 'string' ? (trim(obj[prop].toString()) as P[keyof P]) : obj[prop];
      }
      if (isObject(obj[prop])) {
        newObj[prop] = obj[prop];
        Object.keys(obj[prop]).forEach((key) => {
          nestedObjectAction(prop, key);
        });
      }
    }

    if (isArray(obj[prop])) {
      if (!obj[prop].length) {
        delete newObj[prop];
      }
    }
  });

  return newObj;
}

export function removeProp<P extends Record<string, any>>(obj: P, prop: keyof P | string) {
  return Object.keys(obj).reduce((result, key) => {
    if (key !== prop) {
      result[key as keyof P] = obj[key as keyof P];
    }

    return result;
  }, {} as P);
}

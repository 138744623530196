import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import toNumber from 'lodash/toNumber';
import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';

import { AreaWorkerModel } from 'models';
import { BaseEditorDialogProps, EditorDialog, LeoTextField } from 'components';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import { useCreateAreaWorker, useEditAreaWorker } from 'features/areaWorker';
import { useWorkerListSearch } from 'features/workers';
import { useAreaList } from 'features/area';

export interface AreaWorkerDialogProps extends BaseEditorDialogProps<AreaWorkerModel> {}

const AreaWorkerEditorDialog: FC<AreaWorkerDialogProps> = ({ selected, ...props }) => {
  const isEdit = Boolean(selected && selected.id);

  const { mutateAsync: editAreaWorker } = useEditAreaWorker();
  const { mutateAsync: createAreaWorker } = useCreateAreaWorker();
  const { data: areaData } = useAreaList();
  const { data: workersData } = useWorkerListSearch();

  const sortedAreas = areaData?.slice().sort((a, b) => a.area.localeCompare(b.area));

  const { data: mbaHookData, accessor: mbaHookAccessor } = useFieldDataAndAccessor(
    sortedAreas,
    'id',
    'mba'
  );
  const { data: areaHookData, accessor: areaHookAccessor } = useFieldDataAndAccessor(
    sortedAreas,
    'id',
    'area'
  );

  const { accessor: areaMbaHookAccessor } = useFieldDataAndAccessor(areaData, 'id', 'mba');
  const { data: workerHookData, accessor: workerHookAccessor } = useFieldDataAndAccessor(
    workersData,
    'id',
    'name',
    true,
    'sapId'
  );

  const onSubmit = async (values?: Partial<AreaWorkerModel>) => {
    if (values?.id) {
      await editAreaWorker({
        id: values.id,
        data: {
          areaId: toNumber(values.areaId),
          workerId: toNumber(values.worker?.id),
          priority: toNumber(values.priority),
        },
      });
    } else if (values) {
      const payload = {
        areaId: toNumber(values.areaId),
        workerId: toNumber(values.worker?.id),
        priority: toNumber(values.priority),
      };

      await createAreaWorker(payload);
    }
  };

  const initVal = isEdit ? selected : { priority: 1 };

  return (
    <EditorDialog
      initialValues={{ ...initVal }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        area: Yup.object().shape({
          area: Yup.string().required(),
          mba: Yup.string(),
        }),
        worker: Yup.object().shape({
          id: Yup.string().required().nullable(),
        }),
        priority: Yup.number().required(),
      })}
      title={isEdit ? <Trans i18nKey="COMMON.EDIT" /> : <Trans i18nKey="COMMON.ADD" />}
      {...props}
    >
      <Box mb={2}>
        <Typography variant="subtitle2">
          <Trans i18nKey="COMMON.BASICDATA" />
        </Typography>
      </Box>
      <LeoSelectField
        disableClearable
        clearOnEscape={false}
        name="area.area"
        data={areaHookData}
        accessor={areaHookAccessor}
        label="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.AREA"
        autoFill={{ field: 'area.mba', data: areaMbaHookAccessor, lookup: areaHookAccessor }}
        updateField="areaId"
      />
      <LeoSelectField
        disableClearable
        clearOnEscape={false}
        name="area.mba"
        data={mbaHookData}
        accessor={mbaHookAccessor}
        label="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.MBA"
        autoFill={{ field: 'area.area', data: areaHookAccessor, lookup: areaMbaHookAccessor }}
        updateField="areaId"
      />
      <LeoSelectField
        name="worker.id"
        data={workerHookData}
        accessor={workerHookAccessor}
        label="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.WORKER_ID"
      />
      <LeoTextField
        name="priority"
        label={<Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.PRIORITY" />}
      />
    </EditorDialog>
  );
};

export default AreaWorkerEditorDialog;

import React, { FC, MutableRefObject, ReactNode, useEffect, useState } from 'react';
import i18n from 'i18n';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { FieldProps } from 'formik';
import Dropzone, { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone';

import {
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  SvgIcon,
  Theme,
  Typography,
  alpha,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { UseMutateAsyncFunction } from '@tanstack/react-query';

import { ErrorMessage, showNotification } from 'components';
import { arrayify } from 'utils/arrays';
import { setValue } from 'utils/form';
import { neutralVariant } from 'theme/colors';
import { NotificationType } from 'models';

import { ReactComponent as CancelIcon } from 'assets/icons/Material/cancel.svg';
import { AxiosResponseHeaders } from 'axios';

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${alpha(theme.palette.secondary.main, 0.2)}`,
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    backgroundColor: neutralVariant[100],
    height: theme.spacing(12),
    width: '100%',
    cursor: 'pointer',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
    '&:hover, &.active, &.loading, &.loading': {
      borderColor: theme.palette.secondary.main,

      '& .uploadIcon': {
        '@keyframes float': {
          '0%': {
            transform: 'translateY(0px)',
          },
          '50%': {
            transform: 'translateY(-4px)',
          },
          '100%': {
            transform: 'translateY(0px)',
          },
        },
        animationName: 'float',
        animationDuration: '1s',
        animationTimingFunction: 'ease-out',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
      },
    },
    '&.active.accept': {
      backgroundColor: 'red',
      '& .drop': {
        display: 'block',
      },
    },
    '&.active.reject': {
      borderColor: theme.palette.error.main,
      '& .reject': {
        display: 'block',
      },
    },
  }),
  label: {
    mb: 1,
  },
  labelBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileNameBox: {
    overflow: 'hidden',
  },
  fileNameTypography: {
    wordWrap: 'break-word',
  },
  iconButton: {
    p: 0,
    m: 0,
    maxWidth: 25,
    maxHeight: 25,
  },
  cancelIcon: {
    maxWidth: 20,
    maxHeight: 20,
  },
};

interface FileType extends File {
  path: string;
  lastModifiedDate: string;
  size: number;
  type: string;
}

enum FileUploadError {
  UNSUPPORTED_FILE_TYPE = 'UNSUPPORTED_FILE_TYPE',
}

export const FileErrorLabelMap: Record<FileUploadError, () => string> = {
  [FileUploadError.UNSUPPORTED_FILE_TYPE]: () => i18n.t('ERRORS.UNSUPPORTED'),
};

export interface FileUploadProps extends DropzoneProps, Partial<FieldProps> {
  /**
   * If set to true, a circular progress is goin to appear instead of the dropzone.
   */
  loading?: boolean;
  /**
   * The label content.
   */
  label?: ReactNode;
  /**
   * If set to ```true```, an error message is going to be visible under the dropzone, in case of an error.
   */
  showError?: boolean;
  /**
   * Reference to upload button.
   */
  importButtonRef?: MutableRefObject<HTMLInputElement | undefined | null>;
  /**
   * Reference to data upload service.
   */
  importTask?: UseMutateAsyncFunction<
    { response: BlobPart; responseHeaders: AxiosResponseHeaders },
    any,
    any,
    unknown
  >;
}

export const FileUpload: FC<FileUploadProps> = ({
  multiple = false,
  onDropRejected,
  onDropAccepted,
  loading = false,
  form,
  field,
  label,
  showError = true,
  accept,
  importButtonRef,
  importTask,
  ...props
}) => {
  const [error, setError] = useState<FileUploadError | null>(null);
  const buttonControlOnly = !!importButtonRef;

  useEffect(() => {
    if (error && buttonControlOnly) {
      showNotification({ content: t('ERRORS.UNSUPPORTED'), type: NotificationType.ERROR });
    }
  }, [error, buttonControlOnly]);

  const onRejected = (files: FileRejection[], event: DropEvent) => {
    setError(FileUploadError.UNSUPPORTED_FILE_TYPE);

    if (onDropRejected) {
      onDropRejected(files, event);
    }
  };

  const onAccepted = (files: File[], event: DropEvent) => {
    setError(null);

    if (onDropAccepted) {
      onDropAccepted(files, event);
    }

    setValue(form, field, files);

    if (buttonControlOnly && importTask) {
      importTask(files?.[0]);
    }
  };

  const onClearClick = () => {
    setValue(form, field, null);
  };

  const value = field?.value ? arrayify(field.value) : null;

  return (
    <Box width="100%" sx={{ display: buttonControlOnly ? 'none' : null }}>
      {!!label && (
        <Typography sx={styles.label} variant="subtitle2">
          {label}
        </Typography>
      )}
      <Dropzone
        noClick={buttonControlOnly}
        noKeyboard={buttonControlOnly}
        multiple={multiple}
        onDropRejected={onRejected}
        onDropAccepted={onAccepted}
        accept={accept}
        {...props}
      >
        {({ getRootProps, getInputProps, isDragActive, inputRef }) => {
          if (importButtonRef) {
            importButtonRef.current = inputRef.current;
          }
          return (
            <Box
              {...getRootProps()}
              sx={styles.root}
              className={classNames({
                active: isDragActive,
                loading,
              })}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <div>
                  <input {...getInputProps()} />
                  <Typography>
                    <Trans i18nKey="COMMON.DROP_FILE_HERE" />
                  </Typography>

                  <UploadIcon fontSize="large" color="secondary" className="uploadIcon" />
                </div>
              )}
            </Box>
          );
        }}
      </Dropzone>
      <ErrorMessage show={showError} form={form} field={field} />

      <FormHelperText error>
        {error && FileErrorLabelMap[error] ? FileErrorLabelMap[error]() : null}
      </FormHelperText>

      <Box sx={styles.labelBox}>
        <Box sx={styles.fileNameBox}>
          {arrayify(value).map((val: FileType) => (
            <Typography key={val.path} sx={styles.fileNameTypography}>
              <Trans i18nKey="COMMON.SELECTED_FILE" /> {val.path}
            </Typography>
          ))}
        </Box>
        {value && (
          <IconButton sx={styles.iconButton} onClick={onClearClick}>
            <SvgIcon sx={styles.cancelIcon} component={CancelIcon} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

import { useQuery } from '@tanstack/react-query';

import { statisticsService } from 'services';
import { QueryContextFromKeys, QueryParamModel } from 'models';

type HealthAndSafetyQueryContexts = QueryContextFromKeys<typeof healthAndSafetyKeys>;

export const healthAndSafetyKeys = {
  all: [{ scope: 'healthAndSafety' }] as const,
  lists: () => [{ ...healthAndSafetyKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...healthAndSafetyKeys.lists()[0], params }] as const,
};

const fetchHealthAndSafetyList = async ({
  queryKey: [{ params }],
}: HealthAndSafetyQueryContexts['list']) => statisticsService.getHealthAndSafetyList(params);

const exportHealthAndSafetyList = async ({
  queryKey: [{ params }],
}: HealthAndSafetyQueryContexts['list']) => statisticsService.exportHealthAndSafety(params);

export const useHealthAndSafetyList = (params?: QueryParamModel | null) =>
  useQuery(healthAndSafetyKeys.list(params), fetchHealthAndSafetyList, {
    enabled: !!params?.filter,
  });

export const useExportHealthAndSafetyList = (params?: QueryParamModel | null) =>
  useQuery(healthAndSafetyKeys.list(params), exportHealthAndSafetyList, { enabled: false });

import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import Box from '@mui/material/Box';

import { Dialog, Button, DialogProps } from 'components';

export interface ConfirmDialogProps extends DialogProps {
  /**
   * Function, that triggers on the confirm button click event.
   */
  onSubmit: () => any;
  /**
   * Determines what shows up inside the confirm button.
   */
  confirmTitle?: React.ReactNode;
  /**
   * Determines what shows up inside the cancel button.
   */
  cancelTitle?: React.ReactNode;
}

export const ConfirmDialog = ({
  open,
  title,
  children,
  onSubmit,
  loading: loadingProp,
  confirmTitle,
  cancelTitle,
  onClose,
}: ConfirmDialogProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.SyntheticEvent<any>) => {
    if (onSubmit) {
      setLoading(true);

      try {
        await onSubmit();

        if (onClose) {
          onClose(e);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      actions={
        <Box mt={2} display="flex">
          <Box mr={2}>
            <Button variant="outlined" onClick={onClose} disabled={loading || loadingProp}>
              {cancelTitle || <Trans i18nKey="COMMON.CANCEL" />}
            </Button>
          </Box>
          <Button onClick={handleSubmit} loading={loading || loadingProp}>
            {confirmTitle || <Trans i18nKey="COMMON.DELETE" />}
          </Button>
        </Box>
      }
    >
      {children}
    </Dialog>
  );
};

import React, { forwardRef } from 'react';

import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import { useTestId } from 'components';
import { mergeSx } from 'utils/styles';

const styles = {
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonProgress: {
    color: 'primary.main',
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: 'calc(50% - 12px)',
  },
  fullWidth: {
    width: '100%',
  },
  button: {
    height: '100%',
  },
  loading: {
    '&.MuiButtonBase-root.Mui-disabled': {
      color: 'transparent',
    },
  },
};

export interface ButtonProps extends Omit<MuiButtonProps, 'ref'> {
  /**
   * The system prop that is directly applied to the button.
   */
  buttonSx?: SxProps<Theme>;
  /**
   * The system prop that is directly applied to the circular progress (only shows if the loading prop is set to true).
   */
  progressSx?: SxProps<Theme>;
  /**
   * The class(es) that is/are applied to the internal wrapper element of the button (in case if you prefer classes over sx).
   */
  className?: string;
  /**
   * The class(es) that is/are directly applied to the button (in case if you prefer classes over sx).
   */
  buttonClassName?: string;
  /**
   * The class(es) that is/are directly applied to the circular progress (in case if you prefer classes over sx).
   */
  progressClassName?: string;
  /**
   * If set to true, a circular progress is goin to appear inside the button.
   * @default false
   */
  loading?: boolean;
  /**
   * For testing purposes, you can set a testid for the button.
   */
  customTestId?: string;
}

export const Button = forwardRef<HTMLDivElement, ButtonProps>(
  (
    {
      loading,
      disabled,
      sx,
      buttonSx,
      progressSx,
      className,
      fullWidth,
      buttonClassName,
      progressClassName,
      variant = 'contained',
      color = 'primary',
      customTestId,
      ...props
    },
    ref
  ) => {
    const { testIdObject } = useTestId({
      componentVariant: variant,
      testId: customTestId,
      componentName: 'Button',
    });

    return (
      <Box
        ref={ref}
        className={className}
        sx={mergeSx(styles.root, fullWidth ? styles.fullWidth : null, sx)}
      >
        <MuiButton
          disabled={loading || disabled}
          className={buttonClassName}
          sx={mergeSx(styles.button, buttonSx, loading ? styles.loading : null)}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          disableElevation
          {...testIdObject}
          {...props}
        />
        {loading && (
          <CircularProgress
            size={24}
            className={progressClassName}
            sx={styles.buttonProgress}
            color={color}
          />
        )}
      </Box>
    );
  }
);

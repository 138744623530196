import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { FilterOptionModel, ListItem, ReadingFailureReasonsStatisticsModel } from 'models';
import {
  Page,
  Table,
  Tooltip,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval from 'components/input-fields/leoDateInterval/LeoDateInterval';
import { useValueSetList } from 'features/valueSets';
import {
  useExportReadingFailureReasons,
  useReadingFailureReasonsList,
} from 'features/readingFailureReasons';
import { RouteNames } from 'config/routeNames';
import { getPageName } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { validationSchema } from 'pages/Leo/Monitoring/ReportCodes/ReportCodesPage';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const ReadingFailureReasonsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: readingStatisticsData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReadingFailureReasonsList(apiParams);

  const { isInitialLoading, isRefetching, refetch } = useExportReadingFailureReasons(exportParams);

  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['CRD', 'NLO', 'LEOKOZPONT'],
  });
  const { accessor: readingCenterAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: cyclicReadingType, accessor: crdAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'CRD')
  );
  const { data: readingFailureReasons } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'NLO')
  );

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_NEM_LEOLVASHATOSAGI_OKOK.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      name: 'plannedReadingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
      filterIsRequired: true,
    },
    {
      headerTitle: 'LEO_NEM_LEOLVASHATOSAGI_OKOK.CYCLIC_READING_TYPE',
      component: (
        <LeoSelectField
          name="type"
          data={cyclicReadingType}
          accessor={crdAccessor}
          label={'LEO_NEM_LEOLVASHATOSAGI_OKOK.CYCLIC_READING_TYPE'}
        />
      ),
      name: 'type',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.CYCLIC_READING_TYPE'),
    },
  ];

  const flattenData = (data: ReadingFailureReasonsStatisticsModel[]): ListItem[] =>
    data.map((item) => {
      const flattenedReasons = item.reasons.reduce((acc, curr) => {
        acc[curr.code] = curr.count;
        return acc;
      }, {} as Record<string, number>);

      return {
        readingCenter: item.readingCenter,
        ...flattenedReasons,
      };
    });

  const modifiedList = flattenData(readingStatisticsData?.data || []);

  const readingFailureReasonsFilterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_NEM_LEOLVASHATOSAGI_OKOK),
    validationSchema,
    isFetching,
  });

  const readingFailureReasonsColumns = [
    {
      key: 'readingCenter',
      header: <Trans i18nKey="LEO_LEOLVASOTTSAG.READING_CENTER" />,
      accessor: 'readingCenter',
      tooltip: (item: ListItem) => item.readingCenter && readingCenterAccessor[item.readingCenter],
      field: 'readingCenter',
    },
    readingFailureReasons.map((reason) => ({
      key: reason,
      header: (
        <Tooltip title={reason && getValueSetValues(valueSet, 'NLO', reason)}>
          <>{reason}</>
        </Tooltip>
      ),
      accessor: reason,
      sortable: false,
    })),
  ].flat();

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const panels = [readingFailureReasonsFilterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_NEM_LEOLVASHATOSAGI_OKOK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ crdAccessor }}
          keyField="readingCenter"
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={modifiedList || []}
          total={modifiedList.length}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={readingFailureReasonsColumns}
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default ReadingFailureReasonsPage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Link } from '@mui/material';
import { Panorama } from '@mui/icons-material';

import {
  DropdownMenuItem,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, HealthAndSafetyModel } from 'models';
import { useWorkerListSearch } from 'features/workers';
import { useExportHealthAndSafetyList, useHealthAndSafetyList } from 'features/healthAndSafety';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getPageName } from 'utils/format';
import { formatDate } from 'utils/dates';
import Yup from 'utils/yup';
import { openPhotoInNewTab } from 'utils/getPhotoLink';
import { RouteNames } from 'config/routeNames';
import { PhotoAccess } from 'config/photosConfig';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import { getYesOrNoLabel } from 'pages/Leo/ReadingUnitDetails/ReadingUnitDetailsPage';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const HealthAndSafetyPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    params,
    apiParams,
    exportParams,
    onFilter,
    onLoadQuery,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: healthAndSafetyData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useHealthAndSafetyList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportHealthAndSafetyList(exportParams);
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_H_S_JELZESEK.RECORDING_DATE',
      component: <LeoDateInterval name="recordingDate" />,
      name: 'recordingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
    },
    {
      headerTitle: 'KOZOS_H_S_JELZESEK.SAP_ID',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="KOZOS_H_S_JELZESEK.SAP_ID"
          readOnly
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'KOZOS_H_S_JELZESEK.CITY',
      component: <LeoTextField name="city" label={<Trans i18nKey="KOZOS_H_S_JELZESEK.CITY" />} />,
      name: 'city',
      panelFieldWidth: 12,
    },
  ];

  const getCoordinates = (hs: HealthAndSafetyModel, isLatitude: boolean) => (
    <Link
      variant="cellName"
      underline="none"
      fontWeight="bold"
      target="_blank"
      href={`https://maps.google.com?q=${hs.latitude},${hs.longitude}`}
    >
      {isLatitude ? hs.latitude : hs.longitude}
    </Link>
  );

  const healthAndSafetyColumns = [
    {
      key: 'recordingDate',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.RECORDING_DATE" />,
      accessor: (hs: HealthAndSafetyModel) => formatDate(hs.recordingDate),
      field: 'recordingDate',
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.SAP_ID" />,
      accessor: (hs: HealthAndSafetyModel) => hs.worker?.sapId,
      field: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'city',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.CITY" />,
      accessor: 'city',
      sortable: true,
    },
    {
      key: 'street',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.STREET" />,
      accessor: 'street',
      sortable: true,
    },
    {
      key: 'streetNumber',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.STREET_NUMBER" />,
      accessor: 'streetNumber',
      sortable: true,
    },
    {
      key: 'description',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.DESCRIPTION" />,
      accessor: 'description',
      sortable: true,
    },
    {
      key: 'isReported',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.IS_REPORTED" />,
      accessor: (hs: HealthAndSafetyModel) => getYesOrNoLabel(hs.isReported),
      field: 'isReported',
      sortable: true,
    },
    {
      key: 'latitude',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.LATITUDE" />,
      accessor: (hs: HealthAndSafetyModel) => getCoordinates(hs, true),
      field: 'latitude',
      sortable: true,
    },
    {
      key: 'longitude',
      header: <Trans i18nKey="KOZOS_H_S_JELZESEK.LONGITUDE" />,
      accessor: (hs: HealthAndSafetyModel) => getCoordinates(hs, false),
      field: 'longitude',
      sortable: true,
    },
  ];

  const { activePanel, closePanel, setPanel } = usePanelState();

  const validationSchema = Yup.object().shape({
    recordingDate: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_H_S_JELZESEK),
    validationSchema,
    isFetching,
  });

  const panels = [filterConfig];

  const viewPhotoHandler = ({ id }: HealthAndSafetyModel) =>
    openPhotoInNewTab(PhotoAccess.HEALTH_AND_SAFETY, id.toString());

  const healthAndSafetyActions = [
    {
      name: 'photo',
      label: 'KOZOS_H_S_JELZESEK.PHOTO_BUTTON',
      icon: <Panorama fontSize="small" />,
      onClick: (row) => viewPhotoHandler(row),
    },
  ] as DropdownMenuItem[];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_H_S_JELZESEK.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Table
        actions={healthAndSafetyActions}
        list={healthAndSafetyData?.data || []}
        total={healthAndSafetyData?.meta.total}
        timestamp={dataUpdatedAt}
        loading={isLoading}
        params={params}
        onSort={onSort}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        columns={healthAndSafetyColumns}
        enableCheckbox={false}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        panels={panels}
        pageHeaderActions={headerActions}
        activePanel={activePanel}
        setPanel={setPanel}
        onLoadQuery={onLoadQuery}
      />
    </Page>
  );
};

export default HealthAndSafetyPage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import * as Yup from 'yup';
import { Field } from 'formik';

import { ReadingCenterModel } from 'models';
import { BaseEditorDialogProps, EditorDialog, TextField } from 'components';
import { useCreateReadingCenter, useEditReadingCenter } from 'features/readingCenters';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';

export interface ReadingCenterEditorDialogProps extends BaseEditorDialogProps<ReadingCenterModel> {
  readingCenters?: string[];
}

const ReadingCenterEditorDialog: FC<ReadingCenterEditorDialogProps> = ({
  readingCenters,
  selected,
  ...props
}) => {
  const isEdit = Boolean(selected && selected.id);

  const { mutateAsync: editReadingCenter } = useEditReadingCenter();
  const { mutateAsync: createReadingCenter } = useCreateReadingCenter();

  const onSubmit = async (values?: Partial<ReadingCenterModel>) => {
    if (values?.id) {
      await editReadingCenter({ id: values.id, data: values });
    } else if (values) {
      await createReadingCenter(values);
    }
  };

  return (
    <EditorDialog
      initialValues={{ ...selected }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        leaderName: Yup.string().required().min(1).max(25),
        readingCenterId: Yup.mixed().required(),
      })}
      title={
        isEdit ? (
          <Trans i18nKey="LEO_LEO_KOZPONT_ALAPERTEKEK.EDIT" />
        ) : (
          <Trans i18nKey="LEO_LEO_KOZPONT_ALAPERTEKEK.ADD" />
        )
      }
      {...props}
    >
      <Field
        component={TextField}
        name="leaderName"
        label={<Trans i18nKey="LEO_LEO_KOZPONT_ALAPERTEKEK.LEADER_NAME" />}
      />
      <LeoSelectField
        name="readingCenterId"
        data={readingCenters}
        label="LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER"
      />
    </EditorDialog>
  );
};

export default ReadingCenterEditorDialog;

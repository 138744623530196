import React, { FC, ReactNode } from 'react';

interface ConditionalWrapperProps {
  /**
   * Determines if the wrapper is taking effect or not.
   */
  condition: boolean;
  /**
   * Conditionally wraps the children element, with a wrapper.
   */
  wrapper(children: React.ReactNode): void;
  /**
   * The content of the component.
   */
  children?: ReactNode;
}

export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => {
  return condition ? <>{wrapper(children)}</> : <>{children}</>;
};

import React, { FC, ReactNode } from 'react';

import { Card } from '@mui/material';
import { neutral } from 'theme/colors';

export interface StatsCardProps {
  topText: ReactNode;
  bottomText: ReactNode;
}

const styles = {
  cardContent: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    '@media (min-width: 1600px)': {
      width: '416px',
      height: '250px',
      display: 'flex',
      boxShadow: 'none',
      p: 4,
      border: `1px solid ${neutral[100]}`,
      borderRadius: 3,
    },
    '@media (max-width: 1600px)': {
      width: '333px',
      height: '200px',
      display: 'flex',
      boxShadow: 'none',
      p: 2.5,
      border: `1px solid ${neutral[100]}`,
      borderRadius: 3,
    },
  },
};

export const StatsCard: FC<StatsCardProps> = ({ topText, bottomText }) => {
  return (
    <Card sx={styles.cardContent}>
      {topText}
      {bottomText}
    </Card>
  );
};

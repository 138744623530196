import { styled } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

export const OpusIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: 'transparent',
  '&.Mui-disabled': {
    color: 'transparent',
    opacity: 0.3,
  },
}));

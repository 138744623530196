import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  LeoSelectField,
  Page,
  Table,
  Tooltip,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, ListItemByWorker, WorkerReportCodeModel } from 'models';
import LeoDateInterval from 'components/input-fields/leoDateInterval/LeoDateInterval';
import { RouteNames } from 'config/routeNames';
import {
  useReportCodesByWorkerListList,
  useExportReportCodesByWorker,
} from 'features/reportCodesByWorker';
import { useReportSubgroupKeys, useValueSetList } from 'features/valueSets';
import { getFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { getPageName } from 'utils/format';
import { arrayify } from 'utils/arrays';
import {
  getReportCodeByKey,
  getReportValueSetData,
  validationSchema,
} from 'pages/Leo/Monitoring/ReportCodes/ReportCodesPage';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const ReportCodesByWorkerPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: reportCodesByWorkerData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReportCodesByWorkerListList(apiParams);

  const { data: reportSubgroupKeys, isLoading: isReportSubgroupKeysLoading } =
    useReportSubgroupKeys();

  const { isInitialLoading, isRefetching, refetch } = useExportReportCodesByWorker(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList(
    {
      code: ['CRD', 'LEOKOZPONT', ...arrayify(reportSubgroupKeys)],
    },
    !isReportSubgroupKeysLoading
  );

  const { data: leoKozpontData, accessor: leoKozpontAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: cyclicReadingOrderTypes, accessor: crdAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'CRD')
  );

  const { data: reportCodesList } = getReportValueSetData(valueSet, reportSubgroupKeys);

  const { activePanel, closePanel, setPanel } = usePanelState();

  const flattenData = (data: WorkerReportCodeModel[]): ListItemByWorker[] =>
    data.map((item) => {
      const flattenedReportCodes = item.reportCodes.reduce((acc, curr) => {
        acc[curr.code] = curr.count;
        return acc;
      }, {} as Record<string, number>);

      return {
        field: 'readingCenter',
        readingCenter: item.readingCenter,
        sapId: item.sapId,
        ...flattenedReportCodes,
      };
    });

  const modifiedList = flattenData(reportCodesByWorkerData?.data || []);

  const byWorkerFilters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_JELENTES_KODOK_LEOLVASONKENT.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      name: 'plannedReadingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
      filterIsRequired: true,
    },
    {
      headerTitle: 'LEO_JELENTES_KODOK_LEOLVASONKENT.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenter"
          data={leoKozpontData}
          label="LEO_JELENTES_KODOK_LEOLVASONKENT.READING_CENTER"
        />
      ),
      name: 'readingCenter',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
    {
      headerTitle: 'LEO_JELENTES_KODOK_LEOLVASONKENT.CYCLIC_READING_ORDER_TYPE',
      component: (
        <LeoSelectField
          name="cyclicOrderType"
          data={cyclicReadingOrderTypes}
          accessor={crdAccessor}
          label="LEO_JELENTES_KODOK_LEOLVASONKENT.CYCLIC_READING_ORDER_TYPE"
        />
      ),
      name: 'cyclicOrderType',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.CYCLIC_READING_TYPE'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters: byWorkerFilters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_JELENTES_KODOK_LEOLVASONKENT),
    validationSchema,
    isFetching,
  });

  const reportCodesByWorkerColumns = [
    {
      key: 'readingCenter',
      header: <Trans i18nKey="NOTIFY_LOGS.READING_CENTER" />,
      accessor: 'readingCenter',
      tooltip: (item: ListItemByWorker) =>
        item.readingCenter && leoKozpontAccessor[item.readingCenter],
      field: 'readingCenter',
    },
    {
      key: 'sapId',
      header: <Trans i18nKey="LEO_JELENTES_KODOK_LEOLVASONKENT.WORKER_SAPID" />,
      accessor: 'sapId',
      sortable: true,
    },
    reportCodesList.map((code) => ({
      key: code,
      header: (
        <Tooltip title={valueSet && getReportCodeByKey(valueSet, reportSubgroupKeys, code)}>
          <>{code}</>
        </Tooltip>
      ),
      accessor: code,
      sortable: false,
    })),
  ].flat();

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_JELENTES_KODOK_LEOLVASONKENT.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ leoKozpontAccessor, crdAccessor }}
          keyField={(item) => `${item.sapId}-${item.readingCenter}`}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={modifiedList || []}
          total={modifiedList.length}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={reportCodesByWorkerColumns}
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default ReportCodesByWorkerPage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Grid, Typography, Card, Box, SvgIcon, CircularProgress, Link } from '@mui/material';

import { BaseDialogProps, Button, Dialog } from 'components';
import { useUcseOrderDetails } from 'features/orders';
import { useValueSetList } from 'features/valueSets';
import { neutral, primary } from 'theme/colors';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';
import { formatAddress } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { getYesOrNoLabel } from 'pages/Leo/ReadingUnitDetails/ReadingUnitDetailsPage';

import { ReactComponent as OrderDetails } from 'assets/icons/Titasz/OrderDetails.svg';

export interface UcseOrderDetailsProps extends BaseDialogProps {
  orderId: ID;
  viewPhotoHandler: any;
}

const styles = {
  root: {
    '.MuiDialog-paper': {
      backgroundColor: neutral[100],
    },
  },
  orderIdCard: {
    px: 2.5,
    py: 3,
    mt: 1,
    mb: 3,
    mr: 3,
    height: 120,
    display: 'flex',
    alignItems: 'center',
  },
  firstColumn: {
    p: 2,
    my: 3,
    mr: 3,
    minHeight: 432,
  },
  secondColumn: {
    p: 2,
    mt: 1,
    mb: 3,
    mr: 3,
    minHeight: 556,
  },
  icon: {
    ml: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: primary[100],
    borderRadius: 10,
    width: '52px',
    height: '52px',
  },
  photoContainer: {
    paddingTop: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const UcseOrderDetailsDialog: FC<UcseOrderDetailsProps> = ({
  orderId,
  viewPhotoHandler,
  ...props
}) => {
  const { data: orderDetails, isLoading } = useUcseOrderDetails(orderId);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['SM_ORDER_STATUS', 'FK', 'APPOINTMENT_TIME'],
  });

  const googleMapsLink =
    orderDetails?.measuredLatitude && orderDetails?.measuredLongitude ? (
      <Link
        variant="cellName"
        underline="none"
        fontWeight="bold"
        target="_blank"
        href={`https://maps.google.com?q=${orderDetails?.measuredLatitude},${orderDetails?.measuredLongitude}`}
      >
        {`${orderDetails?.measuredLatitude}, ${orderDetails?.measuredLongitude}`}
      </Link>
    ) : (
      ''
    );

  return (
    <Dialog
      sx={styles.root}
      maxWidth="lg"
      title={
        <Typography variant="h6" component="span">
          <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.DIALOG_TITLE" />
        </Typography>
      }
      {...props}
    >
      {isLoading || isValueSetLoading ? (
        <CircularProgress />
      ) : (
        <Grid container>
          <Grid item sm={6}>
            <Card elevation={0} sx={styles.orderIdCard}>
              <Grid container display="flex" alignItems="center">
                <Grid item sm={2}>
                  <Box sx={styles.icon}>
                    <SvgIcon component={OrderDetails} />
                  </Box>
                </Grid>
                <Grid item sm={10}>
                  <Typography variant="subtitle1">
                    <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.ORDER_ID" />
                  </Typography>
                  <Typography variant="h5">{orderDetails?.orderId}</Typography>
                </Grid>
              </Grid>
            </Card>
            <Card elevation={0} sx={styles.firstColumn}>
              <Grid container>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.ORDER_STATUS" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {orderDetails?.status &&
                      getValueSetValues(valueSet, 'SM_ORDER_STATUS', orderDetails?.status)}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.PARTNER_NAME" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{orderDetails?.partner?.name}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.PARTNER_ADDRESS" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{formatAddress(orderDetails?.address)}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.PARTNER_NUMBER" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{orderDetails?.partner?.sapId}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.CP_ID" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{orderDetails?.sapConsumptionPlaceId}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.BINDING_ID" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{orderDetails?.bindingId}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.APPOINTMENT_DATE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {formatDate(orderDetails?.appointmentDate)}{' '}
                    {orderDetails?.appointmentTime &&
                      getValueSetValues(
                        valueSet,
                        'APPOINTMENT_TIME',
                        orderDetails?.appointmentTime
                      )}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.ORIGINAL_APPOINTMENT_DATE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {formatDate(orderDetails?.originalAppointmentDate)}{' '}
                    {orderDetails?.originalAppointmentTime &&
                      getValueSetValues(
                        valueSet,
                        'APPOINTMENT_TIME',
                        orderDetails?.originalAppointmentTime
                      )}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.MBA" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{orderDetails?.mbaCode}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.CSO" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {getYesOrNoLabel(orderDetails?.bindingDataRequired)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item sm={6}>
            <Card elevation={0} sx={styles.secondColumn}>
              <Grid container>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.DOWNLOADABILITY_DATE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {formatDate(orderDetails?.downloadabilityDate)}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.DOWNLOAD_DATE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {formatDate(orderDetails?.downloadDate)}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.UPLOAD_DATE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{formatDate(orderDetails?.uploadDate)}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.COORDS" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  {googleMapsLink}
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.FINISH_DATE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {formatDate(orderDetails?.resultStatusDate, DATE_TIME_FORMAT)}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.SAP_UPLOAD" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{formatDate(orderDetails?.sapSynced)}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.FAILURE_REASON" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {orderDetails?.readingFailureReason?.value}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.FAILURE_COMMENT" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {orderDetails?.readingFailureReasonComment}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.COMMENT" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{orderDetails?.comment}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.PERSON_ON_PLACE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">{orderDetails?.nameOfPersonOnPlace}</Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.LEGAL_STATE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {orderDetails?.legalStateOfPersonOnPlace &&
                      getValueSetValues(valueSet, 'FK', orderDetails?.legalStateOfPersonOnPlace)}
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellHeaderLarge">
                    <Trans i18nKey="UCSE_MEGRENDELES_RESZLETEK.ANOMALOUS_USAGE" />
                  </Typography>
                </Grid>
                <Grid item sm={6} mb={2}>
                  <Typography variant="cellName">
                    {getYesOrNoLabel(orderDetails?.isUnauthorizedConsumption)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Box sx={styles.photoContainer}>
              <Button onClick={() => viewPhotoHandler(orderDetails)}>
                <Trans i18nKey="LEO_LEO_EGYSEG_MEGRENDELES_RESZLETEK.PHOTO_BUTTON" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default UcseOrderDetailsDialog;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

import { Card } from '@mui/material';

import { Page, Button, ConfirmDialog, useDialogState, showNotification } from 'components';
import { NotificationType } from 'models';
import { useSeed } from 'features/seed';

const styles = {
  importCard: {
    p: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const ValueSetUpdatePage: FC = () => {
  const { refetch, isFetching } = useSeed();

  const { isOpen, openDialog, closeDialog } = useDialogState();

  const onDialogConfirm = async () => {
    showNotification({
      content: i18n.t('KOZOS_ERTEKKESZLET.IN_PROGRESS'),
      type: NotificationType.WARNING,
    });

    await refetch();
    closeDialog();
  };

  return (
    <Page title={<Trans i18nKey="KOZOS_ERTEKKESZLET.TITLE" />}>
      <Card elevation={1} sx={styles.importCard}>
        <Button onClick={openDialog} loading={isFetching}>
          <Trans i18nKey="KOZOS_ERTEKKESZLET.BUTTON_LABEL" />
        </Button>
      </Card>
      <ConfirmDialog
        title={<Trans i18nKey="KOZOS_ERTEKKESZLET.UPDATE" />}
        confirmTitle={<Trans i18nKey="COMMON.OK" />}
        cancelTitle={<Trans i18nKey="COMMON.CANCEL" />}
        onSubmit={onDialogConfirm}
        onClose={closeDialog}
        loading={isFetching}
        open={isOpen}
      >
        <Trans i18nKey="KOZOS_ERTEKKESZLET.DIALOG_QUESTION" />
      </ConfirmDialog>
    </Page>
  );
};

export default ValueSetUpdatePage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Field, Form, Formik } from 'formik';

import { Box, Card } from '@mui/material';

import {
  Button,
  FileUpload,
  Page,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import { useUploadHolidays } from 'features/uploadHolidays';

const styles = {
  uploadCard: {
    p: 2,
    borderRadius: '8px',
    '@media (min-width: 1600px)': {
      width: '35%',
    },
    '@media (max-width: 1599px)': {
      width: '45%',
    },
  },
};

const UploadHolidaysPage: FC = () => {
  const { onFilter, onLoadQuery, params } = useTableQueryParams();

  const { mutateAsync: uploadHolidays, isLoading } = useUploadHolidays();

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE),
  });

  const { activePanel, closePanel, setPanel } = usePanelState();

  const panels = [filterConfig];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Card elevation={1} sx={styles.uploadCard}>
        <Formik
          initialValues={{ file: null }}
          onSubmit={(values, { resetForm }) => {
            uploadHolidays(values?.file?.[0]);
            resetForm();
          }}
        >
          {({ values, resetForm }) => (
            <Form>
              <Field
                label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.FILE_LABEL" />}
                component={FileUpload}
                multiple={false}
                onDropRejected={resetForm}
                name="file"
                accept={{
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                    '.xls',
                    '.xlsx',
                    '.xlsm',
                  ],
                }}
              />
              <Box mt={2}>
                <Button type="submit" disabled={!values.file} loading={isLoading}>
                  <Trans i18nKey="KOZOS_FELADAT_IMPORT.IMPORT_BUTTON" />
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Page>
  );
};

export default UploadHolidaysPage;

import { useState, useCallback } from 'react';

export type UseDialogStateResult<Data> = {
  isOpen: boolean;
  openDialog: (data?: Data | null) => void;
  openEmptyDialog: () => void;
  closeDialog: () => void;
  selected: Data | null;
};

export function useDialogState<Data>(initialOpen = false): UseDialogStateResult<Data> {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [selected, setSelected] = useState<Data | null>(null);

  const openDialog = useCallback(
    (data?: Data | null) => {
      setIsOpen(true);
      setSelected(data || null);
    },
    [setIsOpen, setSelected]
  );

  const openEmptyDialog = useCallback(() => {
    setIsOpen(true);
    setSelected(null);
  }, [setIsOpen, setSelected]);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    setSelected(null);
  }, [setIsOpen, setSelected]);

  return {
    isOpen,
    openDialog,
    closeDialog,
    openEmptyDialog,
    selected,
  };
}

import React, { FC, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Link, SvgIcon, Typography } from '@mui/material';

import { MenuConfigModel } from 'models';
import { DropdownMenu, DropdownMenuItem } from 'components';
import { menuConfig } from 'config/menuConfig';
import { RouteNames } from 'config/routeNames';
import { findRoute } from 'utils/findRoute';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/Material/triangleDropDown.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/Titasz/Home.svg';

const styles = {
  breadcrumb: {
    pl: 1,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'neutral.inverseOnSurface',
  },
  placeholderBox: {
    pr: 1,
    pt: 3,
  },
  iconButton: {
    p: 0,
    ml: 1,
    mr: 0.5,
    mt: '-3px',
  },
  homeIcon: {
    mr: 1,
    width: 14,
    height: 14,
  },
  arrowIcon: {
    width: 24,
    height: 24,
    color: 'neutral.onSurface',
  },
};

interface BreadcrumbItemProps {
  item: MenuConfigModel;
  active: boolean;
}

export const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ item, active }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef();

  const getDropDownItems = (parentRoute: string) =>
    findRoute(parentRoute, menuConfig)?.children?.map((dropdownItem) => ({
      name: dropdownItem.route,
      label: dropdownItem.displayName,
      onClick: () => navigate(`/${dropdownItem.route}`),
      allowedFor: dropdownItem.allowedFor,
    })) as DropdownMenuItem[];

  return (
    <Box data-testid="breadcrumb-item" key={item.route} sx={styles.breadcrumb} ref={containerRef}>
      <Link
        component={NavLink}
        color="neutralVariant.onSurfaceVariant"
        underline="none"
        to={item.route === RouteNames.HOME ? '/' : `/${item.route}`}
      >
        <Box display="flex" alignItems="center">
          {item.route === RouteNames.HOME && <SvgIcon component={HomeIcon} sx={styles.homeIcon} />}
          <Typography variant="body3" sx={{ fontWeight: active ? '600' : 'normal' }}>
            {t(item.displayName)}
          </Typography>
        </Box>
      </Link>
      {item.children ? (
        <DropdownMenu items={getDropDownItems(item.route)} customContainerRef={containerRef}>
          <IconButton sx={styles.iconButton}>
            <SvgIcon component={ArrowDownIcon} sx={styles.arrowIcon} />
          </IconButton>
        </DropdownMenu>
      ) : (
        <Box sx={styles.placeholderBox} />
      )}
    </Box>
  );
};

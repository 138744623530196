import { PageModifier } from 'pages/App/PrivateLayout';
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { selectIsLoggedIn } from 'store/auth';
import { getRedirectLink } from 'utils/redirectLink';

interface PrivateRouteProps extends PageModifier {
  children: ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, noAccess, notFound }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();

  if (isLoggedIn) {
    if (noAccess) {
      return <Navigate to={`/no-access`} />;
    }
    if (notFound) {
      return <Navigate to={`/not-found`} />;
    }
    return <>{children}</>;
  }

  return <Navigate to={`/login${getRedirectLink(location)}`} />;
};

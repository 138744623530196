import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, ReadingResultStatisticsModel } from 'models';
import { RouteNames } from 'config/routeNames';
import { useValueSetList } from 'features/valueSets';
import {
  useExportReadingResultStatistics,
  useReadingResultStatisticsList,
} from 'features/readingResultStatistics';
import Yup from 'utils/yup';
import { getPageName } from 'utils/format';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const RegisterValidator = () => {
  return t('ERRORS.INVALID_DATE');
};

export const plannedReadingDateValidationSchema = Yup.object().shape({
  plannedReadingDate: Yup.object()
    .nullable()
    .shape({
      from: Yup.mixed()
        .nullable()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
      to: Yup.mixed()
        .nullable()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
    }),
  badInterval: Yup.bool().oneOf([true], 'bad interval'),
});

const ReadingResultStatisticsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: readingResultStatisticsData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReadingResultStatisticsList(apiParams);
  const { isInitialLoading, isRefetching, refetch } =
    useExportReadingResultStatistics(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['CRD', 'LEOKOZPONT'],
  });

  const { data: cyclicReadingOrderTypes, accessor: crdAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'CRD')
  );
  const { accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_LEOLVASOTTSAG.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      name: 'plannedReadingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
    },
    {
      headerTitle: 'LEO_LEOLVASOTTSAG.TYPE',
      component: (
        <LeoSelectField
          name="type"
          data={cyclicReadingOrderTypes}
          accessor={crdAccessor}
          label="LEO_LEOLVASOTTSAG.TYPE"
        />
      ),
      name: 'type',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.CYCLIC_READING_TYPE'),
    },
  ];

  const readingResultStatisticsFilterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_LEOLVASOTTSAG),
    validationSchema: plannedReadingDateValidationSchema,
    isFetching,
  });

  const columns = [
    {
      key: 'readingCenter',
      header: <Trans i18nKey="LEO_LEOLVASOTTSAG.READING_CENTER" />,
      accessor: 'readingCenter',
      tooltip: (item: ReadingResultStatisticsModel) =>
        item.readingCenter && leoKozpontAccessor[item.readingCenter],
      field: 'readingCenter',
    },
    {
      key: 'orderCount',
      header: <Trans i18nKey="LEO_LEOLVASOTTSAG.ORDER_COUNT" />,
      accessor: 'orderCount',
      sortable: true,
    },
    {
      key: 'readCount',
      header: <Trans i18nKey="LEO_LEOLVASOTTSAG.READ_COUNT" />,
      accessor: 'readCount',
      sortable: true,
    },
    {
      key: 'unreadableCount',
      header: <Trans i18nKey="LEO_LEOLVASOTTSAG.UNREADABLE_COUNT" />,
      accessor: 'unreadableCount',
      sortable: true,
    },
    {
      key: 'unhandledCount',
      header: <Trans i18nKey="LEO_LEOLVASOTTSAG.UNHANDLED_COUNT" />,
      accessor: 'unhandledCount',
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const panels = [readingResultStatisticsFilterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_LEOLVASOTTSAG.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ crdAccessor }}
          keyField="readingCenter"
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={readingResultStatisticsData?.data || []}
          total={readingResultStatisticsData?.meta.total}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default ReadingResultStatisticsPage;

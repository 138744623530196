import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { Cached } from '@mui/icons-material';

import {
  DropdownMenuItem,
  Page,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, SmOrderAvailabilityModel } from 'models';
import Yup from 'utils/yup';
import { stringify } from 'utils/base64';
import { getPageName } from 'utils/format';
import { DATE_FORMAT, formatDate } from 'utils/dates';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { RouteNames } from 'config/routeNames';
import { selectUserRoles } from 'store/profile';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import { useExportUcseAvailabilty, useUcseAvailabiltyList } from 'features/ucseAvailability';
import LeoCheckBox from 'components/input-fields/leoCheckBox/LeoCheckBox';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { ReactComponent as Edit } from 'assets/icons/Material/modeEdit.svg';

import UcseAvailabilityMassEditDialog from './UcseAvailabilityMassEditDialog';
import UcseAvailabilityEditDialog from './UcseAvailabilityEditDialog';

const UcseAvailabilityPage: FC = () => {
  const [selectedDate, setSelectedDate] = useState<number>();

  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({
    filter: stringify({ morning: ['on'], afternoon: ['on'], evening: ['on'] }),
  });

  const {
    data: availabilityList,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useUcseAvailabiltyList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportUcseAvailabilty(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOREGIO', 'APPOINTMENT_TIME'],
  });
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });

  const { activePanel, closePanel, setPanel } = usePanelState();

  const { data: leoRegioData, accessor: leoRegioAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOREGIO')
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const roles = useSelector(selectUserRoles);

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<SmOrderAvailabilityModel>();

  const {
    isOpen: isBulkEditorOpen,
    openDialog: openBulkEditor,
    closeDialog: closeBulkEditor,
  } = useDialogState();

  const headerActions = [
    {
      name: 'bulkEdit',
      label: 'UCSE_RENDELKEZESRE_ALLAS.BULK_EDIT',
      icon: <Cached fontSize="small" />,
      onClick: () => openBulkEditor(),
    },
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
      visible: getFunctionRole(FunctionRoleName.SM_ORDER_AVAILABILITY_PAGE_ACTIONS, roles),
    },
  ];

  const actions: DropdownMenuItem[] = [
    {
      name: 'edit',
      label: 'COMMON.EDIT',
      icon: <Edit fontSize="small" />,
      onClick: (row: SmOrderAvailabilityModel) => openEditor(row),
      visible: getFunctionRole(FunctionRoleName.AREA_EXCLUSIONS_PAGE_ACTIONS, roles),
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'UCSE_RENDELKEZESRE_ALLAS.DATE',
      component: <LeoDateInterval name="date" isRelativeDateDisabled />,
      name: 'date',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      filterIsRequired: true,
    },
    {
      headerTitle: 'UCSE_RENDELKEZESRE_ALLAS.WORKER_CODE',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label={<Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.WORKER_CODE" />}
          freeSolo
          maxLength={5}
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
    },
    {
      headerTitle: 'UCSE_RENDELKEZESRE_ALLAS.REGION',
      component: (
        <LeoSelectField
          name="region"
          data={leoRegioData}
          accessor={leoRegioAccessor}
          label="UCSE_RENDELKEZESRE_ALLAS.REGION"
        />
      ),
      name: 'region',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.REGION'),
    },
    {
      headerTitle: 'UCSE_RENDELKEZESRE_ALLAS.TIMESLOT',
      component: <LeoCheckBox name="morning" label={<Trans i18nKey="COMMON.MORNING" />} />,
      name: 'morning',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.MORNING'),
    },
    {
      component: <LeoCheckBox name="afternoon" label={<Trans i18nKey="COMMON.AFTERNOON" />} />,
      name: 'afternoon',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.AFTERNOON'),
    },
    {
      component: <LeoCheckBox name="evening" label={<Trans i18nKey="COMMON.EVENING" />} />,
      name: 'evening',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.EVENING'),
    },
  ];

  const validationSchema = Yup.object().shape({
    date: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .required()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction)
          .test(
            'isMax30DaysApart validation',
            t('ERRORS.MAX_1_MONTH_APART', { totalDayOfMonth: selectedDate }),
            function isMaxOneMonthApartTestFunction(value) {
              const { from } = this.parent;
              const { daysInMonth } = DateTime.fromISO(from);

              const startDateObj = new Date(from).getTime();
              setSelectedDate(daysInMonth);
              const endDateObj = value ? new Date(value).getTime() : new Date().getTime();
              const diffInDays = Math.floor((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));
              return diffInDays < daysInMonth;
            }
          ),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.UCSE_RENDELKEZESRE_ALLAS),
    validationSchema,
    isFetching,
  });

  const columns = [
    {
      key: 'date',
      header: <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.DATE" />,
      accessor: (item: SmOrderAvailabilityModel) =>
        `${formatDate(item.date, DATE_FORMAT)} (${item.weekdayName})`,
      field: 'date',
      sortable: true,
    },
    {
      key: 'timeslot',
      header: <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.TIMESLOT" />,
      accessor: (item: SmOrderAvailabilityModel) =>
        item.timeslot && getValueSetValues(valueSet, 'APPOINTMENT_TIME', item.timeslot),
      field: 'timeslot',
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.WORKER_CODE" />,
      accessor: (item: SmOrderAvailabilityModel) => item.worker?.sapId,
      field: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'capacity.comment',
      header: <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.COMMENT" />,
      accessor: (item: SmOrderAvailabilityModel) => item.capacity?.comment,
      field: 'capacity.comment',
      sortable: false,
    },
    {
      key: 'capacity.capacity',
      header: <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.CAPACITY" />,
      accessor: (item: SmOrderAvailabilityModel) => item.capacity?.capacity,
      field: 'capacity.capacity',
      sortable: false,
    },
    {
      key: 'capacity.booked',
      header: <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.BOOKED" />,
      accessor: (item: SmOrderAvailabilityModel) => item.capacity?.booked,
      field: 'capacity.booked',
      sortable: false,
    },
    {
      key: 'capacity.actualOrderCount',
      header: <Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.ACTUAL_ORDER_COUNT" />,
      accessor: (item: SmOrderAvailabilityModel) => item.capacity?.actualOrderCount,
      field: 'capacity.actualOrderCount',
      sortable: false,
    },
  ];

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="UCSE_RENDELKEZESRE_ALLAS.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ leoRegioAccessor }}
          keyField={(item, index) => `${item.date}-${index}`}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={availabilityList?.data || []}
          total={availabilityList?.meta.total}
          timestamp={dataUpdatedAt}
          loading={isLoading}
          enableCheckbox={false}
          enableTableView={true}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
          actions={actions}
          mapActions
          pageHeaderActions={headerActions}
          onLoadQuery={onLoadQuery}
        />
      )}
      <UcseAvailabilityEditDialog
        open={isEditorOpen}
        selected={selectedEdit}
        onClose={closeEditor}
      />
      <UcseAvailabilityMassEditDialog open={isBulkEditorOpen} onClose={closeBulkEditor} />
    </Page>
  );
};

export default UcseAvailabilityPage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';

import { WorkerViewModel } from 'models';
import { BaseEditorDialogProps, EditorDialog } from 'components';
import { useValueSetList } from 'features/valueSets';
import { useEditWorker } from 'features/workers';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';

export interface WorkerEditorDialogProps extends BaseEditorDialogProps<WorkerViewModel> {}

const WorkerEditorDialog: FC<WorkerEditorDialogProps> = ({ selected, ...props }) => {
  const isEdit = Boolean(selected && selected.id);

  const { mutateAsync: editWorker } = useEditWorker();
  const { data: valueSet } = useValueSetList({ code: ['LEOKOZPONT'] });

  const { data: readingCenters } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );

  const onSubmit = async (values: Partial<WorkerViewModel>) => {
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'sapId' && (value === '' || value === null)) {
        delete values[key as keyof WorkerViewModel];
      }
    });

    if (values.id) {
      await editWorker({ id: values.id, data: values });
    }
  };

  const initVal = isEdit ? selected : {};

  return (
    <EditorDialog
      initialValues={{ ...initVal }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        sapId: Yup.string().nullable().min(1).max(5),
        readingCenter: Yup.string().nullable().required(),
      })}
      title={<Trans i18nKey="MUNKAVEGZOK.EDIT" />}
      {...props}
    >
      <Box mb={2}>
        <Typography variant="subtitle2">
          <Trans i18nKey="MUNKAVEGZOK.BASICDATA" />
        </Typography>
      </Box>

      <LeoSelectField
        name="readingCenter"
        data={readingCenters}
        label="MUNKAVEGZOK.READING_CENTER"
      />
      <LeoTextField name="sapId" label={<Trans i18nKey="MUNKAVEGZOK.SAP_ID" />} />
    </EditorDialog>
  );
};

export default WorkerEditorDialog;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { t } from 'i18next';

import {
  DeleteDialog,
  Page,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, ReadingCenterModel } from 'models';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import {
  useDeleteReadingCenter,
  useExportReadingCenters,
  useReadingCenterList,
} from 'features/readingCenters';
import { useValueSetList } from 'features/valueSets';
import { stringify } from 'utils/base64';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { selectUserRoles } from 'store/profile';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import ReadingCenterEditorDialog from 'pages/Leo/ReadingCenter/ReadingCenterEditorDialog';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { ReactComponent as Delete } from 'assets/icons/Material/delete.svg';
import { ReactComponent as Edit } from 'assets/icons/Material/modeEdit.svg';

const styles = {
  root: {
    '.MuiPaper-root': {
      width: 740,
    },
  },
};

const ReadingCenterPage: FC = () => {
  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<ReadingCenterModel>();

  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    selected: selectedDelete,
  } = useDialogState<ReadingCenterModel>();

  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({
    order: stringify([{ orderBy: 'leaderName' }]),
  });

  const {
    data: readingCentersMock,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReadingCenterList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportReadingCenters(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOKOZPONT'],
  });
  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.READING_CENTER_PAGE_ACTIONS, roles);

  const { data: leoKozpontData, accessor: leoKozpontAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );

  const { mutateAsync: deleteReadingCenter } = useDeleteReadingCenter();

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_LEO_KOZPONT_ALAPERTEKEK.LEADER_NAME',
      component: (
        <LeoTextField
          name="leaderName"
          label={<Trans i18nKey="LEO_LEO_KOZPONT_ALAPERTEKEK.LEADER_NAME" />}
        />
      ),
      name: 'leaderName',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.LEADER_NAME'),
    },
    {
      headerTitle: 'LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER',
      component: (
        <LeoSelectField
          name="readingCenterId"
          data={leoKozpontData}
          label={'LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER'}
        />
      ),
      name: 'readingCenterId',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.READING_CENTER'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_LEO_KOZPONT_ALAPERTEKEK),
    isFetching,
  });

  const readingCenterColumns = [
    {
      key: 'leaderName',
      header: <Trans i18nKey="LEO_LEO_KOZPONT_ALAPERTEKEK.LEADER_NAME" />,
      accessor: 'leaderName',
      sortable: true,
    },
    {
      key: 'readingCenterId',
      header: <Trans i18nKey="LEO_LEO_KOZPONT_ALAPERTEKEK.READING_CENTER" />,
      accessor: 'readingCenterId',
      tooltip: (item: ReadingCenterModel) =>
        item.readingCenterId && leoKozpontAccessor[item.readingCenterId],
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const actions = visible
    ? [
        {
          name: 'edit',
          label: 'COMMON.EDIT',
          icon: <Edit fontSize="small" />,
          onClick: (readingCenter: ReadingCenterModel) => openEditor(readingCenter),
        },
        {
          name: 'delete',
          label: 'COMMON.DELETE',
          icon: <Delete fontSize="small" />,
          onClick: (readingCenter: ReadingCenterModel) => openDelete(readingCenter),
        },
      ]
    : [];

  const onDelete = async () => {
    if (selectedDelete?.id) {
      await deleteReadingCenter(selectedDelete.id);
    }
  };

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_LEO_KOZPONT_ALAPERTEKEK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Box sx={styles.root}>
          <Table
            filterDetailsData={{ leoKozpontAccessor }}
            panels={panels}
            activePanel={activePanel}
            setPanel={setPanel}
            onTableColumnVisibilityChange={onTableColumnVisibilityChange}
            list={readingCentersMock?.data || []}
            total={readingCentersMock?.meta.total}
            timestamp={dataUpdatedAt}
            enableCheckbox={false}
            enableTableView={true}
            loading={isLoading}
            params={params}
            onSort={onSort}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            columns={readingCenterColumns}
            pageHeaderActions={headerActions}
            actions={actions}
            mapActions
            onAdd={
              visible ? (readingCenter: ReadingCenterModel) => openEditor(readingCenter) : undefined
            }
            onLoadQuery={onLoadQuery}
          />
        </Box>
      )}
      <ReadingCenterEditorDialog
        open={isEditorOpen}
        readingCenters={leoKozpontData}
        selected={selectedEdit}
        onClose={closeEditor}
      />
      <DeleteDialog
        open={isDeleteOpen}
        selected={selectedDelete}
        onClose={closeDelete}
        onSubmit={onDelete}
        deleteTitleAccessor="leaderName"
      />
    </Page>
  );
};

export default ReadingCenterPage;

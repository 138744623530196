import React, {
  ReactNode,
  ComponentType,
  useCallback,
  ReactElement,
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { Trans } from 'react-i18next';

import { get, isEmpty, isFunction, clone } from 'lodash';
import { Box, Checkbox, TableHead, TableBody, IconButton, Typography } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as OutlinedBox } from 'assets/icons/Material/holeSquare.svg';
import { ReactComponent as Square } from 'assets/icons/Material/square.svg';
import { ReactComponent as Pipe } from 'assets/icons/Material/checkSmall.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/Material/more.svg';

import {
  AccessController,
  ConditionalWrapper,
  DropdownMenu,
  DropdownMenuItem,
  FilterTypes,
  PageHeader,
  PageHeaderProps,
  Tooltip,
} from 'components';
import { PanelConfigModel, QueryParamModel } from 'models';
import { arrayify, removeEmpty } from 'utils/arrays';
import { parse } from 'utils/base64';
import * as qs from 'utils/query';
import { AccessRestriction } from 'utils/auth';
import { Env } from 'config/env';
import theme from 'theme';
import { neutral, neutralVariant, colorCodes, error } from 'theme/colors';
import { mergeSx } from 'utils/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SelectedRecord, setFilterParams } from 'store/common';
import { TableRoot, BaseTableRootProps } from './components/TableRoot';
import { TableCell } from './components/TableCell';
import { TableRow } from './components/TableRow';
import { TableHeaderCell } from './components/TableHeaderCell';

export type AccessorFunction<T> = (item: T, index: number) => ReactNode;

export type checkboxCellFunction<T> = (item: T) => 0 | 1;

export type Accessor<T> = string | AccessorFunction<T>;

export type CheckboxCallback<T> = checkboxCellFunction<T>;

export type KeyExtractor<T> = string | ((row: T, index: number) => string);

export interface Column<T> extends AccessRestriction {
  key: string;
  header?: ReactNode;
  tooltip?: Accessor<T> | ReactNode;
  hidden?: boolean;
}

export interface ColumnProps<T> extends Column<T> {
  cannotBeHidden?: boolean;
  accessor?: Accessor<T>;
  field?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sortable?: boolean;
  noWrap?: boolean;
  padding?: 'normal' | 'checkbox' | 'none';
  hideSortIcon?: boolean;
  customCheckboxCell?: boolean;
  checkboxCell?: boolean;
  checkboxClickHandler?: <O extends Record<string, any>>(
    listState: any[],
    item: O,
    column: ColumnProps<O>,
    originalList: any[]
  ) => (state: any[]) => (any | undefined)[];
  checkboxFieldHandler?: <F extends Record<string, any>>(
    listState: any[],
    item: F,
    column: ColumnProps<F>
  ) => 0 | 1;
  isParentCell?: boolean;
  subCells?: ColumnProps<T>[];
}

export interface ActionColumnProps<T> extends Column<T> {
  icon: ComponentType<SvgIconProps>;
  action?: (item: T, index: number) => unknown;
}

export interface TableProps<T> extends BaseTableRootProps, PageHeaderProps<T> {
  /**
   * The list of items that is rendered in the table.
   */
  list: T[];
  /**
   * Set of columns.
   */
  columns: (ColumnProps<T> | null)[];
  /**
   * List of actions that the user can trigger for the list items.
   */
  actions?: DropdownMenuItem[];
  /**
   * Individually list the actions in every row.
   */
  mapActions?: boolean;
  /**
   * Gets the keys for the rows.
   */
  keyField?: KeyExtractor<T>;
  /**
   * Set it to false, if you don't want to display the checkboxes.
   */
  enableCheckbox?: boolean;
  /**
   * Set it to false, if you don't want to display the table view menu.
   */
  enableTableView?: boolean;
  /**
   * Set it to true, if you don't want to display the divider.
   */
  disableDivider?: boolean;
  /**
   * Gets the panel list.
   */
  panels?: (PanelConfigModel | null)[];
  /**
   * Gets the active panel.
   */
  activePanel?: string | null;
  /**
   * Sets the panel.
   */
  setPanel?: (config?: PanelConfigModel | null) => void;
  onRowDoubleClick?: (item: T) => void;
  onAdd?: (item: any) => void;
  /**
   * The label of the add new item button in the page header.
   */
  addLabel?: ReactNode;
  /**
   * Add checkbox to table. Used for checkbox table.
   */
  checkBoxTable?: boolean;
  /**
   * Days of the checkbox. Used for checkbox table.
   */
  checkBoxHeaders?: { id: number; name: string; header?: string }[];
  /**
   * Slots of the checkbox. Used for checkbox table.
   */
  checkBoxSubHeaders?: { id: number; name: string }[];
  /**
   * State of the columns data. Used for checkbox table.
   */
  listState?: T[];
  /**
   * State of the columns handler. Used for checkbox table.
   */
  setListState?: React.Dispatch<React.SetStateAction<T[]>>;
  /**
   * Abbreviations for checkbox table.
   */
  abbreviations?: any;
  /**
   * Table is touched handler .
   */
  setIsTouched?: Dispatch<SetStateAction<any>>;
  /**
   * Use when use spinner in details page.
   */
  hasDetailParam?: string;
  /**
   * Use when use filter chips on the page.
   */
  onLoadQuery?: (query?: Partial<QueryParamModel> | null | undefined) => QueryParamModel;
  /**
   * Use to choose the checkbox key.
   */
  checkboxKey?: string;
  /**
   * Use it when we want the data immediately.
   */
  dataLoadImmediately?: boolean;
  /**
   * Use it when you want to reset the selected items on action.
   */
  resetSelectedOnAction?: boolean;
  /**
   * Use to show filter details.
   */
  filterDetailsData?: FilterTypes;
  /**
   * Use to scroll to last row in table.
   */
  memoizedRecord?: boolean;
  /**
   * Use to dispatch to last row in table.
   */
  scrollPositionEmitter?: ({ clickedItem, scrollToPosition }: SelectedRecord) => void;
  /**
   * Disable pagination in table.
   */
  disablePagination?: boolean;
}

const styles = {
  root: {
    ...theme.mixins.tableContainer,
  },
  placeholderCell: {
    height: 56,
    maxWidth: 10,
  },
  emptyTable: {
    p: 3,
  },
  cellHeader: {
    borderLeft: `1px solid ${neutral[100]}`,
  },
  parentCellHeader: {
    boxShadow: `0 0 1px rgba(0, 0, 0, 0.3)`,
  },
  subCellHeader: {
    border: 'none',
    boxShadow: `0 0 1px rgba(0, 0, 0, 0.3)`,
    minWidth: '120px',
  },
  cellHeaderBox: {
    pb: 0.5,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customCheckboxCellHeader: {
    minWidth: '120px',
  },
  noWrapCell: {
    whiteSpace: 'nowrap',
  },
  oddCell: {
    backgroundColor: neutralVariant[200],
    borderColor: neutralVariant[100],
  },
  checkboxHeader: {
    height: 56,
  },
  weekHeader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: colorCodes.selected,
    },
  },
  tableRowDeleted: {
    backgroundColor: error[100],
    '&:hover': {
      backgroundColor: error[200],
    },
  },
};

export const Table = <T extends Record<string, any>>({
  columns,
  onAdd,
  addLabel,
  actions,
  emptyState,
  mapActions,
  list,
  keyField,
  panels,
  enableCheckbox = true,
  enableTableView = true,
  activePanel,
  setPanel,
  onTableColumnVisibilityChange,
  onRowDoubleClick,
  pageHeaderActions,
  disableDivider = false,
  checkBoxTable = false,
  checkBoxHeaders = [],
  checkBoxSubHeaders = [],
  listState = [],
  setListState,
  setIsTouched,
  abbreviations,
  loading,
  hasDetailParam = undefined,
  onLoadQuery,
  checkboxKey,
  dataLoadImmediately = false,
  resetSelectedOnAction = false,
  filterDetailsData,
  memoizedRecord,
  scrollPositionEmitter,
  ...props
}: TableProps<T>) => {
  const [selected, setSelected] = useState<T[]>([]);
  const [previousSelected, setPreviousSelected] = useState<T | null>(null);
  const [columnsState, setColumnsState] = useState<(ColumnProps<T> | null)[]>(columns);
  const [firstRendered, setFirstRendered] = useState<boolean>(true);
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);
  const [scrollToPosition, setScrollPosition] = useState<number>();
  const arrayList = useMemo(() => arrayify(list), [list]);
  const validColumns = removeEmpty(columnsState);
  const validActions = removeEmpty(actions);
  const uniqueField = typeof keyField === 'string' ? keyField : 'id';
  const hasFilterParam = props.params?.filter;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const groupedCheckboxTable = checkBoxSubHeaders.length !== 0;

  const initialLoad = useCallback(
    (path: string, params: any) => {
      navigate(`${path}?${qs.stringify(params)}`, { replace: true });
      dispatch(setFilterParams({ ...props.params, from: location.pathname }));
    },
    [navigate, dispatch, location.pathname, props.params]
  );

  useEffect(() => {
    if (firstRendered) {
      initialLoad(location.pathname, props.params);
      setFirstRendered(false);
    }
  }, [initialLoad, firstRendered, location.pathname, props.params]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.shiftKey) {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = () => {
      setIsShiftPressed(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const updateTableView = useCallback(() => {
    const paramsColumns: ColumnProps<T>[] = parse(props.params?.columns);

    setColumnsState((prevState) => {
      const nextState = clone(prevState);

      if (isEmpty(paramsColumns)) {
        nextState.forEach((item) => {
          if (item?.hidden) {
            const { hidden, ...rest } = item;
            return rest;
          }

          return item;
        });

        return nextState;
      }

      return nextState.map((column) => {
        if (!column) return column;

        if (paramsColumns.find((item) => item.key === column?.key)) {
          return { ...column, hidden: true };
        }

        delete column.hidden;

        return column;
      });
    });
  }, [props.params?.columns]);

  useEffect(() => {
    updateTableView();
  }, [updateTableView]);

  const getFieldName = (column: ColumnProps<T>) => {
    if (!column.sortable) {
      return null;
    }

    return typeof column.accessor === 'string' ? column.accessor : column.field;
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && !selected.length) {
      const newSelected = arrayList.map((item) => item);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    const filteredArray = selected.reduce<T[]>(
      (acc, selectedItem) =>
        arrayList.find((x) => selectedItem[uniqueField] === x[uniqueField])
          ? [...acc, selectedItem]
          : acc,
      []
    );

    if (resetSelectedOnAction) {
      setSelected([]);
    } else {
      setSelected(filteredArray);
    }

    // We have to remove "selected" from the dependency list, because it would cause an infinte loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayList, resetSelectedOnAction]);

  const filterDataLoad = hasFilterParam || hasDetailParam ? loading : !loading;
  const isLoading = dataLoadImmediately ? loading : filterDataLoad;
  const emptyMessage = hasFilterParam
    ? emptyState || <Trans i18nKey="COMMON.TABLE_EMPTY_MESSAGE" />
    : '';

  return (
    <Box sx={[styles.root, { userSelect: isShiftPressed ? 'none' : 'auto' }]}>
      <PageHeader
        filterDetailsData={filterDetailsData}
        panels={panels}
        activePanel={activePanel}
        setPanel={setPanel}
        onTableView={enableTableView}
        selectedItems={selected}
        pageHeaderActions={pageHeaderActions}
        validColumns={validColumns.filter((i) => !i.isParentCell)}
        setColumnsState={setColumnsState}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        addLabel={addLabel}
        onAdd={onAdd}
        onLoadQuery={onLoadQuery}
        setFilterHeight={setFilterHeight}
        disableDivider={disableDivider}
      />
      <TableRoot
        {...props}
        loading={isLoading}
        filterHeight={filterHeight}
        setScrollPos={setScrollPosition}
        memoizedRecord={memoizedRecord}
      >
        <TableHead>
          {checkBoxTable && (
            <>
              {groupedCheckboxTable && (
                <TableRow style={styles.checkboxHeader}>
                  <TableHeaderCell
                    align="center"
                    padding="normal"
                    colSpan={
                      validColumns.filter((item) => !item.customCheckboxCell && !item.hidden)
                        .length + 1
                    }
                  />

                  <TableHeaderCell
                    align="center"
                    padding="normal"
                    colSpan={checkBoxHeaders.length * checkBoxSubHeaders.length * 2}
                    style={styles.cellHeader}
                  >
                    <Box display="flex" sx={styles.weekHeader}>
                      <OutlinedBox width={16} height={16} />
                      <Typography variant="body3" pl={1} pr={2}>
                        <Trans i18nKey="TABLE.EVEN_WEEK" />
                      </Typography>

                      <Square width={16} height={16} />
                      <Typography variant="body3" pl={1}>
                        <Trans i18nKey="TABLE.ODD_WEEK" />
                      </Typography>
                    </Box>
                  </TableHeaderCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell sx={styles.placeholderCell} padding="checkbox" />
                {validColumns.map((column) => {
                  const checkBoxTablePadding =
                    checkBoxTable && column.customCheckboxCell ? 'checkbox' : column.padding;
                  const tableHeaderCellStyle = column.checkboxCell
                    ? [styles.cellHeader, styles.customCheckboxCellHeader]
                    : null;
                  const tableHeaderCellChildren = !column.customCheckboxCell ? column.header : null;

                  return !column.hidden && !column.customCheckboxCell ? (
                    <AccessController allowedFor={column.allowedFor} key={column.key}>
                      <TableHeaderCell
                        align={column.align}
                        padding={checkBoxTablePadding}
                        hideSortIcon={false}
                        field={getFieldName(column)}
                        rowSpan={2}
                        sx={mergeSx(tableHeaderCellStyle)}
                      >
                        {tableHeaderCellChildren}
                      </TableHeaderCell>
                    </AccessController>
                  ) : null;
                })}
                {checkBoxHeaders.map((headerItem) => (
                  <TableHeaderCell
                    key={headerItem.name}
                    align="center"
                    padding="normal"
                    colSpan={groupedCheckboxTable ? checkBoxHeaders.length - 1 : 0}
                    sx={mergeSx(
                      groupedCheckboxTable ? null : styles.customCheckboxCellHeader,
                      styles.cellHeader
                    )}
                  >
                    {<Trans i18nKey={headerItem.header || headerItem.name} /> || null}
                  </TableHeaderCell>
                ))}
              </TableRow>

              {groupedCheckboxTable && (
                <TableRow>
                  <TableCell sx={styles.placeholderCell} padding="checkbox" />
                  {checkBoxHeaders.map(() =>
                    checkBoxSubHeaders
                      .map((slot) => ({
                        key: slot.name,
                        header: <Trans i18nKey={slot.name} />,
                      }))
                      .map((slotItem: any) => (
                        <TableHeaderCell
                          key={slotItem.key}
                          align="center"
                          colSpan={2}
                          padding="normal"
                          style={styles.cellHeader}
                        >
                          {slotItem.header || null}
                        </TableHeaderCell>
                      ))
                  )}
                </TableRow>
              )}
            </>
          )}

          {!checkBoxTable && (
            <TableRow>
              {!!validActions.length && !mapActions && <TableHeaderCell />}
              {enableCheckbox ? (
                <TableCell padding="checkbox">
                  <Tooltip
                    enterDelay={Env.TOOLTIP_DELAY}
                    enterNextDelay={Env.TOOLTIP_DELAY}
                    title={<Trans i18nKey="TABLE.SELECT_ALL_TOOLTIP" />}
                  >
                    <Checkbox
                      size="small"
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < list.length}
                      checked={list.length > 0 && selected.length === list.length}
                      onChange={handleSelectAllClick}
                    />
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell sx={styles.placeholderCell} padding="checkbox" rowSpan={3} />
              )}

              {validColumns.map((column) => {
                const tableHeaderCellPadding = checkBoxTable ? 'checkbox' : column.padding;

                return !column.hidden && !column.customCheckboxCell && !column.isParentCell ? (
                  <AccessController allowedFor={column.allowedFor} key={column.key}>
                    <TableHeaderCell
                      align={column.align}
                      padding={tableHeaderCellPadding}
                      hideSortIcon={column.hideSortIcon}
                      field={getFieldName(column)}
                    >
                      {column.header || null}
                    </TableHeaderCell>
                  </AccessController>
                ) : (
                  column.isParentCell && (
                    <AccessController allowedFor={column.allowedFor} key={column.key}>
                      <TableHeaderCell
                        align="center"
                        padding="checkbox"
                        style={styles.parentCellHeader}
                        hideSortIcon={column.hideSortIcon}
                        field={getFieldName(column)}
                        colSpan={column.subCells?.length}
                      >
                        <Box sx={styles.cellHeaderBox}>{column.header || null}</Box>
                        <Box sx={{ mb: -1 }}>
                          {column.subCells?.map((subCell) => (
                            <TableHeaderCell
                              component="div"
                              key={subCell.key}
                              align="center"
                              style={styles.subCellHeader}
                              hideSortIcon={subCell.hideSortIcon}
                              field={getFieldName(subCell)}
                            >
                              {subCell.header || null}
                            </TableHeaderCell>
                          ))}
                        </Box>
                      </TableHeaderCell>
                    </AccessController>
                  )
                );
              })}

              {mapActions &&
                validActions.map((action) => (
                  <AccessController key={action.name}>
                    <TableHeaderCell align="right" padding="checkbox" />
                  </AccessController>
                ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {arrayList.map((item, index) => {
            const getKey = () => {
              if (checkBoxTable && groupedCheckboxTable) {
                return `${item[checkboxKey as string]}`;
              }

              return String(
                isFunction(keyField) ? keyField(item, index) : get(item, keyField || 'id')
              );
            };

            return (
              <Row
                key={getKey()}
                uniqueField={uniqueField}
                list={list}
                item={item}
                index={index}
                enableCheckbox={enableCheckbox}
                columns={removeEmpty(columnsState)}
                actions={removeEmpty(actions)}
                mapActions={mapActions}
                selected={selected}
                setSelected={setSelected}
                previousSelected={previousSelected}
                setPreviousSelected={setPreviousSelected}
                onRowDoubleClick={onRowDoubleClick}
                setListState={setListState}
                listState={listState}
                abbreviations={abbreviations}
                setIsTouched={setIsTouched}
                checkBoxTable={checkBoxTable}
                groupedCheckboxTable={groupedCheckboxTable}
                scrollToPosition={scrollToPosition}
                scrollPositionEmitter={scrollPositionEmitter}
              />
            );
          })}

          {!arrayList.length ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h5" align="left" sx={styles.emptyTable}>
                  {emptyMessage}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </TableRoot>
    </Box>
  );
};

interface RowProps<T> {
  columns: ColumnProps<T>[];
  actions: DropdownMenuItem[];
  mapActions?: boolean;
  list: T[];
  item: T;
  index: number;
  enableCheckbox: boolean;
  selected: T[];
  setSelected: React.Dispatch<React.SetStateAction<T[]>>;
  previousSelected: T | null;
  setPreviousSelected: React.Dispatch<React.SetStateAction<T | null>>;
  onRowDoubleClick?: (row: T) => void;
  uniqueField: string;
  listState?: T[];
  abbreviations?: { [key: string]: string };
  setListState?: Dispatch<SetStateAction<any>>;
  setIsTouched?: Dispatch<SetStateAction<any>>;
  checkBoxTable?: boolean;
  groupedCheckboxTable?: boolean;
  scrollToPosition?: number;
  scrollPositionEmitter?: ({ clickedItem, scrollToPosition }: SelectedRecord) => void;
}

const Row = <T extends Record<string, any>>({
  columns,
  actions,
  mapActions,
  list,
  item,
  index,
  enableCheckbox,
  selected,
  setSelected,
  previousSelected,
  setPreviousSelected,
  onRowDoubleClick,
  uniqueField,
  listState,
  setListState,
  abbreviations,
  setIsTouched,
  checkBoxTable,
  groupedCheckboxTable,
  scrollToPosition,
  scrollPositionEmitter,
}: RowProps<T>) => {
  const getValue = useCallback(
    (accessor?: Accessor<T> | ReactNode) => {
      if (!accessor) {
        return null;
      }

      if (typeof accessor === 'string') {
        return get(item, accessor);
      }

      if (isFunction(accessor)) {
        return accessor(item, index);
      }

      return accessor;
    },
    [item, index]
  );

  const [fieldsAreCalculating, setFieldsAreCalculating] = useState(true);

  useEffect(() => {
    setFieldsAreCalculating(false);
  }, []);

  const checkboxDataStateIsValid = useMemo(
    () => listState?.length === list.length,
    [list.length, listState?.length]
  );

  const isItemSelected = useCallback(
    () => !!selected.find((i) => i[uniqueField] === item[uniqueField]),
    [selected, uniqueField, item]
  );

  const isSpecificItemSelected = (selectedItem: T) =>
    !!selected.find((i) => i[uniqueField] === selectedItem[uniqueField]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: T) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedIndex = selected.indexOf(row);

    if (selectedIndex === -1) {
      setSelected([...selected, row]);
    } else {
      const newSelected = selected.filter((i) => i[uniqueField] !== row[uniqueField]);
      setSelected(newSelected);
    }
  };

  const handleCheckboxClick = (column: ColumnProps<T>) => {
    if (column.checkboxClickHandler && checkboxDataStateIsValid) {
      if (setIsTouched) {
        setIsTouched(true);
      }
      if (setListState && listState) {
        setListState(column.checkboxClickHandler(listState, item, column, list));
      }
    }
  };

  const handleOpacitiy = (column: ColumnProps<T>) => {
    if (listState && column.checkboxFieldHandler && checkboxDataStateIsValid) {
      return column.checkboxFieldHandler(listState, item, column);
    }
    return 0;
  };

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const indexOfPrevious = list.indexOf(previousSelected as T);
    const selectedRows: T[] = [];

    switch (e.detail) {
      case 1:
        if (e.shiftKey && previousSelected) {
          if (indexOfPrevious < index) {
            for (let i = indexOfPrevious; i <= index; i += 1) {
              if (!isSpecificItemSelected(list[i])) selectedRows.push(list[i]);
            }
            setSelected([...selectedRows, ...selected]);
            setPreviousSelected(null);
          } else {
            for (let i = index; i <= indexOfPrevious; i += 1) {
              if (!isSpecificItemSelected(list[i])) selectedRows.push(list[i]);
            }
            setSelected([...selectedRows, ...selected]);
            setPreviousSelected(null);
          }
        } else if (e.shiftKey || e.ctrlKey) {
          if (isItemSelected()) {
            const newSelected = selected.filter((i) => i[uniqueField] !== item[uniqueField]);
            setSelected(newSelected);
            setPreviousSelected(null);
          } else {
            setSelected([...selected, item]);
            setPreviousSelected(item);
          }
        }
        break;
      case 2:
        if (onRowDoubleClick) {
          onRowDoubleClick(item);
          if (scrollPositionEmitter) {
            scrollPositionEmitter({ clickedItem: item, scrollToPosition });
          }
        }
        break;
      default:
    }
  };

  const getTooltip = (children: ReactElement, column: ColumnProps<T>) => (
    <Tooltip title={<>{getValue(column.tooltip)}</>}>{children}</Tooltip>
  );

  const getWrapperChildren = (children: ReactElement, label: string) => (
    <Tooltip title={<Trans i18nKey={label} />}>{children}</Tooltip>
  );

  return (
    <TableRow
      selected={isItemSelected()}
      onClick={handleRowClick}
      sx={{ ...(item.isDeleted ? styles.tableRowDeleted : styles.tableRow) }}
    >
      {!!actions.length && !mapActions && (
        <TableCell align="center" padding="checkbox">
          <DropdownMenu parentItem={item} items={actions}>
            <IconButton>
              <SvgIcon component={MoreIcon} fontSize="small" />
            </IconButton>
          </DropdownMenu>
        </TableCell>
      )}
      {enableCheckbox ? (
        <TableCell padding="checkbox">
          <Tooltip
            enterDelay={Env.TOOLTIP_DELAY}
            enterNextDelay={Env.TOOLTIP_DELAY}
            title={<Trans i18nKey="TABLE.SELECT_TOOLTIP" />}
          >
            <Checkbox
              size="small"
              color="primary"
              checked={isItemSelected()}
              onClick={(e) => handleClick(e, item)}
            />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell sx={styles.placeholderCell} padding="checkbox" />
      )}

      {columns.map((column) => {
        const customCheckBoxcellStyle =
          column.customCheckboxCell &&
          groupedCheckboxTable &&
          column.key.slice(-1) === abbreviations?.ODD
            ? styles.oddCell
            : null;

        const checkBoxCellStyle = checkBoxTable ? 'MuiTableCell-root-checkboxData' : undefined;

        const checkBoxCell = column.checkboxCell ? (
          <TableCell
            align="center"
            padding="checkbox"
            sx={mergeSx(styles.cellHeader)}
            onClick={() => handleCheckboxClick(column)}
          >
            {!fieldsAreCalculating && (
              <Pipe width={16} height={16} fillOpacity={handleOpacitiy(column)} />
            )}
          </TableCell>
        ) : (
          <TableCell
            align={column.align}
            padding={column.padding}
            noWrap={column.noWrap}
            className={checkBoxCellStyle}
          >
            <ConditionalWrapper
              condition={Boolean(column.tooltip)}
              wrapper={(children: ReactElement) => getTooltip(children, column)}
            >
              {getValue(column.accessor)}
            </ConditionalWrapper>
          </TableCell>
        );

        return !column.hidden ? (
          <AccessController allowedFor={column.allowedFor} key={column.key}>
            {column.customCheckboxCell && (
              <TableCell
                align="center"
                padding="checkbox"
                onClick={() => handleCheckboxClick(column)}
                sx={mergeSx(styles.cellHeader, customCheckBoxcellStyle)}
              >
                {!fieldsAreCalculating && (
                  <Pipe width={16} height={16} fillOpacity={handleOpacitiy(column)} />
                )}
              </TableCell>
            )}
            {!column.customCheckboxCell && !column.isParentCell && checkBoxCell}
            {column.isParentCell &&
              column.subCells?.map((subCell) => (
                <TableCell
                  key={subCell.key}
                  align="center"
                  padding={subCell.padding}
                  noWrap={subCell.noWrap}
                >
                  <ConditionalWrapper
                    condition={Boolean(subCell.tooltip)}
                    wrapper={(children: ReactElement) => getTooltip(children, subCell)}
                  >
                    {getValue(subCell.accessor)}
                  </ConditionalWrapper>
                </TableCell>
              ))}
          </AccessController>
        ) : null;
      })}

      {mapActions &&
        actions.map(({ name, label, onClick, icon, disabled, disabledIf, visible = true }) => {
          if (!visible) return null;

          const disabledHandler = () => {
            if (isFunction(disabledIf)) {
              return disabledIf(item);
            }
            return disabled;
          };

          return (
            <TableCell align="right" padding="checkbox" key={name}>
              <ConditionalWrapper
                condition={Boolean(label) && !disabledHandler()}
                wrapper={(children: ReactElement) => getWrapperChildren(children, label)}
              >
                <IconButton
                  sx={{ fill: disabledHandler() ? neutral[200] : neutral[600] }}
                  disabled={disabledHandler()}
                  onClick={() => onClick(item)}
                >
                  {icon}
                </IconButton>
              </ConditionalWrapper>
            </TableCell>
          );
        })}
    </TableRow>
  );
};

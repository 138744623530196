import {
  JobModel,
  ListResponseModel,
  NewPerformacePayModel,
  PerformanceBasedPayModel,
  QueryParamModel,
} from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

export interface ActionObjectModel {
  ids: ID[];
}

class PerformanceBasedPayService extends EntityService {
  constructor() {
    super('performance-based-pay');
  }

  async createPerformanceBasedPay(data: NewPerformacePayModel) {
    return request<{ jobId: string }>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data: { ...data, allActiveWorkers: data.allActiveWorkers === 'every' },
    });
  }

  async getJobById(jobId: string) {
    return request<JobModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/jobs/${jobId}`,
    });
  }

  async approvePerformanceBasedPay(data: ActionObjectModel) {
    return request({
      method: Methods.POST,
      resource: `v1/${this.resource}/approve`,
      data,
    });
  }

  async deletePerformanceBasedPay(data: ActionObjectModel) {
    return request({
      method: Methods.POST,
      resource: `v1/${this.resource}/delete`,
      data,
    });
  }

  async rejectPerformanceBasedPay(data: ActionObjectModel) {
    return request({
      method: Methods.POST,
      resource: `v1/${this.resource}/reject`,
      data,
    });
  }

  async reopenPerformanceBasedPay(data: ActionObjectModel) {
    return request({
      method: Methods.POST,
      resource: `v1/${this.resource}/reopen`,
      data,
    });
  }

  async runPerformanceBasedPay(id: ID) {
    return request<{ jobId: string }>({
      method: Methods.POST,
      resource: `v1/${this.resource}/${id}/run`,
    });
  }

  async sendToApprovePerformanceBasedPay(data: ActionObjectModel) {
    return request({
      method: Methods.POST,
      resource: `v1/${this.resource}/send-to-approve`,
      data,
    });
  }

  async downloadPerformanceBasedPay(data: ActionObjectModel) {
    const response = await requestFile<BlobPart>({
      method: Methods.POST,
      resource: `v1/${this.resource}/download`,
      data,
    });

    exportFile(response);
    return response;
  }

  async getPerformanceBasedPays(params?: QueryParamModel | null) {
    return request<ListResponseModel<PerformanceBasedPayModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async exportPerformanceBasedPays(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });

    exportFile(response);
    return response;
  }

  async editPerformanceBasedPays(id: ID, data: Partial<PerformanceBasedPayModel>) {
    const response = await request<PerformanceBasedPayModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/${id}`,
      data,
    });

    return response;
  }

  async upload(data: string | File) {
    const formData = new FormData();
    formData.append('file', data);

    const response = await requestFile<BlobPart>({
      method: Methods.POST,
      resource: `v1/${this.resource}/import`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    exportFile(response);
    return response;
  }

  async exportPayData(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/pay-data-export`,
      params,
    });

    exportFile(response);
    return response;
  }
}

export const performanceBasedPayService = new PerformanceBasedPayService();

import React, { FC, ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

import { Button, NavigationBreadcrumb } from 'components';
import { logout } from 'store/auth';
import useHideBreadCrumbToolbar from 'utils/useHideBreadCrumbToolbar';
import { neutralVariant } from 'theme/colors';

import { ReactComponent as LogoutIcon } from 'assets/icons/Titasz/Logout.svg';
import { ReactComponent as Logo } from 'assets/logo/LogoHorizontal.svg';

const styles = {
  appBar: (theme: Theme) => ({
    backgroundColor: 'background.paper',
    color: 'text.primary',
    transition: {
      mobileBreakpoint: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
  appBarShift: (theme: Theme) => ({
    width: {
      mobileBreakpoint: `calc(100% - ${theme.mixins.sideBar.width}px)`,
    },
    marginLeft: {
      mobileBreakpoint: theme.mixins.sideBar.width,
    },
    transition: {
      mobileBreakpoint: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  toolbarPlaceholder: {
    marginBottom: 2,
  },
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  logo: {
    pr: 3,
    my: '16px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid ${neutralVariant[200]}`,
  },
  logout: {
    ml: 'auto',
    display: 'flex',
    '.MuiButton-root': {
      textTransform: 'none',
    },
  },
  logoutIcon: {
    mr: 1,
  },
};

export interface HeaderProps {
  title: ReactNode;
  breadcrumbCustomLabel?: string;
}

export const Header: FC<HeaderProps> = ({ title, breadcrumbCustomLabel }) => {
  const dispatch = useDispatch();
  const hideBreadCrumbs = useHideBreadCrumbToolbar();

  const logoutHandler = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <>
      <AppBar elevation={0} position="fixed" sx={styles.appBar}>
        <Toolbar>
          <Box sx={styles.toolbarContainer}>
            <Box sx={styles.logo}>
              <Logo />
            </Box>
            <Typography variant="h6" ml={3}>
              {title}
            </Typography>
            <Box sx={styles.logout}>
              <Button variant="outlined" onClick={logoutHandler}>
                <SvgIcon component={LogoutIcon} fontSize="small" sx={styles.logoutIcon} />
                <Typography variant="subtitle1">
                  <Trans i18nKey="COMMON.LOGOUT" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Toolbar>
        {!hideBreadCrumbs && <NavigationBreadcrumb breadcrumbCustomLabel={breadcrumbCustomLabel} />}
      </AppBar>
      <Toolbar sx={styles.toolbarPlaceholder} />
    </>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { readingCenterService } from 'services';
import { QueryContextFromKeys, QueryParamModel, ReadingCenterModel } from 'models';

type ReadingCenterQueryContexts = QueryContextFromKeys<typeof readingCenterKeys>;

export const readingCenterKeys = {
  all: [{ scope: 'readingCenters' }] as const,
  lists: () => [{ ...readingCenterKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...readingCenterKeys.lists()[0], params }] as const,
};

const fetchReadingCenterList = async ({
  queryKey: [{ params }],
}: ReadingCenterQueryContexts['list']) => readingCenterService.getReadingCenterList(params);

const exportReadingCenters = async ({
  queryKey: [{ params }],
}: ReadingCenterQueryContexts['list']) => readingCenterService.exportReadingCenters(params);

export const useReadingCenterList = (params?: QueryParamModel | null) => {
  return useQuery(readingCenterKeys.list(params), fetchReadingCenterList, {
    enabled: !!params?.filter,
  });
};

export const useExportReadingCenters = (params?: QueryParamModel | null) => {
  return useQuery(readingCenterKeys.list(params), exportReadingCenters, { enabled: false });
};

export const useDeleteReadingCenter = () => {
  const queryClient = useQueryClient();

  return useMutation((id: ID) => readingCenterService.deleteReadingCenter(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: readingCenterKeys.lists() });
    },
  });
};

export const useEditReadingCenter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<ReadingCenterModel> }) =>
      readingCenterService.editReadingCenter(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingCenterKeys.lists() });
      },
    }
  );
};

export const useCreateReadingCenter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<ReadingCenterModel>) => readingCenterService.createReadingCenter(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingCenterKeys.lists() });
      },
    }
  );
};

import { ListResponseModel, QueryParamModel, ReportUnitPriceModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

class ReportUnitPriceService extends EntityService {
  constructor() {
    super('report-value-set-subgroup-unit-price');
  }

  async getReportUnitPriceList(params?: QueryParamModel | null) {
    return request<ListResponseModel<ReportUnitPriceModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async editReportUnitPrice(data: Partial<ReportUnitPriceModel>) {
    return request<ReportUnitPriceModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  async putReportUnitPrice(id: ID, data: Partial<ReportUnitPriceModel>) {
    return request<ReportUnitPriceModel[]>({
      method: Methods.PUT,
      resource: `v1/${this.resource}/${id}`,
      data,
    });
  }

  async deleteReportUnitPrice(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/${id}`,
    });
  }

  private exportReportUnitPriceList = async (params?: QueryParamModel | null) => {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });
    await exportFile(response);
    return response;
  };

  exportReportUnitPrice = (params?: QueryParamModel | null) =>
    this.exportReportUnitPriceList(params);
}

export const reportUnitPriceService = new ReportUnitPriceService();

import React, { FC } from 'react';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
import { Field } from 'formik';

import { SavedFilterModel } from 'models';
import { BaseEditorDialogProps, EditorDialog } from 'components/dialog/EditorDialog';
import { TextField } from 'components/form/text-field/TextField';
import { useSaveFilter } from 'features/filters';
import { LoadingOverlay } from 'components';
import { Theme, alpha } from '@mui/material';

export interface FilterCreationDialogProps
  extends BaseEditorDialogProps<Partial<SavedFilterModel>> {}

export const FilterCreationDialog: FC<FilterCreationDialogProps> = ({
  selected,
  callbackFn,
  isFetching,
  ...props
}) => {
  const { mutateAsync: saveFilter } = useSaveFilter();

  const onSubmit = async ({ filter, name, resource }: Partial<SavedFilterModel>) => {
    if (filter && name && resource) {
      saveFilter({ filter, name, resource }).then(() => {
        if (callbackFn) callbackFn();
      });
    }
  };

  return (
    <>
      {props.open && isFetching && (
        <LoadingOverlay
          show={isFetching}
          sx={{ background: (theme: Theme) => alpha(theme.palette.common.white, 0.5) }}
        />
      )}
      {!isFetching && (
        <EditorDialog
          initialValues={{ ...selected }}
          maxWidth="sm"
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            name: Yup.string().required().min(5).max(255),
          })}
          title={<Trans i18nKey="COMMON.SAVE_FILTER" />}
          isFetching={isFetching}
          {...props}
        >
          <Field component={TextField} name="name" label={<Trans i18nKey="COMMON.NAME" />} />
        </EditorDialog>
      )}
    </>
  );
};

import React from 'react';

import Box from '@mui/material/Box';
import { Field, FormikContextType, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  DatePicker,
  minusRelativeDays,
  plusRelativeDays,
  relativeToday,
} from 'components/form/datepicker/Datepicker';
import { ErrorMessage } from 'components/util/ErrorMessage';
import { isEqual } from 'lodash';
import { isValidDate } from 'utils/dates';
import { mergeSx } from 'utils/styles';
import { Typography } from '@mui/material';

const styles = {
  dateFieldContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  alignLeft: {
    paddingTop: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  fullWidth: {
    width: '33%',
  },
  leftDate: {
    marginRight: 1,
  },
  rightDate: {
    marginLeft: 1,
  },
};

interface ILeoDateInterval {
  name: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  width?: number;
  label?: React.ReactNode;
  isRelativeDateDisabled?: boolean;
}

export const relativeDateTestFunction = (date: string | undefined) => {
  if (date === undefined) return true;

  const relativeValue =
    isEqual(plusRelativeDays, date) ||
    isEqual(minusRelativeDays, date) ||
    isEqual(relativeToday, date);
  const isoDate = DateTime.fromISO(date);
  return !!isValidDate(isoDate) || relativeValue;
};

export const LeoDateInterval: React.FC<ILeoDateInterval> = ({
  minDate,
  maxDate,
  name,
  width = null,
  label = undefined,
  isRelativeDateDisabled = false,
}) => {
  const formCtx: FormikContextType<any> = useFormikContext();

  const dateIsInvalid =
    DateTime.fromISO(formCtx.values[`${name}`]?.from).startOf('day') >
    DateTime.fromISO(formCtx.values[`${name}`]?.to).startOf('day');

  const errorObj = {
    form: { touched: { error: true } } as any,
    field: { name: 'error' } as any,
    message: t('DATE.MIN_CANNOT_BIGGER_MAX'),
    show: dateIsInvalid,
  };

  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <Box sx={mergeSx(width ? styles.alignLeft : styles.dateFieldContent)}>
        <Box sx={mergeSx(styles.leftDate, { width: `${width}%` })}>
          <Field
            type="text"
            label={<Trans i18nKey="INPUT.START-DATE" />}
            id={`${name}.from`}
            name={`${name}.from`}
            minDate={minDate}
            rootKey={`${name}`}
            component={DatePicker}
            isRelativeDateDisabled={isRelativeDateDisabled}
            isInterval
            badInterval={dateIsInvalid}
            placeholder={t('INPUT.YMD')}
            variant="outlined"
          />
        </Box>
        <Box sx={mergeSx(styles.rightDate, { width: `${width}%` })}>
          <Field
            type="text"
            label={<Trans i18nKey="INPUT.END-DATE" />}
            id={`${name}.to`}
            name={`${name}.to`}
            badInterval={dateIsInvalid}
            maxDate={maxDate}
            rootKey={`${name}`}
            component={DatePicker}
            isRelativeDateDisabled={isRelativeDateDisabled}
            isInterval
            placeholder={t('INPUT.YMD')}
            variant="outlined"
          />
        </Box>
      </Box>
      <ErrorMessage {...errorObj} />
    </Box>
  );
};

export default LeoDateInterval;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import UploadIcon from '@mui/icons-material/Upload';

import {
  ColumnProps,
  LeoSelectField,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, JobModel } from 'models';
import { useValueSetList } from 'features/valueSets';
import { RouteNames } from 'config/routeNames';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { getPageName } from 'utils/format';
import Yup from 'utils/yup';
import { useJobList, useSapUpload } from 'features/sapUpload';
import { commonJobColumns } from '../Statistics/ImportTask/ImportTaskPage';

const SapUploadManualPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const { data: sapJobListData, isLoading, dataUpdatedAt } = useJobList(apiParams);
  const { mutateAsync: sapManualUpload, isLoading: isSapManualUploadLoading } = useSapUpload();
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['BACKGROUND_JOB_STATUS'],
  });

  const { data: backgroundJobStatus, accessor: backgroundJobStatusAccessor } =
    useFieldDataAndAccessor(getValueSetValues(valueSet, 'BACKGROUND_JOB_STATUS'));

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_FELADAT_IMPORT.STATUS',
      component: (
        <LeoSelectField
          name="status"
          readOnly
          data={backgroundJobStatus}
          accessor={backgroundJobStatusAccessor}
          label="KOZOS_FELADAT_IMPORT.STATUS"
        />
      ),
      name: 'status',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.STATUS'),
      filterIsRequired: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    status: Yup.mixed().required(),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_SAP_FELTOLTES_MANUALIS),
    validationSchema,
  });

  const { activePanel, closePanel, setPanel } = usePanelState();

  const panels = [filterConfig];

  const headerActions = [
    {
      name: 'upload',
      label: 'KOZOS_SAP_FELTOLTES_MANUALIS.START_UPLOAD',
      icon: <UploadIcon fill="currentColor" />,
      onClick: () => sapManualUpload(),
      isLoading: isSapManualUploadLoading,
    },
  ];

  const columns: ColumnProps<JobModel>[] = [
    {
      key: 'id',
      header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.ID" />,
      accessor: 'id',
      sortable: false,
    },
    ...commonJobColumns,
    {
      key: 'status',
      header: <Trans i18nKey="KOZOS_FELADAT_IMPORT.STATUS" />,
      accessor: (item: JobModel) =>
        item.status && getValueSetValues(valueSet, 'BACKGROUND_JOB_STATUS', item.status),
      field: 'status',
      sortable: false,
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_SAP_FELTOLTES_MANUALIS.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ backgroundJobStatusAccessor }}
          setPanel={setPanel}
          activePanel={activePanel}
          list={sapJobListData?.data || []}
          total={sapJobListData?.meta.total}
          timestamp={dataUpdatedAt}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          enableCheckbox={false}
          columns={columns}
          pageHeaderActions={headerActions}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          onLoadQuery={onLoadQuery}
          panels={panels}
        />
      )}
    </Page>
  );
};

export default SapUploadManualPage;

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { ucseCapacityByAreaService } from 'services/ucseCapacityByArea.service';
import { parse } from 'utils/base64';
import { emptyResponse } from 'features/helpers';

type UcseCapacityByAreaContexts = QueryContextFromKeys<typeof availabilityKeys>;

export const availabilityKeys = {
  all: [{ scope: 'ucseCapacityByArea' }] as const,
  lists: () => [{ ...availabilityKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...availabilityKeys.lists()[0], params }] as const,
};

const fetchUcseCapacityByAreaList = async ({
  queryKey: [{ params }],
}: UcseCapacityByAreaContexts['list']) => {
  const { startDate } = parse(params?.filter);

  return startDate ? ucseCapacityByAreaService.getUcseCapacityByAreaList(params) : emptyResponse;
};

export const useUcseCapacityByAreaList = (params?: QueryParamModel | null) =>
  useQuery(availabilityKeys.list(params), fetchUcseCapacityByAreaList, {
    enabled: !!params?.filter,
  });

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { FilterOptionModel, ListItem, ReportCodeModel, ValueSetModel } from 'models';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import {
  LeoSelectField,
  Page,
  Table,
  Tooltip,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { useExportReportCodes, useReportCodesList } from 'features/reportCodes';
import { useReportSubgroupKeys, useValueSetList } from 'features/valueSets';
import { RouteNames } from 'config/routeNames';
import { getFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import Yup from 'utils/yup';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { getPageName } from 'utils/format';
import { arrayify } from 'utils/arrays';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const RegisterValidator = () => {
  return t('ERRORS.INVALID_DATE');
};

export const validationSchema = Yup.object().shape({
  plannedReadingDate: Yup.object()
    .nullable()
    .shape({
      from: Yup.mixed()
        .nullable()
        .required()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
      to: Yup.mixed()
        .nullable()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
    }),
  badInterval: Yup.bool().oneOf([true], 'bad interval'),
});

export function getReportValueSetData(
  valueSet?: ValueSetModel[],
  reportSubgroupKeys?: string[],
  keyField?: string,
  valueField?: string,
  concenate?: boolean,
  displayKeyField?: string
) {
  if (!reportSubgroupKeys) {
    return {
      data: [],
      accessor: {},
    };
  }

  const fieldData = reportSubgroupKeys.map((key) =>
    getFieldDataAndAccessor(
      getValueSetValues(valueSet, key),
      keyField,
      valueField,
      concenate,
      displayKeyField
    )
  );

  return {
    data: fieldData.map((item) => item.data).flat(),
    accessor: fieldData.reduce((prev, item) => {
      return {
        ...prev,
        ...item.accessor,
      };
    }, {}),
  };
}

export function getReportCodeByKey(
  valueSetArray: ValueSetModel[],
  reportSubgroupKeys?: string[],
  key?: string
): string {
  if (!key || !reportSubgroupKeys) {
    return '';
  }

  let result = '';

  reportSubgroupKeys.forEach((group) => {
    const value = getValueSetValues(valueSetArray, group, key);

    if (value) {
      result = value;
    }
  });

  return result;
}

const ReportCodesPage: FC = () => {
  const { data: reportSubgroupKeys, isLoading: isReportSubgroupKeysLoading } =
    useReportSubgroupKeys();

  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: reportCodesData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useReportCodesList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportReportCodes(exportParams);
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList(
    {
      code: ['CRD', 'LEOKOZPONT', ...arrayify(reportSubgroupKeys)],
    },
    !isReportSubgroupKeysLoading
  );

  const { accessor: readingCenterAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOKOZPONT')
  );
  const { data: cyclicReadingType, accessor: crdAccessor } = getFieldDataAndAccessor(
    getValueSetValues(valueSet, 'CRD')
  );

  const { data: reportCodes } = getReportValueSetData(valueSet, reportSubgroupKeys);

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_JELENTES_KODOK.PLANNED_READING_DATE',
      component: <LeoDateInterval name="plannedReadingDate" />,
      name: 'plannedReadingDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.PLANNED_READING_DATE'),
      filterIsRequired: true,
    },
    {
      headerTitle: 'LEO_JELENTES_KODOK.CYCLIC_READING_ORDER_TYPE',
      component: (
        <LeoSelectField
          name="cyclicOrderType"
          data={cyclicReadingType}
          accessor={crdAccessor}
          label={'LEO_NEM_LEOLVASHATOSAGI_OKOK.CYCLIC_READING_TYPE'}
        />
      ),
      name: 'cyclicOrderType',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.CYCLIC_READING_TYPE'),
    },
  ];

  const flattenData = (data: ReportCodeModel[]): ListItem[] =>
    data.map((item) => {
      const flattenedReportCodes = item.reportCodes.reduce((acc, curr) => {
        acc[curr.code] = curr.count;
        return acc;
      }, {} as Record<string, number>);

      return {
        readingCenter: item.readingCenter,
        ...flattenedReportCodes,
      };
    });

  const modifiedList = flattenData(reportCodesData?.data || []);

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_JELENTES_KODOK),
    validationSchema,
    isFetching,
  });

  const panels = [filterConfig];

  const reportCodesColumns = [
    {
      key: 'readingCenter',
      header: <Trans i18nKey="LEO_JELENTES_KODOK.READING_CENTER" />,
      accessor: 'readingCenter',
      tooltip: (item: ListItem) => item.readingCenter && readingCenterAccessor[item.readingCenter],
      field: 'readingCenter',
    },
    reportCodes.map((code) => ({
      key: code,
      header: (
        <Tooltip title={valueSet && getReportCodeByKey(valueSet, reportSubgroupKeys, code)}>
          <>{code}</>
        </Tooltip>
      ),
      accessor: code,
      sortable: false,
    })),
  ].flat();

  const byWorkerHeaderActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_JELENTES_KODOK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ crdAccessor }}
          keyField="readingCenter"
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={modifiedList || []}
          total={modifiedList.length}
          timestamp={dataUpdatedAt}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading || isReportSubgroupKeysLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={reportCodesColumns}
          pageHeaderActions={byWorkerHeaderActions}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default ReportCodesPage;

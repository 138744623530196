import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { keyBy, pick } from 'lodash';
import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';

import { PerformanceBasedPayModel, PerformanceBasedPayPerformanceMetricsModel } from 'models';
import { BaseEditorDialogProps, EditorDialog } from 'components';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import { useEditPerformanceBasedPay } from 'features/performanceBasedPay';

export interface NewPerformanceEditorDialogProps
  extends BaseEditorDialogProps<PerformanceBasedPayModel> {}

interface FormikValues
  extends Omit<PerformanceBasedPayPerformanceMetricsModel, 'count' | 'toBePaid'> {
  count: string | number;
  toBePaid: string | number;
}

const NewPerformanceEditorDialog: FC<NewPerformanceEditorDialogProps> = ({
  selected,
  ...props
}) => {
  const isEdit = Boolean(selected && selected.id);
  const { mutateAsync: editPerformanceBasedPay } = useEditPerformanceBasedPay();

  const otherPerformanceBasedPay =
    selected?.otherPerformanceBasedPay === 0 ? '0' : selected?.otherPerformanceBasedPay;

  const hoursSpentWithOtherActivities =
    selected?.hoursSpentWithOtherActivities === 0 ? '0' : selected?.hoursSpentWithOtherActivities;

  const onSubmit = async (values: Partial<PerformanceBasedPayModel>) => {
    const performanceBasedPayPerformanceMetrics: any = Object.values(
      values.performanceMetricsObject || {}
    )?.map((metric) => {
      metric.count = +metric.count;
      metric.toBePaid = +metric.toBePaid;
      return pick(metric, 'id', 'count', 'toBePaid');
    });

    const sendData = pick(values, 'otherPerformanceBasedPay', 'hoursSpentWithOtherActivities');
    if (sendData.otherPerformanceBasedPay && sendData.hoursSpentWithOtherActivities) {
      sendData.hoursSpentWithOtherActivities = +sendData.hoursSpentWithOtherActivities;
      sendData.otherPerformanceBasedPay = +sendData.otherPerformanceBasedPay;
    }

    if (values.id) {
      await editPerformanceBasedPay({
        id: values.id,
        data: { ...sendData, performanceBasedPayPerformanceMetrics },
      });
    }
  };

  if (selected) {
    selected.performanceMetricsObject = keyBy(
      selected?.performanceBasedPayPerformanceMetrics,
      'performanceMetric.key'
    );
  }

  const getMetricFields = () => {
    if (selected && selected.performanceMetricsObject) {
      return Object.values(selected.performanceMetricsObject).map((item: FormikValues) => {
        item.toBePaid = item.toBePaid.toString();
        item.count = item.count.toString();

        return (
          <Box key={item.id}>
            <Typography variant="subtitle2">{item.performanceMetric.value}</Typography>
            <Box display="flex" columnGap={5}>
              <LeoTextField
                disabled={item.performanceMetric.isAutoCounted}
                name={`performanceMetricsObject.${item.performanceMetric.key}.count`}
                label={<Trans i18nKey="KOZOS_TELJESITMENYBEREK.COMPLETED" />}
              />
              <LeoTextField
                disabled
                name={`performanceMetricsObject.${item.performanceMetric.key}.toBePaid`}
                label={<Trans i18nKey="KOZOS_TELJESITMENYBEREK.TO_BE_PAID" />}
              />
            </Box>
          </Box>
        );
      });
    }
    return null;
  };

  const initVal = isEdit
    ? {
        ...selected,
        otherPerformanceBasedPay,
        hoursSpentWithOtherActivities,
      }
    : {};

  return (
    <EditorDialog
      initialValues={{ ...initVal }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      title={<Trans i18nKey="KOZOS_TELJESITMENYBEREK.EDIT" />}
      {...props}
    >
      <LeoTextField
        name="otherPerformanceBasedPay"
        label={<Trans i18nKey="KOZOS_TELJESITMENYBEREK.OTHER_PBP" />}
      />

      <LeoTextField
        name="hoursSpentWithOtherActivities"
        label={<Trans i18nKey="KOZOS_TELJESITMENYBEREK.HOURS_SPENT_WITH_OTHER" />}
      />
      {getMetricFields()}
    </EditorDialog>
  );
};

export default NewPerformanceEditorDialog;

const validationSchema = Yup.object().shape({
  otherPerformanceBasedPay: Yup.number().required().min(-999999).max(999999),
  hoursSpentWithOtherActivities: Yup.number().required().min(0).max(250),
  performanceMetricsObject: Yup.object()
    .notRequired()
    .default(undefined)
    .shape({
      CP_VISITED: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      CP_WITH_METERING_PHOTOS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      CUSTOMER_METER_READINGS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      DATA_CORRECTIONS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      LONG_TIME_NOT_SEEN_ABOVE_50_PERCENT: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      LONG_TIME_NOT_SEEN_BELOW_50_PERCENT: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      METER_PHOTOS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      MISSED_CR: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      NOT_DOABLE_CR: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      NOT_READABLE_PHOTOS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      ON_TIME_CR: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      OTHER_READING_PHOTOS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      OTHER_CR_PHOTOS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      READ_METER_READINGS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      REPORTS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
      UNHANDLED_ORDERS: Yup.object()
        .notRequired()
        .default(undefined)
        .shape({
          count: Yup.number().required().min(0).max(9999),
          toBePaid: Yup.number().required().min(-999999).max(999999),
        }),
    }),
});

import { ListResponseModel, QueryParamModel } from 'models';
import { SmOrderCapacityByAreaResponseModel } from 'models/smOrderCapacityByArea.model';
import { EntityService } from 'utils/entityService';
import request, { Methods } from 'utils/request';

class UcseCapacityByAreaService extends EntityService {
  constructor() {
    super('ucse-capacity-by-area');
  }

  async getUcseCapacityByAreaList(params?: QueryParamModel | null) {
    return request<ListResponseModel<SmOrderCapacityByAreaResponseModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }
}

export const ucseCapacityByAreaService = new UcseCapacityByAreaService();

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Link } from '@mui/material';

import {
  ColumnProps,
  Page,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, TimeLogModel } from 'models';
import { typeAccessor, typeData } from 'config/typeRecord';
import { RouteNames } from 'config/routeNames';
import { useExportTimeLogList, useTimeLogList } from 'features/timeLog';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import Yup from 'utils/yup';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { formatAddress, formatCoord, getPageName } from 'utils/format';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

import TimeLogExportDialog from './TimeLogExportDialog';

const TimeLogPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const timeLogValidationSchema = Yup.object().shape({
    taskId: Yup.number(),
    logDate: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .required()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
  });

  const { data: timeLogData, isLoading, isFetching, dataUpdatedAt } = useTimeLogList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportTimeLogList(exportParams);
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: eventLogTypes, isLoading: isValueSetLoading } = useValueSetList({
    code: ['EVENT_LOG_TYPE'],
  });

  const { activePanel, closePanel, setPanel } = usePanelState();

  const { data: eventLogTypeData, accessor: eventLogTypeAccessor } = useFieldDataAndAccessor(
    getValueSetValues(eventLogTypes, 'EVENT_LOG_TYPE'),
    'key',
    'value'
  );
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const getCoordinates = (latitude?: number, longitude?: number) =>
    latitude && longitude ? (
      <Link
        variant="cellName"
        underline="none"
        fontWeight="bold"
        target="_blank"
        href={`https://maps.google.com?q=${latitude},${longitude}`}
      >
        {formatCoord(latitude, longitude)}
      </Link>
    ) : (
      ''
    );

  const { isOpen, openDialog, closeDialog } = useDialogState();

  const doubleFilterPanel = true;

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
    {
      name: 'export-attendance',
      label: 'KOZOS_MUNKAVEGZOK_NAPLOJA.EXPORT_ATTENDANCE',
      icon: <Export fill="currentColor" />,
      onClick: openDialog,
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.DATEOFENTRY',
      component: <LeoDateInterval name="logDate" />,
      name: 'logDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.DATE_OF_ENTRY'),
      filterIsRequired: true,
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.WORKER_CODE',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="KOZOS_MUNKAVEGZOK_NAPLOJA.WORKER_CODE"
          freeSolo
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.CONSUMPTION_PLACE',
      component: (
        <LeoTextField
          name="sapConsumptionPlaceId"
          label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.CONSUMPTION_PLACE" />}
        />
      ),
      name: 'sapConsumptionPlaceId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.CONSUMPTION_PLACE'),
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.WIRING',
      component: (
        <LeoTextField
          name="bindingId"
          label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.WIRING" />}
        />
      ),
      name: 'bindingId',
      panelFieldWidth: 12,
      divider: !doubleFilterPanel,
      abbrName: t('FILTERABBR.WIRING'),
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.CITY',
      component: (
        <LeoTextField name="city" label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.CITY" />} />
      ),
      name: 'city',
      panelFieldWidth: 12,
      divider: true,
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.ENTRYTYPE',
      component: (
        <LeoSelectFieldMulti
          name="type"
          data={typeData}
          accessor={typeAccessor}
          label="KOZOS_MUNKAVEGZOK_NAPLOJA.ENTRYTYPE"
          readOnly
        />
      ),
      name: 'type',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.EVENT',
      component: (
        <LeoSelectField
          name="eventType"
          data={eventLogTypeData}
          accessor={eventLogTypeAccessor}
          label="KOZOS_MUNKAVEGZOK_NAPLOJA.EVENT"
        />
      ),
      name: 'eventType',
      panelFieldWidth: 12,
      divider: true,
    },
    {
      headerTitle: 'KOZOS_MUNKAVEGZOK_NAPLOJA.TASKID',
      component: (
        <LeoTextField name="taskId" label={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.TASKID" />} />
      ),
      name: 'taskId',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.TASK'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_TIME_LOG),
    validationSchema: timeLogValidationSchema,
    doubleFilterPanel: true,
    isFetching,
  });

  const panels = [filterConfig];

  const timeLogColumns: ColumnProps<TimeLogModel>[] = [
    {
      key: 'id',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.ENTRYID" />,
      accessor: 'id',
      sortable: true,
    },
    {
      key: 'logDate',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.DATEOFENTRY" />,
      accessor: (timeLogItem: TimeLogModel) => formatDate(timeLogItem.logDate, DATE_TIME_FORMAT),
      field: 'logDate',
      sortable: true,
    },
    {
      key: 'workerSapId',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.WORKER_CODE" />,
      accessor: 'workerSapId',
      sortable: true,
    },
    {
      key: 'type',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.ENTRYTYPE" />,
      accessor: (timeLogItem: TimeLogModel) => timeLogItem.type && typeAccessor[timeLogItem.type],
      field: 'type',
      sortable: true,
    },
    {
      key: 'eventType',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.EVENT" />,
      accessor: (timeLogItem: TimeLogModel) =>
        timeLogItem.eventType &&
        getValueSetValues(eventLogTypes, 'EVENT_LOG_TYPE', timeLogItem.eventType),
      field: 'eventType',
      sortable: true,
    },
    {
      key: 'sapConsumptionPlaceId',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.CONSUMPTION_PLACE" />,
      accessor: 'sapConsumptionPlaceId',
      sortable: true,
    },
    {
      key: 'bindingId',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.WIRING" />,
      accessor: 'bindingId',
      sortable: true,
    },
    {
      key: 'address.city',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.CONSUMPTION_PLACE_ADDRESS" />,
      accessor: (timeLogItem: TimeLogModel) => formatAddress(timeLogItem.address),
      field: 'address.city',
      sortable: true,
    },
    {
      key: 'latitude',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.ENTRY_GPS" />,
      accessor: (timeLogItem: TimeLogModel) =>
        getCoordinates(timeLogItem.latitude, timeLogItem.longitude),
      field: 'latitude',
      sortable: true,
    },
    {
      key: 'consumptionPlace.latitude',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.SAP_GPS" />,
      accessor: (timeLogItem: TimeLogModel) =>
        getCoordinates(
          timeLogItem.consumptionPlace?.latitude,
          timeLogItem.consumptionPlace?.longitude
        ),
      field: 'consumptionPlace.latitude',
      sortable: true,
    },
    {
      key: 'taskId',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.TASKID" />,
      accessor: 'taskId',
      sortable: true,
    },
    {
      key: 'note',
      header: <Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.NOTES" />,
      accessor: 'note',
      sortable: true,
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_MUNKAVEGZOK_NAPLOJA.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
      panels={panels}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ eventLogTypeAccessor }}
          panels={panels}
          setPanel={setPanel}
          activePanel={activePanel}
          list={timeLogData?.data || []}
          total={timeLogData?.meta.total}
          timestamp={dataUpdatedAt}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          enableCheckbox={false}
          columns={timeLogColumns}
          pageHeaderActions={headerActions}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          onLoadQuery={onLoadQuery}
        />
      )}
      <TimeLogExportDialog open={isOpen} onClose={closeDialog} />
    </Page>
  );
};

export default TimeLogPage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import * as Yup from 'yup';
import { DateTime } from 'luxon';

import { ReadingUnitSummaryModel } from 'models';
import { BaseEditorDialogProps, EditorDialog } from 'components';
import { useEditReadingUnit } from 'features/readingUnits';
import LeoDatePicker from 'components/input-fields/leoDatePicker/LeoDatePicker';
import { t } from 'i18next';

export interface ReadingUnitEditorDialogProps
  extends BaseEditorDialogProps<ReadingUnitSummaryModel> {}

const ReadingUnitEditorDialog: FC<ReadingUnitEditorDialogProps> = ({ selected, ...props }) => {
  const { mutateAsync: editReadingUnit } = useEditReadingUnit();

  const onSubmit = async (values?: Partial<ReadingUnitSummaryModel>) => {
    if (values?.id) {
      await editReadingUnit({ id: values.id, data: values });
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <EditorDialog
      initialValues={{ ...selected }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        downloadabilityDate: Yup.date().required().min(today, t('DATE.MIN_DATE_NOT_ALLOWED')),
      })}
      title={<Trans i18nKey="LEO_LEO_EGYSEGEK.EDIT" />}
      {...props}
    >
      <LeoDatePicker
        name="downloadabilityDate"
        minDate={DateTime.now()}
        label={<Trans i18nKey="LEO_LEO_EGYSEGEK.NEW_DOWNLOAD_DATE" />}
      />
    </EditorDialog>
  );
};

export default ReadingUnitEditorDialog;

import React, { FC, ReactNode, useCallback, useRef } from 'react';
import { Trans } from 'react-i18next';
import isFunction from 'lodash/isFunction';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AccessController } from 'components/auth/AccessController';
import { AllowedFor } from 'utils/auth';

export interface DropdownMenuItem {
  name: string;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  onClick: (e?: any) => void;
  disabledIf?: any;
  visible?: boolean;
  allowedFor?: AllowedFor;
}

export interface DropdownMenuProps {
  items: DropdownMenuItem[];
  parentItem?: any;
  children?: ReactNode;
  customContainerRef?: React.MutableRefObject<undefined>;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  parentItem,
  children,
  customContainerRef,
}) => {
  const containerRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      setAnchorEl((customContainerRef?.current || containerRef.current) ?? null);
    },
    [customContainerRef]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box ref={containerRef}>
      <Box onClick={handleOpen}>{children}</Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        onClick={handleClose}
      >
        {items.map(
          ({ name, label, onClick, icon, disabled, disabledIf, visible = true, allowedFor }) => {
            if (!visible) return null;

            const disabledHandler = () => {
              if (disabledIf) {
                if (isFunction(disabledIf)) {
                  return disabledIf(parentItem);
                }
                const key = Object.keys(disabledIf)[0];

                if (parentItem[key] === disabledIf[key]) {
                  return true;
                }
              }
              return disabled;
            };

            return (
              <AccessController key={name} allowedFor={allowedFor}>
                <MenuItem onClick={() => onClick(parentItem)} disabled={disabledHandler()}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText>
                    <Typography variant="subtitle1">
                      <Trans i18nKey={label} />
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </AccessController>
            );
          }
        )}
      </Menu>
    </Box>
  );
};

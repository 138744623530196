import React, { forwardRef } from 'react';

import Link, { LinkProps } from '@mui/material/Link';

import { Button, ButtonProps } from './Button';

export interface LinkButtonProps extends ButtonProps {
  href: LinkProps['href'];
  /**
   * Specifies where to open the linked document.
   * @default '_self'
   */
  target: LinkProps['target'];
}

export const LinkButton = forwardRef<HTMLDivElement, LinkButtonProps>(({ href, target, ...props }, ref) => {
  return (
    <Link href={href} target={target} underline="none">
      <Button ref={ref} {...props} />
    </Link>
  );
});

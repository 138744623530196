import React, { FC } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { Box, Breadcrumbs, IconButton, SvgIcon, Typography } from '@mui/material';

import { MenuConfigModel } from 'models';
import { AccessController, BreadcrumbItem } from 'components';
import { menuConfig } from 'config/menuConfig';
import { findRoute } from 'utils/findRoute';

import { ReactComponent as ChevronRightIcon } from 'assets/icons/Material/navigateNext.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/Material/arrowLeft.svg';

const styles = {
  breadcrumbBox: {
    pl: 3,
    py: '7px',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'neutralVariant.outlineVariant',
  },
  breadcrumb: {
    p: 0.5,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'neutral.inverseOnSurface',
  },
  rightIcon: {
    width: 24,
    height: 24,
    color: 'neutralVariant.outline',
  },
};

export interface BreadCrumbProps {
  breadcrumbCustomLabel?: string;
}

export const NavigationBreadcrumb: FC<BreadCrumbProps> = ({ breadcrumbCustomLabel }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const currentRoutes = location.pathname.slice(location.pathname.indexOf('/') + 1).split('/');

  const routesList = currentRoutes.reduce<string[]>((acc, _, i) => {
    acc.push(currentRoutes.slice(0, i + 1).join('/'));
    return acc;
  }, []);

  const breadcrumbRoutes = routesList.reduce<(MenuConfigModel | undefined)[]>(
    (acc, route) => (route ? [...acc, findRoute(route, menuConfig)] : acc),
    []
  );

  const getBreadcrumbs = () => [
    <BreadcrumbItem key={menuConfig[0].route} item={menuConfig[0]} active={routesList[0] === ''} />,
    breadcrumbRoutes.map(
      (item) =>
        item && (
          <AccessController allowedFor={item.allowedFor}>
            <BreadcrumbItem
              key={item.route}
              item={item}
              active={item.route === routesList[routesList.length - 1]}
            />
          </AccessController>
        )
    ),
    breadcrumbCustomLabel && (
      <Box key={breadcrumbCustomLabel} sx={styles.breadcrumb}>
        <Box display="flex" alignItems="center">
          <Typography variant="body3" sx={{ fontWeight: '600' }}>
            <Trans i18nKey={breadcrumbCustomLabel} values={{ id }} />
          </Typography>
        </Box>
      </Box>
    ),
  ];

  const navigateToParentRoute = () =>
    id ? navigate(-1) : navigate(`/${breadcrumbRoutes.at(-2)?.route ?? ''}`);

  return (
    <Box data-testid="breadcrumb" sx={styles.breadcrumbBox}>
      <IconButton sx={{ mr: 1 }} onClick={navigateToParentRoute}>
        <ArrowLeftIcon />
      </IconButton>
      <Breadcrumbs separator={<SvgIcon sx={styles.rightIcon} component={ChevronRightIcon} />}>
        {getBreadcrumbs()}
      </Breadcrumbs>
    </Box>
  );
};

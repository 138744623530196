import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type PhotoFailureQueryContexts = QueryContextFromKeys<typeof photoFailureKeys>;

export const photoFailureKeys = {
  all: [{ scope: 'photo-failure' }] as const,
  lists: () => [{ ...photoFailureKeys.all[0], entity: 'list' }] as const,
  photoFailureList: (params?: QueryParamModel | null) =>
    [{ ...photoFailureKeys.lists()[0], params }] as const,
};

const fetchPhotoFailureList = async ({
  queryKey: [{ params }],
}: PhotoFailureQueryContexts['photoFailureList']) =>
  statisticsService.getPhotoFailureReasonList(params);

const exportPhotoFailureList = async ({
  queryKey: [{ params }],
}: PhotoFailureQueryContexts['photoFailureList']) =>
  statisticsService.exportPhotoFailureReasonList(params);

export const usePhotoFailureList = (params?: QueryParamModel | null) => {
  return useQuery(photoFailureKeys.photoFailureList(params), fetchPhotoFailureList, {
    enabled: !!params?.filter,
  });
};

export const useExportPhotoFailureList = (params?: QueryParamModel | null) => {
  return useQuery(photoFailureKeys.photoFailureList(params), exportPhotoFailureList, {
    enabled: false,
  });
};

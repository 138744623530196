import { ValueSetModel, ValueSetValues } from 'models';

export function getValueSetValues(
  valueSet?: ValueSetModel[],
  code?: string,
  key?: string | number
) {
  const reducedValueSet = valueSet?.reduce<ValueSetModel | null>((acc, cur) => {
    if (cur.code === code) {
      acc = cur;
    }
    return acc;
  }, null);

  if (key) {
    return reducedValueSet?.values.find((v) => v.key === key.toString())?.value as any;
  }
  return reducedValueSet?.values as ValueSetValues[];
}

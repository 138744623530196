import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Button, Tooltip } from 'components';
import Link from '@mui/material/Link';

const Tooltips: FC = () => {
  return (
    <Box>
      <Typography variant="h6">Tooltip</Typography>
      <Box mb={2}>
        <Box mr={2} display="inline-block">
          <Tooltip title="I am a tooltip">
            <Button>Hover me</Button>
          </Tooltip>
        </Box>
        <Box mr={2} display="inline-block">
          <Tooltip title="I am a tooltip" placement="top">
            <Button>Top placement</Button>
          </Tooltip>
        </Box>
      </Box>

      <Box mb={1} mt={2}>
        <Link href="https://material-ui.com/components/tooltips/" target="_blank" rel="noopener" underline="hover">
          More examples
        </Link>
      </Box>
    </Box>
  );
};

export default Tooltips;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { TransferTaskFormProps } from 'pages/Ucse/UcseOrders/TransferTaskDialog';
import { DeleteQuery, ucseOrderService } from 'services/ucseOrder.service';

type UcseOrderQueryContexts = QueryContextFromKeys<typeof keys>;

const keys = {
  all: [{ scope: 'ucseOrders' }] as const,
  lists: () => [{ ...keys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...keys.lists()[0], params }] as const,
  details: (id: ID) => [{ ...keys.lists()[0], id }] as const,
};

const fetchUcseOrderList = async ({ queryKey: [{ params }] }: UcseOrderQueryContexts['list']) =>
  ucseOrderService.getUcseOrderList(params);

const fetchUcseOrderDetails = async ({ queryKey: [{ id }] }: UcseOrderQueryContexts['details']) =>
  ucseOrderService.getUcseOrderDetails(id);

const exportUcseOrders = async ({ queryKey: [{ params }] }: UcseOrderQueryContexts['list']) =>
  ucseOrderService.exportUcseOrders(params);

export const useUcseOrderList = (params?: QueryParamModel | null) =>
  useQuery(keys.list(params), fetchUcseOrderList, { enabled: !!params?.filter });

export const useUcseOrderDetails = (id: ID) => useQuery(keys.details(id), fetchUcseOrderDetails);

export const useExportUcseOrders = (params?: QueryParamModel | null) =>
  useQuery(keys.list(params), exportUcseOrders, { enabled: false });

export const useDeleteOrder = () => {
  const queryClient = useQueryClient();

  return useMutation((ids: DeleteQuery) => ucseOrderService.deleteUcseOrder(ids), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useSaveTransferTask = () => {
  const queryClient = useQueryClient();

  return useMutation((data: TransferTaskFormProps) => ucseOrderService.saveTransferTask(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

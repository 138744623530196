import React, { Fragment, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import Grid from '@mui/material/Grid';
import { Box, Divider, Typography } from '@mui/material';
import { FilterOptionModel, QueryParamModel } from 'models';
import { Field, FormikProps } from 'formik';
import { ObjectSchema } from 'yup';
import { v4 as uuid } from 'uuid';

import { arrayify } from 'utils/arrays';
import { mergeSx } from 'utils/styles';
import { ConditionalWrapper } from 'components/util/ConditionalWrapper';
import { SubmitArgs } from 'pages/Ucse/Appointment/AppointmentPage';
import { FilterContainer, FilterSubmitType } from './FilterContainer';

export interface FilterPanelContentProps<Values> {
  pageName: string;
  /**
   * The available filter fields.
   */
  filters?: FilterOptionModel[];
  /**
   * Query params, which the filtering logic is based on.
   */
  params?: QueryParamModel | null;
  /**
   * Default values of the fields
   */
  defaultValues?: Partial<Values>;
  /**
   * Function, that is triggered on the submit event.
   */
  onFilter?: (params?: QueryParamModel | null) => void;
  /**
   * Determines if the submit event is triggered on an onChange event or a button click.
   */
  type?: FilterSubmitType;
  /**
   * Swow when filter panel is load.
   */
  onLoad?: (params?: QueryParamModel | null) => void;
  /**
   * This is formik validation schema.
   */
  validationSchema: ObjectSchema<any>;
  /**
   * Wrap filters to two column.
   */
  doubleFilterPanel: boolean;
  /**
   * Show fetching status.
   */
  isFetching?: boolean;
  /**
   * It helps to call submit function by reference.
   */
  submitRef?: React.MutableRefObject<SubmitArgs | undefined>;
  /**
   * It helps to use state callback function.
   */
  stateHandler?: (formikValues: FormikProps<any> | undefined) => void;
  /**
   * Display hide filter option.
   */
  hidden?: boolean;
}

const styles = {
  twoColumns: {
    columnCount: 2,
    columnGap: 4,
  },
  headerTitleStyle: {
    width: '100%',
    paddingTop: 2,
    paddingLeft: 2,
    paddingBottom: 1,
  },
  dividerStyle: {
    width: '100%',
    paddingLeft: 2,
  },
  groupedContainerStyle: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
      "title title"
      "left right"
      "divider divider"`,
    columnGap: 16,
  },
  groupedFilterHeader: {
    gridArea: 'title',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 8,
  },
  groupedDivider: {
    gridArea: 'divider',
    width: '100%',
  },
  leftPanel: {
    paddingRight: '15px',
    width: '50%',
  },
  rightPanel: {
    paddingLeft: '15px',
    width: '50%',
  },
};

enum Measure {
  FULLWIDTH = 12,
}

export function FilterPanelContent<Values>({
  filters: filtersParam,
  params,
  onFilter,
  type,
  defaultValues,
  onLoad,
  pageName,
  validationSchema,
  doubleFilterPanel,
  submitRef,
  stateHandler,
  isFetching,
}: FilterPanelContentProps<Values>) {
  const order: number[] = [];

  const nonFullwidthFilters = filtersParam?.reduce(
    (acc: FilterOptionModel[] | undefined, filter, index) => {
      if (filter.panelFieldWidth !== Measure.FULLWIDTH) {
        acc?.push(filter);
        if (filter.headerTitle) order.push(index);
      }
      return acc;
    },
    []
  );

  const groupedNonFullWFilters: FilterOptionModel[][] = [];
  nonFullwidthFilters?.forEach((filterItem, filterIndex) => {
    const groupIndex = Math.floor(filterIndex / 2);

    if (groupedNonFullWFilters[groupIndex] === undefined) {
      groupedNonFullWFilters[groupIndex] = [];
    }
    groupedNonFullWFilters[groupIndex].push(filterItem);
  });

  const filters = arrayify(filtersParam).map(
    ({
      name,
      component,
      props,
      label,
      panelFieldWidth,
      panelFieldOffset,
      headerTitle,
      divider = false,
      key,
      hidden,
    }) => {
      return (
        (!doubleFilterPanel || panelFieldWidth === Measure.FULLWIDTH) && (
          <Fragment key={key ?? name}>
            {headerTitle && (
              <Box
                sx={mergeSx(styles.headerTitleStyle, doubleFilterPanel ? { paddingLeft: 0 } : null)}
              >
                <Typography variant="caption">
                  <Trans i18nKey={headerTitle} />
                </Typography>
              </Box>
            )}

            <Grid item xs={panelFieldWidth} sx={mergeSx(hidden ? { display: 'none' } : null)}>
              <Field name={name} component={() => component} label={label && label()} {...props} />
            </Grid>
            {divider && (
              <Box sx={mergeSx(styles.dividerStyle, doubleFilterPanel ? { paddingLeft: 0 } : null)}>
                <Divider sx={{ width: '100%' }} />
              </Box>
            )}

            {panelFieldOffset && <Grid key={`${name}-offset`} item xs={panelFieldOffset} />}
          </Fragment>
        )
      );
    }
  );

  const piecedFilters = arrayify(groupedNonFullWFilters).map((groupedItem, groupedIndex) => (
    <Box
      key={groupedItem[groupedIndex]?.name || uuid()}
      style={styles.groupedContainerStyle}
      sx={mergeSx(
        groupedItem[groupedIndex]?.extraStyle
          ? { ...groupedItem[groupedIndex]?.extraStyle?.gridContainerStyle }
          : null
      )}
    >
      {groupedItem.map(
        ({ name, component, props, label, headerTitle, divider = false, key }, index) => {
          const gridArea = index ? 'right' : 'left';

          return (
            <Fragment key={key ?? name}>
              {headerTitle && (
                <Box style={styles.groupedFilterHeader}>
                  <Typography variant="caption">
                    <Trans i18nKey={headerTitle} />
                  </Typography>
                </Box>
              )}
              <Grid item sx={{ gridArea }}>
                <Field
                  name={name}
                  component={() => component}
                  label={label && label()}
                  {...props}
                />
              </Grid>
              {divider && (
                <Box style={styles.groupedDivider}>
                  <Divider sx={{ width: '100%' }} />
                </Box>
              )}
            </Fragment>
          );
        }
      )}
    </Box>
  ));

  if (doubleFilterPanel) {
    piecedFilters.forEach((filter, index) => {
      filters.splice(order[index] + index, 0, filter);
    });
  }

  const getWrapperChildren = (children: ReactNode[]) => {
    const len = children.length;
    const oddRows = filters.length % 2;
    return (
      <Box sx={{ display: 'flex' }}>
        <Box style={styles.leftPanel}>{children.slice(0, len / 2 + oddRows)}</Box>
        <Box style={styles.rightPanel}>{children.slice(len / 2 + oddRows, len)}</Box>
      </Box>
    );
  };

  return (
    <>
      <FilterContainer
        params={params}
        onFilter={onFilter}
        defaultFilterState={defaultValues}
        type={type}
        onLoad={onLoad}
        pageName={pageName}
        validationSchema={validationSchema}
        isFetching={isFetching}
        submitRef={submitRef}
        stateHandler={stateHandler}
      >
        <ConditionalWrapper
          condition={doubleFilterPanel}
          wrapper={(children: ReactNode[]) => getWrapperChildren(children)}
        >
          {filters}
        </ConditionalWrapper>
      </FilterContainer>
    </>
  );
}

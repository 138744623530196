import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

import Yup from 'utils/yup';
import { mergeSx } from 'utils/styles';
import { getPageName } from 'utils/format';
import { parse } from 'utils/base64';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { colorCodes } from 'theme/colors';
import { FilterOptionModel } from 'models';

import { RouteNames } from 'config/routeNames';
import { useWorkerListSearch } from 'features/workers';
import { useValueSetList } from 'features/valueSets';
import { useUcseCapacityByAreaList } from 'features/ucseCapacityByArea';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import LeoDatePicker from 'components/input-fields/leoDatePicker/LeoDatePicker';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import { CapacityTable } from 'components/table/CapacityTable';
import { Page, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { SmOrderRequestModel } from 'models/smOrderCapacityByArea.model';
import { getHeader, addDataToHeader, FreeCapacityRecords } from './tableHelpers';

const styles = {
  colorCodes: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  colorCode: {
    display: 'flex',
    alignItems: 'center',
  },
  square: {
    height: '20px',
    width: '20px',
    marginRight: 1,
  },
  red: {
    backgroundColor: colorCodes.red,
  },
  green: {
    backgroundColor: colorCodes.green,
  },
  yellow: {
    backgroundColor: colorCodes.yellow,
  },
};

enum Percentages {
  LOW = '0-25%',
  MEDIUM = '26-75%',
  HIGH = '76-100%',
}

export interface TableHelper {
  date: string;
  dayValue: string | undefined;
  weekValue: string | undefined;
  title: string;
}

const UcseFreeCapacityPage: FC = () => {
  const { onPageChange, onRowsPerPageChange, onLoadQuery, onFilter, onSort, params, apiParams } =
    useTableQueryParams();

  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );

  const {
    data: smOrderCapacityByArea,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useUcseCapacityByAreaList(apiParams);

  const parsedParams: SmOrderRequestModel = parse(params.filter);

  const daysHeader: TableHelper[] = getHeader(parsedParams);
  const weeksHeaders: TableHelper[] = getHeader(parsedParams, false);
  const tableHeader: TableHelper[] = [...daysHeader, ...weeksHeaders];

  const areaWorkerData: FreeCapacityRecords[] = smOrderCapacityByArea
    ? addDataToHeader(smOrderCapacityByArea, tableHeader)
    : [];

  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['LEOREGIO'],
  });

  const { activePanel, closePanel, setPanel } = usePanelState();
  const { data: leoRegioData, accessor: leoRegioAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'LEOREGIO')
  );

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'UCSE_TELEPULES_LISTA_KAPACITAS.DATE',
      component: (
        <LeoDatePicker
          name="startDate"
          label={<Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.DATE" />}
        />
      ),
      name: 'startDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.DATE_FROM'),
      filterIsRequired: true,
    },

    {
      headerTitle: 'UCSE_TELEPULES_LISTA_KAPACITAS.DAY',
      component: (
        <LeoTextField
          name="dayCount"
          label={<Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.DAY" />}
          maxLength={3}
        />
      ),
      name: 'dayCount',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.DAY'),
    },
    {
      headerTitle: 'UCSE_TELEPULES_LISTA_KAPACITAS.WEEK',
      component: (
        <LeoTextField
          name="weekCount"
          label={<Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.WEEK" />}
          maxLength={3}
        />
      ),
      name: 'weekCount',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.WEEK'),
    },
    {
      headerTitle: 'UCSE_TELEPULES_LISTA_KAPACITAS.WORKER_CODE',
      component: (
        <LeoSelectFieldMulti
          name="workerSapIds"
          data={workerData}
          accessor={workerAccessor}
          label="UCSE_TELEPULES_LISTA_KAPACITAS.WORKER_CODE"
          readOnly
        />
      ),
      name: 'workerSapIds',
      panelFieldWidth: 12,
      divider: true,
    },
    {
      headerTitle: 'UCSE_TELEPULES_LISTA_KAPACITAS.REGION',
      component: (
        <LeoSelectField
          name="region"
          data={leoRegioData}
          accessor={leoRegioAccessor}
          label="UCSE_TELEPULES_LISTA_KAPACITAS.REGION"
        />
      ),
      name: 'region',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.REGION'),
    },
  ];

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().nullable().required(),
    dayCount: Yup.number().min(1).max(31),
    weekCount: Yup.number().min(1).max(4),
    region: Yup.string().nullable(),
    workerSapIds: Yup.mixed().nullable(),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.UCSE_TELEPULES_LISTA_KAPACITAS),
    validationSchema,
    isFetching,
  });

  const columns = [
    {
      key: 'sapId',
      header: <Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.WORKER_CODE" />,
      accessor: (item: FreeCapacityRecords) => item.sapId,
      field: 'sapId',
      sortable: true,
    },
    {
      key: 'areaList',
      header: <Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.AREA" />,
      accessor: (item: any) => item.areaList,
      field: 'areaList',
      subCell: 'area',
    },
    {
      key: 'regionList',
      header: <Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.REGION" />,
      accessor: (item: any) => {
        return item.regionList.map((region: { name: string; id: string }) => ({
          ...region,
          name: getValueSetValues(valueSet, 'LEOREGIO', region.name),
        }));
      },
      field: 'regionList',
      subCell: 'region',
    },
  ];

  const panels = [filterConfig];

  const colorsPercentage = (
    <Box sx={styles.colorCodes}>
      <Box sx={styles.colorCode}>
        <Box sx={mergeSx(styles.square, styles.green)}></Box>
        <Typography>{Percentages.LOW}</Typography>
      </Box>
      <Box sx={styles.colorCode}>
        <Box sx={mergeSx(styles.square, styles.yellow)}></Box>
        <Typography>{Percentages.MEDIUM}</Typography>
      </Box>
      <Box sx={styles.colorCode}>
        <Box sx={mergeSx(styles.square, styles.red)}></Box>
        <Typography>{Percentages.HIGH}</Typography>
      </Box>
    </Box>
  );

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="UCSE_TELEPULES_LISTA_KAPACITAS.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <CapacityTable
          filterDetailsData={{ leoRegioAccessor }}
          colorCodes={colorsPercentage}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          list={areaWorkerData || []}
          total={smOrderCapacityByArea?.meta.total}
          timestamp={dataUpdatedAt}
          loading={isLoading}
          enableTableView={false}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default UcseFreeCapacityPage;

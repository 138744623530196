import React, { FC } from 'react';
import { Form, Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { Autocomplete, Button, Switch, FileUpload, Checkbox, RadioGroup, TextField, DatePicker } from 'components';

interface ExampleFormValues {
  [key: string]: any;
}

const FormFields: FC = () => {
  const onSubmit = (values: ExampleFormValues, actions: FormikHelpers<ExampleFormValues>) => {
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 1000);
  };

  return (
    <Formik
      initialValues={
        {
          email: '',
          password: '',
          description: '',
          outlined: '',
          age: '',
          date: null,
          singleSelect: null,
          multiSelect: [],
          checkedSwitch: true,
          checkedCheckbox: true,
        } as ExampleFormValues
      }
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
        description: Yup.string().required(),
        date: Yup.date().nullable().required(),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <Box mb={2}>
          <Typography variant="h5">Form</Typography>
        </Box>

        <Field
          component={TextField}
          type="search"
          name="email"
          label="Email (autocomplete turned off)"
          autoComplete="off"
        />

        <Field component={TextField} name="password" label="Password" type="password" autoComplete="new-password" />
        <Field component={DatePicker} name="date" label="Date" />

        <Field component={TextField} name="description" label="Multiline" multiline />

        <Field component={TextField} name="age" label="Age" helperText="Some important helper text" select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Field>

        <Box mb={2}>
          <Typography variant="h6">Autocomplete</Typography>
        </Box>

        <Field
          component={Autocomplete}
          name="singleSelect"
          label="Clearable Single select"
          fullWidth
          options={[
            { value: '1', label: 'First option' },
            { value: '2', label: 'Second option' },
            { value: '3', label: 'Third option' },
            { value: '4', label: 'Fourth option' },
            { value: '5', label: 'Fifth option' },
          ]}
        />

        <Field
          component={Autocomplete}
          name="multiSelect"
          label="Outlined multi select"
          multiple
          variant="outlined"
          fullWidth
          disableClearable
          options={[
            { value: '1', label: 'First option' },
            { value: '2', label: 'Second option' },
            { value: '3', label: 'Third option' },
            { value: '4', label: 'Fourth option' },
            { value: '5', label: 'Fifth option' },
          ]}
        />

        <Box mb={2}>
          <Typography variant="h6">Switch</Typography>
        </Box>

        <Box mb={2}>
          <Field component={Switch} name="checkedSwitch" label="Primary" />
          <Field component={Switch} name="uncheckedSwitch" label="Secondary" color="secondary" />
          <Field component={Switch} name="disabledSwitch" disabled label="Disabled" />
        </Box>

        <Box mb={2}>
          <Typography variant="h6">Checkbox</Typography>
        </Box>

        <Box mb={2}>
          <Field component={Checkbox} name="checkedCheckbox" label="Primary" />
          <Field component={Checkbox} name="uncheckedCheckbox" label="Secondary" color="secondary" />
          <Field component={Checkbox} name="disabledCheckbox" disabled label="Disabled" />
        </Box>

        <Box mb={2}>
          <Typography variant="h6">Radio Group</Typography>
        </Box>

        <Box mb={2}>
          <Field component={RadioGroup} name="gender" label="Gender">
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
            <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
          </Field>
        </Box>

        <Box mb={2}>
          <Typography variant="h6">File/Image upload</Typography>
        </Box>
        <Box mb={2}>
          <Field component={FileUpload} name="file" label="Upload some file" />
        </Box>

        <Box mb={2}>
          <Button type="submit" fullWidth>
            Submit
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};

export default FormFields;

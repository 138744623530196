import { ListResponseModel, QueryParamModel, PerformanceMetricUnitPriceModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

class PerformanceMetricUnitPriceService extends EntityService {
  constructor() {
    super('performance-metric-unit-price');
  }

  async getPerformanceMetricUnitPriceList(params?: QueryParamModel | null) {
    return request<ListResponseModel<PerformanceMetricUnitPriceModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async editPerformanceMetricUnitPrice(data: Partial<PerformanceMetricUnitPriceModel>) {
    return request<PerformanceMetricUnitPriceModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  async putPerformanceMetricUnitPrice(id: ID, data: Partial<PerformanceMetricUnitPriceModel>) {
    return request<PerformanceMetricUnitPriceModel[]>({
      method: Methods.PUT,
      resource: `v1/${this.resource}/${id}`,
      data,
    });
  }

  async deletePerformanceMetricUnitPrice(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/${id}`,
    });
  }

  private exportPerformanceMetricUnitPriceList = async (params?: QueryParamModel | null) => {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });
    await exportFile(response);
    return response;
  };

  exportPerformanceMetricUnitPrice = (params?: QueryParamModel | null) =>
    this.exportPerformanceMetricUnitPriceList(params);
}

export const performanceMetricUnitPriceService = new PerformanceMetricUnitPriceService();

import React, { FC, useRef } from 'react';
import { Trans } from 'react-i18next';

import { DateTime } from 'luxon';
import { Field, Form, Formik, FormikConfig, FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import { t } from 'i18next';
import { isArray, omit } from 'lodash';

import Yup from 'utils/yup';
import { ReadingUnitSummaryModel } from 'models';
import { Checkbox, Dialog, TextField } from 'components';
import LeoDatePicker from 'components/input-fields/leoDatePicker/LeoDatePicker';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';
import { BaseEditorDialogProps } from 'components/dialog/EditorDialog';
import { useSaveExcludeFromEstimation } from 'features/readingUnits';

export interface ExcludeFromEstimationFormProps {
  postponedEstimationDate: string | null;
  reason: string | null;
  confirm?: boolean | null;
}

export interface ExcludeFromEstimationDialogProps
  extends BaseEditorDialogProps<ReadingUnitSummaryModel | ReadingUnitSummaryModel[]> {}

const styles = {
  checkboxError: {
    alignItems: 'flex-start',
    '.MuiSvgIcon-root': {
      outline: '2px solid red',
    },
  },
  checkboxNoError: {
    alignItems: 'flex-start',
  },
};

const ExcludeFromEstimationDialog: FC<ExcludeFromEstimationDialogProps> = ({
  selected,
  open,
  onClose,
  ...props
}) => {
  const formikRef = useRef<FormikProps<ExcludeFromEstimationFormProps> | null>(null);

  const { mutateAsync: saveExcludeFromEstimation, isLoading: isSaveLoading } =
    useSaveExcludeFromEstimation();

  const initialValues: FormikConfig<ExcludeFromEstimationFormProps>['initialValues'] = {
    postponedEstimationDate:
      Array.isArray(selected) || !selected ? null : selected.currentEstimationDate,
    reason: '',
    confirm: false,
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validationSchema: FormikConfig<ExcludeFromEstimationFormProps>['validationSchema'] =
    Yup.object().shape({
      postponedEstimationDate: Yup.date()
        .nullable()
        .required()
        .min(today, t('DATE.MIN_DATE_NOT_ALLOWED')),
      reason: Yup.string().min(3).max(260).required(),
      confirm: Yup.boolean().oneOf([true]).required(),
    });

  const handleSubmit = async () => {
    if (selected && formikRef.current?.values && !isSaveLoading) {
      const { values } = formikRef.current;
      const readingUnits = isArray(selected) ? selected.map((item) => +item.id) : [+selected.id];
      await saveExcludeFromEstimation({ selected: readingUnits, data: omit(values, 'confirm') });
      handleClose();
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    onClose();
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, errors, touched }) => {
        return (
          <Dialog
            title={<Trans i18nKey="LEO_LEO_EGYSEGEK.OUT_OF_ESTIMATION" />}
            component={Form}
            open={open}
            fullWidth={false}
            onClose={handleClose}
            actions={
              <DialogFooterButtons
                cancelClick={handleClose}
                submitText="LEO_LEO_EGYSEGEK.EXCLUSION"
                isLoading={isSaveLoading}
                submitDisabled={!values.confirm}
              />
            }
            {...props}
          >
            <Box sx={{ width: 470 }}>
              <Grid container gap={1.5}>
                <Grid item xs={12}>
                  <LeoDatePicker
                    name="postponedEstimationDate"
                    minDate={DateTime.now()}
                    label={<Trans i18nKey="LEO_LEO_EGYSEGEK.POSTONE_ESTIMATE_UNTIL" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    multiline
                    rows={5}
                    component={TextField}
                    name="reason"
                    label={<Trans i18nKey="LEO_LEO_EGYSEGEK.DEFERRED_CAUSE" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Checkbox}
                    name="confirm"
                    showError
                    sx={
                      errors.confirm && touched.confirm
                        ? styles.checkboxError
                        : styles.checkboxNoError
                    }
                    multilineLabel
                    label={
                      <Trans
                        i18nKey="LEO_LEO_EGYSEGEK.CONFIRM_OUT_OF_ESTIMATION"
                        values={{ value: Array.isArray(selected) ? selected.length : 1 }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ExcludeFromEstimationDialog;

import { JobModel, ListResponseModel, QueryParamModel } from 'models';
import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { SubmittedSapOrdersModel } from 'models/submittedSapOrder.model';

class SapUploadService extends EntityService {
  constructor() {
    super('admin/sap-upward');
  }

  async getSapJobList(params?: QueryParamModel | null) {
    return request<ListResponseModel<JobModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/jobs`,
      params,
    });
  }

  async triggerSapUpload() {
    return request({
      method: Methods.GET,
      resource: `v1/${this.resource}/trigger`,
    });
  }

  async getSapStatisticList(params?: QueryParamModel | null) {
    return request<ListResponseModel<SubmittedSapOrdersModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/statistics`,
      params,
    });
  }
}

export const sapUploadService = new SapUploadService();

import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Location } from 'react-router-dom';
import { omit, isEmpty } from 'lodash';

import { QueryParamModel } from 'models';
import { selectFilterParams, selectPanelState, setFilterParams } from 'store/common';
import { transformColumns } from 'utils/transformColumns';
import { removeFalsy, removeProp } from 'utils/objects';
import { SortDirection } from 'utils/arrays';
import { parse, stringify } from 'utils/base64';
import * as qs from 'utils/query';
import { isBlank } from 'components/input-fields/leoSelectField/common';

import { selectProfile } from 'store/profile';
import { UserSettingsModel } from 'models/userSettings.model';
import {
  useCreateUserSettings,
  useDeleteUserSettings,
  useEditUserSettings,
  useUserSettingsList,
} from 'features/userSettings';
import { defaultPageSize } from './components/TablePagination';

export interface SearchConditions {
  name: string;
  value: string | string[];
}

export type OnSortFunction = (
  event: React.MouseEvent<unknown>,
  orderBy: string,
  order: SortDirection
) => QueryParamModel;

interface PageSizes {
  id: number;
  scope: string;
  value: { [key: string]: string };
}

enum Page {
  size = 'pageSize',
}

export type OnSearchFunction = (searchConditions: SearchConditions) => QueryParamModel;

const initialParams: Partial<QueryParamModel> = {};

function getTransformedParams(
  defaultParams: Partial<QueryParamModel>,
  { page = 1, order, pageSize, ...rest }: any = {},
  savedPageSize: number | undefined = undefined
) {
  const getPageSize = (savedPage?: number) => {
    if (typeof pageSize === 'string') {
      return Number.parseInt(pageSize, 10);
    }
    return savedPage || pageSize || defaultParams.pageSize || defaultPageSize;
  };

  return removeFalsy({
    page: Number.parseInt(page, 10),
    order: typeof order === 'string' ? order : defaultParams.order,
    pageSize: getPageSize(savedPageSize),
    ...rest,
  }) as QueryParamModel;
}

function getInitialParams(
  defaultParams: Partial<QueryParamModel>,
  filterParams: Partial<QueryParamModel>,
  location: Location,
  pageSizes?: PageSizes[]
) {
  const queryParams = { ...qs.parse(location.search) };

  const currentSizeSettings = pageSizes?.filter((size) => size.scope === location.pathname);
  const currentPageSizeValue = currentSizeSettings?.length
    ? +currentSizeSettings[0].value.pageSize
    : undefined;

  if (!isEmpty(queryParams)) {
    return getTransformedParams(defaultParams, queryParams, currentPageSizeValue);
  }

  if (!isEmpty(filterParams) && filterParams.from === location.pathname) {
    return getTransformedParams(defaultParams, filterParams);
  }

  return getTransformedParams(defaultParams, defaultParams, currentPageSizeValue);
}

export const useTableQueryParams = (
  overrideParams: Partial<QueryParamModel> = initialParams,
  blankFields: { [key: string]: null | string | number } | undefined = undefined,
  nonTable = false
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const panelState = useSelector(selectPanelState);
  const userProfile = useSelector(selectProfile);

  const pageSettings: PageSizes[] = Object.keys(userProfile?.settings || {}).map((item, index) => ({
    id: index,
    scope: item,
    value: userProfile?.settings ? userProfile?.settings[`${item}`] : {},
  }));

  const filterParams = useSelector(selectFilterParams);
  const defaultParams = useMemo(() => ({ ...initialParams, ...overrideParams }), [overrideParams]);
  const [params, setParams] = useState<QueryParamModel>(
    getInitialParams(defaultParams, filterParams, location, pageSettings)
  );

  const { data: userSettings } = useUserSettingsList();
  const { mutateAsync: createUserSettings } = useCreateUserSettings();
  const { mutateAsync: editUserSettings } = useEditUserSettings();
  const { mutateAsync: deleteUserSettings } = useDeleteUserSettings();

  const getFilter = (filter: string | undefined) => {
    const filterStr = parse(filter);
    const [objKey] = Object.keys(blankFields || {});
    const blankedKey: string[] | undefined = filterStr[`${objKey}`];
    const hasBlankValue = blankedKey?.find((item) => isBlank(item));

    if (blankFields && blankedKey && hasBlankValue) {
      filterStr[`${objKey}`] = blankFields[objKey];
      return stringify(filterStr);
    }
    return filter;
  };

  const apiParams = {
    page: params.page,
    pageSize: params.pageSize,
    order: params.order,
    search: params.search,
    filter: getFilter(params.filter),
  };

  const exportParams = {
    order: params.order,
    filter: params.filter,
    columns: transformColumns(params.columns),
  };

  const addQueryToHistory = useCallback(
    (queryParams: Partial<QueryParamModel> = params) => {
      const { filter } = queryParams;
      navigate(
        `${location.pathname}?${nonTable ? qs.stringify({ filter }) : qs.stringify(queryParams)}`,
        { replace: true }
      );
    },
    [navigate, location.pathname, params, nonTable]
  );

  const setCurrentParams = useCallback(
    (queryParams?: Partial<QueryParamModel>) => {
      const currentParams = getTransformedParams(defaultParams, queryParams);

      setParams(currentParams);
      dispatch(setFilterParams({ ...currentParams, from: location.pathname }));
      addQueryToHistory(currentParams);

      return currentParams;
    },
    [addQueryToHistory, dispatch, location.pathname, defaultParams]
  );

  const onLoadQuery = useCallback(
    (query?: Partial<QueryParamModel> | null) => {
      return setCurrentParams({ ...params, ...{ columns: undefined }, ...query });
    },
    [setCurrentParams, params]
  );

  const onSort: OnSortFunction = useCallback(
    (event, orderBy, order) => {
      return setCurrentParams({ ...params, order: stringify([{ orderBy, order }]) });
    },
    [setCurrentParams, params]
  );

  const onSearch: OnSearchFunction = useCallback(
    ({ name, value }) => {
      const currentParams = value ? { ...params, [name]: value || null } : removeProp(params, name);

      return setCurrentParams({ ...currentParams });
    },
    [setCurrentParams, params]
  );

  const onPageChange = useCallback(
    (event: unknown, page: number) => {
      return setCurrentParams({
        ...params,
        page: page + 1,
        panel: panelState ? params.panel : undefined,
      });
    },
    [setCurrentParams, params, panelState]
  );

  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const pageSize = Number.parseInt(event.target.value, 10);

      const currentUserSettingId = userSettings?.filter(
        (setting: UserSettingsModel) => setting.scope === filterParams.from
      )[0]?.id;

      if (pageSize !== defaultPageSize) {
        if (currentUserSettingId) {
          editUserSettings({
            id: currentUserSettingId,
            data: {
              scope: filterParams.from,
              key: Page.size,
              value: pageSize,
            },
          });
        } else {
          createUserSettings({
            userId: userProfile?.id,
            scope: filterParams.from,
            key: Page.size,
            value: pageSize,
          });
        }
      }
      if (pageSize === defaultPageSize) {
        if (currentUserSettingId) {
          deleteUserSettings(currentUserSettingId);
        }
      }

      return setCurrentParams({
        ...params,
        page: 1,
        pageSize,
        panel: panelState ? params.panel : undefined,
      });
    },
    [
      setCurrentParams,
      params,
      panelState,
      createUserSettings,
      filterParams,
      userProfile,
      userSettings,
      deleteUserSettings,
      editUserSettings,
    ]
  );

  const onFilter = useCallback(
    (filter?: Partial<QueryParamModel> | null) => {
      return setCurrentParams({ ...omit(params, 'panel'), ...(filter || {}), page: 1 });
    },
    [setCurrentParams, params]
  );

  // pass this prop to the table in the page component if you want to use the column filter
  const onTableColumnVisibilityChange = useCallback(
    (colState: string | undefined) => {
      return setCurrentParams({
        ...params,
        columns: colState,
        panel: panelState ? params.panel : undefined,
      });
    },
    [setCurrentParams, params, panelState]
  );

  return {
    onPageChange,
    onRowsPerPageChange,
    onSearch,
    onSort,
    onLoadQuery,
    onFilter,
    onTableColumnVisibilityChange,
    params,
    apiParams,
    exportParams,
  };
};

import React, { FC, useRef } from 'react';
import { Trans } from 'react-i18next';

import Yup from 'utils/yup';
import { isArray, isPlainObject, uniq } from 'lodash';
import { Field, Form, Formik, FormikConfig, FormikProps } from 'formik';
import { Divider, Grid } from '@mui/material';

import { BaseEditorDialogProps, Dialog, TextField } from 'components';
import { ReadingUnitOrderModel, ReadingUnitSummaryModel, WorkerViewModel } from 'models';
import { useSendMessage, useWorkerListSearch } from 'features/workers';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import { IFormObject } from 'components/input-fields/helpers/interfaces';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';

export interface SendMessageFormProps {
  workerIds: IFormObject | string[] | null;
  subject?: string | null;
  message: string | null;
}

export interface SendMessageDialogProps
  extends BaseEditorDialogProps<
    | ReadingUnitSummaryModel
    | ReadingUnitSummaryModel[]
    | ReadingUnitOrderModel
    | ReadingUnitOrderModel[]
    | WorkerViewModel[]
  > {
  isWorkerModel?: boolean;
}

const SendMessageDialog: FC<SendMessageDialogProps> = ({
  selected,
  open,
  isWorkerModel = false,
  onClose,
  ...props
}) => {
  const formikRef = useRef<FormikProps<SendMessageFormProps> | null>(null);
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { data: workerIds, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'id',
    'name',
    true,
    'sapId'
  );

  const getInitialWorkers = () => {
    if (isArray(selected)) {
      if (isWorkerModel) {
        return uniq((selected as WorkerViewModel[])?.map((item) => item.id.toString()));
      }
      return uniq(
        (selected as any[]).reduce<string[]>(
          (acc: string[], item: ReadingUnitSummaryModel | ReadingUnitOrderModel) => {
            if (item.workerId) {
              acc.push(item.workerId.toString());
            }
            return acc;
          },
          []
        )
      );
    }
    if (isPlainObject(selected)) {
      if (isWorkerModel) {
        return [(selected as unknown as WorkerViewModel).id.toString()];
      }
      return (selected as ReadingUnitSummaryModel).workerId
        ? [(selected as ReadingUnitSummaryModel).workerId.toString()]
        : [];
    }

    return [];
  };

  const { mutateAsync: saveSendMessage, isLoading: isSaveLoading } = useSendMessage();

  const initialValues: FormikConfig<SendMessageFormProps>['initialValues'] = {
    workerIds: getInitialWorkers(),
    subject: null,
    message: null,
  };

  const validationSchema: FormikConfig<SendMessageFormProps>['validationSchema'] =
    Yup.object().shape({
      workerIds: Yup.mixed().nullable().required(),
      subject: Yup.string().nullable().max(50),
      message: Yup.string().nullable().required().min(3).max(500),
    });

  const handleSubmit = async () => {
    if (formikRef.current?.values && !isSaveLoading) {
      const { values } = formikRef.current;
      await saveSendMessage({ data: values });
      handleClose();
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    onClose();
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <Dialog
        title={<Trans i18nKey="MUNKAVEGZOK.SEND_MESSAGE_MODAL_TITLE" />}
        component={Form}
        open={open}
        onClose={handleClose}
        actions={
          <DialogFooterButtons
            cancelClick={handleClose}
            submitText="MUNKAVEGZOK.SEND_BUTTON"
            isLoading={isSaveLoading}
          />
        }
        {...props}
      >
        <Grid container>
          <Grid item xs={12}>
            <LeoSelectFieldMulti
              name="workerIds"
              data={workerIds}
              accessor={workerAccessor}
              readOnly
              label="MUNKAVEGZOK.WORKER"
            />
          </Grid>
          <Grid item xs={12} mt={1} mb={2}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              name="subject"
              label={<Trans i18nKey="MUNKAVEGZOK.SUBJECT" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              multiline
              rows={10}
              name="message"
              label={<Trans i18nKey="MUNKAVEGZOK.MESSAGE" />}
            />
          </Grid>
        </Grid>
      </Dialog>
    </Formik>
  );
};

export default SendMessageDialog;

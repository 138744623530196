import React, {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Trans } from 'react-i18next';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';

import { Box, Divider, SxProps, Theme } from '@mui/material';

import {
  Button,
  ColumnProps,
  DropdownMenuItem,
  FilterDetails,
  FilterTypes,
  PanelIcons,
  SearchBar,
  SearchConditions,
  useWindowResize,
} from 'components';
import { PanelConfigModel, QueryParamModel } from 'models';
import TableView from 'components/table/components/TableView';
import { ReactComponent as PlusIcon } from 'assets/icons/Material/plus.svg';
import DateNavigators, { DateNavigatorObject } from './DateNavigators';

const styles = {
  root: {
    mt: 2,
    justifyContent: 'space-between',
  },
  button: {
    mr: 2,
  },
  filters: {
    display: 'flex',
    pt: 2,
    pb: 2,
    minHeight: '74.5px',
  },
  filterButton: {
    display: 'flex',
  },
  chips: {
    pt: '2.5px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  views: {
    marginLeft: 'auto',
  },
  headerButton: {
    mb: 2,
    mr: 2,
  },
};

export interface PageHeaderAction extends DropdownMenuItem {
  isBulkAction?: boolean;
  bulkActionDisabled?: boolean;
  isLoading?: boolean;
  isTouched?: boolean;
}

export interface PageHeaderProps<T> {
  sx?: SxProps<Theme>;
  onAdd?: MouseEventHandler<HTMLButtonElement>;
  onSearch?: (searchConditions: SearchConditions) => void;
  selectedItems?: T[];
  initialSearchValue?: string;
  searchName?: string;
  searchLabel?: ReactNode;
  addLabel?: ReactNode;
  panels?: (PanelConfigModel | null)[];
  activePanel?: string | null;
  setPanel?: (config?: PanelConfigModel | null) => void;
  validColumns?: ColumnProps<any>[];
  setColumnsState?: (value: React.SetStateAction<(ColumnProps<any> | null)[]>) => void;
  onTableColumnVisibilityChange?: (colState: string | undefined) => QueryParamModel;
  onTableView?: boolean;
  pageHeaderActions?: PageHeaderAction[];
  onLoadQuery?: (query?: Partial<QueryParamModel> | null | undefined) => QueryParamModel;
  setFilterHeight?: Dispatch<SetStateAction<number>>;
  disableDivider?: boolean;
  dateNavigators?: DateNavigatorObject;
  filterDetailsData?: FilterTypes;
}

export function PageHeader({
  sx,
  onAdd,
  onSearch,
  selectedItems,
  setColumnsState,
  initialSearchValue,
  disableDivider = false,
  searchName,
  searchLabel,
  addLabel,
  panels,
  activePanel,
  setPanel,
  validColumns,
  onTableColumnVisibilityChange,
  onTableView = true,
  pageHeaderActions,
  onLoadQuery,
  setFilterHeight,
  dateNavigators,
  filterDetailsData,
}: PageHeaderProps<unknown>) {
  const filterRef = useRef<HTMLDivElement>(null);
  const queryFilter = panels && panels[0]?.params?.filter;

  const checkIfAtLeastTwoSelected = (isBulk?: boolean) =>
    isBulk && selectedItems && selectedItems?.length < 2;

  const notDisabledReturn = (isBulkAction?: boolean, bulkActionDisabled?: boolean) =>
    checkIfAtLeastTwoSelected(isBulkAction) || (isBulkAction && bulkActionDisabled);

  const measureFilterHeight = useCallback(() => {
    const filterHeight = filterRef.current?.clientHeight;
    if (setFilterHeight && filterHeight) {
      setFilterHeight(filterHeight);
    }
  }, [setFilterHeight]);

  useWindowResize(measureFilterHeight);

  useEffect(() => {
    measureFilterHeight();
  }, [queryFilter, measureFilterHeight]);

  return (
    <>
      <Box sx={sx || styles.root} display="flex" width="100%">
        <Box>
          {onSearch ? (
            <SearchBar
              name={searchName || 'search'}
              initialValue={initialSearchValue}
              onSearch={onSearch}
              label={searchLabel || <Trans i18nKey="COMMON.SEARCH" />}
            />
          ) : null}
          {onAdd ? (
            <Button
              onClick={onAdd}
              sx={styles.button}
              variant="outlined"
              startIcon={<PlusIcon fontSize="small" />}
            >
              {addLabel || <Trans i18nKey="COMMON.ADD_NEW_ROW" />}
            </Button>
          ) : null}
          {pageHeaderActions?.map(
            ({
              onClick,
              label,
              icon,
              name,
              isLoading = false,
              isBulkAction,
              isTouched = false,
              bulkActionDisabled = false,
              disabledIf,
              visible = true,
            }) => {
              if (!visible) return null;

              const checkDisabled = () => {
                if (disabledIf) {
                  if (typeof disabledIf === 'boolean') {
                    return disabledIf;
                  }
                  if (isFunction(disabledIf)) {
                    return checkIfAtLeastTwoSelected(isBulkAction) || disabledIf(selectedItems);
                  }
                  const key = Object.keys(disabledIf)[0];

                  if (selectedItems?.some((row) => get(row, key) === disabledIf[key])) {
                    return true;
                  }
                }
                return notDisabledReturn(isBulkAction, bulkActionDisabled);
              };

              return (
                <Button
                  sx={styles.headerButton}
                  key={name}
                  startIcon={icon}
                  loading={isLoading}
                  onClick={isBulkAction ? () => onClick(selectedItems) : onClick}
                  variant="outlined"
                  disabled={checkDisabled()}
                >
                  <Trans i18nKey={label} />
                </Button>
              );
            }
          )}
        </Box>
      </Box>
      {!disableDivider && <Divider />}
      <Box sx={styles.filters} ref={filterRef}>
        <Box sx={styles.filterButton}>
          {panels?.length ? (
            <PanelIcons panels={panels} active={activePanel} onClick={setPanel} />
          ) : null}
        </Box>

        {dateNavigators && <DateNavigators dateNavigators={dateNavigators} />}

        <Box sx={styles.chips}>
          {(panels || onLoadQuery) && (
            <FilterDetails
              onLoadQuery={onLoadQuery}
              panels={panels}
              filterDetailsData={filterDetailsData}
            />
          )}
        </Box>
        <Box sx={styles.views}>
          {onTableView ? (
            <TableView
              setColumnsState={setColumnsState}
              validColumns={validColumns}
              onTableColumnVisibilityChange={onTableColumnVisibilityChange}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
}

import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Button, PublicFormLayout } from 'components';
import { LoginQueryParams } from 'models';
import { Env } from 'config/env';
import { setItemInStorage } from 'utils/localStorage';

const styles = {
  loginButtonContainer: {
    mb: 3,
    height: '56px',
  },
  loginButton: {
    justifyContent: 'space-between',
  },
};

const LoginPage: FC = () => {
  const location = useLocation();

  /**
   * Azure OAuth doesn't support dynamic redirect params at the moment,
   * so we have to store it in localStorage before redirecting
   */
  const azureLoginClickHandler = () => {
    const azureAuthUrl = new URL(Env.REACT_APP_LOGIN_REDIRECT_URL);
    const redirectUrl = new URL('/login', window.location.origin);
    setItemInStorage(LoginQueryParams.IN_APP_REDIRECT, location);
    azureAuthUrl.searchParams.set(LoginQueryParams.AZURE_REDIRECT_URL, redirectUrl.href);
    window.open(azureAuthUrl.href, '_self');
  };

  return (
    <PublicFormLayout title={<Trans i18nKey="LOGIN.WELCOME" />}>
      <Button
        fullWidth
        onClick={azureLoginClickHandler}
        sx={styles.loginButtonContainer}
        buttonSx={styles.loginButton}
        endIcon={<ChevronRightIcon />}
      >
        <Trans i18nKey="LOGIN.AZURE_LOGIN" />
      </Button>
    </PublicFormLayout>
  );
};

export default LoginPage;

import { RoleModel } from 'models';
import { Role } from './roles';

interface FunctionRoleList {
  name: string;
  allowedFor: Role[];
}

export enum FunctionRoleName {
  WORKERS_PAGE_ACTIONS = 'WorkersPage Actions',
  AREA_WORKER_PAGE_ACTIONS = 'AreaWorkerPage Actions',
  AREA_EXCLUSIONS_PAGE_ACTIONS = 'AreaExclusionsPage Actions',
  SM_ORDERS_PAGE_TRANSFER_FUNCTION = 'SmOrdersPage Transfer Function',
  SM_ORDERS_PAGE_DELETE_FUNCTION = 'SmOrdersPage Delete Function',
  SM_ORDER_AVAILABILITY_PAGE_ACTIONS = 'SmOrderAvailabilityPage Actions',
  READING_CENTER_PAGE_ACTIONS = 'ReadingCenterPage Actions',
  TARIFFS_PAGE_ACTIONS = 'Tariffs Edit Action',
  READING_UNIT_PAGE_ACTIONS = 'ReadingUnitPage Actions',
  WAGEITEMS_PAGE_ACTIONS = 'WageItems Action',
  PERFORMANCEBASEDPAY_PAGE_ACTIONS = 'PerformanceBasedPayPage Actions',
  PERFORMANCEBASEDPAY_PAGE_ADMIN_COORD_PERFORMANCE_RULES = 'PerformanceBasedPayPage Approve Reject DownloadSheets Actions',
  OPEM_USERS_PAGE_ACTIONS = 'OpemUsersPage Actions',
  APPOINTMENT_PAGE_ACTIONS = 'AppointmentPage Actions',
  READING_TIME_PAGE_ACTIONS = 'ReadingTimePage Actions',
}

const functionRoleList: FunctionRoleList[] = [
  {
    name: FunctionRoleName.WORKERS_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN],
  },
  {
    name: FunctionRoleName.SM_ORDERS_PAGE_DELETE_FUNCTION,
    allowedFor: [Role.ADMIN],
  },
  {
    name: FunctionRoleName.SM_ORDERS_PAGE_TRANSFER_FUNCTION,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
  },
  {
    name: FunctionRoleName.SM_ORDER_AVAILABILITY_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
  },
  {
    name: FunctionRoleName.READING_CENTER_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN],
  },
  {
    name: FunctionRoleName.OPEM_USERS_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN],
  },
  {
    name: FunctionRoleName.READING_UNIT_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
  },
  {
    name: FunctionRoleName.TARIFFS_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.PERFORMANCE_PAY],
  },
  {
    name: FunctionRoleName.WAGEITEMS_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.PERFORMANCE_PAY],
  },
  {
    name: FunctionRoleName.PERFORMANCEBASEDPAY_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.PERFORMANCE_PAY],
  },
  {
    name: FunctionRoleName.PERFORMANCEBASEDPAY_PAGE_ADMIN_COORD_PERFORMANCE_RULES,
    allowedFor: [Role.ADMIN, Role.PERFORMANCE_PAY, Role.COORDINATOR],
  },
  {
    name: FunctionRoleName.AREA_WORKER_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
  },
  {
    name: FunctionRoleName.AREA_EXCLUSIONS_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.COORDINATOR],
  },
  {
    name: FunctionRoleName.APPOINTMENT_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.CUSTOMER_SERVICE],
  },
  {
    name: FunctionRoleName.READING_TIME_PAGE_ACTIONS,
    allowedFor: [Role.ADMIN, Role.PERFORMANCE_PAY],
  },
];

export const getFunctionRole = (fnRoleName: string, roles: RoleModel[] | undefined) => {
  const functionRole: FunctionRoleList | undefined = functionRoleList.filter(
    (fn) => fn.name === fnRoleName
  )[0];

  return functionRole ? roles?.some((role) => functionRole.allowedFor?.includes(role.name)) : false;
};

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useTableQueryParams, usePanelState, useFilterConfig } from 'components';
import { FilterOptionModel, EventLogModel } from 'models';
import { useUserListSearch } from 'features/users';
import { useValueSetList } from 'features/valueSets';
import { useEventLogList, useExportEventLogList } from 'features/eventLog';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { formatDate, DATE_TIME_FORMAT } from 'utils/dates';
import { getPageName } from 'utils/format';
import Yup from 'utils/yup';
import { RouteNames } from 'config/routeNames';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const RegisterValidator = () => {
  return t('ERRORS.INVALID_DATE');
};

export const validationSchema = Yup.object().shape({
  createdAt: Yup.object()
    .nullable()
    .shape({
      from: Yup.mixed()
        .nullable()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
      to: Yup.mixed()
        .nullable()
        .test('isDate validation', RegisterValidator, relativeDateTestFunction),
    }),
  badInterval: Yup.bool().oneOf([true], 'bad interval'),
});

const EventLogPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    params,
    apiParams,
    exportParams,
    onFilter,
    onLoadQuery,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const { data: eventLogData, isLoading, isFetching, dataUpdatedAt } = useEventLogList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportEventLogList(exportParams);
  const { data: usersData } = useUserListSearch();
  const { data: eventLogTypes, isLoading: isValueSetLoading } = useValueSetList({
    code: ['OPEM_EVENT_LOG_TYPE'],
  });

  const { data: emails, accessor: emailAccessor } = useFieldDataAndAccessor(
    usersData,
    'id',
    'name',
    true,
    'email'
  );
  const { data: eventLogTypesList, accessor: opemEventLogTypeAccessor } = useFieldDataAndAccessor(
    getValueSetValues(eventLogTypes, 'OPEM_EVENT_LOG_TYPE'),
    'key',
    'value'
  );

  const getUserLabel = (eventLog: EventLogModel) =>
    eventLog.user?.name ? (
      `${eventLog.user?.email} - ${eventLog.user?.name}`
    ) : (
      <Trans i18nKey="KOZOS_MUVELETI_NAPLO.SYSTEM_EVENT" />
    );

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_MUVELETI_NAPLO.EVENT_DATE',
      component: <LeoDateInterval name="createdAt" />,
      name: 'createdAt',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
    },
    {
      headerTitle: 'KOZOS_MUVELETI_NAPLO.USER',
      component: (
        <LeoSelectFieldMulti
          name="user"
          data={emails}
          accessor={emailAccessor}
          label="KOZOS_MUVELETI_NAPLO.USER"
          freeSolo
        />
      ),
      name: 'user',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'KOZOS_MUVELETI_NAPLO.EVENT',
      component: (
        <LeoSelectFieldMulti
          name="type"
          data={eventLogTypesList}
          accessor={opemEventLogTypeAccessor}
          label="KOZOS_MUVELETI_NAPLO.EVENT"
        />
      ),
      name: 'type',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'KOZOS_MUVELETI_NAPLO.OBJECT_ID',
      component: (
        <LeoTextField name="objectId" label={<Trans i18nKey="KOZOS_MUVELETI_NAPLO.OBJECT_ID" />} />
      ),
      name: 'objectId',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.OBJECT_ID'),
    },
  ];

  const columns = [
    {
      key: 'createdAt',
      header: <Trans i18nKey="KOZOS_MUVELETI_NAPLO.EVENT_DATE" />,
      accessor: (eventLog: EventLogModel) => formatDate(eventLog.createdAt, DATE_TIME_FORMAT),
      field: 'createdAt',
      sortable: true,
    },
    {
      key: 'type',
      header: <Trans i18nKey="KOZOS_MUVELETI_NAPLO.EVENT" />,
      accessor: (eventLog: EventLogModel) =>
        eventLog.type && getValueSetValues(eventLogTypes, 'OPEM_EVENT_LOG_TYPE', eventLog.type),
      field: 'type',
      sortable: true,
    },
    {
      key: 'objectId',
      header: <Trans i18nKey="KOZOS_MUVELETI_NAPLO.OBJECT_ID" />,
      accessor: 'objectId',
      sortable: true,
    },
    {
      key: 'description',
      header: <Trans i18nKey="KOZOS_MUVELETI_NAPLO.DESCRIPTION" />,
      accessor: 'description',
      sortable: true,
    },
    {
      key: 'user.name',
      header: <Trans i18nKey="KOZOS_MUVELETI_NAPLO.USER" />,
      accessor: (eventLog: EventLogModel) => getUserLabel(eventLog),
      field: 'user.email',
      sortable: true,
    },
  ];

  const { activePanel, closePanel, setPanel } = usePanelState();

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_MUVELETI_NAPLO),
    validationSchema,
    isFetching,
  });

  const panels = [filterConfig];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_MUVELETI_NAPLO.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          filterDetailsData={{ opemEventLogTypeAccessor, usersData }}
          list={eventLogData?.data || []}
          total={eventLogData?.meta.total}
          timestamp={dataUpdatedAt}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
          enableCheckbox={false}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          panels={panels}
          pageHeaderActions={headerActions}
          activePanel={activePanel}
          setPanel={setPanel}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default EventLogPage;

import React, { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { mergeSx } from 'utils/styles';
import { primary } from 'theme/colors';

export interface PanelHeaderProps {
  title: ReactNode;
  rightActions?: ReactNode;
  insetPadding?: boolean;
  leftActions?: ReactNode;
}

const styles = {
  insetPadding: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: primary[50],
    pt: 2,
    boxShadow: '3px 1px 10px -2px #00000040',
  },
  title: {
    paddingTop: 0.75,
    paddingBottom: 0.75,
    flexGrow: 1,
  },
};

export const PanelHeader: FC<PanelHeaderProps> = ({ title, rightActions, leftActions, insetPadding }) => {
  return (
    <Box sx={styles.stickyHeader}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={mergeSx(insetPadding ? styles.insetPadding : null)}
      >
        {leftActions ? <Box>{leftActions}</Box> : null}
        <Typography variant="subtitle1" sx={styles.title}>
          {title}
        </Typography>

        {rightActions ? <Box>{rightActions}</Box> : null}
      </Box>
      <Box mt={1}>
        <Divider />
      </Box>
    </Box>
  );
};

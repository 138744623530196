import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { Page } from 'components';
import { RouteNames } from 'config/routeNames';
import MenuCardPage from 'components/menu-card/MenuCardPage/MenuCardPage';

import cover from 'assets/images/cover.webp';

const styles = {
  bgImage: {
    height: '385px',
    background: `url(${cover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: 'neutral.surface',
    position: 'relative',
    '@media (max-width: 1599px)': {
      height: '308px',
    },
  },
  welcomeText: {
    position: 'absolute',
    left: '80px',
    bottom: '60px',
  },
  pageContainer: {
    '&.MuiContainer-root': {
      px: 0,
    },
  },
  menuCardContainer: {
    '&.MuiGrid-root': {
      p: 0,
      gap: '24px',
    },
  },
  cardContainer: {
    p: 10,
    justifyContent: 'space-between',
    '@media (max-width: 1599px)': {
      p: 6,
      '.MuiTypography-h4': { fontSize: '22px' },
      '.MuiTypography-h5': { fontSize: '16px' },
      '.MuiTypography-h6': { fontSize: '13px' },
    },
  },
  bottomText: {
    display: 'flex',
    alignItems: 'flex-end',
  },
};

const HomePage: FC = () => {
  return (
    <Page title={<Trans i18nKey="HOME.PAGE_TITLE" />} sx={styles.pageContainer}>
      <Box sx={styles.bgImage}>
        <Typography variant="h3" sx={styles.welcomeText}>
          <Trans i18nKey="HOME.WELCOME" />
        </Typography>
      </Box>
      <Grid container sx={styles.cardContainer} rowGap={4}>
        <Grid item>
          <Grid mb={3}>
            <Typography variant="subtitle1">
              <Trans i18nKey="HOME.MANAGEMENT" />
            </Typography>
          </Grid>
          <MenuCardPage parentRoute={RouteNames.HOME} noWrapper sx={styles.menuCardContainer} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default HomePage;

import {
  EstimationDateChangeStatisticsModel,
  EventLogModel,
  HealthAndSafetyModel,
  ListResponseModel,
  NotifiedReadingResultModel,
  QueryParamModel,
  ReadingStatisticsModel,
  TimeLogModel,
  ReadingUnitOrderWithFailureReasonModel,
  PhotoFailureModel,
  EmergencyCodeStatisticsModel,
  ReadingResultStatisticsModel,
  SmOrderTaskSummaryModel,
  ReadingOrderInterfaceLogModel,
  ReadingFailureReasonsStatisticsModel,
  ReportCodeModel,
  WorkerReportCodeModel,
  UploadedJobModel,
} from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';
import { SapBatchExecutionModel } from 'models/sapBatchExecution.model';

class StatisticsService extends EntityService {
  constructor() {
    super('statistics');
  }

  private getStatisticsList = async <T>(api: string, params?: QueryParamModel | null) => {
    return request<ListResponseModel<T>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${api}`,
      params,
    });
  };

  private exportStatistics = async (api: string, params?: QueryParamModel | null) => {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${api}/export`,
      params,
    });
    await exportFile(response);
    return response;
  };

  private exportAsyncExcel = async (api: string, params?: QueryParamModel | null) => {
    return request<UploadedJobModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${api}/export`,
      params,
    });
  };

  exportCommunicationLogAsyncExcel = (params?: QueryParamModel | null) =>
    this.exportAsyncExcel('opem-interface-log', params);

  getReadingStatisticsList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<ReadingStatisticsModel>('reading', params);

  exportReadingStatistics = (params?: QueryParamModel | null) =>
    this.exportStatistics('reading', params);

  getEstimationExclusions = (params?: QueryParamModel | null) =>
    this.getStatisticsList<EstimationDateChangeStatisticsModel>('estimation-exclusion', params);

  exportEstimationExclusions = (params?: QueryParamModel | null) =>
    this.exportStatistics('estimation-exclusion', params);

  getHealthAndSafetyList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<HealthAndSafetyModel>('hs', params);

  exportHealthAndSafety = (params?: QueryParamModel | null) => this.exportStatistics('hs', params);

  getNotifiedReadingResultList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<NotifiedReadingResultModel>('notified-reading-result', params);

  exportNotifiedReadingResults = (params?: QueryParamModel | null) =>
    this.exportStatistics('notified-reading-result', params);

  getEmergencyCodeStatistics = (params?: QueryParamModel | null) =>
    this.getStatisticsList<EmergencyCodeStatisticsModel>('emergency-code', params);

  exportEmergencyCodeStatistics = (params?: QueryParamModel | null) =>
    this.exportStatistics('emergency-code', params);

  getEventLogList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<EventLogModel>('opem-event-log', params);

  exportEventLogs = (params?: QueryParamModel | null) =>
    this.exportStatistics('opem-event-log', params);

  getTimeLogList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<TimeLogModel>('worker-log', params);

  exportTimeLogList = (params?: QueryParamModel | null) =>
    this.exportStatistics('worker-log', params);

  getOrderWithReportsList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<any>('order-with-reports', params);

  exportOrderWithReports = (params?: QueryParamModel | null) =>
    this.exportStatistics('order-with-reports', params);

  getUnreadableOrderList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<ReadingUnitOrderWithFailureReasonModel>('unreadable-orders', params);

  exportUnreadableOrderList = (params?: QueryParamModel | null) =>
    this.exportStatistics('unreadable-orders', params);

  getPhotoFailureReasonList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<PhotoFailureModel>('photo-not-taken-reason', params);

  exportPhotoFailureReasonList = (params?: QueryParamModel | null) =>
    this.exportStatistics('photo-not-taken-reason', params);

  getReadingResultStatisticsList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<ReadingResultStatisticsModel>('reading-results', params);

  exportReadingResultStatistics = (params?: QueryParamModel | null) =>
    this.exportStatistics('reading-results', params);

  getCompletedAfterDeadLineList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<SmOrderTaskSummaryModel>('ucse/completed-after-deadline', params);

  exportCompletedAfterDeadLineStatistics = (params?: QueryParamModel | null) =>
    this.exportStatistics('ucse/completed-after-deadline', params);

  getNotCompletedAfterDeadLineList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<SmOrderTaskSummaryModel>('ucse/not-completed-after-deadline', params);

  exportNotCompletedAfterDeadLineStatistics = (params?: QueryParamModel | null) =>
    this.exportStatistics('ucse/not-completed-after-deadline', params);

  getCommunicationLogList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<ReadingOrderInterfaceLogModel>('opem-interface-log', params);

  exportCommunicationLog = (params?: QueryParamModel | null) =>
    this.exportStatistics('opem-interface-log', params);

  getReadingFailureReasonsList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<ReadingFailureReasonsStatisticsModel>('reading-failure-reasons', params);

  exportReadingFailureReasons = (params?: QueryParamModel | null) =>
    this.exportStatistics('reading-failure-reasons', params);

  getReportCodesList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<ReportCodeModel>('report-code', params);

  exportReportCodes = (params?: QueryParamModel | null) =>
    this.exportStatistics('report-code', params);

  getReportCodesByWorkerList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<WorkerReportCodeModel>('worker-report-code', params);

  exportReportCodesByWorker = (params?: QueryParamModel | null) =>
    this.exportStatistics('worker-report-code', params);

  getSapBatchExecutionList = (params?: QueryParamModel | null) =>
    this.getStatisticsList<SapBatchExecutionModel>('sap-batch-execution', params);

  exportSapBatchExecution = (params?: QueryParamModel | null) =>
    this.exportStatistics('sap-batch-execution', params);
}

export const statisticsService = new StatisticsService();

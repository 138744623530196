import React, { FC, useRef } from 'react';
import { Trans } from 'react-i18next';

import { t } from 'i18next';
import { DateTime } from 'luxon';
import isArray from 'lodash/isArray';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import { Grid } from '@mui/material';

import Yup from 'utils/yup';
import { SmOrderModel } from 'models';
import { BaseEditorDialogProps, Dialog } from 'components';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';
import LeoDatePicker from 'components/input-fields/leoDatePicker/LeoDatePicker';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import { useSaveTransferTask } from 'features/orders';
import { useWorkerListSearch } from 'features/workers';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';

export interface TransferTaskFormProps {
  smOrderConsumptionPlaceIds: number[];
  workerId: number | null;
  downloadabilityDate: Date | null;
}

const TransferTaskDialog: FC<BaseEditorDialogProps<SmOrderModel | SmOrderModel[]>> = ({
  selected,
  open,
  onClose,
  ...props
}) => {
  const formikRef = useRef<FormikProps<TransferTaskFormProps> | null>(null);

  const { mutateAsync: saveTransferTask, isLoading: isSaveTaskLoading } = useSaveTransferTask();

  const { data: workers } = useWorkerListSearch();
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workers,
    'id',
    'name',
    true,
    'sapId'
  );

  const selectedArray = isArray(selected) ? selected : [selected];

  const { data: consumptionPlaceIds } = useFieldDataAndAccessor(
    selectedArray[0] !== null ? selectedArray : [],
    'consumptionPlaceId'
  );

  const initialValues: FormikConfig<TransferTaskFormProps>['initialValues'] = {
    smOrderConsumptionPlaceIds: (consumptionPlaceIds as unknown as number[]) || [],
    workerId: null,
    downloadabilityDate: new Date(),
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validationSchema: FormikConfig<TransferTaskFormProps>['validationSchema'] =
    Yup.object().shape({
      workerId: Yup.mixed().nullable().required(),
      downloadabilityDate: Yup.date()
        .nullable()
        .required()
        .min(today, t('DATE.MIN_DATE_NOT_ALLOWED')),
    });

  const handleSubmit = async () => {
    if (selected && formikRef.current?.values && !isSaveTaskLoading) {
      const { values } = formikRef.current;

      await saveTransferTask(values);

      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values }) => (
        <Dialog
          title={<Trans i18nKey="UCSE_MEGRENDELESEK.TRANSFER_TASK_MODAL_TITLE" />}
          component={Form}
          open={open}
          onClose={handleClose}
          actions={
            <DialogFooterButtons
              cancelClick={handleClose}
              submitText="UCSE_MEGRENDELESEK.TRANSFER_TASK_BUTTON"
              isLoading={isSaveTaskLoading}
              submitDisabled={!isValid || !dirty}
            />
          }
          {...props}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LeoSelectField
                name="workerId"
                data={workerData}
                accessor={workerAccessor}
                label="UCSE_MEGRENDELESEK.NEW_USER_CODE"
              />
            </Grid>
            <Grid item xs={6}>
              <LeoDatePicker
                name="downloadabilityDate"
                label={<Trans i18nKey="UCSE_MEGRENDELESEK.NEW_DOWNLOAD_DATE" />}
                minDate={DateTime.now()}
              />
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
};

export default TransferTaskDialog;

import React, { FC, MouseEventHandler } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import { mergeSx } from 'utils/styles';

interface ClearFieldAdornmentProps {
  value: string;
  onClick: MouseEventHandler;
  showSearchIcon?: boolean;
}

const styles = {
  icon: {
    cursor: 'pointer',
  },
  focus: {
    pointerEvents: 'none',
  },
};

export const ClearFieldAdornment: FC<ClearFieldAdornmentProps> = ({ value, onClick, showSearchIcon }) => {
  return (
    <InputAdornment position="end" onClick={onClick} sx={mergeSx(!value ? styles.focus : null)}>
      {value ? <ClearIcon color="primary" sx={styles.icon} /> : null}
      {!value && showSearchIcon ? <SearchIcon color="primary" sx={styles.icon} /> : null}
    </InputAdornment>
  );
};

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';

type SapBatchExecutionQueryContexts = QueryContextFromKeys<typeof sapBatchExecutionKeys>;

export const sapBatchExecutionKeys = {
  all: [{ scope: 'sapBatchExecution' }] as const,
  lists: () => [{ ...sapBatchExecutionKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...sapBatchExecutionKeys.lists()[0], params }] as const,
};

const fetchSapBatchExecutionList = async ({
  queryKey: [{ params }],
}: SapBatchExecutionQueryContexts['list']) => statisticsService.getSapBatchExecutionList(params);

const exportSapBatchExecutionList = async ({
  queryKey: [{ params }],
}: SapBatchExecutionQueryContexts['list']) => statisticsService.exportSapBatchExecution(params);

export const useSapBatchExecutionList = (params?: QueryParamModel | null) =>
  useQuery(sapBatchExecutionKeys.list(params), fetchSapBatchExecutionList, {
    enabled: !!params?.filter,
  });

export const useExportBatchExecutionList = (params?: QueryParamModel | null) =>
  useQuery(sapBatchExecutionKeys.list(params), exportSapBatchExecutionList, { enabled: false });

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';

import { ReadingTimeModel } from 'models';
import { BaseEditorDialogProps, EditorDialog } from 'components';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import { useEditReadingTime } from 'features/readingTime';

export interface ReadingTimeEditorDialogProps extends BaseEditorDialogProps<ReadingTimeModel> {}

const validationSchema = Yup.object().shape({
  repeatedReading: Yup.number().required().min(0).max(999999),
  controlReading: Yup.number().required().min(0).max(999999),
  dealerChange: Yup.number().required().min(0).max(999999),
});

const ReadingTimeEditorDialog: FC<ReadingTimeEditorDialogProps> = ({ selected, ...props }) => {
  const { mutateAsync: editReadingTime } = useEditReadingTime();

  const onSubmit = async (values: ReadingTimeModel) => {
    await editReadingTime({ data: values });
  };

  const initVal = selected || {
    repeatedReading: 0,
    controlReading: 0,
    dealerChange: 0,
  };

  return (
    <EditorDialog
      initialValues={{ ...initVal }}
      maxWidth="sm"
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      title={<Trans i18nKey="READINGTIME.EDIT" />}
      {...props}
    >
      <LeoTextField
        name="repeatedReading"
        label={<Trans i18nKey="READINGTIME.REPEATED_READING" />}
      />
      <LeoTextField name="controlReading" label={<Trans i18nKey="READINGTIME.CONTROL_READING" />} />
      <LeoTextField name="dealerChange" label={<Trans i18nKey="READINGTIME.DEALER_READING" />} />
    </EditorDialog>
  );
};

export default ReadingTimeEditorDialog;

import React, { ReactNode } from 'react';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import qs from 'query-string';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { ArrowBackIosNew } from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';

import {
  AutoSubmitForm,
  Button,
  OpusIconButton,
  PanelHeader,
  Tooltip,
  useDialogState,
  usePanelState,
  FilterCreationDialog,
  FilterManager,
  showNotification,
} from 'components';
import { FilterOptionModel, NotificationType, QueryParamModel, SavedFilterModel } from 'models';
import { parse, stringify } from 'utils/base64';
import { removeFalsy } from 'utils/objects';
import { ObjectSchema } from 'yup';

import { ReactComponent as BookmarkIcon } from 'assets/icons/Titasz/Bookmark.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/Titasz/Reload.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/Titasz/Refresh.svg';
import { SubmitArgs } from 'pages/Ucse/Appointment/AppointmentPage';

export enum FilterSubmitType {
  BUTTON = 'button',
  ON_CHANGE = 'on-change',
}

export interface FilterContainerProps<Values extends FormikValues> {
  params?: QueryParamModel | null;
  defaultFilterState?: Partial<Values>;
  onFilter?: (params?: QueryParamModel | null) => void;
  children?: ((props: FormikProps<Values>) => ReactNode) | ReactNode;
  type?: FilterSubmitType;
  onLoad?: (params?: QueryParamModel | null) => void;
  pageName: string;
  enableResetFilter?: boolean;
  validationSchema: ObjectSchema<any>;
  isFetching?: boolean;
  submitRef?: React.MutableRefObject<SubmitArgs | undefined>;
  stateHandler?: (formikValues: FormikProps<any> | undefined) => void;
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .Input-hasValue .MuiOutlinedInput-notchedOutline': {
      borderColor: 'primary.main',
    },
    zIndex: 1,
  },
  content: {
    overflowY: 'auto',
    padding: 2,
    flex: 1,
  },
  button: {
    display: 'block',
    width: 'initial',
    mx: 2,
  },
};

export const FilterContainer = <V extends FormikValues = FormikValues>({
  onFilter,
  params,
  children,
  defaultFilterState,
  type = FilterSubmitType.BUTTON,
  onLoad,
  pageName,
  enableResetFilter = false,
  validationSchema,
  isFetching,
  submitRef,
  stateHandler,
}: FilterContainerProps<V>) => {
  const { t } = useTranslation();
  const defaultFilter = defaultFilterState || ({} as Partial<V>);
  const { closePanel, closePanelWithFilter } = usePanelState();
  const queryParams = params || {};
  const filterFunction = onFilter;

  const handleFilter = (values: V) => {
    const nextFilter = stringify(removeFalsy(values));

    if (filterFunction) {
      filterFunction({ filter: nextFilter });
    }

    return nextFilter;
  };

  const handleSubmit = (
    values: V,
    helpers: FormikProps<V> | undefined,
    filterAndClose: boolean
  ) => {
    if (stateHandler) {
      stateHandler(helpers);
    }

    if (!filterAndClose) {
      helpers?.submitForm();
      helpers?.validateForm().then((e: any) => {
        if (isEmpty(e)) {
          const { order, page, pageSize, columns } = params as QueryParamModel;
          const filter = handleFilter(values);
          const filterString = qs.stringify(
            removeFalsy({ filter, order, page, pageSize, columns })
          );

          openEditor({
            filter: filterString,
            name: t('COMMON.DEFAULT_FILTER_NAME'),
            resource: pageName,
          });
        }
      });
    }

    if (filterAndClose) {
      helpers?.submitForm();
      helpers?.validateForm().then((e: any) => {
        if (isEmpty(e)) {
          handleFilter(values);
          closePanelWithFilter();
        }
      });
    }
  };

  if (submitRef) submitRef.current = handleSubmit;

  const clearFilter = async (
    values: V,
    setValues: (values: V, shouldValidate?: boolean) => void
  ) => {
    const newValues = Object.keys({ ...values, ...defaultFilter }).reduce((prev: V, curr) => {
      return {
        ...prev,
        [curr]: '',
      };
    }, {} as V);

    setValues(newValues);
    handleFilter(newValues);
  };

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<Partial<SavedFilterModel>>();

  const resetFilter = async (
    values: V,
    setValues: (values: V, shouldValidate?: boolean) => void
  ) => {
    const newValues = Object.keys({ ...values, ...defaultFilter }).reduce((prev: V, curr) => {
      return {
        ...prev,
        [curr]: defaultFilter[curr] || '',
      };
    }, {} as V);

    setValues(newValues);

    handleFilter(newValues);
  };

  const getFilters = () => {
    if (queryParams.filter) {
      try {
        return parse(queryParams.filter);
      } catch (e) {
        return { ...defaultFilter };
      }
    }

    return { ...defaultFilter };
  };

  const filters = getFilters();

  const hasDefaultFilter = defaultFilter && !isEmpty(defaultFilter);

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={() => undefined}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(props) => (
          <Box sx={styles.root}>
            <Form>
              <AutoSubmitForm timeout={500} isActive={type === FilterSubmitType.ON_CHANGE} />
              <PanelHeader
                insetPadding
                title={<Trans i18nKey="COMMON.FILTER" />}
                leftActions={
                  <Tooltip title={<Trans i18nKey="COMMON.CLOSE_FILTER" />}>
                    <IconButton onClick={closePanel} color="primary" size="large">
                      <ArrowBackIosNew fontSize="small" />
                    </IconButton>
                  </Tooltip>
                }
                rightActions={
                  <>
                    <Tooltip title={<Trans i18nKey="COMMON.SAVE_FILTER" />}>
                      <OpusIconButton
                        onClick={() => handleSubmit(props.values, props, false)}
                        size="large"
                      >
                        <SvgIcon component={BookmarkIcon} fontSize="small" />
                      </OpusIconButton>
                    </Tooltip>
                    <Tooltip title={<Trans i18nKey="COMMON.CLEAR_FILTER" />}>
                      <OpusIconButton
                        onClick={() => clearFilter(props.values, props.setValues)}
                        size="large"
                        disabled={isEmpty(removeFalsy(filters))}
                      >
                        <SvgIcon component={RefreshIcon} fontSize="small" />
                      </OpusIconButton>
                    </Tooltip>

                    {enableResetFilter && (
                      <Tooltip title={<Trans i18nKey="COMMON.RESET_FILTER" />}>
                        <OpusIconButton
                          onClick={() => resetFilter(props.values, props.setValues)}
                          disabled={
                            !hasDefaultFilter ||
                            isEqual(removeFalsy(defaultFilter), removeFalsy(filters))
                          }
                          size="large"
                        >
                          <SvgIcon component={ReloadIcon} fontSize="small" />
                        </OpusIconButton>
                      </Tooltip>
                    )}

                    {type === FilterSubmitType.BUTTON ? (
                      <Button
                        onClick={() => handleSubmit(props.values, props, true)}
                        variant="outlined"
                      >
                        <Trans i18nKey="COMMON.FILTER_BUTTON" />
                      </Button>
                    ) : null}
                  </>
                }
              />
              <FilterManager onLoad={onLoad} pageName={pageName} helper={props} />
              <Box sx={styles.content}>
                {isFunction(children) ? children(props) : children || null}
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
      <FilterCreationDialog
        open={isEditorOpen}
        onClose={closeEditor}
        selected={selectedEdit}
        callbackFn={closePanelWithFilter}
        isFetching={isFetching}
      />
    </>
  );
};

export const showReqFilterOptNotification = (
  filterList: FilterOptionModel[] | undefined,
  t: TFunction<'translation', undefined>
) => {
  const reqFilterOptions = filterList
    ?.filter((filter) => filter.filterIsRequired)
    .map((item) => t(item.headerTitle as string));
  if (reqFilterOptions?.length) {
    const content = `${t('COMMON.REQUIREDFILTER')} ${reqFilterOptions?.join(', ')}`;
    showNotification({ content, type: NotificationType.WARNING });
  }
};

import { useQuery } from '@tanstack/react-query';

import { JobModel, QueryContextFromKeys } from 'models';
import { asyncExportService } from 'services';

type AsyncExportQueryContexts = QueryContextFromKeys<typeof asyncExportKeys>;

export const asyncExportKeys = {
  all: [{ scope: 'asyncExport' }] as const,
  status: (jobId: string | undefined) => [{ ...asyncExportKeys.all[0], entity: 'status', jobId }] as const,
  download: (jobId: string | undefined) => [{ ...asyncExportKeys.all[0], entity: 'download', jobId }] as const,
};

const isJobStatusCompleted = (data: JobModel | undefined | "") => data && data?.status === "completed";
const JOB_STATUS_REFETCH_INTERVAL = 3000;

const fetchAsyncExportStatus = async ({ queryKey: [{ jobId }] }: AsyncExportQueryContexts['status']) =>
  asyncExportService.getAsyncExcelStatus(jobId);

const downloadAsyncExport = async ({ queryKey: [{ jobId }] }: AsyncExportQueryContexts['download']) =>
  asyncExportService.downloadAsyncExcel(jobId);

export const useAsyncExportDownload = (jobId: string | undefined) => {
  return useQuery(asyncExportKeys.download(jobId), downloadAsyncExport, { enabled: false, staleTime: Infinity });
};

export const useAsyncExportStatus = (jobId: string | undefined) => {
  const { refetch: refetchExportDownload } = useAsyncExportDownload(jobId);

  return useQuery(asyncExportKeys.status(jobId), fetchAsyncExportStatus, {
    enabled: !!jobId,
    refetchInterval: (data) => { return isJobStatusCompleted(data) ? false : JOB_STATUS_REFETCH_INTERVAL },
    onSuccess: (data) => {
      if (isJobStatusCompleted(data)) {
        refetchExportDownload();
      }
    },
    staleTime: Infinity
  });
};
import { useEffect, useRef, useCallback } from 'react';

export function useDisposed(): () => boolean {
  const disposed = useRef(false);

  useEffect(() => {
    return () => {
      disposed.current = true;
    };
  }, []);

  return useCallback(() => disposed.current, []);
}

import { ListResponseModel, QueryParamModel, SmOrderModel, SmOrderDetailsModel } from 'models';
import { EntityService } from 'utils/entityService';
import { TransferTaskFormProps } from 'pages/Ucse/UcseOrders/TransferTaskDialog';
import request, { Methods, requestFile } from 'utils/request';
import { exportFile } from 'utils/exportFile';

export interface DeleteQuery {
  id?: ID[];
}

class UcseOrderService extends EntityService {
  constructor() {
    super('ucse-order');
  }

  async getUcseOrderList(params?: QueryParamModel | null) {
    return request<ListResponseModel<SmOrderModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async getUcseOrderDetails(id: ID) {
    return request<SmOrderDetailsModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}`,
    });
  }

  async deleteUcseOrder(params: DeleteQuery) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async saveTransferTask(data: TransferTaskFormProps) {
    return request<ListResponseModel<SmOrderModel>>({
      method: Methods.POST,
      resource: `v1/task/transfer/ucse`,
      data,
    });
  }

  async exportUcseOrders(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });

    exportFile(response);
    return response;
  }
}

export const ucseOrderService = new UcseOrderService();

import * as React from 'react';
import { Field } from 'formik';

import { Box } from '@mui/material';

import { IInputComponentParams } from 'components/input-fields/helpers/interfaces';
import { Checkbox } from 'components/form/checkbox/Checkbox';

export const LeoCheckBox: React.FC<IInputComponentParams> = ({
  name,
  label,
  checked,
  extraStyle,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%', ...extraStyle }}>
      <Field
        type="text"
        id={name}
        name={name}
        label={label}
        component={Checkbox}
        variant="outlined"
        checked={checked}
        {...props}
      />
    </Box>
  );
};

export default LeoCheckBox;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ucseAvailabilityService } from 'services';
import {
  BulkSmOrderAvailabilityModel,
  SmOrderAvailabilityPatchModel,
  QueryContextFromKeys,
  QueryParamModel,
  SmOrderAvailabilityPostModel,
} from 'models';
import { emptyResponse } from 'features/helpers';
import { parse } from 'utils/base64';

type UcseAvailabiltyContexts = QueryContextFromKeys<typeof availabilityKeys>;

export const availabilityKeys = {
  all: [{ scope: 'ucseAvailability' }] as const,
  lists: () => [{ ...availabilityKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...availabilityKeys.lists()[0], params }] as const,
};

const fetchUcseAvailabiltyList = async ({
  queryKey: [{ params }],
}: UcseAvailabiltyContexts['list']) => {
  const { date: requiredParam } = parse(params?.filter);

  return requiredParam ? ucseAvailabilityService.getUcseAvailabilityList(params) : emptyResponse;
};

const exportUcseAvailabilty = async ({ queryKey: [{ params }] }: UcseAvailabiltyContexts['list']) =>
  ucseAvailabilityService.exportUcseAvailability(params);

export const useUcseAvailabiltyList = (params?: QueryParamModel | null) =>
  useQuery(availabilityKeys.list(params), fetchUcseAvailabiltyList, { enabled: !!params?.filter });

export const useExportUcseAvailabilty = (params?: QueryParamModel | null) => {
  return useQuery(availabilityKeys.list(params), exportUcseAvailabilty, { enabled: false });
};

export const usePatchUcseAvailabilty = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<SmOrderAvailabilityPatchModel> }) =>
      ucseAvailabilityService.editUcseAvailability(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: availabilityKeys.lists() });
      },
    }
  );
};

export const usePostUcseAvailabilty = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: { data: Partial<SmOrderAvailabilityPostModel> }) =>
      ucseAvailabilityService.createUcseAvailability(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: availabilityKeys.lists() });
      },
    }
  );
};

export const useBulkUcseAvailabilty = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: { data: Partial<BulkSmOrderAvailabilityModel> }) =>
      ucseAvailabilityService.bulkUcseAvailability(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: availabilityKeys.lists() });
      },
    }
  );
};

import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { t } from 'i18next';
import { clone, get, pick } from 'lodash';
import { Autocomplete, AutocompleteRenderInputParams, TextFieldProps } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { TextField } from 'components';
import { Env } from 'config/env';
import { FieldInputProps, FieldProps } from 'formik';
import { IInputComponentParams } from 'components/input-fields/helpers/interfaces';
import { Empty } from './common';

const LeoAutoComplete = ({
  form,
  field,
  meta,
  options,
  fullWidth,
  accessor,
  autoFill,
  updateField,
  showError = true,
  clearOnEscape = true,
  disableClearable = false,
  blankFilterOption = false,
  variant = Env.DEFAULT_INPUT_VARIANT,
  ...props
}: IInputComponentParams & TextFieldProps & FieldProps) => {
  const onChange = (_: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (updateField && autoFill) {
      const autofillValue = value && autoFill.data[`${+value}`];

      form.setFieldValue(`${updateField}`, value || '');
      form.setFieldValue(`${autoFill.field}`, autofillValue || '');
    }

    form.setFieldValue(field.name, value);
  };

  const getData = useCallback(() => {
    const newData = clone(options);
    if (blankFilterOption && newData) {
      newData.unshift(Empty.VALUE);
    }

    return newData || [];
  }, [blankFilterOption, options]);

  const fieldProps = pick(field, 'name', 'value') as FieldInputProps<any>;

  const renderInput = useCallback(
    (params: FieldProps<any, any> | AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...props}
          {...params}
          form={form}
          meta={meta}
          variant={variant}
          field={fieldProps}
          fullWidth={fullWidth}
          showError={showError}
          selectFieldHelper={true}
        />
      );
    },
    [fieldProps, form, fullWidth, showError, variant, meta, props]
  );

  const isOptionEqualToValue = useCallback(
    (optionParam: string, valueParam: string) => {
      let updatedFieldValue: string | undefined;

      if (autoFill) {
        const autofillValue = Object.keys(autoFill.lookup).find(
          (item) => autoFill.lookup[item] === valueParam
        );

        updatedFieldValue = autofillValue;
      }

      return [valueParam.toString(), updatedFieldValue?.toString()].some(
        (value) => value === optionParam.toString()
      );
    },
    [autoFill]
  );

  const getOptionLabel = useCallback(
    (option: string) => {
      const newAccessor = clone(accessor);
      if (blankFilterOption && newAccessor) {
        Object.assign(newAccessor, { blank: t('COMMON.BLANK') });
      }
      return newAccessor ? get(newAccessor, option) || option || '' : option?.toString().trim();
    },
    [accessor, blankFilterOption]
  );

  const commonProps = useMemo(
    () => ({
      renderInput,
      clearOnEscape,
      getOptionLabel,
      disableClearable,
      options: getData(),
      isOptionEqualToValue,
      noOptionsText: <Trans i18nKey={'COMMON.SELECT_FROM_LIST'} />,
    }),
    [getOptionLabel, clearOnEscape, disableClearable, renderInput, isOptionEqualToValue, getData]
  );

  const getValue = () => field?.value || null;

  return (
    <>
      <Autocomplete
        forcePopupIcon
        value={getValue()}
        onChange={onChange}
        popupIcon={<KeyboardArrowDownIcon />}
        {...commonProps}
      />
    </>
  );
};

export default LeoAutoComplete;

import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import { mergeSx } from 'utils/styles';

const styles = {
  noWrap: {
    whiteSpace: 'nowrap',
  },
  linkCell: {
    padding: 0,
    '&.MuiTableCell-sizeSmall': {
      '& $link': {
        padding: '6px 24px 6px 16px',
      },
    },
    '&.MuiTableCell-paddingCheckbox': {
      '& $link': {
        padding: '0 0 0 4px',
      },
    },
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      color: 'inherit',
      padding: 2,
      textAlign: 'inherit',
      flexDirection: 'inherit',
    },
  },
};

export interface TableLinkProps {
  link?: boolean;
  to?: string;
  replace?: boolean;
}

export interface TableCellProps extends MuiTableCellProps, TableLinkProps {
  noWrap?: boolean;
  container?: boolean;
}

export const TableCell: FC<TableCellProps> = memo(
  ({ container = false, noWrap, children, link, to, replace, sx, ...props }) => {
    if (container) {
      return <MuiTableCell {...props}>{children}</MuiTableCell>;
    }

    return (
      <MuiTableCell
        sx={mergeSx(noWrap ? styles.noWrap : null, link && to ? styles.linkCell : null, sx)}
        {...props}
      >
        {link && to ? (
          <Link to={to} replace={replace}>
            <Typography variant="cellName">{children}</Typography>
          </Link>
        ) : (
          <Typography variant="cellName">{children}</Typography>
        )}
      </MuiTableCell>
    );
  }
);

export default TableCell;

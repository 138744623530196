import i18n from 'i18n';

import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  NewPerformacePayModel,
  NotificationType,
  PerformanceBasedPayModel,
  QueryContextFromKeys,
  QueryParamModel,
} from 'models';
import { performanceBasedPayService, performanceMetricService, ActionObjectModel } from 'services';
import { showNotification } from 'components';
import { isAxiosError } from 'utils/errors';

type PerformanceBasedPayQueryContexts = QueryContextFromKeys<typeof performanceBasedPayKeys>;

enum Errors {
  NO_ENABLED = 'NO_ENABLED_PERFORMANCE_METRIC',
  NO_VALID_PRICE = 'DOES_NOT_HAVE_VALID_PERFRORMANCE_METRIC_UNIT_PRICE',
}

export const performanceBasedPayKeys = {
  all: [{ scope: 'performanceBasedPay' }] as const,
  lists: () => [{ ...performanceBasedPayKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...performanceBasedPayKeys.lists()[0], params }] as const,
};

const fetchPerformanceBasedPayList = async ({
  queryKey: [{ params }],
}: PerformanceBasedPayQueryContexts['list']) =>
  performanceBasedPayService.getPerformanceBasedPays(params);

const exportPerformanceBasedPayList = async ({
  queryKey: [{ params }],
}: PerformanceBasedPayQueryContexts['list']) =>
  performanceBasedPayService.exportPerformanceBasedPays(params);

const exportPayData = async ({
  queryKey: [{ params }],
}: PerformanceBasedPayQueryContexts['list']) => performanceBasedPayService.exportPayData(params);

export const usePerformanceBasedPayList = (params?: QueryParamModel | null) => {
  return useQuery(performanceBasedPayKeys.list(params), fetchPerformanceBasedPayList, {
    enabled: !!params?.filter,
  });
};

export const useImportTask = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => performanceBasedPayService.upload(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
    },
    onError: (error: any) => {
      const { status, statusText } = error.response;
      showNotification({ content: `${status} - ${statusText}`, type: NotificationType.ERROR });
    },
  });
};

export const useExportPerformanceBasedPayList = (params?: QueryParamModel | null) => {
  return useQuery(performanceBasedPayKeys.list(params), exportPerformanceBasedPayList, {
    enabled: false,
  });
};

export const useExportPayData = (params?: QueryParamModel | null) => {
  return useQuery(performanceBasedPayKeys.list(params), exportPayData, {
    enabled: false,
  });
};

export const useDownloadPerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: ActionObjectModel) => performanceBasedPayService.downloadPerformanceBasedPay(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
      },
    }
  );
};

const runOnSuccess = async (response: { jobId: string }, queryClient: QueryClient) => {
  showNotification({
    content: i18n.t('KOZOS_TELJESITMENYBEREK.WAIT_LABEL'),
    type: NotificationType.WARNING,
  });

  await getStatus(response.jobId, queryClient);
};

const runOnError = async (e: any) => {
  const response = await performanceMetricService.getPerformanceMetricList();

  if (
    isAxiosError(e) &&
    e.response?.status === 400 &&
    e.response.data.message === Errors.NO_ENABLED
  ) {
    showNotification({
      content: i18n.t(`ERRORS.${Errors.NO_ENABLED}`),
      type: NotificationType.ERROR,
    });
  }
  if (
    isAxiosError(e) &&
    e.response?.status === 400 &&
    e.response.data.message.includes(Errors.NO_VALID_PRICE)
  ) {
    const keyString = e.response.data.message.substring(
      0,
      e.response.data.message.indexOf(Errors.NO_VALID_PRICE) - 1
    );

    const value = response.data.find((item) => item.key === keyString)?.value;

    showNotification({
      content: i18n.t(`ERRORS.${Errors.NO_VALID_PRICE}`, { value }),
      type: NotificationType.ERROR,
    });
  }
};

const getStatus = async (jobId: string, queryClient: QueryClient) => {
  const response = await performanceBasedPayService.getJobById(jobId);

  if (response.failedReason) {
    showNotification({
      content: i18n.t('COMMON.OPERATION_UNSUCCESSFUL'),
      type: NotificationType.ERROR,
    });

    return;
  }

  if (response.status !== 'completed') {
    setTimeout(() => getStatus(jobId, queryClient), 1000);
  } else {
    queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
    showNotification({
      content: i18n.t('COMMON.OPERATION_SUCCESSFUL'),
      type: NotificationType.SUCCESS,
    });
  }
};

export const useCreatePerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: NewPerformacePayModel) => performanceBasedPayService.createPerformanceBasedPay(data),
    {
      onSuccess: (e) => runOnSuccess(e, queryClient),
      onError: runOnError,
    }
  );
};

export const useApprovePerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: ActionObjectModel) => performanceBasedPayService.approvePerformanceBasedPay(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
      },
    }
  );
};

export const useDeletePerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: ActionObjectModel) => performanceBasedPayService.deletePerformanceBasedPay(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
      },
    }
  );
};

export const useEditPerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<PerformanceBasedPayModel> }) =>
      performanceBasedPayService.editPerformanceBasedPays(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
      },
    }
  );
};

export const useRejectPerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: ActionObjectModel) => performanceBasedPayService.rejectPerformanceBasedPay(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
      },
    }
  );
};

export const useReopenPerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: ActionObjectModel) => performanceBasedPayService.reopenPerformanceBasedPay(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
      },
    }
  );
};

export const useSendToApprovePerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: ActionObjectModel) => performanceBasedPayService.sendToApprovePerformanceBasedPay(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceBasedPayKeys.lists() });
      },
    }
  );
};

export const useRunPerformanceBasedPay = () => {
  const queryClient = useQueryClient();

  return useMutation((data: ID) => performanceBasedPayService.runPerformanceBasedPay(data), {
    onSuccess: (e) => runOnSuccess(e, queryClient),
    onError: runOnError,
  });
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { DateTime } from 'luxon';
import { cloneDeep, differenceWith, get, isEqual, omit, orderBy } from 'lodash';

import SaveIcon from '@mui/icons-material/Save';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  ColumnProps,
  ConfirmDialog,
  Page,
  PageHeaderAction,
  Table,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { RoleModel, UserModel } from 'models';
import { useEditUser, useUserList } from 'features/users';
import { useRolesList } from 'features/roles';
import { stringify } from 'utils/base64';
import { selectUserRoles } from 'store/profile';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';

type TRecord = Record<string, any>;

const OpemUsers: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onLoadQuery,
    params,
    apiParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({
    order: stringify([{ orderBy: 'name' }]),
  });

  const [usersListState, setUsersListState] = useState<UserModel[]>([]);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const { activePanel, closePanel, setPanel } = usePanelState();
  const { data: usersData, isLoading: usersIsLoading, dataUpdatedAt } = useUserList(apiParams);
  const { data: rolesList, isLoading: roleIsLoading } = useRolesList();
  const { mutateAsync: editUsers } = useEditUser();

  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.OPEM_USERS_PAGE_ACTIONS, roles);

  useEffect(() => {
    const defaultState = usersData?.data;
    const newState = usersListState;
    const tableRefreshIsDone = defaultState?.length === newState.length;

    if (tableRefreshIsDone) {
      setIsTouched(isChangesHasOccured(defaultState, newState, 'id'));
    }
  }, [usersListState, usersData]);

  useEffect(() => {
    const allFetchIsDone = !usersIsLoading && !roleIsLoading;

    if (allFetchIsDone) {
      setUsersListState(usersData?.data || []);
    }
  }, [roleIsLoading, usersData?.data, usersIsLoading]);

  const getCheckboxRoleClickHandler =
    <T extends TRecord>(listState: UserModel[], item: T, column: ColumnProps<T>) =>
    (prevState: UserModel[]) => {
      if (!visible) {
        return prevState;
      }

      const modifiedRow = listState?.find((stateItem) => stateItem.id === item.id);
      const newRow = cloneDeep(modifiedRow);

      const selectedRole = newRow?.roles?.findIndex((rowRole) => rowRole.name === column.accessor);

      if (selectedRole !== -1 && selectedRole !== undefined) {
        newRow?.roles?.splice(selectedRole, 1);
      } else {
        const newRole = rolesList?.find((role) => role.name === column.accessor);
        if (newRole) {
          newRow?.roles?.push(newRole);
        }
      }
      const filteredRow = prevState.filter((stateItem) => stateItem.id !== modifiedRow?.id);

      return [...filteredRow, newRow];
    };

  const getCheckboxFieldClickHandler =
    <T extends TRecord>(
      listState: UserModel[] | undefined,
      item: T,
      _: ColumnProps<T>,
      originalList: UserModel[]
    ) =>
    (prevState: UserModel[]) => {
      if (!visible) {
        return prevState;
      }

      const clonedState = cloneDeep(prevState);
      const selected = listState?.find((listItem) => listItem.id === item.id);
      const originalTimeStamp =
        selected?.deletedAt === null
          ? originalList.find((listItem) => listItem.id === item.id)?.deletedAt
          : null;

      const newTimestamp = selected?.deletedAt === null ? DateTime.now().toISO() : null;

      const updatedRow = {
        ...selected,
        deletedAt: originalTimeStamp || newTimestamp,
      };
      const filteredRow = clonedState.filter((stateItem) => stateItem.id !== selected?.id);

      return [...filteredRow, updatedRow];
    };

  const getCheckboxRoleHandler = <T extends TRecord>(
    listState: UserModel[] | undefined,
    item: T,
    column: ColumnProps<T>
  ) =>
    listState
      ?.find((stateItem) => stateItem.id === item.id)
      ?.roles?.some((role) => role.name === column.accessor)
      ? 1
      : 0;

  const getCheckboxFieldHandler = <T extends TRecord>(
    listState: UserModel[] | undefined,
    item: T,
    column: ColumnProps<T>
  ) =>
    column.field &&
    get(
      listState?.find((stateItem) => stateItem.id === item.id),
      column.field,
      0
    )
      ? 0
      : 1;

  const addHeaderName = (rolesParam: RoleModel[], prefix: string) =>
    rolesParam.map((role) => ({ ...role, header: `${prefix}.${role.code}` }));

  const columns: ColumnProps<UserModel>[] = [
    {
      key: 'name',
      header: <Trans i18nKey="USER.NAME" />,
      accessor: (userData) => userData.name,
      field: 'name',
      sortable: true,
      noWrap: true,
    },
    {
      key: 'email',
      header: <Trans i18nKey="USER.EMAIL_ADDRESS" />,
      accessor: (userData) => userData.email,
      field: 'email',
      sortable: true,
      noWrap: true,
    },
    {
      key: 'deletedAt',
      header: <Trans i18nKey="TABLE.ACTIVE" />,
      accessor: (userData) => userData.deletedAt,
      field: 'deletedAt',
      align: 'center',
      sortable: false,
      noWrap: true,
      checkboxClickHandler: getCheckboxFieldClickHandler,
      checkboxFieldHandler: getCheckboxFieldHandler,
      checkboxCell: true,
    },
  ];

  const roleColumns: ColumnProps<UserModel>[] = rolesList
    ? rolesList?.flatMap((role) => ({
        key: role.name,
        accessor: role.name,
        noWrap: false,
        checkboxClickHandler: getCheckboxRoleClickHandler,
        checkboxFieldHandler: getCheckboxRoleHandler,
        customCheckboxCell: true,
      }))
    : [];

  const sendOpemUsersList = useCallback(async () => {
    const difference = differenceWith(usersListState, usersData?.data || [], isEqual);
    const updatedData = difference.map((diff) => omit(diff, ['name', 'password']));

    await editUsers({ data: updatedData });

    window.location.reload();
  }, [usersListState, usersData?.data, editUsers]);

  const headerActions = useMemo<PageHeaderAction[]>(() => {
    const onSaveClickHandler = () => {
      setSaveDialog(true);
    };

    const onCancelClickHandler = () => {
      setConfirmDialog(true);
    };

    return [
      {
        name: 'save',
        label: 'COMMON.SAVE',
        icon: <SaveIcon fill="currentColor" />,
        onClick: onSaveClickHandler,
        isBulkAction: !isTouched,
        visible,
      },
      {
        name: 'cancel',
        label: 'COMMON.DISCARD_CHANGES',
        icon: <HighlightOffIcon fill="currentColor" />,
        onClick: onCancelClickHandler,
        isBulkAction: !isTouched,
        visible,
      },
    ];
  }, [isTouched, visible]);

  const onSaveDialogHandler = () => {
    sendOpemUsersList();
    setIsTouched(false);
  };

  const onCancelDialogHandler = () => {
    setUsersListState(usersData?.data || []);
    setIsTouched(false);
  };

  return (
    <Page
      title={<Trans i18nKey="KOZOS_OPEM_FELHASZNALOK.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!roleIsLoading && (
        <Table
          listState={usersListState}
          setListState={setUsersListState}
          checkBoxTable={true}
          enableCheckbox={false}
          setPanel={setPanel}
          activePanel={activePanel}
          timestamp={dataUpdatedAt}
          list={usersData?.data || []}
          total={usersData?.meta.total}
          loading={usersIsLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          pageHeaderActions={headerActions}
          columns={[...columns, ...roleColumns]}
          checkBoxHeaders={addHeaderName(rolesList || [], 'TABLE')}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          setIsTouched={setIsTouched}
          onLoadQuery={onLoadQuery}
          dataLoadImmediately={true}
        />
      )}
      <ConfirmDialog
        confirmTitle={<Trans i18nKey="COMMON.OK" />}
        cancelTitle={<Trans i18nKey="COMMON.CANCEL" />}
        onSubmit={onSaveDialogHandler}
        open={saveDialog}
        onClose={() => setSaveDialog(false)}
      >
        <Trans i18nKey="COMMON.SAVE_QUESTION" />
      </ConfirmDialog>
      <ConfirmDialog
        confirmTitle={<Trans i18nKey="COMMON.OK" />}
        onSubmit={onCancelDialogHandler}
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
      >
        <Trans i18nKey="COMMON.EDIT_QUESTION" />
      </ConfirmDialog>
    </Page>
  );
};

export default OpemUsers;

const orderState = <U extends TRecord>(state: U[] | undefined, comp: string) =>
  orderBy(
    state?.map((item) => ({
      ...item,
      roles: orderBy({ ...item?.roles }, [comp]),
    })),
    [comp]
  );

export const isChangesHasOccured = <T extends TRecord>(
  oldState: T[] | undefined,
  newState: T[] | undefined,
  comparator: string
) => !isEqual(orderBy(orderState(oldState, comparator)), orderBy(orderState(newState, comparator)));

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { showNotification } from 'components';
import i18n from 'i18n';

import {
  NotificationType,
  QueryContextFromKeys,
  QueryParamModel,
  PerformanceMetricUnitPriceModel,
} from 'models';
import { performanceMetricUnitPriceService } from 'services';
import { isAxiosError } from 'utils/errors';

type PerformanceMetricUnitPriceQueryContexts = QueryContextFromKeys<
  typeof performanceMetricUnitPriceKeys
>;

enum Errors {
  DELETE = 'CAN_NOT_BE_DELETED_PERFORMANCE_BASED_PAY_ALREADY_EXISTS_USING_SPECIFIED_VALID_FROM_DATE',
  EDIT = 'PERFORMANCE_BASED_PAY_ALREADY_EXISTS_AFTER_SPECIFIED_VALID_FROM_DATE',
  PUT = 'CAN_NOT_BE_MODIFIED_PERFORMANCE_BASED_PAY_ALREADY_EXISTS_USING_SPECIFIED_VALID_FROM_DATE',
  DUPLICATION = 'PERFORMANCE_METRIC_UNIT_PRICE_ALREADY_EXISTS_IN_SPECIFIED_MONTH',
}

const performanceMetricUnitPriceKeys = {
  all: [{ scope: 'performance-metric-unit-price' }] as const,
  lists: () => [{ ...performanceMetricUnitPriceKeys.all[0], entity: 'list' }] as const,
  performanceMetricUnitPriceList: (params?: QueryParamModel | null) =>
    [{ ...performanceMetricUnitPriceKeys.lists()[0], params }] as const,
};

const fetchPerformanceMetricUnitPriceList = async ({
  queryKey: [{ params }],
}: PerformanceMetricUnitPriceQueryContexts['performanceMetricUnitPriceList']) =>
  performanceMetricUnitPriceService.getPerformanceMetricUnitPriceList(params);

export const usePerformanceMetricUnitPriceList = (params: QueryParamModel | null = null) => {
  return useQuery(
    performanceMetricUnitPriceKeys.performanceMetricUnitPriceList(params),
    fetchPerformanceMetricUnitPriceList,
    {
      enabled: !!params?.filter,
    }
  );
};

export const unitPriceCreateOnError = (e: any, duplicationKey: string) => {
  if (isAxiosError(e) && e.response?.status === 500 && e.response.data.message === Errors.PUT) {
    showNotification({
      content: i18n.t(`ERRORS.${Errors.PUT}`),
      type: NotificationType.ERROR,
    });
  }
  if (isAxiosError(e) && e.response?.data.message === duplicationKey) {
    showNotification({
      content: i18n.t(`ERRORS.${Errors.DUPLICATION}`),
      type: NotificationType.ERROR,
    });
  }
};

export const unitPriceEditOnError = (e: any, duplicationKey: string) => {
  if (isAxiosError(e) && e.response?.status === 500 && e.response.data.message === Errors.EDIT) {
    showNotification({
      content: i18n.t(`ERRORS.${Errors.EDIT}`),
      type: NotificationType.ERROR,
    });
  }
  if (isAxiosError(e) && e.response?.data.message === duplicationKey) {
    showNotification({
      content: i18n.t(`ERRORS.${Errors.DUPLICATION}`),
      type: NotificationType.ERROR,
    });
  }
};

export const useEditPerformanceMetricUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: { data: Partial<PerformanceMetricUnitPriceModel> }) =>
      performanceMetricUnitPriceService.editPerformanceMetricUnitPrice(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceMetricUnitPriceKeys.lists() });
      },
      onError: (e) => unitPriceEditOnError(e, Errors.DUPLICATION),
    }
  );
};

export const usePutPerformanceMetricUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<PerformanceMetricUnitPriceModel> }) =>
      performanceMetricUnitPriceService.putPerformanceMetricUnitPrice(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceMetricUnitPriceKeys.lists() });
      },
      onError: (e) => unitPriceCreateOnError(e, Errors.DUPLICATION),
    }
  );
};

export const useDeletePerformanceMetricUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: ID) => performanceMetricUnitPriceService.deletePerformanceMetricUnitPrice(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: performanceMetricUnitPriceKeys.lists() });
      },
      onError: (e) => {
        if (
          isAxiosError(e) &&
          e.response?.status === 500 &&
          e.response.data.message === Errors.DELETE
        ) {
          showNotification({
            content: i18n.t(`ERRORS.${Errors.DELETE}`),
            type: NotificationType.ERROR,
          });
        }
      },
    }
  );
};

const exportPerformanceMetricUnitPriceList = async ({
  queryKey: [{ params }],
}: PerformanceMetricUnitPriceQueryContexts['performanceMetricUnitPriceList']) =>
  performanceMetricUnitPriceService.exportPerformanceMetricUnitPrice(params);

export const useExportPerformanceMetricUnitPriceList = (params?: QueryParamModel | null) => {
  return useQuery(
    performanceMetricUnitPriceKeys.performanceMetricUnitPriceList(params),
    exportPerformanceMetricUnitPriceList,
    {
      enabled: false,
    }
  );
};

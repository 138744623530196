import { useQuery } from '@tanstack/react-query';

import { ValueSetQuery, valueSetService } from 'services';
import { QueryContextFromKeys } from 'models';

type ValueSetQueryContexts = QueryContextFromKeys<typeof valueSetKeys>;

export const valueSetKeys = {
  all: [{ scope: 'valueSets' }] as const,
  lists: () => [{ ...valueSetKeys.all[0], entity: 'list' }] as const,
  list: (params?: ValueSetQuery) => [{ ...valueSetKeys.lists()[0], params }] as const,
  reportSubgroupKeys: () => [{ ...valueSetKeys.all[0], entity: 'reportSubgroupKeys' }] as const,
};

const fetchValueSetList = async ({ queryKey: [{ params }] }: ValueSetQueryContexts['list']) =>
  valueSetService.getValueSets(params);

const fetchReportSubgroupKeys = async () =>
  valueSetService.getReportSubgroupKeys();

export const useValueSetList = (params?: ValueSetQuery, enabled?: boolean) => {
  return useQuery(valueSetKeys.list(params), fetchValueSetList, { enabled });
};

export const useReportSubgroupKeys = () => {
  return useQuery(valueSetKeys.reportSubgroupKeys(), fetchReportSubgroupKeys);
}
import React, { Suspense } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { GlobalStyles, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import './utils/yup';

import i18n from 'i18n';
import theme from 'theme';
import store from 'store';
import { config } from 'config/reactQueryConfig';

import App from 'pages/App/App';

import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const queryClient = new QueryClient(config);

const globalStyles = (
  <GlobalStyles
    styles={{
      '.reactQueryButton': {
        opacity: 0,
        transition: 'opacity .5s',
        '&:hover': {
          opacity: 1,
        },
      },
    }}
  />
);

root.render(
  <QueryClientProvider client={queryClient}>
    {globalStyles}
    <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={{ className: 'reactQueryButton' }} />
    <Provider store={store}>
      <Suspense fallback=" ">
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="hu">
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
                    <CssBaseline />
                    <App />
                  </GoogleOAuthProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </I18nextProvider>
      </Suspense>
    </Provider>
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ReadingTimeModel } from "models";
import { performanceMetricService } from "services";

export const readingTimeKeys = {
    all: [{ scope: 'reading-time' }] as const,
    readingTime: () => [{ ...readingTimeKeys.all[0] }] as const,
};

const fetchReadingTime = async () => performanceMetricService.getReadingTime();

export const useReadingTime = () => useQuery(readingTimeKeys.readingTime(), fetchReadingTime);

export const useEditReadingTime = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ data }: { data: ReadingTimeModel }) =>
            performanceMetricService.editReadingTime(data),
        {
            onMutate: async (newData) => {
                await queryClient.cancelQueries(readingTimeKeys.readingTime())

                const previousData = queryClient.getQueryData<ReadingTimeModel>(readingTimeKeys.readingTime())

                if (previousData !== undefined) {
                    queryClient.setQueryData<ReadingTimeModel>(readingTimeKeys.readingTime(), () => newData.data)
                }
                return { previousData }
            },
            onError: (err, newData, context) => {
                queryClient.setQueryData(readingTimeKeys.readingTime(), context?.previousData)
                queryClient.invalidateQueries(readingTimeKeys.readingTime())
            },
        }
    );
};
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes, matchRoutes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { authService } from 'services';
import { LoginQueryParams } from 'models';
import { AuthRoute, CookieConsent, LoadingOverlay, NotFound, Notification } from 'components';
import { logout, selectIsLoggedIn, setRefreshToken, setToken } from 'store/auth';
import { selectLoaderState, setPanelState } from 'store/common';
import { getProfile, selectUserRoles } from 'store/profile';
import { filterRoutes } from 'utils/auth';
import { compose } from 'utils/functions';
import { routes } from 'config/routes';
import { Env } from 'config/env';

import HomePage from 'pages/Home/HomePage';
import LoginPage from 'pages/Login/LoginPage';

import { ConsentDialog } from './ConsentDialog';
import { PrivateLayout } from './PrivateLayout';

const App: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loader = useSelector(selectLoaderState);
  const roles = useSelector(selectUserRoles);

  const [isProfileLoading, setProfileLoading] = useState(true);
  const [isConsentLoading, setConsentLoading] = useState(true);
  const [consentDialogOpen, setConsentDialogOpen] = useState(false);
  const [params] = useState(new URLSearchParams(location.search));

  const isLoading = useMemo(
    () => isProfileLoading || isConsentLoading,
    [isProfileLoading, isConsentLoading]
  );

  const initProfile = useCallback(async () => {
    try {
      setProfileLoading(true);

      if (Env.REACT_APP_USE_COOKIE) {
        await dispatch(getProfile());
      } else {
        const token =
          localStorage.getItem(Env.API_TOKEN_KEY) ?? params.get(LoginQueryParams.ACCESS_TOKEN);
        const refreshToken =
          localStorage.getItem(Env.REFRESH_TOKEN_KEY) ?? params.get(LoginQueryParams.REFRESH_TOKEN);
        await dispatch(setToken(token));
        await dispatch(setRefreshToken(refreshToken));
        await dispatch(getProfile());
      }
    } finally {
      setProfileLoading(false);
    }
  }, [dispatch, params]);

  const initPanelState = useCallback(async () => {
    await dispatch(setPanelState(new URLSearchParams(params).get('panel')));
  }, [params, dispatch]);

  const checkConsent = useCallback(async () => {
    try {
      setConsentLoading(true);

      if (!isLoggedIn) {
        return;
      }

      const consent = await authService.checkConsent();

      if (consent?.hasNewConsent) {
        setConsentDialogOpen(true);
      }
    } finally {
      setConsentLoading(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    initProfile();
    initPanelState();
  }, [initProfile, initPanelState]);

  useEffect(() => {
    checkConsent();
  }, [checkConsent]);

  const onConsentDialogClose = () => {
    setConsentDialogOpen(false);
  };

  const onConsentDialogCancel = () => {
    onConsentDialogClose();
    dispatch(logout());
  };

  const authenticatedRoutes = filterRoutes(routes, roles);

  const rootPath = location.pathname === '/';
  const noAccessPath = location.pathname === '/no-access';
  const notFoundPath = location.pathname === '/not-found';

  const notFound = !rootPath && !matchRoutes(routes, location.pathname);

  const noAccess =
    (!authenticatedRoutes.length ||
      (!!authenticatedRoutes.length &&
        !matchRoutes(authenticatedRoutes, location.pathname) &&
        !rootPath)) &&
    !noAccessPath &&
    !notFoundPath &&
    !notFound;

  const authenticatedErrorRoutes = routes.filter(
    (item) => item.path === 'no-access' || item.path === 'not-found'
  );

  return (
    <>
      <LoadingOverlay show={loader.show || isLoading} fixed />

      <Notification />

      {Env.COOKIE_CONSENT_USED ? <CookieConsent backdrop /> : null}

      <ConsentDialog
        onCancel={onConsentDialogCancel}
        onClose={onConsentDialogClose}
        isOpen={consentDialogOpen}
      />

      {!isLoading ? (
        <Routes>
          <Route
            path="login"
            element={
              <AuthRoute>
                <LoginPage />
              </AuthRoute>
            }
          />
          <Route path="404" element={<NotFound />} />
          <Route path="/*" element={<PrivateLayout noAccess={noAccess} notFound={notFound} />}>
            {authenticatedRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.component} />
            ))}
            {authenticatedErrorRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.component} />
            ))}
            <Route index element={<HomePage />} />
          </Route>
        </Routes>
      ) : null}
    </>
  );
};

export default compose(withTranslation())(App);

import { useQuery } from '@tanstack/react-query';

import { statisticsService } from 'services';
import { QueryContextFromKeys, QueryParamModel } from 'models';
import { parse } from 'utils/base64';
import { emptyResponse } from 'features/helpers';

type NotCompletedAfterDeadLineContexts = QueryContextFromKeys<typeof notCompletedAfterDeadlineKeys>;

export const notCompletedAfterDeadlineKeys = {
  all: [{ scope: 'notCompletedAfterDeadLine' }] as const,
  lists: () => [{ ...notCompletedAfterDeadlineKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...notCompletedAfterDeadlineKeys.lists()[0], params }] as const,
};

const fetchNotCompletedAfterDeadLineList = async ({
  queryKey: [{ params }],
}: NotCompletedAfterDeadLineContexts['list']) => {
  const { appointmentDate: requiredParam } = parse(params?.filter);

  return requiredParam ? statisticsService.getNotCompletedAfterDeadLineList(params) : emptyResponse;
};

const exportNotCompletedAfterDeadLine = async ({
  queryKey: [{ params }],
}: NotCompletedAfterDeadLineContexts['list']) =>
  statisticsService.exportNotCompletedAfterDeadLineStatistics(params);

export const useNotCompletedAfterDeadLineList = (params?: QueryParamModel | null) =>
  useQuery(notCompletedAfterDeadlineKeys.list(params), fetchNotCompletedAfterDeadLineList, {
    enabled: !!params?.filter,
  });

export const useExportNotCompletedAfterDeadLine = (params?: QueryParamModel | null) => {
  return useQuery(notCompletedAfterDeadlineKeys.list(params), exportNotCompletedAfterDeadLine, {
    enabled: false,
  });
};

import React, { FC, useCallback } from 'react';

import { Tooltip, Chip, Grid, Box, Divider, Typography, CircularProgress } from '@mui/material';

import { QueryParamModel } from 'models';
import { useDeleteFilter, useFilterList } from 'features/filters';
import { Env } from 'config/env';
import { parse } from 'utils/base64';
import { FormikProps, FormikState, FormikValues } from 'formik';

export interface FilterManagerProps {
  onLoad?: (params?: QueryParamModel | null) => void;
  pageName: string;
  helper: FormikProps<FormikValues>;
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 2,
  },
  chip: {
    '.MuiChip-label': {
      p: 0,
      mx: 1.5,
    },
  },
  chipTypography: {
    maxWidth: 390,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

export const FilterManager: FC<FilterManagerProps> = ({ pageName, helper }) => {
  const { data: filterList, isLoading: isListLoading } = useFilterList(pageName);
  const { mutateAsync: deleteFilter, isLoading: isDeleteLoading } = useDeleteFilter();
  const loading = isListLoading || isDeleteLoading;
  const isEmpty = filterList && filterList.length === 0;

  const filterClickHandler = useCallback(
    (filter: string) => () => {
      const searchParams = new URLSearchParams(filter);
      const query = Object.fromEntries(searchParams) as QueryParamModel;
      const values = parse(query.filter);

      helper.setFormikState((prevState: FormikState<FormikValues>) => ({
        ...prevState,
        values: {
          ...values,
        },
        errors: {},
        touched: {},
      }));
    },

    [helper]
  );

  const deleteClickHandler = useCallback(
    (id?: ID) => async () => {
      if (id) {
        await deleteFilter(id);
      }
    },
    [deleteFilter]
  );

  return (
    <>
      <Box sx={!isEmpty ? styles.container : {}}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            {filterList?.map(({ id, name, filter }) => {
              return (
                <Grid item key={id} xs="auto">
                  <Chip
                    sx={styles.chip}
                    color="primary"
                    onClick={filterClickHandler(filter)}
                    onDelete={deleteClickHandler(id)}
                    label={
                      <Tooltip
                        title={name}
                        enterDelay={Env.TOOLTIP_DELAY_SHORT}
                        enterNextDelay={Env.TOOLTIP_DELAY_SHORT}
                      >
                        <Typography variant="subtitle2" sx={styles.chipTypography}>
                          {name}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      {!isEmpty && <Divider />}
    </>
  );
};

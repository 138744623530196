import { ValueSetModel } from 'models';
import { EntityService } from 'utils/entityService';
import request, { Methods } from 'utils/request';

export interface ValueSetQuery {
  code?: string[];
  lastSync?: string;
}

class ValueSetService extends EntityService {
  constructor() {
    super('value-set');
  }

  async getValueSets(params?: ValueSetQuery) {
    return request<ValueSetModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async getReportSubgroupKeys() {
    return request<string[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}/report-subgroup-keys`
    });
  }
}

export const valueSetService = new ValueSetService();

import React, { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { get } from 'lodash';

import { FilterOptionModel, ReadingOrderInterfaceLogModel } from 'models';
import {
  ColumnProps,
  LeoSelectField,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import LeoDateInterval from 'components/input-fields/leoDateInterval/LeoDateInterval';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { getPageName } from 'utils/format';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';
import {
  useCommunicationLogList,
  useCommunicationLogAsyncExcelExport,
} from 'features/communicationLog';
import { useAsyncExportDownload, useAsyncExportStatus } from 'features/asyncExport';
import { useValueSetList } from 'features/valueSets';
import { useWorkerListSearch } from 'features/workers';
import { RouteNames } from 'config/routeNames';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { validationSchema } from 'pages/Kozos/Statistics/EventLog/EventLogPage';

type TRecord = Record<string, any>;

const CommunicationLogPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const [communicationLogListState, setCommunicationLogListState] = useState<
    ReadingOrderInterfaceLogModel[]
  >([]);
  const { data: communicationLog, isLoading, dataUpdatedAt } = useCommunicationLogList(apiParams);

  const {
    data: exportJobId,
    refetch: refetchExportJobId,
    isFetching: isFetchingJobId,
  } = useCommunicationLogAsyncExcelExport(exportParams);

  const { data: exportStatus } = useAsyncExportStatus(exportJobId?.jobId);

  const hasCompletedJob = !!exportStatus && exportStatus?.status === 'completed';

  const { isFetching: isFetchingDownload } = useAsyncExportDownload(exportJobId?.jobId);

  const {
    data: valueSet,
    isLoading: isValueSetLoading,
    isFetching,
  } = useValueSetList({
    code: ['READING_INTERFACE_LOG', 'NLO'],
  });
  const { data: workersData } = useWorkerListSearch({ withDeleted: true });
  const { activePanel, closePanel, setPanel } = usePanelState();

  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );
  const { data: readingInterfaceLogData, accessor: readingInterfaceLogAccessor } =
    useFieldDataAndAccessor(getValueSetValues(valueSet, 'READING_INTERFACE_LOG'));

  const headerAction = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetchExportJobId(),
      isLoading: isFetchingJobId || (exportJobId && !hasCompletedJob) || isFetchingDownload,
      disabledIf: !exportParams?.filter,
    },
  ];

  const communicationLogFilters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_KOMMUNIKACIOS_LOGOK.COMMUNICATION_CREATED_AT',
      component: <LeoDateInterval name="createdAt" />,
      name: 'createdAt',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.COMM_CREATED_AT'),
    },
    {
      headerTitle: 'LEO_KOMMUNIKACIOS_LOGOK.WORKER',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerData}
          accessor={workerAccessor}
          label="LEO_KOMMUNIKACIOS_LOGOK.WORKER"
          maxLength={5}
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
      panelFieldOffset: 12,
    },
    {
      headerTitle: 'LEO_KOMMUNIKACIOS_LOGOK.READING_UNIT',
      component: (
        <LeoTextField
          name="readingUnitCode"
          label={<Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.READING_UNIT" />}
          maxLength={10}
        />
      ),
      name: 'readingUnitCode',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.READING_UNIT'),
    },
    {
      headerTitle: 'LEO_KOMMUNIKACIOS_LOGOK.WIRING',
      component: (
        <LeoTextField name="bindingId" label={<Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.WIRING" />} />
      ),
      name: 'bindingId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.WIRING'),
    },
    {
      headerTitle: 'LEO_KOMMUNIKACIOS_LOGOK.DIRECTION',
      component: (
        <LeoSelectField
          name="direction"
          data={readingInterfaceLogData}
          accessor={readingInterfaceLogAccessor}
          label="LEO_KOMMUNIKACIOS_LOGOK.DIRECTION"
        />
      ),
      name: 'direction',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      abbrName: t('FILTERABBR.DIRECTION'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters: communicationLogFilters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_KOMMUNIKACIOS_LOGOK),
    validationSchema,
    isFetching,
  });

  useEffect(() => {
    if (communicationLog?.data) {
      setCommunicationLogListState(communicationLog?.data || []);
    }
  }, [communicationLog?.data]);

  const getCheckboxFieldHandler = <T extends TRecord>(
    listState: ReadingOrderInterfaceLogModel[] | undefined,
    item: T,
    column: ColumnProps<T>
  ): 1 | 0 =>
    column.field &&
    get(
      listState?.find((stateItem) => stateItem.id === item.id),
      column.field,
      0
    )
      ? 1
      : 0;

  const communicationLogColumns: ColumnProps<ReadingOrderInterfaceLogModel>[] = [
    {
      key: 'createdAt',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.CREATED_AT" />,
      accessor: (item: ReadingOrderInterfaceLogModel) =>
        formatDate(item.createdAt, DATE_TIME_FORMAT),
      field: 'createdAt',
      sortable: true,
    },
    {
      key: 'readingUnitCode',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.READING_UNIT_CODE" />,
      accessor: 'readingUnitCode',
      sortable: true,
    },
    {
      key: 'workerSapId',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.WORKER_SAPID" />,
      accessor: 'workerSapId',
      sortable: true,
    },
    {
      key: 'bindingId',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.BINDING" />,
      accessor: 'bindingId',
      sortable: true,
    },
    {
      key: 'serialNumber',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.SERIAL_NUMBER" />,
      accessor: 'serialNumber',
      sortable: true,
    },
    {
      key: 'readingOrderId',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.READING_ORDER_ID" />,
      accessor: 'readingOrderId',
      sortable: true,
    },
    {
      key: 'readingResultDate',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.READING_RESULT_DATE" />,
      accessor: (item: ReadingOrderInterfaceLogModel) =>
        formatDate(item.readingResultDate, DATE_TIME_FORMAT),
      field: 'readingResultDate',
      sortable: true,
    },
    {
      key: 'meterReadingResultCreatedAt',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.DATA_CREATED_AT" />,
      accessor: (item: ReadingOrderInterfaceLogModel) =>
        formatDate(item.meterReadingResultCreatedAt, DATE_TIME_FORMAT),
      field: 'meterReadingResultCreatedAt',
      sortable: true,
    },
    {
      key: 'meterReadingResult',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.READING_RESULT" />,
      accessor: 'meterReadingResult',
      sortable: true,
    },
    {
      key: 'meterReadingFailureReason',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.READING_FAILURE_REASON_ID" />,
      accessor: 'meterReadingFailureReason',
      tooltip: (item: ReadingOrderInterfaceLogModel) =>
        item.meterReadingFailureReason &&
        getValueSetValues(valueSet, 'NLO', item.meterReadingFailureReason),
      sortable: true,
    },
    {
      key: 'reportKeys',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.REPORT" />,
      accessor: 'reportKeys',
      tooltip: 'reportValues',
      sortable: true,
    },
    {
      key: 'direction',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.DIRECTION" />,
      accessor: (item: ReadingOrderInterfaceLogModel) =>
        item.direction && getValueSetValues(valueSet, 'READING_INTERFACE_LOG', item.direction),
      field: 'direction',
      sortable: true,
    },
    {
      key: 'isReturned',
      header: <Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.IS_RETURNED" />,
      accessor: (item: ReadingOrderInterfaceLogModel) => item.isReturned,
      field: 'isReturned',
      align: 'center',
      sortable: false,
      noWrap: true,
      checkboxFieldHandler: getCheckboxFieldHandler,
      checkboxCell: true,
    },
  ];

  const panels = [filterConfig];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_KOMMUNIKACIOS_LOGOK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Table
          listState={communicationLogListState}
          setListState={setCommunicationLogListState}
          filterDetailsData={{ readingInterfaceLogAccessor }}
          panels={panels}
          activePanel={activePanel}
          setPanel={setPanel}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          list={communicationLog?.data || []}
          total={communicationLog?.meta.total}
          timestamp={dataUpdatedAt}
          checkBoxTable={true}
          enableCheckbox={false}
          enableTableView={true}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={communicationLogColumns}
          pageHeaderActions={headerAction}
          onLoadQuery={onLoadQuery}
        />
      )}
    </Page>
  );
};

export default CommunicationLogPage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Box } from '@mui/material';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, EmergencyCodeStatisticsModel } from 'models';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import { useValueSetList } from 'features/valueSets';
import { useEmergencyCodeList, useExportEmergencyCode } from 'features/emergencyCodeStatistics';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { formatAddress, getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import { getValueSetValues } from 'utils/valueSetFunctions';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const styles = {
  root: {
    '.MuiPaper-root': {
      width: 800,
    },
  },
};

const EmergencyCodeStatisticsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onLoadQuery,
    onFilter,
    onSort,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();
  const { activePanel, closePanel, setPanel } = usePanelState();
  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({ code: ['VES'] });
  const {
    data: emergencyCodeStatistics,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useEmergencyCodeList(apiParams);
  const { isInitialLoading, isRefetching, refetch } = useExportEmergencyCode(exportParams);
  const { data: vesData, accessor: vesAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'VES')
  );

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_MUNKAVEDELMI_JELZESEK.CONSUMPTION_PLACE_ID',
      component: (
        <LeoTextField
          name="sapConsumptionPlaceId"
          label={<Trans i18nKey="LEO_MUNKAVEDELMI_JELZESEK.CONSUMPTION_PLACE_ID" />}
        />
      ),
      name: 'sapConsumptionPlaceId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.CONSUMPTION_PLACE'),
    },
    {
      headerTitle: 'LEO_MUNKAVEDELMI_JELZESEK.EMERGENCY_CODE',
      component: (
        <LeoSelectFieldMulti
          name="code"
          data={vesData}
          accessor={vesAccessor}
          label="LEO_MUNKAVEDELMI_JELZESEK.EMERGENCY_CODE"
          readOnly
        />
      ),
      name: 'code',
      panelFieldWidth: 12,
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_MUNKAVEDELMI_JELZESEK),
    isFetching,
  });

  const panels = [filterConfig];

  const emergencyCodeStatisticsColumns = [
    {
      key: 'consumptionPlace.sapConsumptionPlaceId',
      header: <Trans i18nKey="LEO_MUNKAVEDELMI_JELZESEK.CONSUMPTION_PLACE_ID" />,
      accessor: (item: EmergencyCodeStatisticsModel) =>
        item.consumptionPlace?.sapConsumptionPlaceId,
      field: 'consumptionPlace.sapConsumptionPlaceId',
      sortable: true,
    },
    {
      key: 'consumptionPlace.address',
      header: <Trans i18nKey="LEO_MUNKAVEDELMI_JELZESEK.CONSUMPTION_PLACE" />,
      field: 'consumptionPlace.address.city',
      accessor: (item: EmergencyCodeStatisticsModel) =>
        formatAddress(item.consumptionPlace?.address),
      sortable: true,
    },
    {
      key: 'code',
      header: <Trans i18nKey="LEO_MUNKAVEDELMI_JELZESEK.EMERGENCY_CODE" />,
      accessor: (item: EmergencyCodeStatisticsModel) =>
        item.code && getValueSetValues(valueSet, 'VES', item.code),
      field: 'code',
      sortable: true,
    },
    {
      key: 'comment',
      header: <Trans i18nKey="LEO_MUNKAVEDELMI_JELZESEK.COMMENT" />,
      accessor: (item: EmergencyCodeStatisticsModel) => item.comment,
      field: 'comment',
      sortable: true,
    },
  ];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  return (
    <Page
      panels={panels}
      activePanel={activePanel}
      title={<Trans i18nKey="LEO_MUNKAVEDELMI_JELZESEK.TITLE" />}
      inScroll
      closePanel={closePanel}
      setPanel={setPanel}
    >
      {!isValueSetLoading && (
        <Box sx={styles.root}>
          <Table
            filterDetailsData={{ vesAccessor }}
            panels={panels}
            activePanel={activePanel}
            setPanel={setPanel}
            onTableColumnVisibilityChange={onTableColumnVisibilityChange}
            list={emergencyCodeStatistics?.data || []}
            total={emergencyCodeStatistics?.meta.total}
            timestamp={dataUpdatedAt}
            enableCheckbox={false}
            enableTableView={true}
            loading={isLoading}
            params={params}
            onSort={onSort}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            columns={emergencyCodeStatisticsColumns}
            pageHeaderActions={headerActions}
            onLoadQuery={onLoadQuery}
          />
        </Box>
      )}
    </Page>
  );
};
export default EmergencyCodeStatisticsPage;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { FilterOptionModel, SapBatchExecutionModel } from 'models';

import { getPageName } from 'utils/format';
import { formatDate } from 'utils/dates';
import Yup from 'utils/yup';
import { RouteNames } from 'config/routeNames';
import { useExportBatchExecutionList, useSapBatchExecutionList } from 'features/readingOrders';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const ReadingOrdersPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    params,
    apiParams,
    exportParams,
    onFilter,
    onLoadQuery,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: readingOrdersData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useSapBatchExecutionList(apiParams);

  const { isInitialLoading, isRefetching, refetch } = useExportBatchExecutionList(exportParams);

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_LEO_RENDELESEK.LAUFI',
      component: (
        <LeoTextField
          name="executionId"
          label={<Trans i18nKey="LEO_LEO_RENDELESEK.LAUFI" />}
          maxLength={20}
        />
      ),
      name: 'executionId',
      panelFieldWidth: 12,
      divider: true,
      filterIsRequired: true,
      abbrName: t('FILTERABBR.LAUFI'),
    },
    {
      headerTitle: 'LEO_LEO_RENDELESEK.LAUFD',
      component: <LeoDateInterval name="executionDate" />,
      name: 'executionDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      filterIsRequired: true,
      abbrName: t('FILTERABBR.LAUFD'),
    },
  ];

  const readingOrdersColumns = [
    {
      key: 'executionId',
      header: <Trans i18nKey="LEO_LEO_RENDELESEK.LAUFI" />,
      accessor: 'executionId',
      sortable: true,
    },
    {
      key: 'executionDate',
      header: <Trans i18nKey="LEO_LEO_RENDELESEK.LAUFD" />,
      accessor: (item: SapBatchExecutionModel) => formatDate(item.executionDate),
      field: 'executionDate',
      sortable: true,
    },
    {
      key: 'readingOrderId',
      header: <Trans i18nKey="LEO_LEO_RENDELESEK.ORDERID" />,
      accessor: 'readingOrderId',
      sortable: true,
    },
  ];

  const { activePanel, closePanel, setPanel } = usePanelState();

  const validationSchema = Yup.object().shape({
    executionId: Yup.string().required(),
    executionDate: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .required()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .required()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_LEO_RENDELESEK),
    validationSchema,
    isFetching,
  });

  const panels = [filterConfig];

  return (
    <Page
      title={<Trans i18nKey="LEO_LEO_RENDELESEK.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Table
        keyField="readingOrderId"
        list={readingOrdersData?.data || []}
        total={readingOrdersData?.meta.total}
        timestamp={dataUpdatedAt}
        loading={isLoading}
        params={params}
        onSort={onSort}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        columns={readingOrdersColumns}
        enableCheckbox={false}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        panels={panels}
        pageHeaderActions={headerActions}
        activePanel={activePanel}
        setPanel={setPanel}
        onLoadQuery={onLoadQuery}
      />
    </Page>
  );
};

export default ReadingOrdersPage;

import { ListResponseModel, QueryParamModel, ReadingFailureReasonUnitPriceModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

class ReadingFailureReasonUnitPriceService extends EntityService {
  constructor() {
    super('reading-failure-reason-unit-price');
  }

  async getReadingFailureReasonUnitPriceList(params?: QueryParamModel | null) {
    return request<ListResponseModel<ReadingFailureReasonUnitPriceModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async editReadingFailureReasonUnitPrice(data: Partial<ReadingFailureReasonUnitPriceModel>) {
    return request<ReadingFailureReasonUnitPriceModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  async putReadingFailureReasonUnitPrice(
    id: ID,
    data: Partial<ReadingFailureReasonUnitPriceModel>
  ) {
    return request<ReadingFailureReasonUnitPriceModel[]>({
      method: Methods.PUT,
      resource: `v1/${this.resource}/${id}`,
      data,
    });
  }

  async deleteReadingFailureReasonUnitPrice(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/${id}`,
    });
  }

  private exportReadingFailureReasonUnitPriceList = async (params?: QueryParamModel | null) => {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });
    await exportFile(response);
    return response;
  };

  exportReadingFailureReasonUnitPrice = (params?: QueryParamModel | null) =>
    this.exportReadingFailureReasonUnitPriceList(params);
}

export const readingFailureReasonUnitPriceService = new ReadingFailureReasonUnitPriceService();

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';
import { Edit, Delete } from '@mui/icons-material';

import {
  ColumnProps,
  DeleteDialog,
  LeoSelectField,
  Page,
  Table,
  useDialogState,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel } from 'models';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import {
  useAreaWorkerList,
  useDeleteAreaWorker,
  useExportAreaWorkerList,
} from 'features/areaWorker';
import { AreaWorkerModel } from 'models/areaWorker.model';
import LeoSelectFieldMulti from 'components/input-fields/leoSelectField/LeoSelectFieldMulti';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import { FunctionRoleName, getFunctionRole } from 'config/functionRole';
import { selectUserRoles } from 'store/profile';
import { useWorkerListSearch } from 'features/workers';
import { useAreaList } from 'features/area';
import { useValueSetList } from 'features/valueSets';

import AreaWorkerEditorDialog from './AreaWorkerEditorDialog';

const AreaWorkerPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams({});

  const {
    data: areaWorkerData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useAreaWorkerList(apiParams);

  const { isInitialLoading, isRefetching, refetch } = useExportAreaWorkerList(exportParams);

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<AreaWorkerModel>();

  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    selected: selectedDelete,
  } = useDialogState<AreaWorkerModel>();

  const { activePanel, closePanel, setPanel } = usePanelState();
  const { mutateAsync: deleteAreaWorker } = useDeleteAreaWorker();
  const { data: workersData } = useWorkerListSearch();
  const { data: areaData } = useAreaList();
  const { data: regionValueSet, isLoading: isRegionValueSetLoading } = useValueSetList({
    code: ['LEOREGIO'],
  });
  const { data: mbaHookData } = useFieldDataAndAccessor(areaData, 'mba');
  const { data: workerHookData, accessor: workerHookAccessor } = useFieldDataAndAccessor(
    workersData,
    'sapId',
    'name',
    true
  );
  const { data: areaHookData, accessor: areaHookAccessor } = useFieldDataAndAccessor(
    areaData,
    'area',
    'area'
  );
  const { data: leoRegioData, accessor: leoRegioAccessor } = useFieldDataAndAccessor(
    getValueSetValues(regionValueSet, 'LEOREGIO')
  );

  const roles = useSelector(selectUserRoles);
  const visible = getFunctionRole(FunctionRoleName.AREA_WORKER_PAGE_ACTIONS, roles);

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.WORKER_ID',
      component: (
        <LeoSelectFieldMulti
          name="worker"
          data={workerHookData}
          accessor={workerHookAccessor}
          label={<Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.WORKER_ID" />}
        />
      ),
      name: 'worker',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.AREA',
      component: (
        <LeoSelectFieldMulti
          name="area"
          data={[...areaHookData].sort()}
          accessor={areaHookAccessor}
          label={<Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.AREA" />}
        />
      ),
      name: 'area',
      divider: true,
      panelFieldWidth: 12,
    },
    {
      headerTitle: 'UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.MBA',
      component: (
        <LeoSelectFieldMulti
          name="mba"
          data={[...mbaHookData].sort()}
          label={<Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.MBA" />}
        />
      ),
      name: 'mba',
      divider: true,
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.MBA'),
    },
    {
      headerTitle: 'UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.REGION',
      component: (
        <LeoSelectField
          name="region"
          data={leoRegioData}
          accessor={leoRegioAccessor}
          label={'UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.REGION'}
        />
      ),
      name: 'region',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.REGION'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE),
    isFetching,
  });

  const panels = [filterConfig];

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const areaWorkerActions: any[] = visible
    ? [
        {
          name: 'edit',
          label: 'COMMON.EDIT',
          icon: <Edit fontSize="small" />,
          onClick: (areaWorker: AreaWorkerModel) => openEditor(areaWorker),
        },
        {
          name: 'delete',
          label: 'COMMON.DELETE',
          icon: <Delete fontSize="small" />,
          onClick: (areaWorker: AreaWorkerModel) => openDelete(areaWorker),
        },
      ]
    : [];

  const areaWorkerColumns: ColumnProps<AreaWorkerModel>[] = [
    {
      key: 'area.area',
      header: <Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.AREA" />,
      accessor: 'area.area',
      sortable: true,
    },
    {
      key: 'area.mba',
      header: <Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.MBA" />,
      accessor: 'area.mba',
      sortable: true,
    },
    {
      key: 'area.region',
      header: <Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.REGION" />,
      accessor: (item: AreaWorkerModel) =>
        item.area.region && getValueSetValues(regionValueSet, 'LEOREGIO', item.area.region),
      sortable: true,
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.WORKER_ID" />,
      accessor: 'worker.sapId',
      sortable: true,
    },
    {
      key: 'priority',
      header: <Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.PRIORITY" />,
      accessor: 'priority',
      sortable: true,
    },
  ];

  const onDelete = async () => {
    if (selectedDelete?.id) {
      await deleteAreaWorker(selectedDelete.id);
    }
  };

  return (
    <Page
      title={<Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
      panels={panels}
    >
      {!isRegionValueSetLoading && (
        <Table
          filterDetailsData={{ leoRegioAccessor }}
          panels={panels}
          setPanel={setPanel}
          activePanel={activePanel}
          timestamp={dataUpdatedAt}
          list={areaWorkerData?.data || []}
          total={areaWorkerData?.meta.total || 0}
          loading={isLoading}
          params={params}
          onSort={onSort}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          enableCheckbox={false}
          pageHeaderActions={headerActions}
          actions={areaWorkerActions}
          mapActions
          columns={areaWorkerColumns}
          onTableColumnVisibilityChange={onTableColumnVisibilityChange}
          onAdd={visible ? (areaWorker: AreaWorkerModel) => openEditor(areaWorker) : undefined}
          onLoadQuery={onLoadQuery}
        />
      )}

      <AreaWorkerEditorDialog open={isEditorOpen} selected={selectedEdit} onClose={closeEditor} />

      <DeleteDialog
        open={isDeleteOpen}
        selected={selectedDelete}
        onClose={closeDelete}
        onSubmit={onDelete}
        deleteQuestion={<Trans i18nKey="UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.DELETE_QUESTION" />}
        deleteTitleAccessor="id"
      />
    </Page>
  );
};

export default AreaWorkerPage;

import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { statisticsService } from 'services';
import { emptyResponse } from 'features/helpers';
import { parse } from 'utils/base64';

type ReportCodesByWorkerQueryContexts = QueryContextFromKeys<typeof ReportCodesByWorkerKeys>;

export const ReportCodesByWorkerKeys = {
  all: [{ scope: 'reportCodesByWorker' }] as const,
  lists: () => [{ ...ReportCodesByWorkerKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...ReportCodesByWorkerKeys.lists()[0], params }] as const,
};

const fetchReportCodesByWorkerList = async ({
  queryKey: [{ params }],
}: ReportCodesByWorkerQueryContexts['list']) => {
  const { plannedReadingDate: requiredParam } = parse(params?.filter);

  return requiredParam ? statisticsService.getReportCodesByWorkerList(params) : emptyResponse;
};

const exportReportCodesByWorker = async ({
  queryKey: [{ params }],
}: ReportCodesByWorkerQueryContexts['list']) => statisticsService.exportReportCodesByWorker(params);

export const useReportCodesByWorkerListList = (params?: QueryParamModel | null) => {
  return useQuery(ReportCodesByWorkerKeys.list(params), fetchReportCodesByWorkerList, {
    enabled: !!params?.filter,
  });
};

export const useExportReportCodesByWorker = (params?: QueryParamModel | null) => {
  return useQuery(ReportCodesByWorkerKeys.list(params), exportReportCodesByWorker, {
    enabled: false,
  });
};

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  ColumnProps,
  LeoDateInterval,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel } from 'models';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import { getPageName } from 'utils/format';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';
import { RouteNames } from 'config/routeNames';
import { SubmittedSapOrdersModel } from 'models/submittedSapOrder.model';
import { useSapStatisticsPageList } from 'features/sapUpload';
import { useWorkerListSearch } from 'features/workers';

const SubmittedSapOrdersPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    apiParams,
    params,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: submittedSapOrdersData,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useSapStatisticsPageList(apiParams);

  const { activePanel, closePanel, setPanel } = usePanelState();

  const { data: useWorkersData } = useWorkerListSearch();

  const { data: workerHookData, accessor: workerHookAccessor } = useFieldDataAndAccessor(
    useWorkersData,
    'id',
    'name',
    true,
    'sapId',
    true
  );

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.SAP_SYNCED_DATE',
      component: <LeoDateInterval name="sapSynced" />,
      name: 'sapSynced',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.SAP_SYNCED_DATE'),
    },
    {
      headerTitle: 'KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.JOB_ID',
      component: (
        <LeoTextField
          name="jobId"
          label={<Trans i18nKey="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.JOB_ID" />}
          maxLength={36}
        />
      ),
      name: 'jobId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.JOB_ID'),
    },
    {
      headerTitle: 'KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.SAP_ID',
      component: (
        <LeoSelectField
          name="workerId"
          data={workerHookData}
          accessor={workerHookAccessor}
          label="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.SAP_ID"
        />
      ),
      name: 'workerId',
      panelFieldWidth: 12,
      divider: true,
      abbrName: t('FILTERABBR.WORKER_ID'),
    },
    {
      headerTitle: 'KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.ORDER_ID',
      component: (
        <LeoTextField
          name="readingOrderId"
          label={<Trans i18nKey="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.ORDER_ID" />}
        />
      ),
      name: 'readingOrderId',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.ORDERID'),
    },
  ];

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK),
    isFetching,
  });

  const panels = [filterConfig];

  const submittedSapOrdersColumns: ColumnProps<SubmittedSapOrdersModel>[] = [
    {
      key: 'sapSynced',
      header: <Trans i18nKey="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.SAP_SYNCED_TIME" />,
      accessor: (timeLogItem: SubmittedSapOrdersModel) =>
        formatDate(timeLogItem.sapSynced, DATE_TIME_FORMAT),
      field: 'sapSynced',
    },
    {
      key: 'jobId',
      header: <Trans i18nKey="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.JOB_ID" />,
      accessor: 'jobId',
    },
    {
      key: 'worker.sapId',
      header: <Trans i18nKey="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.SAP_ID" />,
      accessor: 'worker.sapId',
    },
    {
      key: 'jobCount',
      header: <Trans i18nKey="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.ORDER_QUANTITY" />,
      accessor: 'jobCount',
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
      panels={panels}
    >
      <Table
        filterDetailsData={{ useWorkersData }}
        keyField="jobId"
        panels={panels}
        setPanel={setPanel}
        activePanel={activePanel}
        timestamp={dataUpdatedAt}
        list={submittedSapOrdersData?.data || []}
        total={submittedSapOrdersData?.meta?.total}
        enableCheckbox={false}
        loading={isLoading}
        params={params}
        onSort={onSort}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        columns={submittedSapOrdersColumns}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        onLoadQuery={onLoadQuery}
      />
    </Page>
  );
};

export default SubmittedSapOrdersPage;

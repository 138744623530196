import { ListResponseModel, QueryParamModel, ReadingTimeModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { PerformanceMetricModel } from 'models/performanceMetric.model';
import { exportFile } from 'utils/exportFile';

class PerformanceMetricService extends EntityService {
  constructor() {
    super('performance-metric');
  }

  async getPerformanceMetricList(params?: QueryParamModel | null) {
    return request<ListResponseModel<PerformanceMetricModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async editPerformanceMetric(data: Partial<PerformanceMetricModel>[]) {
    return request<PerformanceMetricModel[]>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  private exportPerformaceMetric = async (params?: QueryParamModel | null) => {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });
    await exportFile(response);
    return response;
  };

  async getReadingTime() {
    return request<ReadingTimeModel>({
      method: Methods.GET,
      resource: `v1/${this.resource}/reading-time`,
    });
  }

  async editReadingTime(data: Partial<ReadingTimeModel>) {
    return request<ReadingTimeModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/reading-time`,
      data,
    });
  }

  exportPerformanceMetric = (params?: QueryParamModel | null) =>
    this.exportPerformaceMetric(params);
}

export const performanceMetricService = new PerformanceMetricService();

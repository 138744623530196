import { useQuery } from '@tanstack/react-query';

import { statisticsService } from 'services';
import { QueryContextFromKeys, QueryParamModel } from 'models';
import { parse } from 'utils/base64';
import { emptyResponse } from 'features/helpers';

type EstimationExclusionsQueryContexts = QueryContextFromKeys<typeof estimationExclusionsKeys>;

export const estimationExclusionsKeys = {
  all: [{ scope: 'estimationExclusions' }] as const,
  lists: () => [{ ...estimationExclusionsKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) =>
    [{ ...estimationExclusionsKeys.lists()[0], params }] as const,
};

const fetchEstimationExclusionList = async ({
  queryKey: [{ params }],
}: EstimationExclusionsQueryContexts['list']) => {
  const { createdAt: requiredParam } = parse(params?.filter);
  return requiredParam ? statisticsService.getEstimationExclusions(params) : emptyResponse;
};

const exportEstimationExclusionList = async ({
  queryKey: [{ params }],
}: EstimationExclusionsQueryContexts['list']) =>
  statisticsService.exportEstimationExclusions(params);

export const useEstimationExclusionList = (params?: QueryParamModel | null) => {
  return useQuery(estimationExclusionsKeys.list(params), fetchEstimationExclusionList, {
    enabled: !!params?.filter,
  });
};

export const useExportEstimationExclusionList = (params?: QueryParamModel | null) => {
  return useQuery(estimationExclusionsKeys.list(params), exportEstimationExclusionList, {
    enabled: false,
  });
};

import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Field, FieldProps, FormikProps } from 'formik';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';

import { DatePicker } from 'components/form/datepicker/Datepicker';

interface ILeoDatePicker {
  name?: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  label?: ReactNode;
  isYearAndMonthOnly?: boolean;
  formikProps?: React.MutableRefObject<FormikProps<any> | undefined>;
}

export const LeoDatePicker: React.FC<ILeoDatePicker> = ({
  name = 'date',
  minDate,
  maxDate,
  isYearAndMonthOnly,
  formikProps,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Field name={name} id={name}>
        {({ field, form, meta }: FieldProps) => {
          if (formikProps) {
            formikProps.current = form;
          }

          return (
            <DatePicker
              field={field}
              form={form}
              meta={meta}
              type="text"
              label={<Trans i18nKey="INPUT.SELECT_DATE" />}
              id={name}
              minDate={minDate}
              maxDate={maxDate}
              variant="outlined"
              isYearAndMonthOnly={isYearAndMonthOnly}
              {...props}
            />
          );
        }}
      </Field>
    </Box>
  );
};

export default LeoDatePicker;

import { FieldInputProps, FormikProps } from 'formik';
import get from 'lodash/get';

type FieldStatusFunction<R> = (
  form?: FormikProps<any> | null,
  field?: FieldInputProps<any> | null,
  fromSelect?: boolean
) => R;

export const isSubmitted: FieldStatusFunction<boolean> = (form) => {
  return Boolean(form && form.submitCount);
};

export const isTouched: FieldStatusFunction<boolean> = (form, field) => {
  return Boolean(form && field && get(form.touched, field.name)) || isSubmitted(form);
};

export const getErrorMessage: FieldStatusFunction<string> = (form, field) => {
  return form && field ? (get(form.errors, field.name) as string) || '' : '';
};

export const hasErrorMessage: FieldStatusFunction<boolean> = (form, field) => {
  return Boolean(getErrorMessage(form, field));
};

export const inErrorState: FieldStatusFunction<boolean> = (form, field, selectFieldHelper) => {
  const shouldShowErrorMessage = isTouched(form, field) || isSubmitted(form);

  if (selectFieldHelper) {
    return hasErrorMessage(form, field);
  }
  return shouldShowErrorMessage && hasErrorMessage(form, field);
};

export const setValue = <Values = any>(
  form?: FormikProps<Values> | null,
  field?: FieldInputProps<Values> | null,
  newValue?: Values | null,
  shouldValidate?: boolean
) => {
  if (form && field) {
    form.setFieldValue(field.name, newValue, shouldValidate);
  }
};

export const setTouched = <Values = any>(
  form?: FormikProps<Values> | null,
  field?: FieldInputProps<Values> | null,
  touched = true,
  shouldValidate = true
) => {
  if (form && field) {
    form?.setFieldTouched(field.name, touched, shouldValidate);
  }
};

import { ListResponseModel, ModificationsModel, QueryParamModel } from 'models';
import request, { Methods, requestFile } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { exportFile } from 'utils/exportFile';

class ModificationService extends EntityService {
  constructor() {
    super('modification');
  }

  async getModificationList(params?: QueryParamModel | null) {
    return request<ListResponseModel<ModificationsModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async exportModifications(params?: QueryParamModel | null) {
    const response = await requestFile<BlobPart>({
      method: Methods.GET,
      resource: `v1/${this.resource}/export`,
      params,
    });

    exportFile(response);
    return response;
  }
}

export const modificationService = new ModificationService();

import { ListResponseModel, PasswordQuery, QueryParamModel, UserModel } from 'models';
import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';

const mockUsers: UserModel[] = [
  { id: 1, name: 'Teszt 1', deletedAt: null, email: 'teszt1@teszt.com', roles: [] },
  { id: 2, name: 'Teszt 2', deletedAt: null, email: 'teszt2@teszt.com', roles: [] },
];

class UserService extends EntityService {
  constructor() {
    super('user');
  }

  async validateToken(data: PasswordQuery) {
    return request<any>({
      resource: `${this.resource}/validate-token`,
      params: {
        email: data.email,
        token: data.token,
        lang: data.lang,
      },
    });
  }

  async getUserListSearch(params?: QueryParamModel | null) {
    return request<UserModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}/search`,
      params,
    });
  }

  async getUserList(params?: QueryParamModel | null) {
    return request<ListResponseModel<UserModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
      params,
    });
  }

  async editUser(data: Partial<UserModel>[]) {
    return request<UserModel[]>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  async getMockUserList() {
    return mockUsers;
  }
}

export const userService = new UserService();

import React, { FC, createContext, ReactNode, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';

import { selectSelectedRecord, setSelectedRecord } from 'store/common';
import { QueryParamModel } from 'models';
import { OnSortFunction, LoadingOverlay } from 'components';
import { neutralVariant } from 'theme/colors';
import { SortDirection } from 'utils/arrays';
import { mergeSx } from 'utils/styles';
import { parse } from 'utils/base64';

import {
  TablePagination,
  OnPageChange,
  OnRowsPerPageChange,
  TablePaginationProps,
} from './TablePagination';

export interface TableContextValues {
  onSort?: OnSortFunction | null;
  orderBy?: string | null;
  order?: SortDirection | null;
}

export const TableContext = createContext<TableContextValues>({
  orderBy: null,
  order: null,
  onSort: null,
});

export interface BaseTableRootProps extends MuiTableProps, TableContextValues {
  /**
   * The query paramater for the table (page size, order, etc.).
   */
  params?: QueryParamModel;
  /**
   * Custom message if the table is empty.
   */
  emptyState?: ReactNode;
  /**
   * If set to ```true```, the loading spinner shows up.
   */
  loading?: boolean;
  /**
   * The total length of the list.
   */
  total?: number;
  /**
   * Server response timestamp (if you want to disable it, set it to false).
   */
  timestamp: number | undefined | false;
  /**
   * If using cache table, the date when the cache is last updated, omit or set to null if you don't want to show it
   */
  lastUpdated?: string | null;
  /**
   * If using cache table, the date when the cache will be updated, omit or set to null if you don't want to show it
   */
  nextUpdate?: string | null;
  /**
   * Callback fired when the page is changed.
   */
  onPageChange: OnPageChange;
  /**
   * Callback fired when the number of rows per page is changed.
   */
  onRowsPerPageChange?: OnRowsPerPageChange;
  /**
   * Props applied to the pagination element.
   */
  pagination?: TablePaginationProps;
}

export interface TableRootProps extends BaseTableRootProps {
  count?: number;
  disableStickyHeader?: boolean;
  colorCodes?: ReactNode;
  filterHeight?: number;
  setScrollPos?: React.Dispatch<React.SetStateAction<number | undefined>>;
  memoizedRecord?: boolean;
  disablePagination?: boolean;
}

export const TableRoot: FC<TableRootProps> = ({
  children,
  onSort,
  orderBy,
  order,
  loading,
  onPageChange,
  onRowsPerPageChange,
  total,
  params,
  pagination,
  timestamp,
  lastUpdated,
  nextUpdate,
  disableStickyHeader,
  colorCodes,
  filterHeight = 0,
  setScrollPos,
  memoizedRecord,
  disablePagination,
  ...props
}) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const tableRef = useRef<HTMLElement>();
  const dispatch = useDispatch();
  const lastSelectedRecord = useSelector(selectSelectedRecord<Record<string, any>>);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (memoizedRecord && !loading && lastSelectedRecord?.scrollToPosition !== undefined) {
      tableRef.current?.scrollTo(0, lastSelectedRecord?.scrollToPosition);
      dispatch(setSelectedRecord(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const styles = {
    root: {
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-moz-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-ms-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: neutralVariant[500],
      },
      '&::-moz-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: neutralVariant[500],
      },
      '&::-ms-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: neutralVariant[500],
      },
      maxHeight: windowHeight - filterHeight - 310,
      overflowX: 'auto',
      flex: 1,
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      position: 'relative',
      marginBottom: 2,
      flex: 1,
      borderRadius: 3,
    },
    paperLoading: {
      height: 40,
      overflowY: 'hidden',
      boxShadow: 'none',
    },
  };

  const { orderBy: orderByParam, order: orderParam } = params?.order
    ? parse(params?.order)[0]
    : { orderBy: null, order: null };

  const onClickHandler = (event: React.MouseEvent<HTMLElement>) =>
    setScrollPos && setScrollPos(event.currentTarget.scrollTop);

  return (
    <TableContext.Provider
      value={{
        onSort,
        orderBy: orderBy || orderByParam,
        order: order || orderParam,
      }}
    >
      <Paper square sx={mergeSx(styles.paper, loading ? styles.paperLoading : null)}>
        <LoadingOverlay show={loading} />
        {!loading ? (
          <>
            <Box sx={styles.root} onClick={onClickHandler} ref={tableRef}>
              <MuiTable {...props} stickyHeader={!disableStickyHeader}>
                {children}
              </MuiTable>
            </Box>
            <TablePagination
              {...(pagination || {})}
              count={total}
              page={params?.page}
              timestamp={timestamp}
              lastUpdated={lastUpdated}
              nextUpdate={nextUpdate}
              rowsPerPage={params?.pageSize}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              component="div"
              colorCodes={colorCodes}
              disablePagination={disablePagination}
            />
          </>
        ) : null}
      </Paper>
    </TableContext.Provider>
  );
};

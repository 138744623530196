import React, { FC, ReactNode } from 'react';
import { FieldProps } from 'formik';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiRadioGroup, { RadioGroupProps as MuiMuiRadioGroupProps } from '@mui/material/RadioGroup';

import { ErrorMessage, useTestId } from 'components';

interface RadioGroupProps extends MuiMuiRadioGroupProps, Partial<FieldProps> {
  /**
   * The label content.
   */
  label?: ReactNode;
  /**
   * If set to ```true```, an error message is going to be visible under the group, in case of an error.
   */
  showError?: boolean;
  /**
   * For testing purposes, you can set a testid for the radio group.
   */
  customTestId?: string;
}

export const RadioGroup: FC<RadioGroupProps> = ({
  label,
  form,
  field,
  name,
  value,
  onChange,
  onBlur,
  showError,
  customTestId,
  ...props
}) => {
  const fieldName = field?.name || name;
  const { testIdObject } = useTestId({ name: fieldName, testId: customTestId, componentName: 'RadioGroup' });
  return (
    <FormControl component="fieldset" {...testIdObject}>
      {label ? <FormLabel component="legend">{label}</FormLabel> : null}
      <MuiRadioGroup
        name={field?.name || name}
        value={field?.value || value || ''}
        onChange={field?.onChange || onChange}
        onBlur={field?.onBlur || onBlur}
        {...props}
      />
      {showError ? <ErrorMessage form={form} field={field} /> : null}
    </FormControl>
  );
};

RadioGroup.defaultProps = {
  showError: false,
};

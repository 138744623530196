import get from 'lodash/get';
import { indexOf, isArray, isBoolean, isNumber, isObject, isString } from 'lodash';
import { DateTime } from 'luxon';

import { defaultPageSize } from 'components/table/components/TablePagination';


export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum LowerCaseSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

function filter<P = any>(object: P, key: keyof P, term?: string | string[]) {
  const value = get(object, key) || '';

  if (isBoolean(term)) {
    return true;
  }

  // term from multiple select field
  if (isArray(term)) {
    // if value is object
    if (isObject(value)) {
      return listFilterObject(term, value);
    }
    // if value is string
    if (isString(value)) {
      return listFilterString(term, value);
    }
  }
  // if term is object
  if (isObject(term)) {
    if (key === 'plannedReadingDate') return true; // BE implementation..

    const itemKey: any = Object.keys(term)[0];

    return value[itemKey]?.toLowerCase().includes(term[itemKey]?.toLowerCase());
  }

  if ((key as string).includes('_from') || (key as string).includes('_to')) {
    return intervalFilter<P>(key, object, term as string); // Use for string interval filter. works on numeric / data.
  }

  const valueString: string = isNumber(value) ? value.toString() : value;

  // Use when value is string ( simple object - key value pair)
  return valueString?.toLowerCase().indexOf((term || '').toString()?.toLowerCase()) > -1;
}

function intervalFilter<P = any>(
  key: string | number | symbol,
  object: P,
  term: string | undefined
) {
  const rootKey = (key as string).substring(0, indexOf(key as string, '_'));
  const customValue = get(object, rootKey) || '';

  if (term && (key as string).toLocaleLowerCase().includes('date')) {
    const dateValue = DateTime.fromISO(customValue);
    const dateTerm = DateTime.fromISO(term);
    return (key as string).includes('_from') ? dateTerm <= dateValue : dateTerm >= dateValue;
  }

  const numValue = +customValue.replace(/\D/g, '');
  const numTerm = +(term?.replace(/\D/g, '') || '0');
  return (key as string).includes('_from') ? numTerm <= numValue : numTerm >= numValue;
}

function listFilterObject(term: string[] | undefined, value: object) {
  return term ? term.includes((value as any).name) : false;
}

function listFilterString(term: string[] | undefined, value: string) {
  return term ? term.includes(value as any) : false;
}

export function arrayFilter<P = any>(
  collection: P[],
  filterKeys: Array<keyof P>,
  term?: string | string[]
) {
  return collection.filter((item) =>
    filterKeys.reduce<boolean>((result, value) => {
      return result || filter(item, value, term);
    }, false)
  );
}

export function getArraySortComparer<P = any>(arr: P[], prop: keyof P, direction?: SortDirection) {
  const isAscending = !direction || direction === SortDirection.ASC;
  const sortModifier = isAscending ? 1 : -1;

  if (arr.length && get(arr[0], prop) && get(arr[0], prop).localeCompare) {
    return (a: P, b: P) => get(a, prop).localeCompare(get(b, prop)) * sortModifier;
  }

  return (a: P, b: P) => {
    if (a[prop] === b[prop]) {
      return 0;
    }

    return a[prop] > b[prop] ? 1 * sortModifier : -1 * sortModifier;
  };
}

export function arraySort<P extends Record<string, any> = any>(
  arr: P[],
  prop: keyof P,
  direction?: SortDirection
) {
  const comparerFunction = getArraySortComparer(arr, prop, direction);

  return [...arr].sort(comparerFunction);
}

export function getPage<P = any>(arr: P[], page = 1, pageSize = defaultPageSize) {
  return arr.slice((page - 1) * pageSize, page * pageSize);
}

export function removeDuplicates<P extends Record<string, any> = any>(array: P[], key: keyof P) {
  const lookup = new Set();
  return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
}

export function getDifferenceBy<P extends Record<string, any> = any>(
  array1: P[],
  array2: P[],
  key: keyof P
) {
  return array1.filter((a1) => !array2.find((a2) => a1[key] === a2[key]));
}

export function arrayify<T>(item?: T | T[] | null | undefined) {
  if (Array.isArray(item)) {
    return item;
  }

  if (item) {
    return [item];
  }

  return [];
}

export function removeEmpty<T>(items?: T[] | null) {
  const array = arrayify(items);

  return array.filter((item) => item) as NonNullable<T>[];
}

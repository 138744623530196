export enum MenuDisplayNames {
  HOME = 'HOME.TITLE',
  LEO = 'LEO.TITLE',
  LEO_LEO_EGYSEGEK = 'LEO_LEO_EGYSEGEK.TITLE',
  LEO_LEO_RENDELESEK = 'LEO_LEO_RENDELESEK.TITLE',
  LEO_LEO_EGYSEG_RESZLETEK = 'LEO_LEO_EGYSEG_RESZLETEK.TITLE',
  LEO_LEO_KOZPONT_ALAPERTEKEK = 'LEO_LEO_KOZPONT_ALAPERTEKEK.TITLE',
  LEO_STATISZTIKAK = 'LEO_STATISZTIKAK.TITLE',
  LEO_LEOLVASO_STATISZTIKA = 'LEO_LEOLVASO_STATISZTIKA.TITLE',
  LEO_NEM_LEOLVASHATO_RENDELESEK = 'LEO_NEM_LEOLVASHATO_RENDELESEK.TITLE',
  LEO_JELENTESEKET_TARTALMAZO_RENDELESEK = 'LEO_TOBBSZOROS_JELENTES.TITLE',
  LEO_BECSLESBOL_KIZARASOK = 'LEO_BECSLESBOL_KIZARAS.TITLE',
  LEO_MUNKAVEDELMI_JELZESEK = 'LEO_MUNKAVEDELMI_JELZESEK.TITLE',
  LEO_ADATPONTOSITASOK = 'LEO_ADATPONTOSITASOK.TITLE',
  LEO_MONITORING = 'LEO_MONITORING.TITLE',
  LEO_JELENTES_KODOK = 'LEO_JELENTES_KODOK.TITLE',
  LEO_JELENTES_KODOK_LEOLVASONKENT = 'LEO_JELENTES_KODOK_LEOLVASONKENT.TITLE',
  LEO_NEM_LEOLVASHATOSAGI_OKOK = 'LEO_NEM_LEOLVASHATOSAGI_OKOK.TITLE',
  LEO_LEOLVASOTTSAG = 'LEO_LEOLVASOTTSAG.TITLE',
  LEO_LOGOK = 'LEO_LOGOK.TITLE',
  LEO_KOMMUNIKACIOS_LOGOK = 'LEO_KOMMUNIKACIOS_LOGOK.TITLE',
  LEO_ERTESITO_JELZESEK = 'NOTIFY_LOGS.TITLE',
  LEO_FOTO_MEGHIUSULASOK_OKA = 'LEO_FOTO_MEGHIUSULASOK_OKA.TITLE',
  KOZOS_FUNKCIOK = 'KOZOS_FUNKCIOK.TITLE',
  KOZOS_MUNKAVEGZOK = 'KOZOS_MUNKAVEGZOK.TITLE',
  KOZOS_OPEM_FELHASZNALOK = 'KOZOS_OPEM_FELHASZNALOK.TITLE',
  KOZOS_H_S_JELZESEK = 'KOZOS_H_S_JELZESEK.TITLE',
  KOZOS_MUVELETI_NAPLO = 'KOZOS_MUVELETI_NAPLO.TITLE',
  KOZOS_TIME_LOG = 'KOZOS_MUNKAVEGZOK_NAPLOJA.TITLE',
  KOZOS_FELADAT_IMPORT = 'KOZOS_FELADAT_IMPORT.TITLE',
  KOZOS_SAP_FELTOLTES_MANUALIS = 'KOZOS_SAP_FELTOLTES_MANUALIS.TITLE',
  KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK = 'KOZOS_SAP_BEKULDOTT_LEO_RENDELESEK.TITLE',
  KOZOS_ERTEKKESZLET = 'KOZOS_ERTEKKESZLET.TITLE',
  KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE = 'KOZOS_MUNKASZUNETI_NAPOK_FELTOLTESE.TITLE',
  KOZOS_DOKUMENTUM_FELTOLTES = 'KOZOS_DOKUMENTUM_FELTOLTES.TITLE',
  KOZOS_TELJESITMENYBER = 'KOZOS_TELJESITMENYBER.TITLE',
  KOZOS_TELJESITMENYBEREK = 'KOZOS_TELJESITMENYBEREK.TITLE',
  KOZOS_BERELEMEK = 'KOZOS_BERELEMEK.TITLE',
  KOZOS_TARIFAK = 'KOZOS_TARIFAK.TITLE',
  KOZOS_TARIFAK_BERELEMEK = 'KOZOS_TARIFAK_BERELEMEK.TITLE',
  KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK = 'KOZOS_TARIFAK_NEM_LEOLVASHATOSAGI_OK.TITLE',
  KOZOS_TARIFAK_JELENTESEK = 'KOZOS_TARIFAK_JELENTESEK.TITLE',
  KOZOS_FELADAT_BETOLTES = 'KOZOS_FELADAT_BETOLTES.TITLE',
  KOZOS_FELADAT_BETOLTES_EXCELBOL = 'KOZOS_FELADAT_BETOLTES_EXCELBOL.TITLE',
  KOZOS_RENDELES_AZONOSITOK_CSEREJE = 'KOZOS_RENDELES_AZONOSITOK_CSEREJE.TITLE',
  UCSE = 'UCSE.TITLE',
  UCSE_RENDELESEK = 'UCSE_RENDELESEK.TITLE',
  UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE = 'UCSE_KORZETEK_LEOLVASOHOZ_RENDELESE.TITLE',
  UCSE_KORZETKIZARASOK = 'UCSE_KORZETKIZARASOK.TITLE',
  UCSE_RENDELKEZESRE_ALLAS = 'UCSE_RENDELKEZESRE_ALLAS.TITLE',
  UCSE_STATISZTIKAK = 'UCSE_STATISZTIKAK.TITLE',
  UCSE_IDOPONTFOGLALAS = 'UCSE_IDOPONTFOGLALAS.TITLE',
  UCSE_TELEPULES_LISTA_KAPACITAS = 'UCSE_TELEPULES_LISTA_KAPACITAS.TITLE',
  UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK = 'UCSE_HATARIDO_UTAN_TELJESULT_FELADATOK.TITLE',
  UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK = 'UCSE_HATARIDORE_NEM_TELJESULT_FELADATOK.TITLE',
  SULYSZAMOK = 'READINGTIME.TITLE',
}

import { EntityService } from 'utils/entityService';

import { ListResponseModel } from 'models';
import request, { Methods } from 'utils/request';
import { UserSettingsModel } from 'models/userSettings.model';
import { RouteNames } from 'config/routeNames';

class UserSettingsService extends EntityService {
  constructor() {
    super('user-settings');
  }

  async getUserSettingsList() {
    return request<UserSettingsModel[]>({
      method: Methods.GET,
      resource: `v1/${this.resource}`,
    });
  }

  async getUserSettingsById(id?: number | null) {
    return request<ListResponseModel<UserSettingsModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${id}`,
    });
  }

  async getUserSettingsByScope(scope?: RouteNames) {
    return request<ListResponseModel<UserSettingsModel>>({
      method: Methods.GET,
      resource: `v1/${this.resource}/${scope}`,
    });
  }

  async createUserSettings(data: Partial<UserSettingsModel>) {
    return request<UserSettingsModel>({
      method: Methods.POST,
      resource: `v1/${this.resource}`,
      data,
    });
  }

  async editUserSettings(id: ID, data: Partial<UserSettingsModel>) {
    return request<UserSettingsModel>({
      method: Methods.PATCH,
      resource: `v1/${this.resource}/${id}`,
      data,
    });
  }

  async deleteUserSettings(id: ID) {
    return request({
      method: Methods.DELETE,
      resource: `v1/${this.resource}/${id}`,
    });
  }
}

export const userSettingsService = new UserSettingsService();

import i18n from 'i18n';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { showNotification } from 'components';
import {
  NotificationType,
  QueryContextFromKeys,
  QueryParamModel,
  ReadingFailureReasonUnitPriceModel,
} from 'models';
import { readingFailureReasonUnitPriceService } from 'services';
import { isAxiosError } from 'utils/errors';
import { unitPriceCreateOnError, unitPriceEditOnError } from 'features/performanceMetricUnitPrice';

type ReadingFailureReasonUnitPriceQueryContexts = QueryContextFromKeys<
  typeof readingFailureReasonUnitPriceKeys
>;

enum Errors {
  DELETE = 'CAN_NOT_BE_DELETED_PERFORMANCE_BASED_PAY_ALREADY_EXISTS_USING_SPECIFIED_VALID_FROM_DATE',
  EDIT = 'PERFORMANCE_BASED_PAY_ALREADY_EXISTS_AFTER_SPECIFIED_VALID_FROM_DATE',
  PUT = 'CAN_NOT_BE_MODIFIED_PERFORMANCE_BASED_PAY_ALREADY_EXISTS_USING_SPECIFIED_VALID_FROM_DATE',
  DUPLICATION = 'READING_FAILURE_REASON_UNIT_PRICE_ALREADY_EXISTS_IN_SPECIFIED_MONTH',
}

const readingFailureReasonUnitPriceKeys = {
  all: [{ scope: 'reading-failure-reason-unit-price' }] as const,
  lists: () => [{ ...readingFailureReasonUnitPriceKeys.all[0], entity: 'list' }] as const,
  readingFailureReasonUnitPriceList: (params?: QueryParamModel | null) =>
    [{ ...readingFailureReasonUnitPriceKeys.lists()[0], params }] as const,
};

const fetchReadingFailureReasonUnitPriceList = async ({
  queryKey: [{ params }],
}: ReadingFailureReasonUnitPriceQueryContexts['readingFailureReasonUnitPriceList']) =>
  readingFailureReasonUnitPriceService.getReadingFailureReasonUnitPriceList(params);

export const useReadingFailureReasonUnitPriceList = (params: QueryParamModel | null = null) => {
  return useQuery(
    readingFailureReasonUnitPriceKeys.readingFailureReasonUnitPriceList(params),
    fetchReadingFailureReasonUnitPriceList,
    {
      enabled: !!params?.filter,
    }
  );
};

export const useEditReadingFailureReasonUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: { data: Partial<ReadingFailureReasonUnitPriceModel> }) =>
      readingFailureReasonUnitPriceService.editReadingFailureReasonUnitPrice(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingFailureReasonUnitPriceKeys.lists() });
      },
      onError: (e) => unitPriceEditOnError(e, Errors.DUPLICATION),
    }
  );
};

export const usePutReadingFailureReasonUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: ID; data: Partial<ReadingFailureReasonUnitPriceModel> }) =>
      readingFailureReasonUnitPriceService.putReadingFailureReasonUnitPrice(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingFailureReasonUnitPriceKeys.lists() });
      },
      onError: (e) => unitPriceCreateOnError(e, Errors.DUPLICATION),
    }
  );
};

export const useDeleteReadingFailureReasonUnitPrice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: ID) => readingFailureReasonUnitPriceService.deleteReadingFailureReasonUnitPrice(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: readingFailureReasonUnitPriceKeys.lists() });
      },
      onError: (e) => {
        if (
          isAxiosError(e) &&
          e.response?.status === 500 &&
          e.response.data.message === Errors.DELETE
        ) {
          showNotification({
            content: i18n.t(`ERRORS.${Errors.DELETE}`),
            type: NotificationType.ERROR,
          });
        }
      },
    }
  );
};

const exportReadingFailureReasonUnitPriceList = async ({
  queryKey: [{ params }],
}: ReadingFailureReasonUnitPriceQueryContexts['readingFailureReasonUnitPriceList']) =>
  readingFailureReasonUnitPriceService.exportReadingFailureReasonUnitPrice(params);

export const useExportReadingFailureReasonUnitPriceList = (params?: QueryParamModel | null) => {
  return useQuery(
    readingFailureReasonUnitPriceKeys.readingFailureReasonUnitPriceList(params),
    exportReadingFailureReasonUnitPriceList,
    {
      enabled: false,
    }
  );
};

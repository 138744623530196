import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { selectApiInfoState } from 'store/common';
import { Env } from 'config/env';

const styles = {
  version: {
    mx: 2,
    my: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
};

interface VersionDisplayProps {
  timestamp: number | undefined | false;
  lastUpdated?: string | null;
  nextUpdate?: string | null;
}

export const VersionsDisplay: FC<VersionDisplayProps> = ({
  timestamp,
  lastUpdated,
  nextUpdate,
}) => {
  const apiInfo = useSelector(selectApiInfoState);

  const responseTimestamp = timestamp ? new Date(timestamp).toLocaleString() : '-';
  const lastUpdatedTimestamp = lastUpdated ? new Date(lastUpdated).toLocaleString() : null;
  const nextUpdateTimestamp = nextUpdate ? new Date(nextUpdate).toLocaleString() : null;

  return timestamp === false ? null : (
    <Box sx={styles.version}>
      <Typography variant="cellHeaderSmall">
        <Trans i18nKey="COMMON.FRONTEND_VERSION" />: v{Env.REACT_APP_VERSION}
      </Typography>
      <Typography variant="cellHeaderSmall">
        <Trans i18nKey="COMMON.BACKEND_API_VERSION" />: v{apiInfo.version}
      </Typography>
      <Typography variant="cellHeaderSmall">
        <Trans i18nKey="COMMON.TIMESTAMP" />: {responseTimestamp}
      </Typography>
      {lastUpdated ? (
        <Typography variant="cellHeaderSmall">
          <Trans i18nKey="COMMON.DATA_LAST_UPDATED" />: {lastUpdatedTimestamp}
        </Typography>
      ) : null}
      {nextUpdate ? (
        <Typography variant="cellHeaderSmall">
          <Trans i18nKey="COMMON.DATA_NEXT_UPDATE" />: {nextUpdateTimestamp}
        </Typography>
      ) : null}
    </Box>
  );
};

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import Yup from 'utils/yup';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Card, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Button,
  ColumnProps,
  FileUpload,
  LeoSelectField,
  Page,
  Table,
  useFilterConfig,
  usePanelState,
  useTableQueryParams,
} from 'components';
import { FilterOptionModel, JobModel } from 'models';
import { useValueSetList } from 'features/valueSets';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import { getValueSetValues } from 'utils/valueSetFunctions';
import {
  useSwapOrderIdActiveTask,
  useDownloadSwapOrderIdResult,
  useJobStatus,
  useSwapOrderIdsUpload,
  useSwapOrderIdsList,
  useRetrySwapOrderIdUpload,
} from 'features/swapOrderIds';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import { DATE_TIME_FORMAT, formatDate } from 'utils/dates';

const styles = {
  uploadCard: {
    p: 2,
    borderRadius: '8px',
    '@media (min-width: 1600px)': {
      width: '35%',
    },
    '@media (max-width: 1599px)': {
      width: '45%',
    },
  },
  table: {
    '.MuiPaper-root': {
      '@media (min-height: 900px)': {
        maxHeight: '400px',
      },
      '@media (max-height: 899px)': {
        maxHeight: '265px',
      },
    },
  },
};

const SwapOrderIdsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onFilter,
    onLoadQuery,
    onSort,
    params,
    onTableColumnVisibilityChange,
    apiParams,
  } = useTableQueryParams();

  const { data: valueSet, isLoading: isValueSetLoading } = useValueSetList({
    code: ['BACKGROUND_JOB_STATUS'],
  });

  const { data: execStatus, accessor: backgroundJobStatusAccessor } = useFieldDataAndAccessor(
    getValueSetValues(valueSet, 'BACKGROUND_JOB_STATUS')
  );

  const {
    data: loadTaskListData,
    isLoading: isTableDataLoading,
    isFetching,
    dataUpdatedAt,
  } = useSwapOrderIdsList(apiParams);
  const {
    mutateAsync: upLoadTask,
    isLoading: isUploading,
    data: uploadData,
  } = useSwapOrderIdsUpload();
  const { mutateAsync: retryLoadTask, isLoading: isRetryLoading } = useRetrySwapOrderIdUpload();
  const { mutateAsync: downloadResult, isLoading: isDownloading } = useDownloadSwapOrderIdResult();
  const { data: activeTask } = useSwapOrderIdActiveTask();
  const { data: jobStatus } = useJobStatus(activeTask?.job?.id || uploadData?.jobId || '');
  const hasActiveJob = jobStatus?.status && jobStatus?.status !== 'completed';

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'KOZOS_RENDELES_AZONOSITOK_CSEREJE.STATUS',
      component: (
        <LeoSelectField
          name="status"
          data={execStatus}
          accessor={backgroundJobStatusAccessor}
          label="KOZOS_RENDELES_AZONOSITOK_CSEREJE.STATUS"
        />
      ),
      name: 'status',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.STATUS'),
      filterIsRequired: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    status: Yup.mixed().required(),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.KOZOS_RENDELES_AZONOSITOK_CSEREJE),
    validationSchema,
    isFetching,
    defaultFilter: { status: 'active' },
  });

  const columns: ColumnProps<JobModel>[] = [
    {
      key: 'id',
      header: <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.ID" />,
      accessor: 'id',
      sortable: false,
    },
    {
      key: 'data.originalFileName',
      header: <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.FILE_NAME" />,
      accessor: 'data.originalFileName',
      sortable: false,
    },
    {
      key: 'processedOn',
      header: <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.PROCESS_TIME" />,
      accessor: (item: JobModel) =>
        item.processedOn && formatDate(DateTime.fromMillis(item.processedOn), DATE_TIME_FORMAT),
      field: 'processedOn',
      sortable: false,
    },
    {
      key: 'finishedOn',
      header: <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.FINISH_TIME" />,
      accessor: (item: JobModel) =>
        item.finishedOn && formatDate(DateTime.fromMillis(item.finishedOn), DATE_TIME_FORMAT),
      field: 'finishedOn',
      sortable: false,
    },
    {
      key: 'status',
      header: <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.STATUS" />,
      accessor: (item: JobModel) =>
        item.status && getValueSetValues(valueSet, 'BACKGROUND_JOB_STATUS', item.status),
      field: 'status',
      sortable: false,
    },
  ];

  const panels = [filterConfig];
  const { activePanel, closePanel, setPanel } = usePanelState();

  const actions = [
    {
      name: 'retry',
      label: 'KOZOS_RENDELES_AZONOSITOK_CSEREJE.RETRY',
      icon: <ReplayIcon fontSize="small" />,
      disabledIf: isRetryLoading,
      onClick: (job: JobModel) => retryLoadTask(job.id),
    },
    {
      name: 'download',
      label: 'KOZOS_RENDELES_AZONOSITOK_CSEREJE.DOWNLOAD',
      icon: <DownloadIcon fontSize="small" />,
      disabledIf: (job: JobModel) => {
        return isDownloading || job.status === 'active' || job.status === 'failed';
      },
      onClick: (job: JobModel) => downloadResult(job.id),
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.TITLE" />}
      inScroll
      panels={panels}
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
    >
      <Card elevation={1} sx={styles.uploadCard}>
        {!hasActiveJob && (
          <Formik
            initialValues={{ file: null }}
            onSubmit={async (values, { resetForm }) => {
              await upLoadTask(values?.file?.[0]);
              resetForm();
            }}
          >
            {({ values, resetForm }) => (
              <Form>
                <Field
                  label={<Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.FILE_LABEL" />}
                  component={FileUpload}
                  multiple={false}
                  onDropRejected={resetForm}
                  name="file"
                  accept={{
                    'application/json': ['.xls', '.xlsx'],
                  }}
                />
                <Box mt={2}>
                  <Button type="submit" disabled={!values.file} loading={isUploading}>
                    <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.IMPORT_BUTTON" />
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        )}
        {hasActiveJob && jobStatus && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <Typography variant="body1">
                  <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.ACTIVE_JOB" />
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign={'right'}>
                <CircularProgress size={24} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.ID" />
                </Typography>
                <Typography variant="body2">{jobStatus.id}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.FILE_NAME" />
                </Typography>
                <Typography variant="body2">{jobStatus.data.originalFileName}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.PROCESSED_ROWS" />
                </Typography>
                <Typography variant="body2">{jobStatus.data.completedRows}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Trans i18nKey="KOZOS_RENDELES_AZONOSITOK_CSEREJE.FAILED_ROWS" />
                </Typography>
                <Typography variant="body2">{jobStatus.data.failedRows}</Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      <Box sx={styles.table}>
        {!isValueSetLoading && (
          <Table
            filterDetailsData={{ backgroundJobStatusAccessor }}
            setPanel={setPanel}
            activePanel={activePanel}
            list={loadTaskListData?.data || []}
            total={loadTaskListData?.meta.total}
            timestamp={dataUpdatedAt}
            loading={isTableDataLoading}
            params={params}
            onSort={onSort}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            enableCheckbox={false}
            columns={columns}
            onTableColumnVisibilityChange={onTableColumnVisibilityChange}
            disableDivider={true}
            onLoadQuery={onLoadQuery}
            actions={actions}
            panels={panels}
          />
        )}
      </Box>
    </Page>
  );
};

export default SwapOrderIdsPage;

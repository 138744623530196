export const primary = {
  50: '#FFFFFF',
  100: '#D6E3FF',
  200: '#A8C8FF',
  300: '#7CACFA',
  400: '#6091DD',
  500: '#4477C1',
  600: '#265EA7',
  700: '#13529A',
  800: '#003062',
  900: '#001B3D',
};

export const secondary = {
  50: '#FFFFFF',
  100: '#BBE2F4',
  200: '#ADDCF2',
  300: '#A0D7F0',
  400: '#92D1EE',
  500: '#85CBEC',
  600: '#67C2E7',
  700: '#039DC6',
  800: '#0081A4',
  900: '#004D63',
};

export const success = {
  50: '#FFFFFF',
  100: '#C3FFD0',
  200: '#99F6B4',
  300: '#62BD81',
  400: '#46A268',
  500: '#288750',
  600: '#006D3A',
  700: '#00522A',
  800: '#00391C',
  900: '#00210E',
};

export const error = {
  50: '#FFFFFF',
  100: '#FFDAD6',
  200: '#FFB4AB',
  300: '#FF897D',
  400: '#FF5449',
  500: '#DE3730',
  600: '#BA1A1A',
  700: '#93000A',
  800: '#690005',
  900: '#410002',
};

export const warning = {
  50: '#FFFFFF',
  100: '#FFDDB6',
  200: '#FFB959',
  300: '#E39D35',
  400: '#C5831A',
  500: '#A56B00',
  600: '#845400',
  700: '#643F00',
  800: '#462B00',
  900: '#2A1800',
};

export const neutral = {
  50: '#FFFFFF',
  100: '#F1F0F4',
  200: '#E3E2E6',
  300: '#C7C6CA',
  400: '#ABABAF',
  500: '#77777A',
  600: '#5E5E62',
  700: '#46474A',
  800: '#1A1B1E',
  900: '#000000',
};

export const neutralVariant = {
  50: '#FFFFFF',
  100: '#F0F3F5',
  200: '#E0E2EC',
  300: '#C4C6CF',
  400: '#A8ABB4',
  500: '#74777F',
  600: '#5B5E66',
  700: '#43474E',
  800: '#181C22',
  900: '#000000',
};

export const colorCodes = {
  green: '#C0E5CD',
  yellow: '#FFF0B7',
  red: '#FF7C74',
  selected: '#265EA714',
  reactCalendarBackground: '#f5f5f5',
  checkboxGreen: 'green',
  checkboxRed: 'red',
  footerBorder: '#ddd',
  commonButtonBorderColor: '#265ea77f',
};

import React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '@mui/material';
import { Field } from 'formik';

import { Checkbox } from 'components/form/checkbox/Checkbox';
import { TextField } from 'components/form/text-field/TextField';
import { neutral, neutralVariant } from 'theme/colors';

enum Quantity {
  PIECE = 'db',
}

const styles = {
  daySlotContainer: {
    border: `1px solid ${neutralVariant[200]}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingY: 0,
    marginY: 0,
  },
  pieceContainer: {
    paddingRight: 2,
    margin: 0,
  },
  inputProps: {
    color: `${neutral[600]}`,
    backgroundColor: `${neutralVariant[200]}`,
    borderRadius: '0 4px 4px 0',
    border: 'none',
    fontSize: '12px',
    height: '48px',
  },
  extraInputProps: {
    maxLength: 3,
  },
  inputLabelProps: {
    fontSize: '12px',
    backgroundColor: `${neutralVariant[200]}`,
  },
};

interface DayPartProps {
  name: string;
  label: React.ReactNode;
  setCheckBox?: React.Dispatch<React.SetStateAction<boolean[]>>;
  checkBoxIndex?: number;
}

const DayPart = ({ name, label, setCheckBox, checkBoxIndex }: DayPartProps) => {
  return (
    <Box sx={styles.daySlotContainer}>
      <Field
        sx={{ pl: 3 }}
        daySlot={true}
        component={Checkbox}
        checkBoxIndex={checkBoxIndex}
        setCheckBox={setCheckBox}
        name={name}
        label={label}
      />
      <Box>
        <Field
          sx={styles.pieceContainer}
          dayPart
          InputProps={{ style: styles.inputProps }}
          extraInputProps={{ ...styles.extraInputProps }}
          InputLabelProps={{ style: styles.inputLabelProps }}
          component={TextField}
          name={`${name}${Quantity.PIECE}`}
          label={<Trans i18nKey={`${Quantity.PIECE}`} />}
        />
      </Box>
    </Box>
  );
};

export default DayPart;

import React, { ChangeEvent, FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { t } from 'i18next';
import { Field, FieldArray, Form, Formik, FormikConfig, FormikProps } from 'formik';
import { isArray, isEmpty, isString, uniq } from 'lodash';
import { DateTime } from 'luxon';

import {
  Checkbox as MuiCheckbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  Box,
} from '@mui/material';

import { ReadingUnitOrderModel, ReadingUnitSummaryModel } from 'models';
import { BaseEditorDialogProps, Dialog, Tooltip } from 'components';
import LeoDatePicker from 'components/input-fields/leoDatePicker/LeoDatePicker';
import DialogFooterButtons from 'components/dialog/DialogFooterButtons';
import LeoSelectField from 'components/input-fields/leoSelectField/LeoSelectField';
import { useFieldDataAndAccessor } from 'utils/useFieldDataAndAccessor';
import Yup from 'utils/yup';
import {
  useSaveTransferConsumptionPlace,
  useSaveTransferTask,
  useReadingUnitReadingFailureReasons,
} from 'features/readingUnits';
import { useWorkerListSearch } from 'features/workers';
import { Env } from 'config/env';

export interface TransferTaskFormProps {
  workerId: number | string | null;
  downloadabilityDate: Date | string | undefined | null;
  readingFailureReasons?: string[] | number[] | null;
  consumptionPlaceIds?: number[] | null;
  transferAllReadingFailureReason?: boolean;
}

const transferAllReadingFailureReason = true;

export enum TransferTaskType {
  TRANSFER_TASK,
  TRANSFER_CONSUMPTION_PLACE,
}

export interface TransferTaskDialogProps
  extends BaseEditorDialogProps<
    ReadingUnitSummaryModel | ReadingUnitOrderModel[] | ReadingUnitSummaryModel[]
  > {
  type: TransferTaskType;
  defaultDownloadDate?: Date | null;
}

const TransferTaskDialog: FC<TransferTaskDialogProps> = ({
  selected,
  open,
  onClose,
  type,
  defaultDownloadDate,
  ...props
}) => {
  const formikRef = useRef<FormikProps<TransferTaskFormProps> | null>(null);
  const isTransferTask = type === TransferTaskType.TRANSFER_TASK;
  const { readingUnitId } = useParams();

  const selectedArray = isArray(selected) ? selected : [selected];

  const idsArray = (selectedArray as ReadingUnitSummaryModel[])?.reduce<number[]>(
    (acc: number[], obj: ReadingUnitSummaryModel) => {
      if (obj?.id) {
        acc.push(obj.id);
      }
      return acc;
    },
    []
  );
  const { mutateAsync: saveTransferTask, isLoading: isSaveTaskLoading } = useSaveTransferTask();
  const { mutateAsync: saveTransferConsumptionPlace, isLoading: isSaveConsumptionPlaceLoading } =
    useSaveTransferConsumptionPlace();

  const { data: notReadableCauses, isLoading: isNotReadableCausesLoading } =
    useReadingUnitReadingFailureReasons(idsArray);

  const { data: workers } = useWorkerListSearch();
  const { data: workerData, accessor: workerAccessor } = useFieldDataAndAccessor(
    workers,
    'id',
    'name',
    true,
    'sapId'
  );

  const isSaveLoading = isSaveTaskLoading || isSaveConsumptionPlaceLoading;

  const initialValues: FormikConfig<TransferTaskFormProps>['initialValues'] = {
    readingFailureReasons: isTransferTask ? [] : null,
    downloadabilityDate:
      defaultDownloadDate && defaultDownloadDate < new Date() ? new Date() : defaultDownloadDate,
    workerId: null,
    consumptionPlaceIds: isTransferTask ? null : [],
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validationSchema: FormikConfig<TransferTaskFormProps>['validationSchema'] =
    Yup.object().shape({
      workerId: Yup.string().nullable().required(),
      downloadabilityDate: Yup.date()
        .nullable()
        .required()
        .min(today, t('DATE.MIN_DATE_NOT_ALLOWED')),
      readingFailureReasons: Yup.array().nullable(),
    });

  const handleSubmit = async () => {
    if (selected && formikRef.current?.values && !isSaveLoading) {
      const { values } = formikRef.current;

      const data = {
        workerId: values.workerId,
        downloadabilityDate: isString(values.downloadabilityDate)
          ? values.downloadabilityDate
          : (values.downloadabilityDate as Date).toISOString(),
      };

      switch (type) {
        case TransferTaskType.TRANSFER_TASK:
          await saveTransferTask({
            selected: [
              ...new Set((selectedArray as ReadingUnitSummaryModel[]).map((item) => item.id)),
            ],
            data: {
              ...data,
              ...(!isEmpty(values.readingFailureReasons) && {
                readingFailureReasons: values.readingFailureReasons,
              }),
              transferAllReadingFailureReason,
            },
          });
          break;

        case TransferTaskType.TRANSFER_CONSUMPTION_PLACE:
          await saveTransferConsumptionPlace({
            selected: uniq(
              (selectedArray as ReadingUnitOrderModel[]).map((item) => item.consumptionPlaceId)
            ),
            data,
            readingUnitId: readingUnitId || '',
          });
          break;

        default:
          break;
      }
    }

    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, values }) => {
        const failureReasons = isNotReadableCausesLoading ? (
          <Grid item xs={12} textAlign="center">
            <CircularProgress />
          </Grid>
        ) : (
          <FieldArray
            name="readingFailureReasons"
            render={(arrayHelpers) => (
              <>
                {notReadableCauses?.map(({ id, key, value }) => {
                  return (
                    <Grid item key={key} xs={4}>
                      <Tooltip
                        title={value}
                        enterDelay={Env.TOOLTIP_DELAY_SHORT}
                        enterNextDelay={Env.TOOLTIP_DELAY_SHORT}
                      >
                        {transferAllReadingFailureReason ? (
                          <Box mb={1}>
                            <Typography variant="body2">{key}</Typography>
                          </Box>
                        ) : (
                          <FormControlLabel
                            label={key}
                            control={
                              <Field
                                component={MuiCheckbox}
                                name="readingFailureReasons"
                                value={id}
                                checked={(values.readingFailureReasons as number[])?.includes(id)}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(id);
                                  } else {
                                    const idx = Number(
                                      (values.readingFailureReasons as number[])?.indexOf(id)
                                    );
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                              />
                            }
                          />
                        )}
                      </Tooltip>
                    </Grid>
                  );
                })}
              </>
            )}
          />
        );

        return (
          <Dialog
            title={
              <Trans
                i18nKey={
                  isTransferTask
                    ? 'LEO_LEO_EGYSEGEK.TRANSFER_TASK_MODAL_TITLE'
                    : 'LEO_LEO_EGYSEGEK.TRANSFER_CONSUMPTION_PLACE_MODAL_TITLE'
                }
              />
            }
            component={Form}
            open={open}
            onClose={handleClose}
            actions={
              <DialogFooterButtons
                cancelClick={handleClose}
                submitDisabled={!isValid || !dirty}
                submitText="LEO_LEO_EGYSEGEK.TRANSFER_TASK_BUTTON"
                isLoading={isSaveLoading}
              />
            }
            {...props}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LeoSelectField
                  name="workerId"
                  data={workerData}
                  accessor={workerAccessor}
                  label="LEO_LEO_EGYSEGEK.NEW_USER_CODE"
                />
              </Grid>
              <Grid item xs={6}>
                <LeoDatePicker
                  name="downloadabilityDate"
                  label={<Trans i18nKey="LEO_LEO_EGYSEGEK.NEW_DOWNLOAD_DATE" />}
                  minDate={DateTime.now()}
                />
              </Grid>
            </Grid>
            <Grid container>
              {selectedArray.some(
                (item) => (item as ReadingUnitSummaryModel)?.isMultipleWorkers
              ) ? (
                <>
                  <Divider sx={{ width: '100%' }} />
                  <Grid item xs={12} my={2}>
                    <Typography variant="body2">
                      <Trans i18nKey="LEO_LEO_EGYSEGEK.SPLIT_READING_UNIT_TRANSFER_INFO" />
                    </Typography>
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid container>
              {isTransferTask ? (
                <>
                  {!!notReadableCauses?.length && (
                    <>
                      <Divider sx={{ width: '100%' }} />
                      <Grid item xs={12} my={2}>
                        <Typography variant="body2">
                          <Trans i18nKey="LEO_LEO_EGYSEGEK.NOT_READABLE_CAUSES_INFO" />
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {failureReasons}
                </>
              ) : null}
            </Grid>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default TransferTaskDialog;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Page, Table, useFilterConfig, usePanelState, useTableQueryParams } from 'components';
import { EstimationDateChangeStatisticsModel, FilterOptionModel } from 'models';
import Yup from 'utils/yup';
import { formatDate } from 'utils/dates';
import { getPageName } from 'utils/format';
import { RouteNames } from 'config/routeNames';
import {
  useEstimationExclusionList,
  useExportEstimationExclusionList,
} from 'features/estimationExclusions';
import LeoTextField from 'components/input-fields/leoTextField/LeoTextField';
import LeoDateInterval, {
  relativeDateTestFunction,
} from 'components/input-fields/leoDateInterval/LeoDateInterval';

import { ReactComponent as Export } from 'assets/icons/Material/fileDownload.svg';

const EstimationExclusionsPage: FC = () => {
  const {
    onPageChange,
    onRowsPerPageChange,
    onSort,
    onFilter,
    onLoadQuery,
    params,
    apiParams,
    exportParams,
    onTableColumnVisibilityChange,
  } = useTableQueryParams();

  const {
    data: estimationExclusionsList,
    isLoading,
    isFetching,
    dataUpdatedAt,
  } = useEstimationExclusionList(apiParams);

  const { isInitialLoading, isRefetching, refetch } =
    useExportEstimationExclusionList(exportParams);

  const headerActions = [
    {
      name: 'export',
      label: 'COMMON.EXPORT',
      icon: <Export fill="currentColor" />,
      onClick: () => refetch(),
      isLoading: isInitialLoading || isRefetching,
      disabledIf: !exportParams?.filter,
    },
  ];

  const filters: FilterOptionModel[] = [
    {
      headerTitle: 'LEO_BECSLESBOL_KIZARAS.CREATED_AT',
      component: <LeoDateInterval name="createdAt" />,
      name: 'createdAt',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.READING_RESULT_DATE'),
      filterIsRequired: true,
    },
    {
      headerTitle: 'LEO_BECSLESBOL_KIZARAS.NEW_ESTIMATION_DATE',
      component: <LeoDateInterval name="newEstimationDate" />,
      name: 'newEstimationDate',
      panelFieldWidth: 12,
      panelFieldOffset: 12,
      divider: true,
      abbrName: t('FILTERABBR.NEW_ESTIMATION_DATE'),
    },
    {
      headerTitle: 'LEO_LEOLVASO_STATISZTIKA.READING_UNIT',
      component: (
        <LeoTextField
          name="readingUnitCode"
          label={<Trans i18nKey="LEO_BECSLESBOL_KIZARAS.READING_UNIT_CODE" />}
        />
      ),
      name: 'readingUnitCode',
      panelFieldWidth: 12,
      abbrName: t('FILTERABBR.READING_UNIT'),
    },
  ];

  const { activePanel, closePanel, setPanel } = usePanelState();

  const validationSchema = Yup.object().shape({
    createdAt: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .required()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    newEstimationDate: Yup.object()
      .nullable()
      .shape({
        from: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
        to: Yup.mixed()
          .nullable()
          .test('isDate validation', t('ERRORS.INVALID_DATE'), relativeDateTestFunction),
      }),
    badInterval: Yup.bool().oneOf([true], 'bad interval'),
  });

  const filterConfig = useFilterConfig({
    params,
    onFilter,
    filters,
    onLoad: onLoadQuery,
    isButton: true,
    pageName: getPageName(RouteNames.LEO_BECSLESBOL_KIZARASOK),
    validationSchema,
    isFetching,
  });

  const panels = [filterConfig];

  const estimationExclustionColumns = [
    {
      key: 'bundle',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.BUNDLE" />,
      accessor: 'bundle',
      sortable: true,
    },
    {
      key: 'readingUnitCode',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.READING_UNIT_CODE" />,
      accessor: 'readingUnitCode',
      sortable: true,
    },
    {
      key: 'plannedEstimationDate',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.PLANNED_ESTIMATION_DATE" />,
      accessor: (item: EstimationDateChangeStatisticsModel) =>
        formatDate(item.plannedEstimationDate),
      field: 'plannedEstimationDate',
      sortable: true,
    },
    {
      key: 'oldEstimationDate',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.OLD_ESTIMATION_DATE" />,
      accessor: (item: EstimationDateChangeStatisticsModel) => formatDate(item.oldEstimationDate),
      field: 'oldEstimationDate',
      sortable: true,
    },
    {
      key: 'newEstimationDate',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.NEW_ESTIMATION_DATE" />,
      accessor: (item: EstimationDateChangeStatisticsModel) => formatDate(item.newEstimationDate),
      field: 'newEstimationDate',
      sortable: true,
    },
    {
      key: 'createdAt',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.CREATED_AT" />,
      accessor: (item: EstimationDateChangeStatisticsModel) => formatDate(item.createdAt),
      field: 'createdAt',
      sortable: true,
    },
    {
      key: 'comment',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.COMMENT" />,
      accessor: 'comment',
      sortable: true,
    },
    {
      key: 'userName',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.USER" />,
      accessor: 'userName',
      sortable: true,
    },
    {
      key: 'orderCount',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.ORDER_COUNT" />,
      accessor: 'orderCount',
      sortable: true,
    },
    {
      key: 'orderReadCount',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.ORDER_READ_COUNT" />,
      accessor: 'orderReadCount',
      sortable: true,
    },
    {
      key: 'orderDismantledCount',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.ORDER_DISMANTLED_COUNT" />,
      accessor: 'orderDismantledCount',
      sortable: true,
    },
    {
      key: 'orderUnreadableCount',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.ORDER_UNREADABLE_COUNT" />,
      accessor: 'orderUnreadableCount',
      sortable: true,
    },
    {
      key: 'orderUnhandledCount',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.ORDER_UNHANDLED_COUNT" />,
      accessor: 'orderUnhandledCount',
      sortable: true,
    },
    {
      key: 'orderReadAfterPlannedCount',
      header: <Trans i18nKey="LEO_BECSLESBOL_KIZARAS.ORDER_READ_AFTER_PLANNED_COUNT" />,
      accessor: 'orderReadAfterPlannedCount',
      sortable: true,
    },
  ];

  return (
    <Page
      title={<Trans i18nKey="LEO_BECSLESBOL_KIZARAS.TITLE" />}
      inScroll
      activePanel={activePanel}
      closePanel={closePanel}
      setPanel={setPanel}
      panels={panels}
    >
      <Table
        panels={panels}
        setPanel={setPanel}
        activePanel={activePanel}
        list={estimationExclusionsList?.data || []}
        total={estimationExclusionsList?.meta.total}
        timestamp={dataUpdatedAt}
        loading={isLoading}
        enableCheckbox={false}
        params={params}
        onSort={onSort}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        columns={estimationExclustionColumns}
        pageHeaderActions={headerActions}
        onTableColumnVisibilityChange={onTableColumnVisibilityChange}
        onLoadQuery={onLoadQuery}
      />
    </Page>
  );
};

export default EstimationExclusionsPage;

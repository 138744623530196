import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { sapUploadService } from 'services';
import { emptyResponse } from 'features/helpers';
import { parse } from 'utils/base64';

type SapUploadQueryContexts = QueryContextFromKeys<typeof sapUploadKeys>;

export const sapUploadKeys = {
  all: [{ scope: 'sapUpload' }] as const,
  lists: () => [{ ...sapUploadKeys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...sapUploadKeys.lists()[0], params }] as const,
};

const fetchJobList = async ({ queryKey: [{ params }] }: SapUploadQueryContexts['list']) => {
  const { status: requiredParam } = parse(params?.filter);

  return requiredParam ? sapUploadService.getSapJobList(params) : emptyResponse;
};

export const useJobList = (params?: QueryParamModel | null) =>
  useQuery(sapUploadKeys.list(params), fetchJobList, {
    enabled: !!params?.filter,
  });

export const useSapUpload = () => {
  const queryClient = useQueryClient();

  return useMutation(() => sapUploadService.triggerSapUpload(), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: sapUploadKeys.lists() });
    },
  });
};

const fetchSapStatisticsList = async ({
  queryKey: [{ params }],
}: SapUploadQueryContexts['list']) => {
  return sapUploadService.getSapStatisticList(params);
};

export const useSapStatisticsPageList = (params?: QueryParamModel | null) => {
  return useQuery(sapUploadKeys.list(params), fetchSapStatisticsList, {
    enabled: !!params?.filter,
  });
};
